
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import TextBase from './TextBase'

@Component({
  name: 'TableCellText'
})
class TableCellText extends Mixins(TableCellBase, TextBase) {}
export default TableCellText
