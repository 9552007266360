<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellRate from './TableCellRate.vue'
import FormRate from './Rate.vue'

@Component({ name: 'LwRate', components: { TableCell: TableCellRate, FormItem: FormRate } })
class LwRate extends FormControlBase {}
export default LwRate
</script>
