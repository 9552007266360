


















































































































































































































































































































































/* eslint-disable camelcase */
import { Component, Mixins, Watch } from 'vue-property-decorator'
import QueriableByFilterComponent from '@/components/layout/QueriableByFilterComponent'
import dragColumnWidth from '@/components/layout/basic/drag-column-width'
import { LayoutComponent } from '@/types/layout'
import QuickEditForTable from '@/components/layout/inner/QuickEditForTable.vue'
import FiltersForTable from '@/components/layout/inner/FiltersForTable.vue'
import { addResizeListener, calculateHeight, closeAllErrorMessages, isHidden, removeResizeListener } from '@/utils/dom'
import _, { cloneDeep, difference, intersection, isFunction, keyBy, omit, pick, toNumber } from 'lodash'
import {
  ColumnGroup, initTableExtraStruct,
  ISetting,
  ITableCheckboxConfig, ITableCommonArgs,
  ITableRadioConfig,
  ITableRowArgs,
  ITableRowConfig,
  ITableVirtualScrollConfig,
  RenderColumns,
  SelectionMode,
  SortType,
  TableOperation,
  TableOperationControl,
  tableOperationControls,
  tableOperationMap
} from '@/types/table'
import Args from '@/models/Args'
import {
  attributeSymbol,
  attributeType,
  layoutStatus,
  rowPersist,
  SemanticColorMainList,
  SemanticColorSchemes
} from '@/utils/const'
import { checkValueIsEmpty, formatDataRow, getEventName, isObservable } from '@/utils/data'
import {
  attribute2Boolean, attribute2Number,
  attribute2Strings,
  getFinalAttributeValue,
  getI18nContent, getPlainAttributeValue,
  setLayoutForOutsideComponent
} from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import ColumnWidth from '@/components/layout/basic/table-v2/ColumnWidth'
import { ColumnInfo, Colgroup, Column } from 'vxe-table'
import { getUserSettings, saveUserSettings } from '@/utils/common'
import { DataRow as IDataRow, DataSet } from '@/types/data'
import { LayoutModule } from '@/store/modules/layout'
import logwire from '@/logwire'
import { doDelete } from '@/http/api'
import VxeTable from '@/3rd-party-extension/Table/src/VxeTable.vue'
import MoreOpsForTable from '@/components/layout/inner/MoreOpsForTable.vue'
import { Instance, createPopper } from '@popperjs/core'
import TableBase from '@/components/layout/basic/table-v2/TableBase'
import eventbus from '@/utils/event'
import ExpandRowWrapper from '@/components/layout/inner/ExpandRowWrapper.vue'
import { AxiosResponse } from 'axios'
import TableSelectAllTip from '@/components/layout/inner/TableSelectAllTip.vue'
import FieldsDisplay from '@/components/layout/basic/advanceFilter/fieldsDisplay.vue'
import FieldsSort from '@/components/layout/basic/advanceFilter/fieldsSort.vue'
import TableCell from '@/components/layout/basic/table-v2/TableCell.vue'
import Vue from 'vue'
import debounce from 'xe-utils/debounce'
import { Bind, Debounce } from 'lodash-decorators'

const sortIconMap = {
  [SortType.None]: 'morenpaixu',
  [SortType.Asc]: 'shengxu',
  [SortType.Desc]: 'jiangxu'
}

@Component({
  name: 'lw-table',
  components: {
    'more-ops-for-table': MoreOpsForTable,
    'table-select-all-tip': TableSelectAllTip,
    'expand-row-wrapper': ExpandRowWrapper,
    'quick-edit-for-table': QuickEditForTable,
    'filters-for-table': FiltersForTable,
    'fields-display': FieldsDisplay,
    'fields-sort': FieldsSort,
    'table-cell': TableCell
  }
})
class LwTable extends Mixins(TableBase, dragColumnWidth, ColumnWidth) {
  declare component: LayoutComponent & {
    fields: Record<string, any>[] // fields 节点内的各个组件结构
  }

  $refs!: {
    table: VxeTable
    tableOperations: MoreOpsForTable
    saveForQuickEdit: HTMLElement
    quickEdit: QuickEditForTable
    quickEditWrapper: HTMLElement
  }

  popperInstance!: Instance // 更多操作popper实例

  rowConfig!: ITableRowConfig
  radioConfig!: ITableRadioConfig
  checkboxConfig!: ITableCheckboxConfig
  virtualScrollConfig!: ITableVirtualScrollConfig
  tableHeight = 200
  defaultColumnWidth = 180
  minColumnWidth = 60
  opsColumnWidth = 60

  renderColumns: RenderColumns = []
  _renderColumnOnce!: boolean
  radioCheckedRow!: IDataRow
  iterateRowsMap = {} // 遍历过的行 map { rowKey1: true, rowKey2: true,,,,}
  disabledRowsKeys: any[] = [] // 禁止选择的行 [rowKey1, rowKey2,,,,]
  rowSpanFields: any[] = []

  operationControl: Partial<Record<TableOperationControl, boolean>> = {}

  settingCopy = {}
  setting: ISetting = {
    user_id: LocalModule.user.id,
    table_control: this.layoutName + '.' + this.dataSetName,
    display_fields_enabled: false,
    all_fields: [],
    display_fields: [],
    display_fields_json: null,
    sort_enabled: false,
    sort: [],
    sort_json: null,
    fields_width_json: null,
    changed_fields_width: [],
    page_size: null,
    default_advanced_filter_id: null
  }

  fieldsVisibilityDialogVisible = false
  fieldsSortDialogVisible = false
  editPopperVisible = false // 快速编辑的弹出框是否显示

  cacheFieldOptions = {}

  handleResize: null | (() => void) = null

  lastClickedRow = {}
  backupDataSetRows!: any[]

  hasFixedLeftColumn = false

  scrollAsynced = false

  /** ************* 表格属性相关 ************* **/

  get maxHeight () {
    let result = 2000
    if (this.component.maxHeight) {
      result = parseInt(this.component.maxHeight.replace('px', ''))
    }
    return result
  }

  get minHeight () {
    let result = 150
    if (this.component.minHeight) {
      result = parseInt(this.component.minHeight.replace('px', ''))
    }
    return result
  }

  get virtualScrollYConfig () {
    return this.getFinalAttributeValue('verticalVirtualScrollEnabled', { type: attributeType.BOOLEAN }) === false
      ? { enabled: false }
      : this.virtualScrollConfig
  }

  get showIndex () {
    return checkValueIsEmpty(this.component.showIndex) ? true : attribute2Boolean(this.component.showIndex)
  }

  get widthCacheable () {
    return attribute2Boolean(this.component.widthCacheable) !== false
  }

  // 判断是否使用小蓝点标识
  get useTableSetting () {
    const { display_fields_enabled, sort_enabled } = this.setting
    return display_fields_enabled || sort_enabled
  }

  get selectionMode () {
    return this.component.selectionMode || 'multiple'
  }

  get maxUnfoldedOperations () {
    return this.component.maxUnfoldedOperations
      ? this.getFinalAttributeValue('maxUnfoldedOperations', { type: attributeType.NUMBER })
      : 2
  }

  /**
     * 将表格的按钮转换为 { type, visible }[] 的形式
     */
  get defaultOpColumns () {
    const columns = []
    const pickKeys = [TableOperationControl.Viewable].concat(this.popupLayoutStatus !== layoutStatus.VIEW ? [TableOperationControl.Editable, TableOperationControl.Cloneable, TableOperationControl.Deletable] : [])
    const controls = intersection(tableOperationControls, pickKeys)
    for (const control of Object.values(controls)) {
      control && columns.push({ operation: tableOperationMap.get(control), type: control, visible: this.operationControl[control] })
    }
    return columns
  }

  get visibleOps () {
    return this.defaultOpColumns.filter(o => o.visible === true)
  }

  get showOpColumns () {
    const usedOpCount = this.visibleOps.length
    const operations = this.component.operations || []
    return !!(usedOpCount || operations.length)
  }

  get visibleOpColumns () {
    return this.visibleOps.slice(0, this.maxUnfoldedOperations)
  }

  get moreOpColumns () {
    return this.visibleOps.slice(this.maxUnfoldedOperations)
  }

  get operationsDisabled () {
    let result = false
    const status = this.context.getLayoutStatus()
    // 在抽屉或者弹出层处于 view 状态或者，或者页面状态不是 view 或 eidt 或 HEADER_DETAIL_EDIT 或 HEADER_DETAIL_NEW 的时候，table 的新增按钮置为 diasbled
    if (this.popupLayoutStatus === layoutStatus.VIEW || (status && ![layoutStatus.VIEW, layoutStatus.EDIT, layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(status))) {
      result = true
    }
    if (this.component.headerDataSet && ![layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status)) {
      return true
    }
    return result
  }

  get selectAllEnabled () {
    return this.component.selectAllEnabled
      ? this.getFinalAttributeValue('selectAllEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  get crossPageSelectAllEnabled () {
    return this.component.crossPageSelectAllEnabled
      ? this.getFinalAttributeValue('crossPageSelectAllEnabled', { type: attributeType.BOOLEAN })
      : true
  }

  @Watch('parentRow.subDataSets', { deep: true })
  onParentRowsSubDataSetsChange (val?: DataSet) {
    if (val) {
      const _rows = val?.[this.component.subDataSet]?.rows
      if (_rows) {
        // 对于虚拟滚动来说，row中必须要有一个 rowKey字段
        _rows.forEach((row: IDataRow, index: number) => { row.rowKey = `${this.component.subDataSet}${index}` })
      }
      const data = _rows || []
      this.tableData = data
      this.$refs.table.loadData(data)
    }
  }

  @Watch('editRows')
  onEditRowsChange (rows: any[]) {
    const editingDataSet = LayoutModule.data[this.encodedLayoutName]?.editingDataSet
    const currentDataSet = `${this.encodedLayoutName}.${this.dataSetName}`
    if (editingDataSet !== currentDataSet && rows.length > 0) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: this.dataSetName })
    } else if (editingDataSet === currentDataSet && rows.length === 0) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
    }
    this.changeOpsColumnWidth()
  }

  @Watch('tableData')
  onTableDataChange () {
    this.changeOpsColumnWidth()
  }

  @Watch('dataSet.rows')
  onDataSetRowsChange (val: any[]) {
    if (!val) return
    if (this.backupDataSetRows) {
      const isAnyOneRowChange = val.length > 0
        ? val.some((o, index) => o !== this.backupDataSetRows[index])
        : true
      if (isAnyOneRowChange) {
        this.backupDataSetRows = val.slice()
        this.loadTableData()
      }
    } else {
      this.backupDataSetRows = val.slice()
      this.loadTableData()
    }
    if (this.component.height === 'auto') {
      this.calculateTableHeight()
    }
  }

  /*
* 通过addRow api添加的行、单元格弹出小窗口的编辑 状态都变更为 expressEdit
* */
  @Watch('status')
  onStatusChange (val: string) {
    // if (!this.enabled) return
    const saveForQuickEdit = this.$refs.saveForQuickEdit
    if (val === layoutStatus.VIEW) {
      saveForQuickEdit.style.display = 'none'
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.VIEW })
      eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'visible'), true)
    } else if (val === layoutStatus.EXPRESS_EDIT) {
      saveForQuickEdit.style.display = 'flex'
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EXPRESS_EDIT })
      eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'visible'), false)
    }
  }

  initTableConfig () {
    const rowConfig = {
      isHover: true,
      keyField: 'rowKey'
    }
    const radioConfig = {}
    const checkboxConfig = {
      // 这里绑定 isSelected 字段的原因是为了提升否选的性能,否则 默认勾选 全部数据的时候,性能很差
      checkField: 'isSelected'
    }
    const virtualScrollConfig = { gt: 0, oSize: 5 }
    Object.assign(this, { rowConfig, radioConfig, checkboxConfig, virtualScrollConfig })
  }

  handleRowClassName ({ row }: ITableRowArgs) {
    let rowClassName = 'lw-table-tr'
    // 最后点击行添加左边框提示
    if (this.lastClickedRow === row) rowClassName = `${rowClassName} table-tr-style--last-clicked`
    const { rowColorScheme } = this.component
    const colorScheme = rowColorScheme
      ? this.getFinalAttributeValue('rowColorScheme', { args: new Args(this.context, { row }) })
      : ''
    if (colorScheme && SemanticColorMainList.includes(colorScheme)) {
      rowClassName = `${rowClassName} table-tr-style--${colorScheme}`
    }

    const [_rowEnabled, _rowSelectable] = ['rowEnabled', 'rowSelectable'].map(p => {
      return this.component[p]
        ? this.getFinalAttributeValue(p, { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
        : true
    })

    Object.assign(row, { _rowEnabled, _rowSelectable })

    return rowClassName
  }

  checkDefaultButtonExistOutside (type: TableOperation) {
    return this.visibleOpColumns.find(o => o.operation === type) !== undefined
  }

  // 数据变更后，根据每行的数据改变操作列宽度
  changeOpsColumnWidth (rowOpsColumnWidthList?: number[]) {
    const defaultOps = [TableOperation.View, TableOperation.Edit, TableOperation.Clone, TableOperation.Delete]
    const initWidth = defaultOps.reduce((acc, cur) => acc + this.calculateDefaultOpWidth(cur), 0)
    const defaultOpsColumnWidth = 60
    const moreOpsWidth = rowOpsColumnWidthList || this.getAllRowsOpsColumnWidth()
    // 检查一下是否还有更多菜单
    const opsWidth = Math.max(...moreOpsWidth) > 0 ? [defaultOpsColumnWidth].concat(moreOpsWidth) : [0]
    const maxOpsWidth = Math.max(...opsWidth)
    this.opsColumnWidth = Math.max(maxOpsWidth + initWidth, defaultOpsColumnWidth)
  }

  getAllRowsOpsColumnWidth () {
    const rows = this.dataSet.rows
    if (!rows.length) return []
    return rows.map((row) => this.calculateRowOpColumnsWidth(row))
  }

  calculateRowOpColumnsWidth (row: IDataRow) {
    const more = this.isShowMoreOperationBtn({ row })
    const defaultFontString = '400 14px'
    const defaultPadding = 34
    row.__table__!.showMoreOperation = more
    let opWidth = more ? (this.getTextWidth('...', defaultFontString) + defaultPadding) : 0
    const generateOpColumns = this.generateOpColumnsNotHidden(row)
    generateOpColumns.forEach((op) => {
      const isButtonGroup = op.is === 'lw-button-group'
      let text = getFinalAttributeValue('title', op, new Args(this.context, { row }), this.associatedContext)
      // groupClickable 为 true 时，需要获取 group 下的第一个元素的 title 作为菜单标题
      if (isButtonGroup) {
        const isGroupClickable = getFinalAttributeValue('groupClickable', op, new Args(this.context, { row }), this.associatedContext)
        if (isGroupClickable) {
          text = getFinalAttributeValue('title', op.components[0], new Args(this.context, { row }), this.associatedContext)
        }
      }
      // 菜单组还有下拉按钮的宽度需要算进去，下拉按钮的宽度约为 30px
      const width = this.getTextWidth(text, defaultFontString) + defaultPadding + (isButtonGroup ? 30 : 0)
      opWidth += width
    })
    return opWidth
  }

  calculateDefaultOpWidth (type: TableOperation) {
    return this.checkDefaultButtonExistOutside(type)
      ? this.getTextWidth(logwire.ui.getI18nMessage(`client.common.${type}`, 'core'), '400 14px') + 34
      : 0
  }

  // 是否在操作列展示 ‘...’ 更多操作按钮
  isShowMoreOperationBtn ({ row }: { row: IDataRow }) {
    // 操作列按钮的数量
    let buttonCount = this.visibleOpColumns.length
    const moreButtonCount = this.moreOpColumns.length
    if (moreButtonCount > 0) {
      return true
    } else {
      const ops: LayoutComponent[] = this.component.operations || []
      for (const op of ops) {
        const visible = op.visible
          ? getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
          : true
        visible && buttonCount++
        // 循环过程中如果发现则 return 趁早结束循环
        if (buttonCount > this.maxUnfoldedOperations) return true
      }
      return false
    }
  }

  getTitleI18nContent (key: string) {
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // getI18nContent 内部会拿环境变量来识别 layout，如果不自己手动设置的话，可能因为环境变量指向其他页面而无法查出内容
    return getI18nContent(key)
  }

  calculateTableHeight () {
    if (this.component.subDataSet || isHidden(this.$el as HTMLElement)) return
    const tr: HTMLElement | null = document.querySelector('.vxe-body--row .lw-table-tr')
    const header: HTMLElement | null = document.querySelector('.vxe-header--row')
    const defaultHeaderHeight = 40
    const defaultRowHeight = 40 // 现在填充的 TableCell 都是 40px 固定高
    const rowsObj = {
      rowsLength: this.dataSet.rows.length || 0,
      rowHeight: tr ? tr.offsetHeight : defaultRowHeight,
      headerHeight: header ? header.offsetHeight : defaultHeaderHeight
    }
    const height = calculateHeight(
      this.component.height,
      this.$el as HTMLElement,
      this.minHeight,
      this.maxHeight,
      rowsObj
    )
    this.tableHeight = toNumber(height)
    // 以下代码是为了解决在列表存在固定列时（包括操作按钮），表格已经发生滚动时，在页面发小发生变化时，固定列内容不显示的问题
    // #2479
    const fixedTable = this.$el.querySelector('.vxe-table--fixed-wrapper')?.children.length
    const scrollStatus = this.$refs.table.getScroll()
    if (this.scrollAsynced) {
      if (!fixedTable) {
        this.scrollAsynced = false
      }
    } else {
      if (fixedTable && scrollStatus.scrollTop) {
        // 纵向滚动 1px，经过测试是性能影响最小的方式
        this.$refs.table.scrollTo(scrollStatus.scrollLeft, scrollStatus.scrollTop + 1)
        this.scrollAsynced = true
      }
    }
  }

  prepareTableHeight () {
    if (this.component.height && !(this.component.height.endsWith('%') || this.component.height === 'auto')) {
      const height = this.component.height.replace('px', '') * 1
      this.tableHeight = Math.min(this.maxHeight, Math.max(height, this.minHeight))
    } else {
      this.handleResize = () => {
        this.calculateTableHeight()
      }
      addResizeListener(this.$el, this.handleResize)
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    }
  }

  getSortIcon (sortMode: SelectionMode) {
    return 'icon-' + sortIconMap[sortMode]
  }

  getRenderColumns (): RenderColumns {
    let columns = cloneDeep(this.component.fields) as ColumnInfo[]
    const groupList = []
    if (this.setting.display_fields_enabled) {
      columns = columns.filter(c => c.display).sort((a, b) => a.index - b.index)
    }
    for (const c of columns) {
      const originalTitle = c.title
      c.title = getI18nContent(c.title)
      c.sortable = getFinalAttributeValue('sortable', c, new Args(this.context), this.associatedContext)
      c.sort = 'none'

      if (c.fixed === 'left') this.hasFixedLeftColumn = true

      // FIXME 这里获取 dimension 不是很稳妥，
      if (['lw-choice', 'lw-radio'].includes(c.is) && c.dimension) {
        LocalModule.getOrCreateDimensionChoices(c.dimension)
      }

      if (!this.enabled) {
        c.enabled = false
      }

      this.calculateColumnWidth(c)
      if (c.group) {
        const group = groupList.find(g => g.type === 'groupColumn' && g.title === c.group) as ColumnGroup
        if (group) {
          group.children.push(c)
        } else {
          groupList.push({
            type: 'groupColumn',
            title: c.group,
            children: [c]
          })
        }
      } else {
        groupList.push({
          type: 'column',
          column: c
        })
      }
      // 将 title 属性还原回去，然后在 template 渲染中使用方式渲染，这样可以在 vuex 改变时引起页面的实时变化
      if (originalTitle.startsWith('{i18n}')) {
        c.title = originalTitle
      }
    }
    return groupList as RenderColumns
  }

  /** ********* 表格操作相关  ********* **/
  showFieldsVisibilitySettingDialog () {
    this.settingCopy = cloneDeep(this.setting)
    this.fieldsVisibilityDialogVisible = true
  }

  showFieldsSortSettingDialog () {
    this.settingCopy = cloneDeep(this.setting)
    this.fieldsSortDialogVisible = true
  }

  closeDisplayDialog () {
    this.fieldsVisibilityDialogVisible = false
  }

  closeSortDialog () {
    this.fieldsSortDialogVisible = false
  }

  saveDisplayDialog (res: AxiosResponse<{ data: { display_fields_json: string, display_fields_enabled: boolean } }>) {
    this.fieldsVisibilityDialogVisible = false
    const { display_fields_json: displayFieldsJson, display_fields_enabled: displayFieldsEnabled } = res.data.data
    if (displayFieldsJson) {
      this.setting.display_fields_json = displayFieldsJson
    }
    this.setting.display_fields_enabled = displayFieldsEnabled
    this.setCurrentDisplay()
    // 修改表格中列的隐藏显示和列的显示顺序
    const columns = this.component.fields
    this.handleComponentDisplayFields(columns)
    const renderColumns = this.getRenderColumns()
    this.loadTableColumns(renderColumns)
  }

  saveSortDialog (res: AxiosResponse<{ data: { sort_json: string, sort_enabled: boolean } }>) {
    // 使用Retrieve而不是页面刷新
    const { sort_json: sortJson, sort_enabled: sortEnabled } = res.data.data
    if (sortJson) {
      this.setting.sort_json = sortJson
    }
    this.setting.sort_enabled = sortEnabled
    this.setCurrentSort()
    // 修改表头的排序图标状态，全部置为排序图标，取消表头的排序
    this.renderColumns.forEach((item) => {
      if (item.type === 'column') {
        item.column.sort = 'none'
      }
    })
    this.handleDataSetRetrieve()
    this.fieldsSortDialogVisible = false
  }

  hideMoreOps () {
    this.popperInstance && this.popperInstance.destroy()
  }

  showTableOpts ($event: MouseEvent, row: any) {
    this.targetRow = row
    const target = $event.target as HTMLElement
    this.$refs.tableOperations.currentRow = row

    const attrMap = {
      [TableOperation.View]: 'showView',
      [TableOperation.Edit]: 'showEdit',
      [TableOperation.Clone]: 'showClone',
      [TableOperation.Delete]: 'showDelete'
    }

    Object.entries(attrMap).forEach(([op, attr]) => {
      const isOpEnabled = this.visibleOps.findIndex(opc => opc.operation === op) > -1
      const isOpVisible = this.visibleOpColumns.findIndex(opc => opc.operation === op) > -1
      this.$refs.tableOperations[attr] = isOpEnabled && !isOpVisible
    })

    const moreOpts = this.generateOpColumnsHidden(row)
    this.$refs.tableOperations.moreOpts = moreOpts

    this.$nextTick(() => {
      const popperConfig = {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
          { name: 'offset', options: { offset: [0, 10] } },
          { name: 'computeStyles', options: { adaptive: false } }
        ]
      } as any
      this.popperInstance = createPopper(target, this.$refs.tableOperations.$el as HTMLElement, popperConfig)
    })
  }

  // 直接在列表上展示的操作
  generateOpColumnsNotHidden (row: IDataRow) {
    // 自定义的操作按钮在 popupLayoutStatus 为 view 的状态下不进行渲染
    if (this.popupLayoutStatus === layoutStatus.VIEW) return []
    const usedOpCount = this.visibleOpColumns.length
    const ops = this.component.operations || []
    const opColumns = []
    const allOpColumns = []
    for (let i = 0; opColumns.length < this.maxUnfoldedOperations - usedOpCount && i < ops.length; i++) {
      const op = ops[i]
      if (!op) continue
	    const visible = op.visible
        ? getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
		    : true
      allOpColumns.push(op)
	    visible && opColumns.push(op)
    }
    row.__table__!.allOperation = allOpColumns
    return opColumns
  }

  operationVisible (op: any, row: IDataRow) {
    return op.visible
      ? getFinalAttributeValue('visible', op, new Args(this.context, { row: row }), this.associatedContext, [attributeType.BOOLEAN])
      : true
  }

  // 在列表更多按钮中展示的自定义操作
  generateOpColumnsHidden (row: IDataRow) {
    // 自定义的操作按钮在 popupLayoutStatus 为 view 的状态下不进行渲染
    if (this.popupLayoutStatus === layoutStatus.VIEW) return []
    const usedOpCount = this.visibleOpColumns.length
    const ops = this.component.operations || []
    let opColumns = []
    for (const op of ops) {
      const visible = op.visible
        ? getFinalAttributeValue('visible', op, new Args(this.context, { row }), this.associatedContext, [attributeType.BOOLEAN])
        : true
      visible && opColumns.push(op)
    }
    opColumns = opColumns.slice(this.maxUnfoldedOperations - usedOpCount)
    return opColumns
  }

  handleOperation ($event: MouseEvent, type: TableOperation, row: any) {
    if (this.operationsDisabled && type !== 'view') return

    const handlerMap = new Map([
      [TableOperation.View, () => this.handleTableRowView(row)],
      [TableOperation.Edit, () => this.handleTableRowEdit(layoutStatus.EDIT, row)],
      [TableOperation.Clone, () => this.handleTableRowClone(row)],
      [TableOperation.Delete, () => this.handleTableRowDelete({ row })],
      [TableOperation.More, () => this.showTableOpts($event, row)]
    ])

    const handler = handlerMap.get(type)

    if (isFunction(handler)) handler()
  }

  handleColumSort (column: ColumnInfo) {
    const sortTypes = [SortType.Asc, SortType.Desc, SortType.None]
    const currentIndex = sortTypes.indexOf(column.sort)
    let nextIndex = currentIndex + 1
    nextIndex = nextIndex === sortTypes.length ? 0 : nextIndex
    const nextSort = sortTypes[nextIndex]
    column.sort = nextSort
    this.sortColumn(column, nextSort)
  }

  sortColumn (column: ColumnInfo, order: string) {
    if (order === SortType.None) order = ''
    let orderBy = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].orderBy || []
    orderBy = orderBy.filter(item => item.source === 'header')
    // 从当前排序中找到即将要加入的排序字段，先删除，r如果order不为空，再添加到最后
    const index = orderBy.findIndex(item => item.field === column.field)
    index !== -1 && orderBy.splice(index, 1)
    order && orderBy.unshift({ field: column.field, order, source: 'header' })
    LayoutModule.loadDataSetOrderBy({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      orderBy
    })
    this.handleDataSetRetrieve()
  }

  handleColumnWidth () {
    const rows = this.makeColumnWidthFitContent(this.dataSet.rows, this.component.fields)
    if (!rows.length) return
    const columns = this.getRenderColumns()
    this.loadTableColumns(columns)
    this.saveColumnsWidth(columns)
  }

  saveColumnsWidth (renderColumns: RenderColumns) {
    if (this.widthCacheable === false) return
    const recordColumns = this.formatColumnsWidth(renderColumns)
    const widthJson = JSON.stringify(recordColumns)
    const widthMap = keyBy(recordColumns, (f) => f.name)
    this.component.fields.forEach(item => {
      if (item.is === 'lw-image' && item.width) {
        item.imageRealWidth = item.width.toString().replace('px', '')
      }
      item.width = widthMap[item.field]?.width
    })
    this.setting.fields_width_json = widthJson
    saveUserSettings(this.layoutName, this.dataSetName, { fields_width_json: widthJson }, undefined, undefined, this.anonymousAccessible, true)
  }

  // 格式化列宽记录
  formatColumnsWidth (renderColumns: RenderColumns) {
    const recordColumns: { name: string, width: number }[] = []
    renderColumns.forEach(item => {
      if (item.type === 'groupColumn') {
        item.children.forEach(i => {
          recordColumns.push({
            name: i.field,
            width: i.width
          })
        })
      } else {
        recordColumns.push({
          name: item.column.field,
          width: item.column.width as number
        })
      }
    })
    return recordColumns
  }

  handleTableSetting (command: 'display' | 'sort' | 'width') {
    const map = {
      display: this.showFieldsVisibilitySettingDialog,
      sort: this.showFieldsSortSettingDialog,
      width: this.handleColumnWidth
    }
    const func = map[command]
    isFunction(func) && func()
  }

  handleRowSelected (row: IDataRow) {
    // 根据 rowSelected 属性,判断出来该行数据是否勾选
    if (this.component.rowSelected && this.selectionMode !== SelectionMode.None) {
      const isSelected = this.getFinalAttributeValue('rowSelected', { args: new Args(this.context, { row }), type: attributeType.BOOLEAN })
      if (isSelected) {
        if (this.selectionMode === 'radio') {
          // 单选模式下，确保只有一行的 isSelected 被设置为了 true
          // 原则，后一行覆盖前一行
          this.radioCheckedRow && this.$set(this.radioCheckedRow, 'isSelected', false)
          this.$set(row, 'isSelected', true)
          this.radioCheckedRow = row
        } else {
          this.$set(row, 'isSelected', true)
        }
      }
    }
  }

  // 更新 store 中的选中行数据
  updateSelection (selection?: any[]) {
    if (!selection) {
      const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
      selection = rows.filter(row => row.isSelected)
    }
    LayoutModule.setTableSelection({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      selection
    })
  }

  handleCellMouseenter ({ row, column, $event }: ITableCommonArgs) {
    // 快速编辑框显示时，说明已经进入快速编辑状态，此时则不应该再更改编辑的行和列
    if (this.editPopperVisible) return
    this.editColumn = column!.params
    this.targetRow = row
    this.targetCellDom = $event.target as HTMLElement
  }

  handleRowMouseenter ({ row }: ITableCommonArgs) {
    this.component.onRowMouseOver && this.runRunnableContent('onRowMouseOver', { args: new Args(this.context, { row }) })
  }

  handleRowMouseleave ({ row }: ITableCommonArgs) {
    if (this.component.onRowMouseOut) {
      this.runRunnableContent('onRowMouseOut', { args: new Args(this.context, { row }) })
    } else if (this.component.onRowMouseLeave) {
      this.runRunnableContent('onRowMouseLeave', { args: new Args(this.context, { row }) })
    }
  }

  // 使用 cellClick 来达到 onRowClick 的目的
  handleRowClick ({ event, row }: ITableCommonArgs) {
    // 点击行添加样式
    this.lastClickedRow = row
    this.component.onRowClick && this.runRunnableContent('onRowClick', { args: new Args(this.context, { row }) })
  }

  handleToggleRowExpand ({ expanded, row }: ITableCommonArgs) {
    if (expanded && !row._hasExpanded) {
      row._hasExpanded = true
      this.component.onRowFirstTimeExpand && this.runRunnableContent('onRowFirstTimeExpand', { args: new Args(this.context, { row }) })
    }
  }

  // table 中渲染的行数据发生变化以后
  handleRenderTableDataChange ({ rows }: { rows: any[] }) {
    const { table } = this.$refs
    // this.getSpanArray(rows)
    const oldMergeCells = table.getMergeCells()
    if (oldMergeCells && oldMergeCells.length) {
      table.removeMergeCells(oldMergeCells)
        .then(() => {
          this.getSpanArray(rows)
        })
    } else {
      this.getSpanArray(rows)
    }
  }

  handleRadioChange ({ row }: { row: LwTable['tableData'][number] }) {
    const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
    rows.forEach(r => this.$set(r, 'isSelected', false))
    this.$set(row, 'isSelected', true)
    // 执行脚本
    this.doRowSelectChange({ row })
    // 更新 store 中勾选行的数据
    this.updateSelection([row])
  }

  handleCheckboxChange ({ checked, row }: { row: LwTable['tableData'][number], checked: boolean }) {
    // 拿到当前是否是跨页全选
    // 如果是跨页全选，那么 当前如果是 取消勾选状态，应该取消跨页全选标志
    const isSelectAll = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selectAll
    if (isSelectAll && !checked) {
      LayoutModule.setTableSelectAll({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        selectAll: false
      })
    }
    // 筛选出当前全部的勾选行，更新 store 中的数据
    this.updateSelection()
    // 执行脚本
    this.doRowSelectChange({ row })
    // 更新快速编辑框中数据
    this.notifyQuickEdit()
  }

  // 全选的时候依次执行 行勾选脚本
  handleCheckAll ({ checked }: { checked: boolean }) {
    const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
    // 全不选
    if (!checked) {
      LayoutModule.setTableSelectAll({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        selectAll: false
      })
    }
    // 更新 stroe 勾选行数据,同时逐行执行 onRowSelectChange 脚本
    const selection = rows.filter(row => {
      this.doRowSelectChange({ row })
      return row.isSelected
    })
    this.updateSelection(selection)
    // 更新快速编辑框中数据
    this.notifyQuickEdit()
  }

  // 拖动列宽后获取拖动后的列宽
  afterDragColumn (data: any) {
    const dragField = data.column.params.field
    const resizeWidth = data.resizeWidth
    const columns = this.renderColumns
    let curColumn: ColumnInfo | undefined
    columns.forEach(item => {
      if (item.type === 'column' && item.column.field === dragField) {
        curColumn = item.column
      } else if (item.type === 'groupColumn') {
        item.children.forEach(child => {
          if (child.field === dragField) {
            curColumn = child
          }
        })
      }
    })
    if (!curColumn || curColumn.width === resizeWidth) return
    curColumn.width = resizeWidth
    this.saveColumnsWidth(columns)
  }

  // 实现单列自适应列宽, 注意这个函数参数会经过 vxe-patch 文件的处理
  makeCurColumnWidthFitContent ({ column, dblCallback }: { column: ColumnInfo, dblCallback: (resizeWidth?: number) => void }) {
    const dragField = column.params.field
    const columns = this.renderColumns
    let curColumn: Partial<ColumnInfo> | undefined
    columns.forEach(item => {
      if (item.type === 'column' && item.column.field === dragField) {
        curColumn = item.column
      } else if (item.type === 'groupColumn') {
        item.children.forEach(child => {
          if (child.field === dragField) {
            curColumn = child
          }
        })
      }
    })
    const _rows = this.dataSet.rows
    const rows = _rows.filter(row => {
      return row.rowPersist !== rowPersist.DELETE
    })
    if (!rows || !rows.length || !curColumn) return
    // 这里删除在逻辑上是需要这么操作，这样才可以在 calculate 的时候计算新的值，但是从类型上不应该这样做
    delete curColumn.width
    const columnArr = [curColumn] as ColumnInfo[]
    rows.map((row) => {
      this.generateColumns(row, columnArr)
    })

    this.calculateColumnWidth(curColumn as ColumnInfo)

    // 还要改善 renderColumn中的计算内容
    dblCallback(curColumn.width)
    this.saveColumnsWidth(columns)
  }

  // 执行项目开发者所定义的 doRowSelectChange 脚本
  doRowSelectChange ({ row }: { row: LwTable['tableData'][number] }) {
    this.component.onRowSelectChange && this.runRunnableContent('onRowSelectChange', { args: new Args(this.context, { row }) })
  }

  findIndexInRenderColumns (field: string) {
    let findIndex = -1
    let columnIndex = 0
    this.renderColumns.forEach((f, i) => {
      // 二级表头
      if (f.type === 'groupColumn') {
        const findIndexGroupChild = f.children.findIndex(ff => ff.field === field)
        if (findIndexGroupChild !== -1) {
          findIndex = columnIndex + findIndexGroupChild
        }
        columnIndex += f.children.length
      } else {
        if (f.column.field === field) findIndex = columnIndex
        columnIndex++
      }
    })
    return findIndex
  }

  getSpanArray (rows: any[]) {
    let rowSpanFields = checkValueIsEmpty(this.component.rowSpanFields) ? [] : attribute2Strings(this.component.rowSpanFields)
    if (rowSpanFields.length === 0) return
    // 虚拟滚动此时第一行数据的实际索引
    const startRowIndex = rows[0]?.___index ? rows[0]?.___index - 1 : 0
    // 根据 xml 中配置的 fields 顺序进行排序
    rowSpanFields.sort((a, b) => {
      const aIndex = this.findIndexInRenderColumns(a)
      const bIndex = this.findIndexInRenderColumns(b)
      return aIndex - bIndex
    })
    // 过滤掉不在页面渲染的列中的字段
    rowSpanFields = rowSpanFields.filter(f => this.findIndexInRenderColumns(f) !== -1)
    this.rowSpanFields = rowSpanFields
    const mergeCells: {
      [k in string]: ({
        row: any
        col: number
        rowspan: number,
        colspan: number
      } | null)[]
    } = {}
    let startIndex = 0
    if (this.showIndex) startIndex++
    if (this.selectionMode !== 'none') startIndex++
    if (this.component.rowExpansion && this.component.rowExpansion.length) startIndex++
    rowSpanFields.forEach((field, fIdx) => {
      // 计算出当前字段在表格的第几列
      const findIndex = this.findIndexInRenderColumns(field)
      //  .findIndex(f => f.field === field)
      if (findIndex !== -1) {
        const colIndex = findIndex + startIndex
        mergeCells[field] = []
        let pos = 0
        for (let i = 0; i < rows.length; i++) {
          if (i === 0) {
            mergeCells[field].push({
              row: i + startRowIndex,
              col: colIndex,
              rowspan: 1,
              colspan: 1
            })
          } else {
            const curColEquals = rows[i].currentData[field] === rows[i - 1].currentData[field] // 与当前列比较
            const preColEquals = fIdx && (!mergeCells[this.rowSpanFields[fIdx - 1]][i] || mergeCells[this.rowSpanFields[fIdx - 1]][i]?.rowspan === 1) // 与前一个列比较
            if ((fIdx === 0 && curColEquals) || (fIdx !== 0 && curColEquals && preColEquals)) {
              const obj = mergeCells[field][pos]
              obj && obj.rowspan++
              mergeCells[field].push(null)
            } else {
              pos = i
              mergeCells[field].push({
                row: i + startRowIndex,
                col: colIndex,
                rowspan: 1,
                colspan: 1
              })
            }
          }
        }
      }
    })
    let result: any[] = []
    for (const f in mergeCells) {
      const arr = mergeCells[f].filter(m => m && m.rowspan > 1)
      result = [...result, ...arr]
    }

    this.$refs.table.setMergeCells(result)
  }

  loadTableColumns (columnGroup: RenderColumns) {
    this.renderColumns = []
    // 20230621 即使只有group内只有一个字段，也要用多级表头来显示
    // 修改列宽时，由于嵌套层级太深，及时使用forceUpdate也不能刷新table，所以采用先置空再赋值的方式，如果不这样，会发现表格没有渲染正确的宽度
    this.$nextTick(() => {
      this.renderColumns = columnGroup
    })
  }

  loadTableData () {
    const { fields: columns } = this.component
    // 不被作为透明细相关的表格时
    const isNormalTable = ![layoutStatus.EXPRESS_EDIT, layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status) && !this.component.headerDataSet

    if (isNormalTable) {
      this.iterateRowsMap = {}
      this.disabledRowsKeys = []
    }

    const tableData: any[] = []
    const timestamp = new Date().getTime()
    const { rows } = this.dataSet
    const opsColumnWidthList = []
    for (const index in rows) {
      const row = rows[index]

      // 初始化 table 要用的行特殊结构
      if (!row.__table__) {
        row.__table__ = Vue.observable(cloneDeep(initTableExtraStruct))
      } else if (row.__table__ && !isObservable(row.__table__)) {
        // 假如存在 row.__table 但是并不是一个可观察对象，说明在 dataSet 的行数据在 table 加载之前就已经被修改过。
        row.__table__ = Vue.observable(cloneDeep({ ...row.__table__, ...initTableExtraStruct }))
      }

      if (row.rowPersist === rowPersist.DELETE) continue
      if (isNormalTable || (!isNormalTable && !row.rowKey)) {
        row.rowKey = `${timestamp}${index}` // rowKey每行都要不一样，即使是翻页了，也不要和之前的相同
        row.___index = +index + 1 // 用于行中展示序号的字段
        this.$set(row, 'isSelected', row.isSelected || false) // 当前行是否勾选
      }
      opsColumnWidthList.push(this.calculateRowOpColumnsWidth(row))
      this.handleRowSelected(row)
      this.generateColumns(row, columns)
      tableData.push(row)
    }

    const noWidthColumns = columns.filter(item => !item.width)
    if (rows?.length && noWidthColumns.length && !this._renderColumnOnce) {
      // 计算列宽后重新渲染table列
      const groupList = this.getRenderColumns()
      this.loadTableColumns(groupList)
      this._renderColumnOnce = true // 在加载过程中，可能两次触发 loadTableData 的间隔很近，导致多次触发计算列宽
      // 以前这里控制下次不进入条件语句，是通过 getRenderColumns 中赋值给 columns width 属性，但是现在认为赋值属性给他们是不合理的，会改变表格组件中 field 原本的值，进而导致传递给其他组件的时候，不再是原值
      // 首次加载 table 的时候如果用户没有保存过列宽，则根据 width 属性显示列宽，如果字段未设置 width 属性，则根据自适应列宽计算，保存到 user_table_setting 表中, 这样下次进入页面就不会再触发计算
      this.saveColumnsWidth(groupList)
    }
    this.updateSelection()
    const { table } = this.$refs
    if (this.selectionMode === SelectionMode.Single && this.radioCheckedRow) {
      // 单选模式下通过方法主动勾选被勾选行
      // 因为 单选模式下不支持勾选框绑定字段
      // eslint-disable-next-line no-unused-expressions
      this.$refs.table?.setRadioRow(this.radioCheckedRow)
    }
    this.changeOpsColumnWidth(opsColumnWidthList)
    // eslint-disable-next-line no-unused-expressions
    this.$refs.table?.loadData(tableData)
  }

  initTableOperation () {
    for (const control of tableOperationControls) {
      const condition = control === TableOperationControl.Viewable ? true : this.accessibleResourcesWithEdit
      const result = condition
        ? checkValueIsEmpty(this.component[control])
          ? false
          : attribute2Boolean(this.component[control]) as boolean
        : false
      // this.operationControl[control] = result
      this.$set(this.operationControl, control, result)
    }
  }

  initTableExtraInfo () {
    const aggregationFields = this.getFinalAttributeValue('aggregationFields', { type: attributeType.STRING_ARRAY })
    // 初始时,给非表单组件添加 filed 属性，便于对比查询，命名规则如：__field_lw-label_1
    this.component.fields.forEach((item: any, index: number) => {
      if (!item.field) {
        item.field = `__field_${item.is}_${index}`
      }
    })
    // 初始化列表所需的附加信息，比方 pageNo pageSize等
    !this.parentRow && LayoutModule.initTableExtraData({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      // pageSize: -1,
      queryName: this.query,
      aggregationFields,
      showFields: this.component.fields
    })
  }

  getUserTableSettings () {
    const allFields = this.setting.all_fields
    const allComponentFields = this.component.fields
    allComponentFields.forEach((item, index) => {
      allFields.push({
        name: item.field,
        title: getI18nContent(item.title),
        freeze: false,
        display: true
      })
    })
    this.setting.display_fields = cloneDeep(allFields)
    const successCallback = (res: any) => {
      const rows = res.rows
      if (!rows || !rows.length) {
        this.afterGetUserTableSettings()
        return
      }
      const row = rows[0]
      // 根据this.component.fields和显示排序字段做无效校验，如果两个enabled已经是false了，则不需要无效校验了
      let allFieldsName: string[] = []
      allFieldsName = allComponentFields.map(item => item.field)

      const comparor = (targetList: string[], comparorList: string[], oneWay = false) => {
        const oneWayResult = !difference(targetList, comparorList).length
        return oneWay ? oneWayResult : oneWayResult && !difference(comparorList, targetList).length
      }

      const [sortFieldsName, displayFieldsName, recordWidthColumn] = ['sort_json', 'display_fields_json', 'fields_width_json'].map(key => {
        if (row[key]) {
          const fields = JSON.parse(row[key])
          const names = fields.map((item: Record<string, any>) => item.name)
          return names
        }
        return []
      })

      const [sortFlag, displayFlag, widthFlag] = [sortFieldsName, displayFieldsName, recordWidthColumn].map((names, i) => {
        return names.length ? comparor(names, allFieldsName, i === 0) : true
      })

      const { sort_enabled, display_fields_enabled } = row

      if ((!sortFlag && sort_enabled) || (!displayFlag && display_fields_enabled) || !widthFlag) {
        if (!sortFlag && sort_enabled) {
          // 将保存的setting全改了
          row.sort_enabled = false
          row.sort_json = null
          logwire.ui.message({
            type: 'info',
            message: this.$i18n('core', 'client.table-setting.check-sort-valid')
          })
        }
        if (!displayFlag && display_fields_enabled) {
          row.display_fields_enabled = false
          row.display_fields_json = null
          setTimeout(() => {
            logwire.ui.message({
              type: 'info',
              message: this.$i18n('core', 'client.table-setting.check-display-valid')
            })
          })
        }
        if (!widthFlag) {
          const widths = JSON.parse(row.fields_width_json).filter((item: any) => allFieldsName.includes(item.name))
          for (const field of allComponentFields) {
            if (recordWidthColumn.includes(field.field)) continue
            widths.push({ name: field.field, width: field.width })
          }
          const widthsJson = JSON.stringify(widths)
          this.setting.fields_width_json = widthsJson
          row.fields_width_json = widthsJson
        }

        if (this.widthCacheable) {
          this.handleSetting(row)
          return
        }
        saveUserSettings(this.layoutName, this.dataSetName, row,
          () => {
            this.handleSetting(row)
          }, () => 0, this.anonymousAccessible)
      } else {
        this.handleSetting(row)
      }
    }
    getUserSettings(this.layoutName, this.dataSetName, successCallback, e => { this.afterGetUserTableSettings() }, this.anonymousAccessible)
  }

  // table组件获取到UserTableSettings之后的操作
  afterGetUserTableSettings () {
    // 1. 看有没有page_size，有的话触发页面条数更新
    const { page_size: settingPageSize, default_advanced_filter_id: settingDefaultAdvancedFilterId } = this.setting

    settingPageSize && eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.change-pageSize`, settingPageSize)
    // 2. 看有没有default_advanced_filter_id，有的话触发页面搜索条件的更新
    if (settingDefaultAdvancedFilterId) {
      // 3. 如果有默认的advanced_filter,那initRetrieve操作应该在完成搜索条件后在searchBar里操作
      settingDefaultAdvancedFilterId && eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.set_default_advancedFilter`, settingDefaultAdvancedFilterId)
    } else {
      // 4. 当table/pagination/searchBar都准备完成后，再根据条件获取table数据
      this.initRetrieve()
    }
    // 获取用户设置后根据保存的显示列，排序，列宽渲染列表
    this.handleComponentFields()
    const groupList = this.getRenderColumns()
    this.loadTableColumns(groupList)
  }

  handleComponentFields () {
    const columns = this.component.fields
    // 根据显示列过滤columns
    this.handleComponentDisplayFields(columns)
    // 根据列宽显示表格
    this.handleComponentWidthFields(columns)
  }

  handleComponentDisplayFields (columns: LayoutComponent[]) {
    const { display_fields: displayFields, display_fields_enabled } = this.setting
    // 根据显示列显示表格
    if (display_fields_enabled) {
      displayFields.forEach((item, index) => {
        item.index = index
      })
      const displayMap = keyBy(displayFields, (f) => f.name)
      columns.forEach(item => {
        item.display = displayMap[item.field]?.display
        item.fixed = displayMap[item.field]?.freeze === true ? 'left' : undefined
        item.index = displayMap[item.field]?.index
      })
    }
  }

  handleComponentWidthFields (columns: LayoutComponent[]) {
    const { fields_width_json: fieldsWidthJson } = this.setting
    if (fieldsWidthJson) {
      const fieldsWidth = JSON.parse(fieldsWidthJson)
      const widthMap = keyBy(fieldsWidth, (f) => f.name)
      columns.forEach(item => {
        if (item.is === 'lw-image' && item.width) {
          item.imageRealWidth = item.width.toString().replace('px', '')
        }
        item.width = widthMap[item.field]?.width
      })
    }
  }

  handleSetting (row: IDataRow) {
    this.setting = Object.assign(this.setting, row)
    this.setCurrentDisplay()
    this.setCurrentSort()
    this.afterGetUserTableSettings()
  }

  setCurrentDisplay () {
    const titleMap = keyBy(this.setting.all_fields, (f) => f.name)
    // 解析显示列
    if (!this.setting.display_fields_json) return
    if (!this.setting.display_fields_enabled) {
      this.setting.display_fields = this.setting.all_fields
      return
    }
    this.setting.display_fields = JSON.parse(this.setting.display_fields_json)
    this.setting.display_fields.forEach(item => {
      if (titleMap[item.name]) item.title = getI18nContent(titleMap[item.name].title)
    })
  }

  setCurrentSort () {
    const titleMap = keyBy(this.setting.all_fields, (f) => f.name)
    // 解析排序列
    if (!this.setting.sort_json) return
    this.setting.sort = JSON.parse(this.setting.sort_json)
    // 将排序字段设置到查询条件中
    let sort: { field: string, order: string }[] = this.setting.sort.map((item) => {
      if (titleMap[item.name]) item.title = getI18nContent(titleMap[item.name].title)
      return { field: item.name, order: item.order }
    })

    if (!this.setting.sort_enabled) {
      sort = []
      this.setting.sort = sort
    }
    LayoutModule.loadDataSetOrderBy({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName,
      orderBy: sort
    })
  }

  handleDataRowSelectedChange ({ row, select }: { row: IDataRow, select: boolean }) {
    // 相应 DataRow 的 select 方法
    // 判断是不是自己的 row
    if (this.dataSet.rows.indexOf(row) !== -1) {
      if (this.selectionMode === 'radio') {
        const rows = LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName]?.rows
        rows.forEach(row => this.$set(row, 'isSelected', false))
        if (select) {
          this.$set(row, 'isSelected', true)
          this.radioCheckedRow = row
          this.$refs.table.setRadioRow(this.radioCheckedRow)
          this.updateSelection([row])
        }
      } else {
        this.updateSelection()
      }
    }
  }

  getSimpleExportExcelFields (payload: any) {
    const fields: { title: string, field: string, type: string, isMerge: boolean, displayContent?: string, displayField?: string, decimalScale?: number, category?: string }[] = []
    this.component.fields.forEach(f => {
      let title = f.title
      if (title.startsWith(attributeSymbol.I18N)) {
        title = logwire.ui.getI18nMessage(getPlainAttributeValue(title, attributeSymbol.I18N))
      }
      const fieldObj: typeof fields[number] = {
        title,
        field: f.field,
        type: 'text',
        isMerge: false
      }
      // 被合并的字段
      if (this.rowSpanFields.includes(f.field)) {
        fieldObj.isMerge = true
      }
      if (f.is === 'lw-select') {
        fieldObj.type = 'select'
        if (f.displayContent) {
          fieldObj.displayContent = f.displayContent
        } else if (f.displayField) {
          fieldObj.displayField = f.displayField
        }
      } else if (f.is === 'lw-number') {
        fieldObj.type = 'number'
        if (attribute2Boolean(f.formatEnabled)) {
          fieldObj.decimalScale = checkValueIsEmpty(f.decimalScale) ? 0 : attribute2Number(f.decimalScale) as number
        }
      } else if (f.is === 'lw-choice' || f.is === 'lw-radio') {
        fieldObj.type = attribute2Boolean(f.multiple) ? 'multipleChoice' : 'choice'
        fieldObj.category = f.category
      }
      // 根据显示列筛选 fields
      const isDisplay = _.findKey(this.setting?.display_fields, { name: f.field, display: true })
      if (!['lw-upload-file', 'lw-image', 'lw-label'].includes(f.is) && isDisplay) {
        fields.push(fieldObj)
      }
    })
    if (this.setting?.display_fields) {
      fields.sort((a, b) => {
        const aIndex = this.setting.display_fields.findIndex(o => o.name === a.field && o.display === true)
        const bIndex = this.setting.display_fields.findIndex(o => o.name === b.field && o.display === true)
        return aIndex - bIndex
      })
    }
    payload.fields = fields
  }

  generateUpdatedRows (payload: any) {
    if (this.query) {
      payload.queryName = this.query
    }
    payload.headerDetailSave = this.detailDataSets?.length > 0 && [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)
    const rows = this.filterRows(this.editRows)
    payload.recordList.push(...rows)
    // 响应头明细保存 事件以后，对 editRows 和 selection 包含的 editRows 进行删除
    const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
    this.editRows.forEach(r => {
      selection.splice(selection.indexOf(r), 1)
    })
    // 这里不直接清空，是因为 save 方法有可能失败,要在 save 成功以后再去清空
    // this.editRows = []
  }

  bindEvents () {
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.init-retrieve`, this.initRetrieve)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.dataSet-row-changed`, this.handleDataRowChange)
    eventbus.$on('dataSet-row-field-changed', this.handleDataRowFieldChange)
    eventbus.$on('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.getSimpleExportExcelFields`, this.getSimpleExportExcelFields)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.clearEditRows`, this.clearEditRows)
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.paging`, this.handlePaging)
  }

  unbindEvents () {
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.dataSet-row-changed`, this.handleDataRowChange)
    eventbus.$off('dataSet-row-field-changed', this.handleDataRowFieldChange)
    eventbus.$off('dataSet-row-selected-changed', this.handleDataRowSelectedChange)
    if (this.handleResize) {
      removeResizeListener(this.$el, this.handleResize)
      window.removeEventListener('resize', this.handleResize)
    }
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.getSimpleExportExcelFields`, this.getSimpleExportExcelFields)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.clearEditRows`, this.clearEditRows)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.paging`, this.handlePaging)
  }

  cacheFieldOption ({ field, option }: { field: string, option: any }) {
    this.cacheFieldOptions = this.cacheFieldOptions || {}
    this.cacheFieldOptions[field] = option
  }

  created () {
    this.initTableConfig()
    this.initTableOperation()
    this.initTableExtraInfo()
    this.bindEvents()
  }

  mounted () {
    this.prepareTableHeight()
    this.getUserTableSettings()

    this.onDataSetRowsChange(this.dataSet.rows)
  }

  beforeDestroy () {
    this.unbindEvents()
  }
}

export default LwTable
