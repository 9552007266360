




























import { Component, Mixins, Watch } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import logwire from '@/logwire'
import { getMaxZIndex } from '@/utils/dom'
import { formatDataRow, formatDate } from '@/utils/data'
import LocalDate from '@/models/LocalDate'
import { DatePickerOptions, ElDatePicker } from 'element-ui/types/date-picker'
import Args from '@/models/Args'
import { attributeType } from '@/utils/const'
import DateBase from './DateBase'

const VALUE_FORMAT_MAP = {
  year: 'yyyy',
  month: 'yyyy-MM',
  date: 'yyyy-MM-dd',
  daterange: 'yyyy-MM-dd'
}

@Component({ name: 'LwDate' })
class LwDate extends Mixins(FormControlComponent, DateBase) {
  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    } else if (this.form.asQuickEditForm) {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const { field } = this.component
    return row.currentData[field] !== row.originalData[field]
  }

  get minValue (): string | LocalDate | undefined {
    return this.getFinalAttributeValue('minValue', { args: this.args, types: [attributeType.STRING, attributeType.OBJECT] })
  }

  get maxValue (): string | LocalDate | undefined {
    return this.getFinalAttributeValue('maxValue', { args: this.args, types: [attributeType.STRING, attributeType.OBJECT] })
  }

  get pickerOption (): DatePickerOptions {
    const disabledDate = (date: Date) => {
      let result = true
      const localDate = new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
      if (result && typeof this.minValue === 'string') {
        const dateMinValue = new Date(this.minValue)
        const localDateMinValue = new LocalDate(dateMinValue.getFullYear(), dateMinValue.getMonth() + 1, dateMinValue.getDate())
        result = localDate.compareTo(localDateMinValue) >= 0
      } else if (result && this.minValue instanceof LocalDate) {
        const localDateMinValue = this.minValue
        result = localDate.compareTo(localDateMinValue) >= 0
      }
      if (result && typeof this.maxValue === 'string') {
        const dateMaxValue = new Date(this.maxValue)
        const localDateMaxValue = new LocalDate(dateMaxValue.getFullYear(), dateMaxValue.getMonth() + 1, dateMaxValue.getDate())
        result = localDate.compareTo(localDateMaxValue) <= 0
      } else if (result && this.maxValue instanceof LocalDate) {
        const localDateMaxValue = this.maxValue
        result = localDate.compareTo(localDateMaxValue) <= 0
      }
      if (result && this.component.validateDate) {
        result = result && this.runRunnableContent('validateDate', {
          args: new Args(this.context, {
            row: formatDataRow(this.form.dataRow),
            evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow'),
            getValidatingDate: () => date
          })
        })
      }
      return !result
    }
    return {
      disabledDate
    }
  }

  get valueFormat (): string { return VALUE_FORMAT_MAP[this.type] }

  @Watch('inputValue')
  handleInputValueChange (value: any, oldValue?: any): void {
    if (this.tableRow && oldValue) {
      const isDateRange = this.type === 'daterange'
      let isChanged = false
      if (isDateRange) {
        isChanged = (value as Array<Date>).some((d, index) => {
          return +d !== +oldValue[index]
        })
      } else {
        isChanged = +value !== +oldValue
      }

      if (isChanged) {
        this.handleChange(value)
      }
    }

    // LwInputForm 不需要状态 一直处于编辑状态
    if (!this.form ||
      this.form.showFormOperations ||
      this.form.asFilterForm ||
      !this.form.$options ||
      this.form.$options.name === 'LwInputForm'
    ) return
    this.form.handleFieldChange && this.form.handleFieldChange(this.component.field, value)
  }

  handleFocus (self: any): void {
    this.component.onFocus && this.runRunnableContent('onFocus')
    setTimeout(() => {
      self.picker.$el.style.zIndex = getMaxZIndex() + ''
    })
  }
}
export default LwDate
