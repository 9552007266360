















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import FilterRow from './filterRow.vue'
import BaseComponent from '../../BaseComponent'
import { filterGroupOperator } from '@/utils/const'

type Condition = {
  label: string
  value: any
  type: 'condition'
  layouts: any
  is: string
  field: string
  operator: filterGroupOperator
}

type Filter = {
  type: 'group'
  not: boolean
  connector: string
  conditions: (Condition | Filter)[]
}
@Component({ name: 'FilterGroup', components: { FilterRow } })
class FilterGroup extends BaseComponent {
  @Prop() filter !: Filter
  @Prop({ default: 0 }) filterIndex!: number
  @Prop({ default: false }) notPos!: boolean
  // @Prop() encodedLayoutName!: string
  @Prop() dataSetName!: string

  get connector (): string {
    if (this.filter.connector === 'and') {
      return this.$i18n('core', 'client.advanced-filter.logic.and')
    } else {
      return this.$i18n('core', 'client.advanced-filter.logic.or')
    }
  }

  // 添加条件/组
  addRowOrGroup (command: string) {
    const initValue: Condition | Filter = command === 'condition' ? {
      label: '',
      value: '',
      type: 'condition',
      layouts: [],
      is: '',
      field: '',
      operator: filterGroupOperator.EQ
    } : {
      type: 'group',
      not: false,
      connector: 'and',
      conditions: []
    }
    this.filter.conditions.push(initValue)
  }

  handleNotCommand (command: boolean) {
    this.filter.not = command
  }

  handleConnectCommand (command: string) {
    this.filter.connector = command
  }

  // 该方法永远通知父级进行操作
  handleGroupOrRow (command: string) {
    if (command === 'delete') this.deleteGroupOrRow()
    if (command === 'wrapInGroup') this.wrapInGroup()
  }

  wrapInGroupDisabled () {
    let flag = false
    const level = this._calculateFilterLevel(this.filter, this.filterIndex)
    if (level >= 4) flag = true
    return flag
  }

  _calculateFilterLevel (filter: any, index: number) {
    let level = index
    if (filter && filter.conditions) {
      ++index
      const levelList :any = []
      filter.conditions.forEach((condition: any) => {
        levelList.push(this._calculateFilterLevel(condition, index))
      })
      if (levelList.length > 0) {
        level = Math.max(...levelList)
      } else {
        level = index
      }
    }
    return level
  }

  // 包裹进组
  wrapInGroup () {
    this._formateRowToGroupValue(this.filter)
    const conditions: Filter[] = [
      {
        type: 'group',
        not: false,
        connector: 'and',
        conditions: this.filter.conditions
      }
    ]
    this.filter.conditions = conditions
  }

  // 删除条件/组
  deleteGroupOrRow () {
    this.$emit('deleteCondition')
  }

  deleteCondition (index: number) {
    this.filter.conditions.splice(index, 1)
  }

  // 条件变成组
  changeRowToGroup () {
    this.$emit('changeCondition')
  }

  _formateRowToGroupValue (data: any) {
    const { type, conditions } = data
    if (type === 'group' && Array.isArray(conditions)) {
      data.conditions = conditions.map(item => this._formateRowToGroupValue(item))
    }
    if (type === 'condition') {
      const row = this.context.getOrCreateDataSet('__advancedFilterDs').getRow(0)
      if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(data.operator)) {
        const value1 = row.getData(data.layouts[0].components[0].field)
        const value2 = row.getData(data.layouts[0].components[1].field)
        data.value = [value1, value2]
      } else {
        const value = row.getData(data.layouts[0].id)
        data.value = value
      }
    }
    return data
  }

  changeCondition (index: number) {
    this._formateRowToGroupValue(this.filter)
    const initGroup: Filter = {
      type: 'group',
      not: false,
      connector: 'and',
      conditions: [this.filter.conditions[index]]
    }
    this.filter.conditions.splice(index, 1, initGroup)
  }
}
export default FilterGroup
