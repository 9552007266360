
















import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import LwBadge from '../inner/Badge.vue'
import { attributeType, layoutStatus, SemanticColorSchemes } from '@/utils/const'
import _ from 'lodash'
import { AppModule } from '@/store/modules/app'
import { DataRow } from '@/types/data'
import Args from '@/models/Args'

@Component({ name: 'LwButton', components: { LwBadge } })
class LwButton extends BaseComponent {
  _uid!: number
  @Prop() currentRow!: DataRow

  get size (): string { return this.getFinalAttributeValue('size') || 'small' }

  get title (): string {
    const title = this.getFinalAttributeValue('title', { args: this.args })
    this.$emit('set-group-title', title)
    return title
  }

  get visible (): boolean {
    return this.component.visible
      ? this.getFinalAttributeValue('visible', { args: this.args, type: attributeType.BOOLEAN })
      : true
  }

  get args (): Args {
    return new Args(this.context, { row: this.row })
  }

  get colorScheme (): SemanticColorSchemes {
    let result = SemanticColorSchemes.SECONDARY
    if (this.component.colorScheme) {
      let colorAttr = this.getFinalAttributeValue('colorScheme', { args: this.args })
      if (_.values(SemanticColorSchemes).includes(colorAttr) || colorAttr === 'text') {
        if (colorAttr === SemanticColorSchemes.WARN) colorAttr = 'warning'
        if (colorAttr === SemanticColorSchemes.ERROR) colorAttr = 'danger'
        result = colorAttr
      }
    }
    return result
  }

  get loading (): boolean { return AppModule.processingEventOwnerUIds.includes(this._uid) }

  get layoutEditing (): boolean {
    let result = false
    const status = this.context.getLayoutStatus()
    if (status) {
      result = status === layoutStatus.EXPRESS_EDIT
    }
    return result
  }

  get enabled (): boolean {
    let result = true
    if (this.component.enabled) {
      result = this.getFinalAttributeValue('enabled', { args: this.args, type: attributeType.BOOLEAN })
    }
    return result
  }

  get disabled (): boolean {
    // 页面处于编辑状态下，或者是抽屉或者弹出框处于 view 状态下，按钮 变为不可点击
    return this.layoutEditing || (this.popupLayoutStatus === layoutStatus.VIEW)
      ? true
      : !this.enabled
  }

  get row (): DataRow {
    return this.currentRowInParent || this.currentRow
  }

  get showBadge () {
    return this.tagContent !== null
  }

  get tagContent (): string | null {
    let result = null
    // 这里允许 this.component.tagContent 属性值为空字符串，空字符串展示为小红点
    if (this.component.tagContent !== undefined) {
      result = this.getFinalAttributeValue('tagContent')
    }
    return result
  }

  get tagColorScheme () {
    let tagColorScheme: any = SemanticColorSchemes.PRIMARY
    if (this.component.tagColorScheme) {
      tagColorScheme = this.getFinalAttributeValue('tagColorScheme')
    }
    return tagColorScheme
  }

  @Watch('disabled', { immediate: true })
  onRowChange (val: boolean): void {
    this.$emit('disabled-changed', val)
  }

  // TODO 留着给日后万一要做按钮级别的 loading 效果
  handleButtonClick (): void {
    this.$emit('hide-ops-popper')
    // AppModule.updateAppCurrentEventOwner(this._uid)
    this.runRunnableContent('onClick', { args: this.args })
  }
}
export default LwButton
