import { Component, Vue } from 'vue-property-decorator'
import QueriableByFilterComponent from '@/components/layout/QueriableByFilterComponent'
import { DataRow as IDataRow } from '@/types/data'
import _, { cloneDeep, pick } from 'lodash'
import {
  attributeType, EDIT_LAYOUT_SAVE_TRIGGER_MAP,
  layoutStatus, operationType,
  rowPersist,
  SYSTEM_DB_RESERVED_FIELDS,
  TABLE_RELATED_DATASET_NAME
} from '@/utils/const'
import {
  formatDataRow,
  getDataListForSave,
  getDataRowsForSave
} from '@/utils/data'
import logwire from '@/logwire'
import { LayoutModule } from '@/store/modules/layout'
import { addResizeListener, closeAllErrorMessages, removeResizeListener } from '@/utils/dom'
import { doDelete, doSave } from '@/http/api'
import Args from '@/models/Args'
import VxeTable from '@/3rd-party-extension/Table/src/VxeTable.vue'
import DataRow from '@/models/DataRow'
import { LayoutComponent, PopupLayoutConfig } from '@/types/layout'
import QuickEditForTable from '@/components/layout/inner/QuickEditForTable.vue'
import { emitLayoutEvent, findMasterFields } from '@/utils/layout'
import { createPopper } from '@popperjs/core'
@Component
class TableBase extends QueriableByFilterComponent {
  targetRow = {} as IDataRow
  editRows: IDataRow[] = []
  hasExpandRowKeys: any[] = [] // 已经展开过的行的 rowkey
  tableData: (IDataRow & { _hasExpanded?: boolean, _rowEnabled?: boolean, _rowSelectable?: boolean })[] = []
  editVal = null // 当前快速编辑的值
  editField!: string // 当前快速编辑的字段名
  editPopperVisible = false // 快速编辑的弹出框是否显示

  editColumn!: LayoutComponent
  batch!: boolean
	quickEditingRow!: IDataRow
  targetCellDom!: HTMLElement

  // 记录某一个字段发生变化时，其余一起变化的字段
  updatedFieldsMap = {}

  editorInstance!: any

  $refs!: {
    table: VxeTable,
	  quickEdit: QuickEditForTable,
	  quickEditWrapper: HTMLElement
  }

  // 是否可以进入快速编辑
  get enabled () {
    let result = true
    if (this.component.enabled) {
      result = this.getFinalAttributeValue('enabled', { type: attributeType.BOOLEAN })
    }
    return result
  }

  get systemState () {
    return LayoutModule.data?.[this.encodedLayoutName]?.systemState?.[this.dataSetName]
  }

  getDataSetNewFields () {
    return this.component.fields.filter((f: LayoutComponent) => !SYSTEM_DB_RESERVED_FIELDS.includes(f.field)) as { field: string }[]
  }

  resetStatus ({ isForceReset } = { isForceReset: false }) {
    if (!isForceReset && [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) return
    this.status = layoutStatus.VIEW
  }

  resetRows ({ isForceReset } = { isForceReset: false }) {
    this.handleDataSetRetrieve()
    this.resetStatus({ isForceReset })
  }

  saveRows () {
    const updatedRows: IDataRow[] = this.batch ? LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[] : this.editRows
    const getUpdatedDataRows = () => updatedRows.map(row => new DataRow(row))
    if (this.component.onSave) {
      this.runRunnableContent('onSave', { args: new Args(this.context, { getUpdatedDataRows, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    const cb = () => {
      this.resetStatus()
      // 新增 删除 修改数据，保存后重新拉取table数据
      this.handleDataSetRetrieve()
      this.component.afterSave && this.runRunnableContent('afterSave')
    }
    const proceed = () => {
      const rows = getDataRowsForSave(updatedRows) as IDataRow[]
      const dataListForSave = getDataListForSave(this.dataSetName, this.query, rows)
      const layoutName = this.editLayoutName || this.layoutName
      rows.length && doSave({ layoutName, namespace: this.context.getNamespace(), dataSetName: this.dataSetName, data: dataListForSave, queryName: this.query }).then(res => {
        emitLayoutEvent(`${this.dataSetName}.clearEditRows`)
        cb()
      }, e => { console.error(e) })
    }
    // todo 校验逻辑可能复杂，比方，每一行的每个字段校验规则可能都不同
    // 暂时只对静态的required的简单处理？？
    if (this.component.beforeSave) {
      this.runRunnableContent('beforeSave', { args: new Args(this.context, { getUpdatedDataRows, proceed }), noWarning: false })
    } else {
      proceed()
    }
  }

  handleTableRowView (row: IDataRow) {
    const dataRow = cloneDeep(row).currentData
    const dr = formatDataRow(dataRow)
    this.openEditLayout(layoutStatus.VIEW, dr)
  }

  handleTableRowEdit (mode: layoutStatus, row: IDataRow) {
    // 没有 edit 权限不应该响应 edit 事件
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    this.throughDataSetEditEvents(row, (editingIRow, layoutParams) => {
      this.targetRow = row
      this.openEditLayout(mode, editingIRow, layoutParams)
    })
    // 如果是在头明细编辑或者头明细新增的状态下,不改变状态,头明细状态目前重置的方式应该只是在保存 主 DataSet 以后才重置状态为 view
    if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      this.status = layoutStatus.EDIT
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EDIT })
    }
  }

  handleTableRowClone (row: IDataRow) {
    const dataRow = cloneDeep(row).currentData
    delete dataRow.id
    delete dataRow.version
    const dr = formatDataRow(dataRow)
    this.targetRow = row
    this.openEditLayout(layoutStatus.NEW, dr)
  }

  handleTableRowDelete (payload: { row?: IDataRow, logicDelete?: boolean } = {}) {
    // 没有 edit 权限不应该响应 edit 事件
    const { row, logicDelete } = payload
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    closeAllErrorMessages()
    // 如果自定义了删除，则执行自定义删除事件
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: any = this
    if (self.component.onDelete) {
      self.runRunnableContent('onDelete', { args: new Args(self.context, { row, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    // 删除逻辑
    const proceed = function () {
      const afterDelete = () => {
        self.component.afterDelete && self.runRunnableContent('afterDelete', { args: new Args(self.context, { getCurrentDataSet: self.getCurrentDataSet }) })
      }
      const { selection = [] } = self.systemState
      const rows: IDataRow[] = row ? [row] : [...selection]
      // persistRows才发送请求删除；客户端临时添加的行直接删除即可
      const deleteCallback = (row: IDataRow, logic?: boolean) => {
        !logic && self.dataSet.rows.splice(self.dataSet.rows.indexOf(row), 1)
        selection.length && selection.splice(selection.indexOf(row), 1)
        self.editRows.splice(self.editRows.indexOf(row), 1)
      }
      if (logicDelete || self.component.headerDataSet) {
        let hasLogic = true
        // 如果有id，则更改rowPersist为D；手动调用loadTableData重新渲染
        // 如果没有id（认为是新增的数据），直接从rows中清除
        for (const row of rows) {
          if (!row) continue
          if (row.currentData.id) {
            // 头明细保存的时候，这些持久化要被删除的行应该发到后端
            // 先判断是否已经被修改过了
            row.rowPersist = rowPersist.DELETE
            if (self.component.headerDataSet) {
              const index = self.getRowIndexExistInEditRows(row)
              index === -1
                ? self.editRows.push(row)
                : self.editRows.splice(index, 1, row)
            }
            // 删除取消行勾选
            selection.length && selection.splice(selection.indexOf(row), 1)
          } else {
            hasLogic = false
            self.dataSet.rows.splice(self.dataSet.rows.indexOf(row), 1)
            deleteCallback(row, true)
          }
        }
        hasLogic && self.loadTableData()
        afterDelete()
        return
      }
      const persistRows: { id: string, version: number }[] = []
      const _persistRows: IDataRow[] = []
      rows.forEach(r => {
        if (r.currentData.id) {
          persistRows.push({
            id: r.currentData.id,
            version: r.currentData.version
          })
          _persistRows.push(r)
        } else {
          deleteCallback(r)
        }
      })
      // 如果都不是发请求进行删除，则表明都是删除的 rowPersist=I 的行
      if (rows.length && _persistRows.length === 0) {
        afterDelete()
      }
      if (persistRows.length) {
        const param = {
          message: self.$i18n('core', 'client.tips.is-delete') as string,
          callback: (action: string) => {
            if (action === 'confirm') {
              // 点击确定之后进行删除操作
              const layoutName = self.editLayoutName || self.layoutName
              doDelete({ layoutName, namespace: self.context.getNamespace(), queryName: self.query, data: { query: self.query, selectedRows: persistRows } }).then(res => {
                _persistRows.forEach(r => {
                  deleteCallback(r)
                })
                afterDelete()
                const { pageNo = 1 } = self.systemState
                // TODO 这里应该将 tableData 替换掉
                const newPageNo = self.tableData.length === 1 ? Math.max(1, pageNo - 1) : pageNo
                self.handlePaging({ pageNo: newPageNo, forceCount: true })
              }, e => { console.error(e) })
            }
          },
          distinguishCancelAndClose: true
        }
        logwire.ui.confirm(param)
      }
    }
    if (this.component.beforeDelete) {
      this.runRunnableContent('beforeDelete', { args: new Args(this.context, { row, proceed, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
    } else {
      proceed()
    }
  }

  /**
     * 跳转到第多少页
     * 只有因为改变页码数时的查询，才认为可以不需要 count
     * 而像删除、新增后的查询，都需要 count 来获得总数量
     * @param {{ pageNo: number, pageSize?: number }} params
     */
  handlePaging (param: { pageNo: number, pageSize?: number, forceCount?: boolean }) {
    const payload = { layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, tempPageNo: param.pageNo }
    LayoutModule.setTempTablePageNo(payload)
    LayoutModule.setTempTablePageSize(payload)
    // 让表格滚动到头部
    this.$refs.table.scrollTo(0, 0)
    this.handleDataSetRetrieveForQueryByFilterComponent('table', param.forceCount ? 'retrieve' : 'paging')
    this.clearEditRows()
    // 重新拉取数据后就清空 editRows，不然 editRows 中的数据和 store 中的数据不一致，导致界面没有表现出修改状态，但是保存时候却有修改值
    // 每次重新获取数据,不论是翻页还是保存，或是加载，都重置已展开行的记录 hasExpandRowKeys
    this.hasExpandRowKeys = []
  }

  filterRows (_rows: IDataRow[]) {
    // 只返回包含 currentData originalData rowPersist的数据
    // 如果不存在 id 同时 rowPersist 等于 Delete， 说明是通过 API 对新增的明细数据进行了删除，此时不应该提交该数据
    return _rows.filter(o => o.currentData.id || o.rowPersist !== rowPersist.DELETE).map(r => {
      return {
        currentData: r.currentData,
        originalData: r.originalDataForHeaderDetailEdit || r.originalData,
        rowPersist: r.currentData.id ? r.rowPersist ? r.rowPersist : rowPersist.UPDATE : rowPersist.INSERT
      }
    })
  }

  handleDataSetSave (payload: Partial<{ rows: IDataRow[], mode: string, op: string, cbFromPopup: () => void, defaultAfterSave: () => void }> = {}) {
    // getTrigger 返回三种状态： insert  insert-and-new update
    const { rows, mode = 'edit', op = 'save', cbFromPopup } = payload
    const getTrigger = () => {
      if (op === operationType.SAVE_AND_NEW) {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP.saveAndNew
      }
      if (op === operationType.UPDATE_AND_NEXT) {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP.updateAndNext
      }
      return EDIT_LAYOUT_SAVE_TRIGGER_MAP[mode]
    }
    if (!rows) {
      this.saveRows()
      return
    }
    closeAllErrorMessages()
    if (this.checkUploadingFieldsExist()) {
      return
    }
    if (this.component.onSave) {
      this.runRunnableContent('onSave', { args: new Args(this.context, { row: rows?.[0], getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      return
    }
    const updateRowIndex = this.dataSet.rows.indexOf(this.targetRow)
    const lastIndex = this.dataSet.rows.length - 1
    const hasNextRow = updateRowIndex < lastIndex
    const proceed = () => {
      const filterRows = this.filterRows(rows) as IDataRow[]
      const dataListForSave = getDataListForSave(this.dataSetName, this.query, filterRows)
      // 头明细编辑的情况下，只改数据不保存
      if ([layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW, layoutStatus.EXPRESS_EDIT].includes(this.status)) {
        const currentData = filterRows[0].currentData
        if (mode === layoutStatus.NEW) {
          const newDataRow = {
            currentData: _.cloneDeep(currentData),
            originalData: _.cloneDeep(currentData),
            rowPersist: rowPersist.INSERT,
            ___index: this.dataSet.rows.length + 1,
            rowKey: new Date().getTime().toString(),
            isSelected: false
          } as IDataRow
          this.editRows.push(newDataRow)
          // 列表添加一行
          LayoutModule.addNewItem({
            layoutName: this.encodedLayoutName,
            dataSetName: this.dataSetName,
            row: newDataRow
          })
        } else {
          // 更新targetRow数据
          // 根据 id 查找 editRows 中是否有已插入的 行
          if (mode === layoutStatus.EDIT) {
            const editRowIndex = this.getRowIndexExistInEditRows(rows[0])
            const editRow = this.editRows[editRowIndex]
            if (editRow) {
              editRow.currentData = _.cloneDeep(currentData)
              // 保存的时候 会将当前行的 currentData 和 originalData 都设置成当前的 数据
              // 但是头明细状态数据的的实际保存是在头表保存的时候，所以将 originalData 记录下来，在保存的时候替换掉 originalData
              editRow.originalDataForHeaderDetailEdit = _.cloneDeep(editRow.originalDataForHeaderDetailEdit || this.targetRow.originalData || currentData)
            } else {
              this.editRows.push({
                currentData: _.cloneDeep(currentData),
                originalData: _.cloneDeep(this.targetRow.originalData),
                rowPersist: rowPersist.UPDATE,
                rowKey: rows[0].rowKey,
                isSelected: false
              } as IDataRow)
            }
          }
          this.targetRow.currentData = _.cloneDeep(currentData)
          this.targetRow.originalData = _.cloneDeep(currentData);
          (this as any).generateColumns(this.targetRow, this.component.fields, true)
        }
        this.handleSaveAndUpdate(op, hasNextRow, cbFromPopup, updateRowIndex)
      } else {
        // 非头明细编辑的情况
        doSave({ layoutName: this.editLayoutName || this.layoutName, namespace: this.context.getNamespace(), queryName: this.query, data: dataListForSave }).then(res => {
          const updatedCurrentData = filterRows[0].currentData
          const currentData = { ...updatedCurrentData, ...res.data.data[this.dataSetName].records[0] }
          if (mode === layoutStatus.NEW) {
            const newDataRow = { currentData, originalData: _.cloneDeep(currentData), rowPersist: '' } as IDataRow
            // 列表添加一行
            LayoutModule.addNewItem({
              layoutName: this.encodedLayoutName,
              dataSetName: this.dataSetName,
              row: newDataRow
            })
            this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: newDataRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })

            const pageNo = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].pageNo as number
            this.handlePaging({ pageNo, forceCount: true }) // 在新增时，按照既有的方式查询
          } else {
            // 清除editRows中快速编辑的一行，同时根据 editRows 是否为空来调整status
            this.editRows.splice(this.editRows.indexOf(rows[0]), 1)
            if (this.editRows.length === 0) {
              this.status = layoutStatus.VIEW
            }
            // 更新targetRow数据
            this.targetRow.currentData = currentData
            this.targetRow.originalData = _.cloneDeep(currentData);
            (this as any).generateColumns(this.targetRow, this.component.fields, true)
            this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: this.targetRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
          }
          this.handleSaveAndUpdate(op, hasNextRow, cbFromPopup, updateRowIndex)
        }, e => { console.error(e) })
      }
    }
    if (this.component.beforeSave) {
      this.runRunnableContent('beforeSave', { args: new Args(this.context, { row: rows[0], proceed, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
    } else {
      proceed()
    }
  }

  handleSaveAndUpdate (op: string, hasNextRow: boolean, cbFromPopup: undefined | (() => void), updateRowIndex: number) {
    const getEditingRow = () => this.context.getOrCreateDataSet(this.editDataSet).getRow(0)
    const { editLayout } = this.component
    const { editDataSet } = this
    const newPopupOrPanelLayoutName = editLayout
      ? editLayout.includes('.')
        ? editLayout
        : `${this.context.getNamespace()}.${editLayout}`
      : this.layoutName
    const setRowIntoPopupDataSet = (row: IDataRow, layoutParams: any) => {
      // 将经过事件处理的 row 作为 DataRow 保存起来
      LayoutModule.loadLayoutDataSet({
        layoutName: newPopupOrPanelLayoutName,
        data: {
          dataSetName: editDataSet,
          rows: [row]
        }
      })
    }
    if (op === operationType.SAVE_AND_NEW) {
      // 不需要单独做 resetData, 因为 setRowIntoPopupDataSet 时会覆盖掉原来的数据
      this.throughDataSetNewEvents(this.getDataSetNewFields(), setRowIntoPopupDataSet)
    } else if (op === operationType.UPDATE_AND_NEXT) {
      if (hasNextRow) {
        const nextRow = this.dataSet.rows[updateRowIndex + 1]
        this.throughDataSetEditEvents(nextRow, (row, layoutParams) => {
          this.targetRow = nextRow
          setRowIntoPopupDataSet(row, layoutParams)
        })
      } else {
        if (logwire.ui.message.warning) {
          logwire.ui.message.warning(this.$i18n('core', 'data-saved-but-next-data-is-not-found'))
        }
      }
    } else {
      // 其他情况下，都认为应该关闭弹窗，但是发现有开发者会在 beforeSave 里写 setData，导致弹窗的编辑状态被更新了
      // 这里加一个兜底处理
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
      if (cbFromPopup) {
        cbFromPopup()
      }
    }
  }

  handleDataSetEdit (rowIndex?: number) {
    if (rowIndex === undefined || rowIndex === -1) return
    const row = this.tableData[rowIndex]
    if (row) {
      this.handleTableRowEdit(layoutStatus.EDIT, row)
    }
  }

  handleDatasetExpressEdit () { /* do nothing */ }

  /**
   * 执行 ds.delete 事件时，触发此方法，params 参数为 { row?, logicDelete? } | number
   * row: 行中的删除传过来的参数
   * logicDelete: 是否是逻辑删除，默认为 false; 列表的数据保存如果自定义处理，一般会设置为true
   * */
  handleDataSetDelete (params: number | { row?: any, logicDelete?: boolean }) {
    if (typeof params === 'number') {
      params = { row: this.tableData[params] }
    }
    this.handleTableRowDelete(params)
  }

  handleDataSetNew () {
    // 没有 edit 权限不应该响应 new 事件
    if (!this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    closeAllErrorMessages()
    this.throughDataSetNewEvents(this.getDataSetNewFields(), (row, layoutParams) => {
      this.handleAfterQueryMeta(() => {
        this.openEditLayout(layoutStatus.NEW, row, layoutParams)
      })
    })

    // 如果是在头明细编辑或者头明细新增的状态下,不改变状态,头明细状态目前重置的方式应该只是在保存 主 DataSet 以后才重置状态为 view
    if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      this.status = layoutStatus.NEW
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.NEW })
    }
  }

  // 获取列表数据
  handleDataSetRetrieve (params?: Record<string, any>) {
    // 判断是否是searchBar中首次触发，如果是要根据retrieveOnLayoutLoad判断是否立即显示数据
    if (params && params.searchBarInit && !this.retrieveOnLayoutLoad) return

    LayoutModule.setTempTablePageNo({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, tempPageNo: 1 })

    closeAllErrorMessages()
    if (params && params.scrollToTop) {
      this.$refs.table.scrollTo(0, 0)
    }
    this.handleDataSetRetrieveForQueryByFilterComponent('table')
    this.clearEditRows()
    // 重新拉取数据后就清空 editRows，不然 editRows 中的数据和 store 中的数据不一致，导致界面没有表现出修改状态，但是保存时候却有修改值
    // 每次重新获取数据,不论是翻页还是保存，或是加载，都重置已展开行的记录 hasExpandRowKeys
    this.hasExpandRowKeys = []
  }

  /**
   * 当发生了行的变化时，需要判断当前行是否存在于 editRows 内，避免重复添加数据
   * 但是判断不可以直接使用 indexOf, 因为编辑时的 row 和 tableData 内的 row 并不是同一个对象，所以需要从 currentData.id, rowKey 判断
   * @param row
   */
  getRowIndexExistInEditRows (row: IDataRow): number {
    const { currentData: { id }, rowKey } = row
    // 这里的使用 rowKey 的场景是,头明细状态,新增了一条数据保存,这时候没有和后端进行交互,所以也就不存在 id
    // 然后对新增的这条数据进行编辑, 因为此时没有 id,无法判断出到底编辑的是哪一条数据,所以只能根据 rowKey 来进行判断
    // rowKey 是在 handleDataSetEdit 时设置的
    return id || rowKey
      ? this.editRows.findIndex(o => {
        return id ? o.currentData.id === id : o.rowKey === rowKey
      })
      : this.editRows.indexOf(row)
  }

  clearEditRows () {
    this.editRows = []
    const editingDataSet = LayoutModule.data[this.encodedLayoutName]?.editingDataSet
    const currentDataSet = `${this.encodedLayoutName}.${this.dataSetName}`
    if (editingDataSet === currentDataSet) {
      LayoutModule.data[this.encodedLayoutName].editingDataSet = ''
    }
  }

  // 内容回撤
  handleDataRowReset (params?: { fields: any[] }) {
    const editField = this.editColumn.field
    const newRow = pick(this.targetRow.originalData, this.updatedFieldsMap[editField])
    this.afterRowFieldChanged(editField, this.targetRow, newRow)
    this.afterRowChange()
  }

  applyChangedField (fieldName: string, value: any, batch: boolean, row: any) {
    const editVal = this.editVal = value
    const oldValue = this.targetRow.currentData[fieldName]
    if (oldValue !== editVal) {
      // 收集改动中，一起发生变化的其他字段
      this.updatedFieldsMap[fieldName] = []
      for (const key in row) {
        if (row[key] === this.targetRow.currentData[key]) continue
        this.updatedFieldsMap[fieldName].push(key)
      }
      const rowData = pick(row, this.updatedFieldsMap[fieldName])
      this.afterRowFieldChanged(fieldName, this.targetRow, rowData)
      this.batch = batch
      // 难以区分 `批量修改其他项` 先勾选还是后勾选
      if (this.batch) {
        const selection = this.systemState.selection as IDataRow[]

        selection.forEach(selectedRow => {
          if (selectedRow !== this.targetRow) {
            // row.currentData[fieldName] = this.editVal
            this.afterRowFieldChanged(fieldName, selectedRow, rowData)
          }
        })
        this.editRows.push(...selection)

        this.editRows = [...new Set(this.editRows)]
      } else {
        this.editRows.indexOf(this.targetRow) < 0 && this.editRows.push(this.targetRow)
      }
    }
    this.afterRowChange()
  }

  // this.targetRow.currentData[fieldName] = editVal
  // 这里采用 merge 的形式的原因是
  // lw-select （目前只有这一个，不太确定日后会不会有）展示的内容和值有可能是两个不同字段
  // 那么在 lw-select 组件内部，值字段发生变化时，展示字段也会随之发生变化
  // 由于快速编辑时的行数据是拷贝而非行数据本身，所以行数据本身展示字段的不会随之发生变化
  // 所以将这次改动中都发生变化的字段与编辑字段相关联，并且对应字段都 merge 到行数据中
  afterRowFieldChanged (fieldName: string, targetRow: IDataRow, newRowData: any) {
    targetRow.currentData = Object.assign(targetRow.currentData, newRowData);
    (this as any).generateColumns(targetRow, this.component.fields, true)
    targetRow.__table__!.content[fieldName].rowChanged = targetRow.currentData[fieldName] !== targetRow.originalData[fieldName]
  }

  afterRowChange () {
    if (
      ![layoutStatus.HEADER_DETAIL_NEW, layoutStatus.HEADER_DETAIL_EDIT].includes(this.status) && this.enabled
    ) {
      let modified
      for (const row of this.editRows) {
        const content = row.__table__!.content
        const fields = Object.values(content)
        for (const field of fields) {
          if (field.rowChanged) {
            modified = true
            break
          }
        }
      }
      this.status = modified ? layoutStatus.EXPRESS_EDIT : layoutStatus.VIEW
    }
  }

  closeQuickEditPopper (data?: any) {
    // 回归原位置隐藏
    if (this.editPopperVisible) {
      this.$refs.quickEdit.$el.removeAttribute('data-show')
      // this.$refs.quickEditWrapper.appendChild(this.$refs.quickEdit.$el)
      // eslint-disable-next-line no-unused-expressions
      // this.popperInstance?.hide()
      if (data?.type === 'confirm') {
        this.applyChangedField(this.editField, data.val, data.batch, data.row)
      }
      this.$refs.quickEdit.hide()
      this.editPopperVisible = false
      this.component.afterExpressEdit && this.runRunnableContent('afterExpressEdit')
    }
  }

  handleQuickEdit () {
    const proceed = () => {
      // TODO 这里先调用返回原位隐藏的方式，可以尝试进行优化
      this.closeQuickEditPopper()
      this.$nextTick(function () {
        this.quickEditingRow = this.targetRow
        const target = this.targetCellDom
        this.editField = this.editColumn.field
        // 如果不是多选，快速编辑框内不显示 `批量修改其他选中项` 复选框
        const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
        let isBatch = false
        if (selection.length > 1 || !selection.includes(this.quickEditingRow)) {
          isBatch = true
        }
        const editInstance = this.$refs.quickEdit
        editInstance.show({
          component: this.editColumn,
          updateRowsLength: isBatch ? selection.length : 0,
          currentData: this.targetRow.currentData
        })
        this.editPopperVisible = true
        // eslint-disable-next-line
        const _this = this
        editInstance.$el.setAttribute('data-show', '')
        this.editorInstance = createPopper(target, editInstance.$el as any, {
          placement: 'bottom-start'
        })
        // target.appendChild(editInstance.$el)

        addResizeListener(target, function hideQuickEdit () {
          if (!target.parentNode?.parentNode) {
            _this.closeQuickEditPopper()
            // eslint-disable-next-line dot-notation
            if (target && target['__resizeListeners__'] && target['__resizeListeners__'].indexOf(hideQuickEdit) !== -1) {
              removeResizeListener(target, hideQuickEdit)
            }
          }
        })
        this.component.afterEdit && this.runRunnableContent('afterEdit', { args: new Args(this.context, { row: this.targetRow, proceed }), noWarning: false })
      })
    }

    if (this.component.beforeEdit) {
      this.runRunnableContent('beforeEdit', { args: new Args(this.context, { row: this.targetRow, proceed }), noWarning: false })
    } else {
      proceed()
    }
  }

  /**
   * 如果已经弹出了快速编辑框，行的勾选项发生变化，通知快速编辑框重新渲染，使修改的数量实时变化
   **/
  notifyQuickEdit () {
    if (this.editPopperVisible) {
      // 如果不是多选，快速编辑框内不显示 `批量修改其他选中项` 复选框
      const selection = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].selection as IDataRow[]
      let isBatch = false
      if (selection.length && (selection[0] !== this.quickEditingRow || selection.length > 1)) {
        isBatch = true
      }
      const editInstance = this.$refs.quickEdit
      if (isBatch) {
        editInstance.updateRowsLength = selection.length
      } else {
        editInstance.updateRowsLength = 0
      }
    }
  }

  getEditFormLayoutComponent (mode?: string) {
    const { onRetrieve } = this.component
    // 平台预留的字段，新增 或者 编辑状态下去掉组件字段；查看状态下不处理。
    // 有的时候页面只展示了少量字段，而在editLayout中会展示多个字段，此时先点击编辑再点击新增，这些多出来的字段没有被重置
    let formFields: LayoutComponent[] = _.cloneDeep(this.component.fields)
    if (mode !== layoutStatus.VIEW) {
      formFields = formFields.filter(f => !SYSTEM_DB_RESERVED_FIELDS.includes(f.field))
    }
    if (!onRetrieve) {
      // 不是主数据字段，保存接口并不会处理；因此这些非主数据字段，enabled默认应该是false
      const masterFields = findMasterFields(this.layoutName, this.query)
      formFields.forEach(f => {
        if (!masterFields.includes(f.field)) {
          f.enabled = 'false'
        }
      })
    }
    return formFields
  }

  async openEditLayout (mode: layoutStatus, row: any, layoutParams: Record<string, any> = {}) {
    const { editPanel } = this.component
    const editDataSet = this.editDataSet
    if (editPanel) {
      this.openEditPanel({ mode, row, editDataSet })
    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const commonParams = await this.getCommonOpenLayoutParams(mode, row, layoutParams)
      this.openedPopupLayout = {
        ...commonParams,
        fromTable: true,
        sourceLayoutStatus: this.status,
        onClose: (args) => {
          this.resetStatus()
        }
      }
      if (!this.openedPopupLayout.editLayout) {
        this.openedPopupLayout.layouts = [{
          is: 'lw-form',
          dataSet: editDataSet,
          components: this.getEditFormLayoutComponent()
        }]
      }
      logwire.ui.openLayoutInDialog(this.openedPopupLayout as PopupLayoutConfig)
    }
  }

  // rowChange 一般是整行的变化，比如新增、删除操作。有可能在删除前执行了编辑操作，导致 editRows 内存储的 row 对象和表格的 row 对象不一致了
  handleDataRowChange ({ row, index }: { row: IDataRow, index: number }) {
    // 由于rows变化，必然引起 loadTableData 重新执行
    setTimeout(() => {
      if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) && this.enabled) {
        this.status = layoutStatus.EXPRESS_EDIT
      }
    }, 0)
    if (this.getRowIndexExistInEditRows(row) === -1) {
      this.editRows.push(row)
    } else {
      this.editRows.splice(this.getRowIndexExistInEditRows(row), 1, row)
    }
    if (row.rowPersist === rowPersist.DELETE) {
      this.dataSet.rows.splice(index, 1)
    }
  }

  handleDataRowFieldChange (data: { row: IDataRow, rowPersistAware: boolean }) {
    const { row, rowPersistAware } = data;
    (this as any).generateColumns(row, this.component.fields, true)
    if (!rowPersistAware) return
    // 判断是不是自己的 row,并且此条 row 有没有被纪录过
    if (this.dataSet.rows.indexOf(row) !== -1 && this.getRowIndexExistInEditRows(row) === -1) {
      setTimeout(() => {
        if (![layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) && this.enabled) {
          this.status = layoutStatus.EXPRESS_EDIT
        }
      }, 0)
      this.editRows.push(row)
    }
  }
}

export default TableBase
