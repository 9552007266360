





























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { attributeType, SemanticColorSchemes } from '@/utils/const'
import clickoutside from 'element-ui/src/utils/clickoutside'
import _ from 'lodash'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'

@Component({ name: 'LwButtonGroup', directives: { clickoutside } })
class LwButtonGroup extends Mixins(BaseComponent, BaseComponentWithRow) {
  inDropdown = false
  groupTitle = ''
  disabledChildren: boolean[] = []
  $refs!: {
    buttonGroupItemWrapper: any
    btnGroupPop: any
  }

  get disabled (): boolean {
    // FIXME
    // 这里为什么要 slice？
    // 开发过程中发现，button-group 被收进 search-bar 的更多菜单中后，按钮组之间的状态相互粘连
    // 但是无论是从 emit 次数还是 watch 来看，数据一切正常
    // 暂时找不到原因是什么，保险起见，先 slice
    const notAllDisabled = this.disabledChildren.slice(0, this.component.components?.length).some(disabled => !disabled)
    return !notAllDisabled
  }

  get placement (): string {
    if (this.inDropdown) return 'right-start'
    return 'bottom-end'
  }

  get size (): string { return this.getFinalAttributeValue('size') || 'small' }

  get colorScheme (): SemanticColorSchemes {
    let result = SemanticColorSchemes.SECONDARY
    if (this.component.colorScheme) {
      let colorAttr = this.getFinalAttributeValue('colorScheme', { args: this.argsWithRow })
      if (_.values(SemanticColorSchemes).includes(colorAttr) || colorAttr === 'text') {
        if (colorAttr === SemanticColorSchemes.WARN) colorAttr = 'warning'
        if (colorAttr === SemanticColorSchemes.ERROR) colorAttr = 'danger'
        result = colorAttr
      }
    }
    return result
  }

  get groupClickable (): boolean { return this.getFinalAttributeValue('groupClickable', { args: this.argsWithRow, type: attributeType.BOOLEAN }) || false }

  setGroupTitle (index: number, title: string) {
    if (!index) this.groupTitle = title
  }

  getIconStyle () {
    let fontSize = '12px'
    if (this.size === 'large' || this.size === 'medium') fontSize = '14px'
    return { fontSize }
  }

  show () :void {
    if (!this.groupClickable) return
    // 隐藏第一个子节点按钮
    const childNode = this.$refs.buttonGroupItemWrapper.childNodes[0]
    if (childNode) childNode.style.display = 'none'
  }

  groupClick () :void {
    if (!this.groupClickable) return
    const button = this.$refs.buttonGroupItemWrapper.childNodes[0]?.querySelector('button')
    // eslint-disable-next-line no-unused-expressions
    button?.click()
  }

  handleClickOutside () :void {
    this.$refs.btnGroupPop.doClose()
  }

  handleDisabledChanged (index: number, event: any) {
    this.$set(this.disabledChildren, index, event)
  }

  mounted () {
    if (this.$parent?.$options.name === 'ElDropdownItem') this.inDropdown = true
  }
}
export default LwButtonGroup
