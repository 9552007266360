import { Component, Vue } from 'vue-property-decorator'
import formatter from '@/components/layout/form-item/timestamp/formatter'

@Component
class TimestampBase extends Vue {
  [propName: string]: any;

  get displayValue (): string {
    return formatter.formatContent(this.inputValue)
  }
}

export default TimestampBase
