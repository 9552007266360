import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from '@/utils/data'
import formatter from './formatter'

const TYPES = ['select', 'picker']

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get type (): string {
    return TYPES.includes(this.component.type)
      ? this.component.type
      : 'picker'
  }

  // 复写inputValue逻辑，当type为select的时候，显示格式为hh:mm，传输格式为hh:mm:ss
  get inputValue (): any {
    const dataRow = this.form.dataRow
    const value = dataRow[this.component.field]
    return formatter.formatValue({ value, type: this.type })
  }

  set inputValue (value) {
    if (this.type === 'select' && value.length > 0) {
      value = value + ':00'
    }
    this.form.dataRow[this.component.field] = value
  }

  get displayValue (): string {
    return formatter.formatContent({ value: this.inputValue, type: this.type })
  }
}

export default DateBase
