import { Component, Vue } from 'vue-property-decorator'
import { calculation, checkValueIsEmpty } from '@/utils/data'
import { attribute2Boolean, attribute2Number } from '@/utils/layout'
import { calculationType } from '@/utils/const'
import formatter from './formatter'

@Component
class NumberBase extends Vue {
  [propName: string]: any;

  get append (): string { return this.getFinalAttributeValue('append', { args: this.args }) }

  get prepend (): string { return this.component.prepend ? this.getFinalAttributeValue('prepend', { args: this.args }) : '' }

  get positiveOnly () : boolean { return checkValueIsEmpty(this.component.positiveOnly) ? false : attribute2Boolean(this.component.positiveOnly) as boolean }

  get formatEnabled () : boolean { return checkValueIsEmpty(this.component.formatEnabled) ? false : attribute2Boolean(this.component.formatEnabled) as boolean }

  get percentage (): boolean { return checkValueIsEmpty(this.component.percentage) ? false : attribute2Boolean(this.component.percentage) as boolean }

  get decimalScale () : number { return checkValueIsEmpty(this.component.decimalScale) ? 0 : attribute2Number(this.component.decimalScale) as number }

  get inputValue (): any {
    const dataRow = this.form.dataRow
    const result = dataRow[this.component.field]
    return formatter.formatValue({ value: result, formatEnabled: this.formatEnabled, percentage: this.percentage })
  }

  set inputValue (value) {
    let result = value
    if (this.formatEnabled && this.percentage && result !== '') {
      result = calculation([result, 100], calculationType.DIVISION)
    }
    this.form.dataRow[this.component.field] = result
  }

  get displayValue (): string {
    const { append, prepend, positiveOnly, formatEnabled, percentage, decimalScale } = this
    const result = formatter.formatContent({
      append,
      prepend,
      positiveOnly,
      formatEnabled,
      percentage,
      decimalScale,
      thousandsGroupStyle: this.component.thousandsGroupStyle,
      value: this.inputValue
    })
    return result
  }
}
export default NumberBase
