











import { IntuitiveColorAllList, IntuitiveColorLightListWithWhiteFont, IntuitiveColorMainList, SemanticColorSchemes } from '@/utils/const'
import _ from 'lodash'
import { Debounce } from 'lodash-decorators'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({ name: 'LwBadge' })
export default class LwBadge extends Vue {
  @Prop() content?: string | null
  @Prop() colorScheme!: any

  get showBadge (): boolean {
    return this.content !== null
  }

  get isDot (): boolean {
    return this.content?.trim() === ''
  }

  get type () {
    let type: SemanticColorSchemes | string = SemanticColorSchemes.PRIMARY
    if (_.values(SemanticColorSchemes).includes(this.colorScheme)) {
      type = this.colorScheme
    }
    if (this.colorScheme === SemanticColorSchemes.WARN) type = 'warning'
    if (this.colorScheme === SemanticColorSchemes.ERROR) type = 'danger'
    return type
  }

  get style () {
    const style = {
      color: '',
      background: ''
    }
    let colorScheme = this.colorScheme
    if (IntuitiveColorMainList.includes(colorScheme)) {
      colorScheme = colorScheme + '-c'
      style.background = `var(--${colorScheme})`
      style.color = 'var(--gray-9)'
    }
    if (IntuitiveColorAllList.includes(colorScheme)) {
      style.background = `var(--${colorScheme})`
      style.color = 'var(--gray-9)'
    }
    if (IntuitiveColorLightListWithWhiteFont.includes(colorScheme)) {
      style.color = 'var(--gray-1)'
    }
    return style
  }

  @Watch('style', { immediate: true })
  setStyle ({ color, background }: { color: string, background: string }) {
    this.$nextTick(() => {
      const badgeDom = this.$el.querySelector('.el-badge__content') as HTMLElement
      if (badgeDom) {
        if (color) badgeDom.style.color = color
        if (background) badgeDom.style.background = background
      }
    })
  }
}
