









































































import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import {
  getUserDashboard,
  getClientResourceList,
  getClientResource, apiClientResource, getClientConfig, getAsset, getI18nMessages, getTheme, getLoginEntry, cancelAction
} from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import { formatDuration, setLocale, tryEval } from '@/utils/common'
import logwire from '@/logwire'
import { getGlobalI18nMessage, getLayoutNameOutsideComponent, getLoginLayoutFromStorage, stashLoginLayoutToStorage, switchTheme, toLoginLayout } from '@/utils/layout'
import { LayoutModule } from '@/store/modules/layout'
import { getMaxZIndex, setDocumentTitle } from '@/utils/dom'
import { Task } from '@/types/common'
import eventbus from '@/utils/event'
import _ from 'lodash'
import { socketMessageType } from './utils/const'
import PreviewFile from '@/components/PreviewFile.vue'
import Clipboard from 'clipboard'
import { clearTraceIdsCache, getTraceIds, saveCsrfToken } from './utils/data'
import { LoadingLevel } from './http'

@Component({ components: { PreviewFile } })
export default class App extends Vue {
  authenticationChecked = false // login-check 是否完成的标志
  commonJsCount = 0
  actions: Array<Task> = []
  loadingShowTimestamp: number | null = null
  currentTimestamp: number = new Date().getTime()
  timer: any = null
  isCancel = false
  actionsProcessingZIndex = 2100
  finishedActionIdArr: Array<string> = [] // 这里用一个长度为 10 的数组记录一下已完成的 action id
  showErrorDetail = false
  clipboard: any = null
  darkLogo = ''
  lightLogo = ''
  errorDetail = {
    message: '',
    stackTrace: '',
    traceId: ''
  }

  @Provide() popupLayout = false
  @Provide() panelLayout = false
  @Provide() editLayoutName = null
  @Provide() popupSetFormInstance = () => 0

  get appLoading (): boolean { return AppModule.appLoading }
  get theme (): any { return logwire.store.getConfig('theme') }

  @Watch('theme', { immediate: true, deep: true })
  watchTheme (theme : Record<string, any>, oldTheme: Record<string, any>) : void {
    // 引入深浅不同的css文件
    if (theme && (!oldTheme || oldTheme.mode !== theme.mode)) {
      if (theme.mode === 'dark') {
        document.getElementsByTagName('body')[0].className = 'theme-dark theme-both'
        require('vue-easytable/libs/theme-dark/index.css')
      } else {
        document.getElementsByTagName('body')[0].className = 'theme-light theme-both'
        require('vue-easytable/libs/theme-default/index.css')
      }
    }
    theme && switchTheme(theme)
  }

  @Watch('appLoading', { immediate: true })
  setActionsProcessingZIndex (val: boolean) {
    if (val) {
      this.actionsProcessingZIndex = getMaxZIndex(10) < 2100 ? 2100 : getMaxZIndex(10)
      !(this.loadingShowTimestamp) && (this.loadingShowTimestamp = new Date().getTime())
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.currentTimestamp = new Date().getTime()
      }, 1000)
    } else {
      this.loadingShowTimestamp = null
      this.isCancel = false
      clearInterval(this.timer)
    }
  }

  /**
   * 进入 App 则获取用户首页
   * 假如请求成功返回，当前页面 url 并不是 "/" 说明已经处于某个 layout，否则跳转至首页
   * 假如请求失败
   * * 假如请求 401，响应拦截器中会处理并跳转至登录页，这里不再处理
   * * 其余情况（500）等，假如当前页面 url 并不是 "/" 说明已经处于某个 layout，否则跳转至 404 页面
   * */
  getUserHomeLayout (): void {
    getUserDashboard()
      .then(async (res) => {
        // 已登录的情况
        const { data: { data } } = res
        const targetLayout = data.layoutName
        // 当前路由为 '/' 时则跳转到首页
        // 生产环境下的首页 fullPath 是 index.html
        if (['/', '/index.html'].includes(this.$route.path)) {
          this.$router.push({ path: `/layout/${targetLayout}`, query: this.$route.query })
        }
        // 如果在已经登录的情况下进入登录页的 url，则进行跳转
        const { params: { layoutName } } = this.$route
        if (layoutName === getLoginLayoutFromStorage()) {
          logwire.ui.proceedAfterLogin()
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          const { params: { layoutName } } = this.$route
          // 未登录，这里不由拦截器统一处理
          // 先判断当前是否是在访问某个 layout
          // 如果是在访问某个 layout, 因为 layout 有可能是一个可匿名访问的 layout,所以是否 401 跳转登录由 get-layout 来判断
          // 如果此时并没有访问某个 layout, 并且处于未登录,则应该去跳转至登录页面
          if (!layoutName) {
            toLoginLayout()
          }
        } else if (error.response.status === 403) {
          logwire.ui.message({
            message: getGlobalI18nMessage('core', 'client.tips.no-permission'),
            type: 'error'
          })
        } else {
          this.$route.fullPath === '/' && this.$router.push({ name: '404' })
        }
      })
      .finally(() => {
        this.authenticationChecked = true
      })
  }

  getClientConfig (callback: () => void): void {
    getClientConfig()
      .then(res => {
        const data = res.data.data
        data.applicationName = data['application-name']
        const { applicationName, primaryNamespace } = data
        if (applicationName) setDocumentTitle(applicationName)
        LocalModule.loadClientConfig(Object.assign({}, data))
        const appearance = data['core.appearance.theme']
        const appearanceForHeader = data['core.appearance.theme-color-for-header']
        getTheme(appearance).then((res) => {
          const theme = res.data.data[0]
          logwire.store.setConfig('theme', theme)
          if (!appearanceForHeader && theme && theme.mode === 'light') {
            LocalModule.loadLogo({ logoUrl: this.lightLogo })
          } else {
            this.darkLogo && LocalModule.loadLogo({ logoUrl: this.darkLogo })
          }
        })
        logwire.store.setConfig('headerTheme', JSON.parse(data['core.appearance.theme-color-for-header']))
        logwire.store.setConfig('menuTheme', JSON.parse(data['core.appearance.theme-color-for-menu']))
        setLocale(data['core.i18n.language'])
        callback()
      })
  }

  getCostTime () {
    const costTime = this.currentTimestamp - (this.loadingShowTimestamp || new Date().getTime())
    if (costTime < 0) return '00:00:00'
    return formatDuration(costTime)
  }

  handleAppUnload (e: any): void {
    const layoutName = getLayoutNameOutsideComponent()
    const editingDataSet = LayoutModule.data[layoutName]?.editingDataSet
    if (editingDataSet) {
      // 这里设置显示内容并不会起效果
      e.returnValue = 'changes not saved....'
    }
    clearTraceIdsCache()
  }

  handleProcessingAction (action: { type: socketMessageType, data: Task}): void {
    // 获取当前未响应的请求的所有 traceIds
    const traceIdArr = getTraceIds()
    if (!traceIdArr.includes(action.data.traceId)) return
    let index = -1
    let id: any = null
    // 如果有 id 先根据 id 找到对应 action
    if (action.data.id || _.isString(action.data)) {
      id = action.data.id || action.data
      this.actions.forEach((item, i) => {
        if (item.id === id) {
          index = i
        }
      })
    } else {
      // 没有 id 根据 traceId 找到对应 action
      const traceId = action.data.traceId
      this.actions.forEach((item, i) => {
        if (item.traceId === traceId) {
          id = item.id
          index = i
        }
      })
    }

    if ([socketMessageType.FINISH_ACTION, socketMessageType.CANCEL_ACTION].includes(action.type)) {
      // 完成或者取消 action, 从 actions 中删掉这一条
      if (index !== -1) {
        this.actions.splice(index, 1)
        if (this.finishedActionIdArr.length > 10) {
          this.finishedActionIdArr.splice(0, 1)
        }
        this.finishedActionIdArr.push(id as string)
      }
    } else {
      if (this.finishedActionIdArr.includes(id as string)) return
      // 计算出当前进度条的宽度百分比
      const { current, max } = action.data
      action.data.percent = current ? Math.round(current / max * 100) : 0
      if (index !== -1) {
        this.actions[index] = action.data
      } else {
        this.actions.push(action.data)
      }
    }
  }

  cancelAction () {
    this.isCancel = true
    const traceIdArr = getTraceIds()
    traceIdArr.forEach((traceId: string) => {
      cancelAction(traceId)
    })
  }

  closeLoading () {
    AppModule.updateAppLoadingStatus({ level: LoadingLevel.APP, status: false })
    clearTraceIdsCache()
    this.actions = []
  }

  copyStackTrace () {
    this.clipboard = new Clipboard('.clipButton', {
      text: () => this.errorDetail.stackTrace
    })
    this.clipboard.on('success', () => {
      logwire.ui.message({
        type: 'success',
        message: this.$i18n('core', 'client.tips.copy-stack-trace-successfully')
      })
      this.clipboard.destroy()
      this.clipboard = null
    })
    this.clipboard.on('error', () => {
      logwire.ui.message({
        type: 'error',
        message: this.$i18n('core', 'client.tips.copy-stack-trace-unsuccessfully')
      })
      this.clipboard.destroy()
      this.clipboard = null
    })
  }

  closeErrorDetail () {
    this.showErrorDetail = false
    this.errorDetail = {
      message: '',
      stackTrace: '',
      traceId: ''
    }
  }

  getI18nMessageWithDefault (namespace: string, code: string, defaultValue: string) {
    const value = this.$i18n(namespace, code)
    if (value === code) return defaultValue
    else return value
  }

  async created (): Promise<void> {
    LocalModule.loadGatewayProperties()
    // #2454 原先这里的 catch 处理，现在放到各自接口的 axios 拦截中
    Promise.all([getClientResourceList(), getI18nMessages()])
      .then(async (res: any) => {
        const [resourcesRes, i18nRes] = res
        LocalModule.loadI18nMessages(i18nRes.data.data)
        const { data } = resourcesRes.data
        this.commonJsCount = data.filter((path: string) => path.endsWith('.common.js')).length
        for (const path of data) {
          if (!/(.css|.js|logo.png|logo.jpg|favicon.ico)$/.test(path)) continue
          const url = `${apiClientResource}?path=${path}&resourceType=resource-browser`
          if (path.endsWith('.css')) {
            const link = document.createElement('link')
            link.href = url
            link.rel = 'stylesheet'
            link.type = 'text/css'
            document.head.appendChild(link)
          } else if (path.endsWith('.js')) {
            // 特殊处理 .common.js 中的内容，需要将其挂在到 logwire.common 对象上
            if (path.endsWith('.common.js')) {
              await getClientResource({ path, resourceType: 'resource-browser' })
                .then(res => {
                  const namespace = path.replace(/\/.*$/, '')
                  const content = res.data
                  --this.commonJsCount
                  // 当所有的 common.js 都加载完成之后才去加载别的内容
                  // 避免页面中使用 common.js 中的内容找不到
                  if (this.commonJsCount === 0) {
                    this.getClientConfig(() => {
                      this.getUserHomeLayout()
                    })
                  }
                  // 假如之前已经有同 namespace 的 common 内容，则取之前的进行扩充，没有则新建
                  logwire.common[namespace] = logwire.common[namespace] || {}
                  const namespacedCommon = logwire.common[namespace]
                  tryEval(`var common = param.common; ${content};`, { common: namespacedCommon })
                })
                .catch(e => console.log(e))
            } else {
              const script = document.createElement('script')
              script.src = url
              document.body.appendChild(script)
            }
          } else if (path.endsWith('logo.png') || path.endsWith('logo.jpg')) {
            if (path.endsWith('dark-logo.png') || path.endsWith('dark-logo.jpg')) {
              this.darkLogo = url
            } else {
              this.lightLogo = url
            }
          } else if (path.endsWith('favicon.ico')) {
            const link = document.createElement('link')
            link.rel = 'icon'
            link.href = url
            document.head.append(link)
          }
        }
        Object.freeze(logwire.common)
      })
      .catch(() => 0)
    window.addEventListener('beforeunload', this.handleAppUnload)
    eventbus.$on('update-processing-action', this.handleProcessingAction)
    eventbus.$on('show-error-detail', (errorDetail: any) => {
      const { message, stackTrace, traceId } = errorDetail
      this.showErrorDetail = true
      this.errorDetail = {
        message,
        stackTrace,
        traceId
      }
    })
  }

  beforeDestroy () {
    eventbus.$off('show-error-detail')
  }
}
