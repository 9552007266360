
























































import CleaveNumber from '@/components/layout/inner/CleaveNumber.vue'
import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { calculation, checkValueIsEmpty } from '@/utils/data'
import { attributeType, calculationType } from '@/utils/const'
import NumberBase from '@/components/layout/form-item/number/NumberBase'
import { isNumber } from 'lodash'

@Component({
  name: 'LwNumber',
  components: {
    'lw-cleave-number': CleaveNumber
  }
})
export default class LwNumber extends Mixins(FormControlComponent, NumberBase) {
  get minValue () : number { return this.getFinalAttributeValue('minValue', { args: this.args, types: [attributeType.NUMBER] }) }

  get maxValue () : number { return this.getFinalAttributeValue('maxValue', { args: this.args, types: [attributeType.NUMBER] }) }

  get title (): string { return this.getFinalAttributeValue('title', { args: this.args }) }

  validateNumberRange = (rule: any, value: any, callback: any) => {
    if (isNumber(value)) {
      const hasMax = !checkValueIsEmpty(this.maxValue)
      const hasMin = !checkValueIsEmpty(this.minValue)
      if (hasMax && hasMin) {
        value < this.minValue || value > this.maxValue ? callback(new Error(this.$i18n('core', 'client.form.number.range', [this.title, this.maxValue, this.minValue]))) : callback()
      } else if (hasMin) {
        value < this.minValue ? callback(new Error(this.$i18n('core', 'client.form.number.minimum', [this.title, this.minValue]))) : callback()
      } else if (hasMax) {
        value > this.maxValue ? callback(new Error(this.$i18n('core', 'client.form.number.maximum', [this.title, this.maxValue]))) : callback()
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  get rules (): Record<string, any> | void {
    const tipArr = []
    if (this.required) {
      const requiredTip = {
        required: this.required,
        message: this.$i18n('core', 'client.form.is-required', this.title),
        trigger: 'blur'
      }
      tipArr.push(requiredTip)
    }
    if (!checkValueIsEmpty(this.maxValue) || !checkValueIsEmpty(this.minValue)) {
      const mmTip = { validator: this.validateNumberRange, trigger: 'blur' }
      tipArr.push(mmTip)
    }
    return tipArr
  }
}
