<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellAutoComplete from '../TableCellBase.vue'
import FormAutoComplete from './AutoComplete.vue'

@Component({ name: 'LwAutoComplete', components: { TableCell: TableCellAutoComplete, FormItem: FormAutoComplete } })
class LwAutoComplete extends FormControlBase {}
export default LwAutoComplete
</script>
