import { formatDate } from '@/utils/data'
import logwire from '@/logwire'

export interface ITimeContentStruct {
  value: any;
  type?: string;
}

const TYPES = ['select', 'picker']

export const dynamicAttrs: Array<keyof ITimeContentStruct> = ['type']

export const getDateTimeFormat = () => {
  const formatDate = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  const formatTime = logwire.store.getConfig('core.i18n.time-format') === '24-hour'
    ? 'HH:mm:ss'
    : 'hh:mm:ss A'
  return formatDate + ' ' + formatTime
}

const formatValue = (params: ITimeContentStruct) => {
  const { type = 'select', value } = params
  const finalType = TYPES.includes(type) ? type : 'picker'
  let result = value
  if (type === 'select' && value && value.includes(':')) {
    const times = value.split(':')
    result = times[0] + ':' + times[1]
  }
  return result
}

const formatContent = ({ value, type }: ITimeContentStruct) => {
  let display = formatDate(value, 'time')
  if (type === 'select' && value && value.includes(':')) {
    const displays = display.split(' ')
    const times = displays[0].split(':')
    display = times[0] + ':' + times[1] + (displays.length > 1 ? ' ' + displays[1] : '')
  }
  return display
}

export default {
  formatValue,
  formatContent,
  dynamicAttrs
}
