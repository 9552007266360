<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellCheckbox from './TableCellCheckbox.vue'
import Checkbox from './Checkbox.vue'

@Component({ name: 'LwCheckbox', components: { TableCell: TableCellCheckbox, FormItem: Checkbox } })
class LwCheckbox extends FormControlBase {}
export default LwCheckbox
</script>
