import { DataForSave, DataListForSave, DataRow, DataSet, DetailDataSets, ICondition, IConditionGroup } from '@/types/data'
import _, { isObject } from 'lodash'
import {
  QUICK_FILTER_ATTRIBUTE_MAP,
  SCREEN_SIZE_BREAK_POINTS,
  filterOperator,
  attributeType,
  operationType,
  connector,
  conditionType,
  totalByType,
  calculationType,
  commonSemanticPartitions,
  CSRF_TOKEN,
  CHECKED_ARCHIVE_STORAGE,
  rowPersist
} from '@/utils/const'
import Vue from 'vue'
import { LayoutModule, LayoutState } from '@/store/modules/layout'
import logwire from '@/logwire'
import Args from '@/models/Args'
import Condition from '@/models/Condition'
import ConditionGroup from '@/models/ConditionGroup'
import moment from 'moment-timezone'
import eventbus from './event'
import DataRowObj from '@/models/DataRow'
import { formatDate as formatDateFn } from 'element-ui/src/utils/date-util'
import { AxiosResponse } from 'axios'
import { emitLayoutEvent, proceedAfterEditingDataSetCheck } from './layout'
import { LwForm } from '@/types/form'

/**
 * 合并数据
 * @param existDataRow
 * @param newDataRow
 * @param init: 合并旧数据之前，是否将之前的数据重置
 */
export function mergeDataRow (existDataRow: DataRow, newDataRow: DataRow, init = false) : void {
  if (init) {
    for (const idx in existDataRow.currentData) {
      existDataRow.currentData[idx] = ''
    }
    for (const idx in existDataRow.originalData) {
      existDataRow.originalData[idx] = ''
    }
  }
  existDataRow.currentData = { ...existDataRow.currentData, ...newDataRow.currentData }
  existDataRow.rowPersist = newDataRow.rowPersist
  if (existDataRow.originalData && newDataRow.originalData) {
    existDataRow.originalData = { ...existDataRow.originalData, ...newDataRow.originalData }
  }
}

// 无值字段不会覆盖有值字段
export function mergeDataRowCompactly (existDataRow: any, newDataRow: any) : void {
  for (const key in newDataRow) {
    if (!newDataRow[key] && existDataRow[key]) continue
    if (!Object.hasOwnProperty.call(existDataRow, key) || _.isString(newDataRow[key]) || _.isNumber(newDataRow[key])) Vue.set(existDataRow, key, newDataRow[key])
    if (_.isFunction(newDataRow[key])) {
      existDataRow[key] = newDataRow[key]
    } else if (_.isObject(newDataRow[key])) {
      mergeDataRowCompactly(existDataRow[key], newDataRow[key])
    }
  }
}

export function formatDataset (dataSet: DataSet): void {
  if (dataSet.rows) {
    dataSet.rows = dataSet.rows.map(row => formatDataRow(row))
  }
}

export function formatDataRow (row: Record<string, any>): DataRow {
  // 有些情况下，传过来的row已经格式化
  if (row?.currentData) return row as DataRow
  const formattedRow = {} as DataRow
  formattedRow.currentData = row
  formattedRow.originalData = _.cloneDeep(row)
  // TODO 是否在使用 state 时再去生成添加 state
  formattedRow.state = {}
  return formattedRow
}

/**
 * 根据 form 变量得到 DataRow，满足开发者在 onChange 等修改时能够改变 originalData 的需求；
 * 如果是快速查询，则直接返回数据对象
 */
export function getFormDataRow (form: LwForm): DataRow | undefined {
  if (!form.asFilterForm && form.dataSet) {
    return form.dataSet.rows[0]
  } else if (form.asFilterForm) {
    return formatDataRow(form.dataRow)
  }
}

export function getEventName (layoutName: string, dataset: string, eventName: string): string {
  return `${layoutName}.${dataset}.${eventName}`
}

export function formatQuickFilter (quickFilterBak: any): IConditionGroup {
  const fieldMap = _.keyBy(quickFilterBak.fields, (f) => f.field)
  const fieldDataArr = []
  for (const d in quickFilterBak.data) {
    const qd = quickFilterBak.data[d]
    if (!(checkValueIsEmpty(qd) || (Object.prototype.toString.call(qd) === '[object Array]' && (qd.indexOf(null) > -1 || qd.length < 1)))) {
      fieldDataArr.push(_.merge(fieldMap[d], { value: qd }))
    }
  }
  // 转换并过滤fieldDataArr
  const filterRes = new logwire.class.ConditionGroup()
  for (const item of fieldDataArr) {
    const filterItem: ICondition = {
      type: conditionType.CONDITION,
      field: item.quickFilterField || item.field,
      value: item.quickFilterOperator === filterOperator.IN && _.isString(item.value)
        ? item.is === 'lw-textarea'
          ? item.value.split(/\r+|\n+/g).filter((v: string) => v)
          : item.value.split(',').filter((v: string) => v)
        : item.value,
      name: item.name,
      operator: item.quickFilterOperator,
      subQueryName: item.quickFilterSubQuery,
      subQueryFilterField: item.quickFilterSubQueryFilterField,
      subQueryFilterOperator: item.quickFilterSubQueryFilterOperator
    }
    if (filterItem.field && filterItem.value) filterRes.add(filterItem)
  }
  return filterRes.build()
}

export function checkValueIsEmpty (val: any): boolean {
  if (val === '' || val === null || val === undefined || (Array.isArray(val) && val.length < 1) || val === 'undefined' || val === 'null') {
    return true
  }
  return false
}

export function resetDataRow (item: Record<string, any>) : void {
  for (const key in item) {
    item[key] = ''
  }
}

export function getQueryFilter (ds: LayoutState['data'][number]['systemState'][number]) {
  const filter: Partial<{
    pageSize: number,
    pageNo: number,
    masterFilter: Record<string, any>,
    queryParams: Record<string, any>,
    quickFilter: any,
    advancedFilter: any,
    partitionFilter: any,
    getTotalBy: totalByType,
    aggregationFields: any[],
    orderBy: any[]
    keywordFilter: any,
    countState: string
  }> = {}
  let pageSize = ds.pageSize
  if (typeof pageSize !== 'number' || pageSize < 1) {
    pageSize = -1
  }
  filter.pageSize = ds.tempPageSize || pageSize
  filter.pageNo = ds.tempPageNo || ds.pageNo

  const masterFilter = ds.masterFilter
  if (masterFilter?.conditions?.length) {
    filter.masterFilter = masterFilter
  }
  const queryParams = ds.queryParams
  if (queryParams) {
    filter.queryParams = queryParams
  }

  // relationQuickData是真正的表单值，quickFilter是约束条件的集合
  const quickFilterData = ds.quickFilter
  if (quickFilterData) {
    // remove 空值
    const filtersBak = JSON.parse(JSON.stringify(quickFilterData))
    const quickFilter = formatQuickFilter(filtersBak)
    quickFilter.conditions.length && (filter.quickFilter = quickFilter)
  }

  const advancedFilter = ds.advancedFilter
  if (advancedFilter?.conditions?.length) {
    filter.advancedFilter = advancedFilter
  }

  const partitionFilter = ds.partitionFilter
  if (partitionFilter) {
    filter.partitionFilter = partitionFilter
  }

  const keywordFilter = ds?.keywordFilter
  keywordFilter?.fields.length && !checkValueIsEmpty(keywordFilter.value) && (filter.keywordFilter = keywordFilter)

  // 对于后端来说，getTotalBy 为 count 会触发聚合、查询精确总数；none 不会查询精确总数
  filter.getTotalBy = ds?.getTotalBy || totalByType.CONUT
  if (filter.getTotalBy === totalByType.CONUT) {
    const aggregationFields = ds?.aggregationFields
    if (aggregationFields) {
      filter.aggregationFields = aggregationFields
    }
  }

  // 视图中的 orderBy, todo 还有其他排序规则？
  const orderBy = ds?.orderBy
  orderBy && (filter.orderBy = orderBy)
  return filter
}

export function getDataRowsForSave (rows: DataRow[]) {
  const dataRows = []
  for (const row of rows) {
    if (!row.currentData.id && row.rowPersist === rowPersist.DELETE) continue
    dataRows.push({
      currentData: row.currentData,
      originalData: row.originalDataForHeaderDetailEdit || row.originalData,
      rowPersist: row.currentData.id ? row.rowPersist ? row.rowPersist : rowPersist.UPDATE : rowPersist.INSERT
    })
  }
  return dataRows
}

export function getDataListForSave (headerDataSetName: string, headerQueryName: string, headerRecordList: Array<DataRow>, layoutName?: string, detailDataSets?: Array<DetailDataSets>): DataListForSave {
  const dataListForSave = {
    dataList: [
      {
        dataSetName: headerDataSetName,
        queryName: headerQueryName,
        recordList: headerRecordList
      }
    ]
  }
  if (detailDataSets) {
    for (const detailDataSet of detailDataSets) {
      const { detailDataSetName } = detailDataSet
      const dataForSave: DataForSave = {
        recordList: [],
        queryName: '',
        dataSetName: detailDataSetName
      }
      // 通过事件通知对应的组件传递行数据
      emitLayoutEvent(`${detailDataSetName}.generateUpdatedRows`, dataForSave)
      dataForSave.recordList = dataForSave.recordList.map((row: any) => _.pick(row, ['currentData', 'originalData', 'rowPersist']) as DataRow)
      dataForSave.recordList.length && dataListForSave.dataList.push(dataForSave)
    }
  }
  return dataListForSave
}

export function getSelection (layoutName: string, dataSetName: string) : Record<string, any> {
  const ds = LayoutModule.data[layoutName].systemState?.[dataSetName]
  // 存在 selection 说明是表格
  if (ds?.selection) {
    const selectAll = ds?.selectAll
    if (selectAll) {
      let filter = getQueryFilter(ds)
      filter = JSON.parse(JSON.stringify(filter))
      delete filter.pageSize
      delete filter.pageNo
      delete filter.getTotalBy
      delete filter.orderBy
      return {
        query: ds.queryName,
        filters: filter
      }
    } else {
      const selection = LayoutModule.data[layoutName].systemState?.[dataSetName]?.selection || []
      return { query: ds.queryName, selectedRows: selection.map((row: DataRow) => JSON.parse(JSON.stringify(row.currentData))) }
    }
  }
  // form
  const dataSet = LayoutModule.data[layoutName].dataSet[dataSetName]
  return {
    query: ds?.queryName,
    selectedRows: dataSet.rows
      ? dataSet.rows.map((row: DataRow) => JSON.parse(JSON.stringify(row.currentData)))
      : []
  }
}

// 区别于 getSelection
// 根据 dataRows 数据拼成 doSelectedRowsAction 所需要数据
export function getSelectionByDataRows (layoutName: string, dataSetName: string, dataRows: Array<DataRowObj>) {
  const ds = LayoutModule.data[layoutName].systemState?.[dataSetName]
  const selection = dataRows.map(dataRow => dataRow.row.currentData)
  return {
    query: ds.queryName,
    selectedRows: selection
  }
}

/**
   * 遍历树结构数据
   * @parmas {Array<treeData>} 需要遍历的树结构数据
   * @params {prop} 遍历字段
   * @params {callback} 遍历的回调
   */
export function forEachOfTreeData (treeData: Array<any>, prop: string, callback: (...res: any) => void) {
  for (const t of treeData) {
    if (t[prop]) {
      forEachOfTreeData(t[prop], prop, callback)
    }
    callback(t)
  }
}

// TODO 之后版本应废除
/**
 * 旧版 masterFilter 形式为 masterFilter: { field: [ field: '', operator: '', value: '' ] }
 * 新版形式发生了变化，为了兼容过度，这里将旧版格式整理为新版所需格式，在之后版本中再进行废除
 */
export function formatMasterFilter (masterFilter: Array<any> | Condition | ConditionGroup | IConditionGroup | undefined): IConditionGroup | undefined {
  const cg = new ConditionGroup()
  let result!: IConditionGroup | undefined
  if (masterFilter instanceof Condition) {
    result = cg.add(masterFilter).build()
  } else if (masterFilter instanceof ConditionGroup) {
    result = masterFilter.build()
  } else if (_.isArray(masterFilter)) {
    masterFilter.forEach(f => {
      result = cg.add(new Condition(f.field, f.operator, f.value)).build()
    })
  } else {
    result = masterFilter
  }
  return result
}

/**
 * 解决 js 计算精度丢失问题
 * @param {params} 要计算的数的数组，计算顺序从左向右
 * @param {type} 计算类型，sum、subtract、multiplication、division
 */
export function calculation (params: Array<number>, type: calculationType) {
  let result!: number
  // 加
  const add = (arg1: number, arg2: number) => {
    let r1, r2
    try {
      // 第一个数的小数位数
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      // 第二个数的小数位数
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    // 小数位数之差
    const c = Math.abs(r1 - r2)
    // 小数位数补全后所放大的倍数
    const m = Math.pow(10, Math.max(r1, r2))
    // 将两数小数位数补全
    if (c > 0) {
      const cm = Math.pow(10, c)
      if (r1 > r2) {
        arg1 = Number(arg1.toString().replace('.', ''))
        arg2 = Number(arg2.toString().replace('.', '')) * cm
      } else {
        arg1 = Number(arg1.toString().replace('.', '')) * cm
        arg2 = Number(arg2.toString().replace('.', ''))
      }
    } else {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', ''))
    }
    return (arg1 + arg2) / m
  }
  // 减
  const minus = (arg1: number, arg2: number) => {
    let r1, r2
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    const m = Math.pow(10, Math.max(r1, r2))
    const n = (r1 >= r2) ? r1 : r2
    return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
  }
  // 乘
  const multiply = (arg1: number, arg2: number) => {
    let m = 0
    const s1 = arg1.toString()
    const s2 = arg2.toString()
    try {
      m += s1.split('.')[1].length
    } catch (e) {
    }
    try {
      m += s2.split('.')[1].length
    } catch (e) {
    }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
  }
  // 除
  const divide = (arg1: number, arg2: number) => {
    let t1 = 0
    let t2 = 0
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {
    }
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {
    }
    const r1 = Number(arg1.toString().replace('.', ''))
    const r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  }
  if (type === calculationType.SUM) {
    // 求和
    result = params.reduce((total: number, currentValue: number) => {
      return add(total, currentValue)
    }, 0)
  } else if (type === calculationType.SUBTRACT) {
    // 减
    params.forEach((n, i) => {
      if (i === 0) {
        result = n
      } else {
        result = minus(result, n)
      }
    })
  } else if (type === calculationType.MULTIPLICATION) {
    // 乘
    result = params.reduce((total: number, currentValue: number) => {
      return multiply(total, currentValue)
    }, 1)
  } else if (type === calculationType.DIVISION) {
    // 除
    params.forEach((n, i) => {
      if (i === 0) {
        result = n
      } else {
        result = divide(result, n)
      }
    })
  }
  return result
}

// 缓存当前请求的 traceId 到 sessionStorage 中
export function updateTraceIdsCache ({ traceId, type }: { traceId: string, type: 'add' | 'remove' }) {
  const traceIdArr = sessionStorage.getItem('__traceIds') ? JSON.parse(sessionStorage.getItem('__traceIds') as string) : []
  if (type === 'add') {
    traceIdArr.push(traceId)
  } else {
    const index = traceIdArr.indexOf(traceId)
    if (index !== -1) {
      traceIdArr.splice(index, 1)
    }
  }
  sessionStorage.setItem('__traceIds', JSON.stringify(traceIdArr))
}

export function getTraceIds () {
  return sessionStorage.getItem('__traceIds') ? JSON.parse(sessionStorage.getItem('__traceIds') as string) : []
}

export function clearTraceIdsCache () {
  sessionStorage.removeItem('__traceIds')
}

// 保存 csrfToken 到本地缓存
export function saveCsrfToken (csrfToken: string) {
  localStorage.setItem(CSRF_TOKEN, csrfToken)
}

// 获取本地缓存中的 csrfToken
export function getCsrfToken () {
  return localStorage.getItem(CSRF_TOKEN)
}

// 获取 sessionStorage 中的当前所在归档数据库
export function getCheckedArchiveStorage () {
  return sessionStorage.getItem(CHECKED_ARCHIVE_STORAGE)
}

// 获取常用时间区间
export function getPartition (code: string) {
  const end = logwire.util.now().toString()
  let start: any = null
  switch (code) {
    case commonSemanticPartitions.TODAY : start = moment(end)
      break
    case commonSemanticPartitions.LAST_WEEK : start = moment(end).subtract(6, 'days')
      break
    case commonSemanticPartitions.LAST_MONTH : start = moment(end).subtract(1, 'months')
      break
    case commonSemanticPartitions.LAST_3_MONTH : start = moment(end).subtract(3, 'months')
      break
    case commonSemanticPartitions.LAST_6_MONTH : start = moment(end).subtract(6, 'months')
      break
    case commonSemanticPartitions.LAST_YEAR : start = moment(end).subtract(1, 'years')
      break
  }
  const beginDate = formatDateFn(start, 'yyyy-MM-dd')
  const endDate = formatDateFn(end, 'yyyy-MM-dd')
  return {
    beginDate, endDate
  }
}

export function isLeapYear (year: number) {
  return (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0))
}

/**
 * 判断是否是正整数
 * @param n
 * @returns {boolean}
 */
export const isPositiveInteger = (n: any) => _.isInteger(n) && n > 0

/**
 * 用 0 补全数字或者字符串的位数
 */
export const completionFormat = (value: string | number, length: number): string => {
  let result = String(value)
  const currentLength = result.length
  if (currentLength < length) {
    const arr = new Array(length - currentLength).fill(0)
    result = `${arr.join('')}${value}`
  }
  return result
}

// type 的类型有 date、dateTime、time
// formatDate 方法将会根据首选项设置的日期格式对 传入的 date 进行转换
export function formatDate (date: string | Date, type: string): string {
  const formatDate = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  const formatTime = logwire.store.getConfig('core.i18n.time-format') === '24-hour'
    ? 'HH:mm:ss'
    : 'hh:mm:ss A'
  let format = 'yyyy-MM-dd HH:mm:ss'
  if (type === 'date') {
    format = formatDate
  } else if (type === 'dateTime') {
    format = formatDate + ' ' + formatTime
  } else if (type === 'time') {
    // time 类型时如果后端返回 19:12:00 这种格式需要对日期进行补全 "1971-01-01"
    const reg = /^\d{2}:\d{2}(:\d{2})?/
    if (_.isString(date) && reg.test(date)) {
      date = `1971-01-01 ${date}`
    }
    format = formatTime
  }
  return formatDateFn(date, format)
}

export function dateTime2Date (dateTime: string) {
  return dateTime?.match(/\d{4}-\d{2}-\d{2}/)?.[0]
}

export function isPureDate (dateTime: string) {
  return /^\d{4}-\d{2}-\d{2}$/.test(dateTime)
}

export function date2DateTime (dateTime: string) {
  return isPureDate(dateTime) ? dateTime + 'T00:00:00' : dateTime
}

export function getFileNameFromResponse (response: AxiosResponse) {
  let filename = response.headers['content-disposition'] || ''
  if (!filename) return ''
  filename = filename.split('=')[1] || ''
  if (filename.startsWith('UTF-8') || filename.startsWith('utf-8')) {
    // 如果是UTF-8开头则代表服务端已经decode过了
    filename = decodeURIComponent(filename.replace(/UTF-8''/i, ''))
  } else {
    // 如果不是UTF-8开头则代表服务端未decode过，需要escape()
    filename = decodeURIComponent(escape(filename))
  }
  return filename
}

/**
 * 在 isEditLayout 或者 isEditPanel 时打开了 PopupLayout 或者 Panel 弹窗，这是走的是平台的编辑流程，所以弹窗上具有取消、保存按钮
 * 在关闭弹窗前对当前 Layout 下的 editingDataSet 进行检查，如果发现进行了编辑，就应该在关闭弹窗时进行提示
 */
export function checkEditDataSetBeforePopupDialogClose (config: {
  layoutName: string, // 如果在弹窗内打开了新 Layout 则是新 Layout 名称
  editDataSet?: string, // Panel 或 Popup 的 editDataSet 属性, 平台默认的新增编辑行为，肯定都会携带
  isEditLayout?: boolean,
  onClose: () => void
  onSave: () => void
}): void {
  // 关闭弹窗回调
  const callback = () => {
    // 确定关闭时，把正在编辑的 DataSet 还原
    if (config.isEditLayout) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: config.layoutName, dataSet: '' })
    }
    if (config.editDataSet) {
      LayoutModule.removeDataSet({ layoutName: config.layoutName, dataSetName: config.editDataSet })
    }
    config.onClose && config.onClose()
  }
  // 关闭弹窗时，如果存在数据未保存，则作为 "保存并关闭" 或者 "保存" 等行为的回调
  const saveCallback = () => {
    config.onSave && config.onSave()
  }
  proceedAfterEditingDataSetCheck(callback, saveCallback, config.layoutName)
}

/**
 * 判断是不是一个 Observer 对象
 * */
export const isObservable = (obj: Record<string, any>) => {
  return obj && isObject(obj) && (obj as Record<string, any>).__ob__
}
