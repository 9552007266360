


















import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import RadioBase from '@/components/layout/form-item/radio/RadioBase'

@Component({ name: 'LwRadio' })
export default class LwRadio extends Mixins(FormControlComponent, RadioBase) {
  get inputValue () {
    const value = this.form.dataRow[this.field]
    return value
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = value
  }

  get valueChanged (): boolean {
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    } else if (this.form.asQuickEditForm) {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const { field } = this.component
    return row.currentData[field] !== row.originalData[field]
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.displayValue
  }
}
