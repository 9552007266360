import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import logwire from '@/logwire'
import { attributeType } from '@/utils/const'
import { formatDataRow } from '@/utils/data'
import formatter from '@/components/layout/form-item/select/formatter'

@Component
class SelectBase extends Vue {
  [propName: string]: any;

  get displayValue () {
    return this.getTemplate()
  }

  getTemplate (): string | string[] {
    const { displayTemplate, displayField } = this.component
    const row = this.form?.dataRow
    if (this.evaluatingBase === 'advancedFilter') {
      return row[this.field + '_label']
    } else {
      const content = this.getFinalAttributeValue('displayContent', { args: this.args, types: [attributeType.STRING, attributeType.STRING_ARRAY] })
      const dataRow = formatDataRow(row)
      return formatter.formatContent({ displayTemplate, displayContent: content, displayField, row: dataRow, args: this.args })
    }
  }
}

export default SelectBase
