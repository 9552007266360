import { Component, Vue } from 'vue-property-decorator'
import formatter, { getDateTimeFormat } from '@/components/layout/form-item/dateTime/formatter'
const DATE_TYPES = ['datetime', 'datetimerange']

@Component
class DateTimeBase extends Vue {
  [propName: string]: any;

  get type (): string {
    const type = this.getFinalAttributeValue('type', { args: this.args })
    return DATE_TYPES.includes(type) ? type : 'datetime'
  }

  get displayFormat (): string {
    return getDateTimeFormat()
  }

  get inputValue (): any {
    const { field } = this.component
    const dataRow = this.form.dataRow
    const value = dataRow[field]
    return formatter.formatValue({ value, type: this.type })
  }

  set inputValue (value: any) {
    this.form.dataRow[this.component.field] = value
  }

  // 根据首选项
  get displayValue (): string {
    return formatter.formatContent({ value: this.inputValue })
  }
}

export default DateTimeBase
