






import { Component, Prop, Watch } from 'vue-property-decorator'
import { DataRow } from '@/types/data'
import Args from '@/models/Args'
import { attributeType, layoutStatus } from '@/utils/const'
import { getColor } from '@/utils/common'
import BaseComponentCommon from '@/components/layout/mixin/BaseComponentCommon'

@Component({
  name: 'TableCellBase'
})
class TableCellBase extends BaseComponentCommon {
  @Prop() tableRow!: DataRow;
  @Prop() rowEnabled!: boolean;

  get displayValue (): string {
    return this.inputValue
  }

  get args () {
    return new Args(this.context, { row: this.tableRow })
  }

  get form () {
    return { dataRow: this.tableRow?.currentData }
  }

  get inputValue (): any {
    return this.tableRow?.currentData?.[this.component.field]
  }

  get disabled () {
    // 在弹出层或者抽屉处于 view 状态下的元素置为 disabled
    if (this.popupLayoutStatus === layoutStatus.VIEW) {
      return true
    }
    // 不具备编辑权限应该只能看
    if (!this.accessibleResourcesWithEdit) {
      return true
    }
    // table 行是否可用rowEnabled
    if (!this.rowEnabled) {
      return true
    }

    return this.component.enabled
      ? !this.getFinalAttributeValue('enabled', { args: this.args, type: attributeType.BOOLEAN })
      : false
  }

  get visible (): boolean {
    const result = this.component.visible
      ? this.getFinalAttributeValue('visible', { args: this.args, type: attributeType.BOOLEAN })
      : true
    return result
  }

  get fontColorScheme () {
    return this.getFinalAttributeValue('fontColorScheme', { args: this.args })
  }

  get backColorScheme () {
    return this.getFinalAttributeValue('backColorScheme', { args: this.args })
  }

  get href () {
    return this.getFinalAttributeValue('href', { args: this.args })
  }

  get target () {
    return this.getFinalAttributeValue('target', { args: this.args }) || '_blank'
  }

  get hasOnClick () {
    return this?.component.onClick
  }

  get displayElement () {
    return this.href ? 'a' : 'div'
  }

  get classList () {
    const classList = []
    if (this.href) classList.push('underline')
    if (this.hasOnClick) classList.push('clickable')
    return classList
  }

  handleClick (evt: MouseEvent) {
    if (this.hasOnClick) {
      evt.preventDefault()
      this.runRunnableContent('onClick', { args: this.args })
    }
  }

  @Watch('fontColorScheme', { immediate: true })
  handleFontColorSchemeChange (newScheme: string) {
    if (!newScheme) return
    const color = getColor(newScheme) || ''
    const setColor = () => {
      // 当有字段名称时，真正展示的内容会被放置在 display-field-value 这个类名元素底下
      const el = this.$el.querySelector('.display-field-value')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el && (el.style.color = color)
    }
    this.$nextTick(() => {
      setColor()
    })
  }

  @Watch('backColorScheme', { immediate: true })
  handleBackColorSchemeChange (newScheme: string) {
    if (!newScheme) return
    const color = getColor(newScheme) || ''
    const setColor = () => {
      let el: any = this.$el
      while (!el.classList.contains('vxe-body--column') || el.nodeName === 'td') {
        el = el.parentNode
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el.style.backgroundColor = color
    }
    this.$nextTick(() => {
      setColor()
    })
  }

  getChangedStatus () {
    const { currentData, originalData } = this.tableRow
    const { field } = this.component
    return currentData?.[field] !== originalData?.[field]
  }

  handleMouseEnter () {
    if (!this.disabled && this.component.is !== 'lw-timestamp') {
      const editIcon = document.createElement('i')
      editIcon.setAttribute('class', 'iconfont icon-bianjixiao table-edit-icon')
      editIcon.onclick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('quick-edit')
      }

      const resetIcon = document.createElement('i')
      resetIcon.setAttribute('class', 'iconfont icon-chexiao table-edit-icon')
      resetIcon.onclick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('data-row-reset')
      }
      this.$el.appendChild(editIcon)
      const changed = this.getChangedStatus()
      if (changed) {
        this.$el.insertBefore(resetIcon, editIcon)
      }
    }
  }

  handleMouseLeave () {
    const icons = this.$el.querySelectorAll('.table-edit-icon')
    if (icons?.length) {
      for (const icon of icons) {
        this.$el.removeChild(icon)
      }
    }
  }

  mounted () {
    this.$el.addEventListener('mouseenter', this.handleMouseEnter)
    this.$el.addEventListener('mouseleave', this.handleMouseLeave)
  }

  beforeDestroy () {
    this.$el.removeEventListener('mouseenter', this.handleMouseEnter)
    this.$el.removeEventListener('mouseleave', this.handleMouseLeave)
  }
}
export default TableCellBase
