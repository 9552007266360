
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import ChoiceBase from './ChoiceBase'

@Component({
  name: 'TableCellChoice'
})
class TableCellChoice extends Mixins(TableCellBase, ChoiceBase) {}
export default TableCellChoice
