import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator'
import DatasetBoundComponent from '@/components/layout/DatasetBoundComponent'
import _ from 'lodash'
import {
  attributeSymbol,
  attributeType,
  EDIT_LAYOUT_DATASET_NAME,
  EDIT_LAYOUT_POPUP_NAME_SUFFIX,
  EDIT_LAYOUT_SAVE_TRIGGER_MAP,
  layoutStatus,
  operationType, RequestError,
  rowPersist,
  SCRIPT_SAVE_AND_NEW_EVENT,
  SYSTEM_DB_RESERVED_FIELDS
} from '@/utils/const'
import eventbus from '@/utils/event'
import { LayoutModule } from '@/store/modules/layout'
import {
  emitLayoutEvent,
  findMasterFields,
  getGlobalI18nMessage,
  getPlainAttributeValue,
  isEventRegistered,
  warnDuplicatedEventRegistration,
  warnEventNotSupported
} from '@/utils/layout'
import { formatDataRow, formatMasterFilter, getDataListForSave, getEventName } from '@/utils/data'
import { doDelete, doSave, getLayout, getQueryMeta } from '@/http/api'
import logwire from '@/logwire'
import { DataRow, DetailDataSets, DataSet as IDataSet, IConditionGroup } from '@/types/data'
import Args from '@/models/Args'
import DataSet from '@/models/DataSet'
import { PopupLayoutConfig } from '@/types/layout'
import DataRowCls from '@/models/DataRow'
import { closeAllErrorMessages } from '@/utils/dom'
import { AppModule } from '@/store/modules/app'

@Component
class QueriableComponent extends DatasetBoundComponent {
  status = layoutStatus.VIEW
  afterSearched = false
  openedPopupLayout!: Partial<PopupLayoutConfig>
  queryMeta = null
  afterGetQueryMeta: Array<any> = []

  @InjectReactive() searchBarComplete!: Record<string, boolean>
  @InjectReactive() queriableComponentComplete!: Record<string, boolean>

  // TODO 文档中注明拒绝 query 写动态的
  get query (): string { return this.component.query }

  get editDataSet (): string { return this.component.editDataSet || EDIT_LAYOUT_DATASET_NAME }

  get retrieveOnLayoutLoad (): boolean {
    const result = this.getFinalAttributeValue('retrieveOnLayoutLoad', { type: attributeType.BOOLEAN })
    return _.isUndefined(result) ? true : result
  }

  get layoutEditing (): boolean {
    const status = this.context.getLayoutStatus()
    return status && status === layoutStatus.EXPRESS_EDIT
  }

  get detailDataSets (): Array<DetailDataSets> {
    return LayoutModule.resource[this.encodedLayoutName]?.detailDataSets?.filter(o => o.headerDataSetName === this.dataSetName) || []
  }

  get afterLoadEvents (): string[] {
    return LayoutModule.data[this.encodedLayoutName].afterLoadEvents || []
  }

  // 当一个页面有一些事件需要在页面加载后触发时，有可能需要 queryMeta
  // 所以将这些事件移到 queryMeta 获取到之后
  @Watch('queryMeta')
  handleQueryMetaFetched (value: any) {
    if (!this.afterLoadEvents.length && !this.afterGetQueryMeta.length) return
    if (this.query || this.component.onRetrieve) {
      this.afterGetQueryMeta.forEach(fn => {
        fn(value)
      })
      this.afterGetQueryMeta = []
      for (const event of this.afterLoadEvents) {
        const eventDataSetName = event.split('.')[0]
        if (eventDataSetName === this.dataSetName) {
          emitLayoutEvent(event)
          LayoutModule.removeAfterLoadEvent({ layoutName: this.encodedLayoutName, eventName: event })
        }
      }
    }
  }

  // 在 layout 节点的 afterLoad 脚本中，或者在 panel 的 afterOpen 脚本中，开发者可能会主动触发 new 或者 edit 事件
  // 而此时的 queryMeta 可能还没有查询结果, new 的时候不知道表单里有哪些字段
  handleAfterQueryMeta (fun: (params: any) => unknown) {
    if (this.queryMeta || this.component.onRetrieve) {
      fun(this.queryMeta)
    } else {
      this.afterGetQueryMeta.push(fun)
    }
  }

  getCurrentDataSet (): DataSet {
    return this.context.getOrCreateDataSet(this.dataSetName)
  }

  handleHeaderEdit (mode: layoutStatus) {
    this.status = mode
  }

  // beforeRetrieve、beforeNew 等 before 事件脚本执行逻辑
  // 如果定义了 behavior 则执行 behavior，并由开发者自行调用 proceed 来决定是否继续 此行为
  beforeBehaviorCommon (behavior: string, proceed: (data?: any) => void, params?: { [propName: string]: any }) {
    if (this.component[behavior]) {
      // 默认 args 上只携带 proceed 方法
      const props = { row: (this as any).dataRow, proceed }
      // 如果 args 还需要别的方法，由 params 传递进来
      if (params) {
        for (const key in params) {
          props[key] = params[key]
        }
      }
      this.runRunnableContent(behavior, { args: new Args(this.context, props) })
    } else {
      proceed()
    }
  }

  registerUniqueEvent (eventName: string, handler: (args: any) => void, duplicateKeys: Array<string>) {
    if (isEventRegistered(eventName)) {
      warnDuplicatedEventRegistration(eventName, this.component.is, duplicateKeys)
      return
    }
    eventbus.$on(eventName, handler)
  }

  proceedAfterStatusCheck (process: () => void) {
    if (this.status === layoutStatus.VIEW && !this.layoutEditing) {
      process()
    } else {
      logwire.ui.message({
        message: this.$i18n('core', 'client.tips.is-editing'),
        type: 'warning'
      })
    }
  }

  handleDataSetRetrieve (): void {
    if (this.component.onRetrieve) {
      // 写了 onRetrieve 则不在执行 beforeRetrieve 与 afterRetrieve
      // before 和 after 的逻辑由开发者在 onRetrieve 中自行完成
      const applyDataToCurrentDataSet = ({ rows, total }: { rows: Array<Record<string, any>>, total: number }) => {
        const dataSetName = this.dataSetName
        const dataSet = {
          dataSetName,
          rows
        } as IDataSet
        LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: Object.assign(dataSet, { dataSetName }) })
        if (total) LayoutModule.data[this.encodedLayoutName].systemState[dataSetName].total = total
      }
      this.runRunnableContent('onRetrieve', { args: new Args(this.context, { getCurrentDataSet: this.getCurrentDataSet, applyDataToCurrentDataSet }) })
    } else {
      const proceed = (param?: { id?: number }) => {
        // 当 url 中含有 id 字段并且设置了 query 属性时，按照 ID 进行 query
        // 开发者可以在 onRetrieve 中 通过 调用 args.proceed({id: **}) 来指定根据 id 进行加载
        const id = param?.id || parseFloat(this.context.getLayoutParam('id'))
        const layoutName = this.editLayoutName || this.layoutName
        if (!_.isFinite(id)) return
        // 校验 id 是否来自 layoutParam
        const queryFromLayoutParam = id && !param?.id
        LayoutModule.loadDataById({
          layoutName,
          encodedLayoutName: this.encodedLayoutName,
          namespace: this.context.getNamespace(),
          query: this.component.query,
          dataSetName: this.dataSetName,
          id,
          callback: () => {
            this.component.afterRetrieve && this.runRunnableContent('afterRetrieve', { args: new Args(this.context, { getCurrentDataSet: this.getCurrentDataSet }) })
          },
          failCallback: (message: string) => {
            // 当请求出现 record not found 错误并且当前 id 来源是 layoutParam 时，进行错误提示，并且 layout 进入不可操作状态
            if (message === RequestError.RecordNotFound && queryFromLayoutParam) {
              const { error } = logwire.ui.message
              error && error(this.$i18n('core', 'client.tips.record-not-found'))
              LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.ERROR })
            }
          }
        })
      }
      this.beforeBehaviorCommon('beforeRetrieve', proceed, { getCurrentDataSet: this.getCurrentDataSet })
    }
  }

  /**
   * 查询 editlayout 的结构信息，并存储 LayoutResource 到 vuex 里，避免打开弹窗后，弹窗卡一段时间再显示内容的情况
   * @param editLayout
   */
  async getEditLayoutComponents (editLayout: string) {
    const layoutName = editLayout.includes('.')
      ? editLayout
      : `${this.context.getNamespace()}.${editLayout}`
    // 先从缓存获取，获取不到再发请求
    const cacheLayoutResource = LayoutModule.resource[layoutName]
    if (cacheLayoutResource) {
      const layout = cacheLayoutResource?.metaData?.components
      return layout
    } else {
      return getLayout({ layout: layoutName }).then(res => {
        const resData = res.data.data
        LayoutModule.loadLayoutResource({
          ...resData,
          layoutName
        })
        const layout = resData?.metaData?.components
        return layout
      }, e => { console.error(e) })
    }
  }

  async getCommonOpenLayoutParams (mode: layoutStatus, row: any, layoutParams: Record<string, any> = {}) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const { editLayout } = this.component
    const editDataSet = this.editDataSet

    const getLayoutName = (defaultName: string | null) => {
      return editLayout
        ? editLayout.includes('.')
          ? editLayout
          : `${this.context.getNamespace()}.${editLayout}`
        : defaultName
    }

    const drawerParams: Partial<PopupLayoutConfig> = {
      layoutName: getLayoutName(this.layoutName + EDIT_LAYOUT_POPUP_NAME_SUFFIX),
      editLayout: getLayoutName(null),
      layouts: [],
      layoutStatus: mode,
      isEditLayout: true,
      editDataSet,
      params: layoutParams,
      dataRow: row,
      sourceDataSetName: this.dataSetName,
      sourceLayoutName: this.encodedLayoutName
    }

    if (drawerParams.editLayout) {
      const layouts = await this.getEditLayoutComponents(drawerParams.editLayout)
      drawerParams.layouts = layouts
    } else {
      // 当有dynamic的时候过滤掉dynamic
      const allSameDataSetLayout = this.getSameDataSetLayout()
      drawerParams.layouts = [{
        is: 'lw-form',
        dataSet: editDataSet,
        components: allSameDataSetLayout.filter((item) => item.is !== 'lw-dynamic')
      }]
    }
    return drawerParams as PopupLayoutConfig
  }

  /**
   * #1383 现在 editLayout 会作为独立的上下文存在
   * -----
   * 对于onClose的处理，由于保存、保存并新增 都涉及到ajax请求，应该在请求的正确回调中处理。因此在这里仅处理cancel的情况
   * cancel处理严格遵循 operationType 以及 id
   * ----
   * 目前默认所有form的编辑，编辑的都是头信息 ！！！后续的操作(关闭drawer并刷新页面)也都是沿着该思路，而如果编辑的不是头信息将很奇怪
   */
  async openEditLayout (mode: layoutStatus, row: any, layoutParams: Record<string, any> = {}) {
    const { editPanel } = this.component
    const editDataSet = this.editDataSet
    if (editPanel) {
      this.openEditPanel({ mode, row, editDataSet })
    } else {
      const dataRow = (this as any).dataRow
      const isNewOperation = !dataRow.id
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const commonParams = await this.getCommonOpenLayoutParams(mode, row, layoutParams)
      this.openedPopupLayout = {
        ...commonParams,
        isHeaderInfo: true,
        onClose: ({ operationType: type }: any) => {
          // 源页面数据没有id, 就认为是 打开新增页面并立即打开头部编辑drawer; 那么关闭时要同时关闭新增页面
          if ((type === operationType.CANCEL || type === operationType.NONE) && isNewOperation) {
            setTimeout(() => { // onClose 触发时有可能还处于 layoutParams.editLayout 内
              logwire.ui.closeCurrentLayout({})
            })
          }
          // 关闭后重置表单的状态
          // const { resetStatus } = this as any
          // _.isFunction(resetStatus) && resetStatus()
          emitLayoutEvent(`${this.dataSetName}.resetStatus`)
        }
      }
      logwire.ui.openLayoutInDialog(this.openedPopupLayout as PopupLayoutConfig)
    }
  }

  openEditPanel ({ mode, row, fromTable = false, editDataSet }: { mode: layoutStatus, row: DataRow, fromTable?: boolean, editDataSet?: string }) {
    const { editPanel } = this.component
    const panelParams = {
      name: editPanel,
      isEditPanel: true,
      dataRow: row,
      fromTable,
      panelStatus: mode,
      editDataSet,
      sourceDataSetStatus: this.status,
      sourceDataSetName: this.dataSetName
    }
    logwire.ui.openPanelInDialog(panelParams)
  }

  // Form 中需要显示编辑页面时，获取页面组件
  getSameDataSetLayout (): { is: string, field?: string }[] {
    const { onRetrieve } = this.component
    let allSameDataSetLayout: any[] = []
    eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'captureFormLayout'), allSameDataSetLayout)
    allSameDataSetLayout = _.cloneDeep(allSameDataSetLayout)
    allSameDataSetLayout = allSameDataSetLayout.filter((f) => !SYSTEM_DB_RESERVED_FIELDS.includes(f.field))
    if (!onRetrieve) {
      // 不是主数据字段，保存接口并不会处理；因此这些非主数据字段，enabled默认应该是false
      const masterFields = findMasterFields(this.layoutName, this.query)
      allSameDataSetLayout.forEach((f : any) => {
        if (!masterFields.includes(f.field)) {
          f.enabled = 'false'
        }
      })
    }
    return allSameDataSetLayout
  }

  // 封装下 beforeNew, afterNew 的处理过程，方便 "新增并下一条" 时能够通过事件处理
  throughDataSetNewEvents (fields: { field: string }[], callback?: (row: DataRow, layoutParams: Record<string, any>) => void) {
    const initData = {}
    for (const f of fields) {
      initData[f.field] = ''
    }
    const row = {
      currentData: initData,
      originalData: _.cloneDeep(initData),
      rowPersist: rowPersist.INSERT
    } as DataRow
    const editingRow = new DataRowCls(row)
    const layoutParams = {}
    const setEditLayoutParam = (key: string, value: string) => {
      layoutParams[key] = value
    }
    const proceed = () => {
      this.component.afterNew && this.runRunnableContent('afterNew', {
        args: new Args(this.context, { getEditingRow: () => editingRow, getCurrentDataSet: this.getCurrentDataSet })
      })
      callback && callback(row, layoutParams)
    }
    if (this.component.beforeNew) {
      this.runRunnableContent('beforeNew', {
        args: new Args(this.context, {
          proceed,
          getCurrentDataSet: this.getCurrentDataSet,
          setEditLayoutParam,
          getEditingRow: () => editingRow
        }),
        noWarning: false
      })
    } else {
      proceed()
    }
  }

  handleDataSetNew () {
    if ((this as any).disabled || !this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    closeAllErrorMessages()
    this.proceedAfterStatusCheck(() => {
      this.handleAfterQueryMeta(() => {
        const allSameDataSetLayoutFields = this.getSameDataSetLayout().filter(o => !!o.field).map(o => ({ field: o.field as string }))
        this.throughDataSetNewEvents(allSameDataSetLayoutFields, (row, layoutParams) => {
          this.status = layoutStatus.NEW
          // TODO 处理新增相关操作
          this.openEditLayout(layoutStatus.NEW, row, layoutParams)
          LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.NEW })
        })
      })
    })
  }

  throughDataSetEditEvents (row: DataRow, callback: (row: DataRow, layoutParams: Record<string, any>) => void) {
    const layoutParams = {}
    const setEditLayoutParam = (key: string, value: any) => {
      layoutParams[key] = value
    }
    const editingRow = new DataRowCls({
      ...formatDataRow(_.clone(row.currentData)),
      rowKey: row.rowKey // 编辑时提供 rowKey，如果是明细编辑则可以进行判断
    })
    const currentRow = new DataRowCls(row)
    const proceed = () => {
      this.component.afterEdit && this.runRunnableContent('afterEdit', {
        args: new Args(this.context, { getEditingRow: () => editingRow, getCurrentDataSet: this.getCurrentDataSet })
      })
      callback && callback(editingRow.row, layoutParams)
    }
    if (this.component.beforeEdit) {
      this.runRunnableContent('beforeEdit', {
        args: new Args(this.context, {
          row,
          proceed,
          getCurrentDataSet: this.getCurrentDataSet,
          getCurrentRow: () => currentRow,
          getEditingRow: () => editingRow,
          setEditLayoutParam
        }),
        noWarning: false
      })
    } else {
      proceed()
    }
  }

  handleDataSetEdit (...args: any[]) {
    if ((this as any).disabled || !this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }

    this.proceedAfterStatusCheck(() => {
      this.handleAfterQueryMeta(() => {
        const dataRow = this.dataSet.rows[0]
        this.throughDataSetEditEvents(dataRow, (row, layoutParams) => {
          this.status = layoutStatus.EDIT
          // TODO 处理编辑相关操作
          this.openEditLayout(layoutStatus.EDIT, row, layoutParams)
          LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EDIT })
          // LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: this.dataSetName })
        })
      })
    })
  }

  handleDatasetExpressEdit () {
    if ((this as any).disabled || !this.accessibleResourcesWithEdit) {
      const { error } = logwire.ui.message
      error && error(this.$i18n('core', 'client.tips.no-edit-permission'))
      return
    }
    // 触发快速编辑事件不需要提示，进不去快速编辑状态就不做任何处理
    if (this.status === layoutStatus.VIEW && !this.layoutEditing) {
      const proceed = () => {
        this.status = layoutStatus.EXPRESS_EDIT
        LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.EXPRESS_EDIT })
        this.component.afterEdit && this.runRunnableContent('afterEdit', { args: new Args(this.context, { row: this.dataSet.rows[0] }) })
      }
      this.beforeBehaviorCommon('beforeEdit', proceed, { getCurrentDataSet: this.getCurrentDataSet })
    }
  }

  handleDatasetHeaderDetailEdit (): void {
    const proceed = () => {
      if (this.$options.name !== 'LwForm') {
        warnEventNotSupported(layoutStatus.HEADER_DETAIL_EDIT, this.component.is)
        return
      }
      this.status = layoutStatus.HEADER_DETAIL_EDIT
      LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.HEADER_DETAIL_EDIT })
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.detailStatusChange`, layoutStatus.HEADER_DETAIL_EDIT)
      // 通知其它具有相同 dataSet 但是没有 query 或者 onRetrieve 的组件进入头明细状态
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveStatusChange`, layoutStatus.HEADER_DETAIL_EDIT)
      this.component.afterEdit && this.runRunnableContent('afterEdit', { args: new Args(this.context, { row: this.dataSet.rows[0] }) })

      this.headerDetailRowPersistChange('U')
    }
    this.beforeBehaviorCommon('beforeEdit', proceed, { getCurrentDataSet: this.getCurrentDataSet })
  }

  /**
   * headerDetailNew 的实现
   * 1.清空 头和明细表 的 dataset
   * 2.通知相关 dataset 进入 headerDetailNew 状态
   */
  handleDatasetHeaderDetailNew (): void {
    closeAllErrorMessages()
    const process = () => {
      LayoutModule.resetDataSet({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName })
      if (this.detailDataSets?.length) {
        for (const dataSet of this.detailDataSets) {
          LayoutModule.resetDataSet({ layoutName: this.encodedLayoutName, dataSetName: dataSet.detailDataSetName })
        }
      }
      this.headerDetailStatusNew()
      this.headerDetailRowPersistChange('I')
    }
    this.beforeBehaviorCommon('beforeNew', process, { getCurrentDataSet: this.getCurrentDataSet })
  }

  /**
   * 头明细新增/编辑时，是直接在原始的数据集上做操作，但是初始 rowPersist 为空，在触发事件时改变 rowPersist 为对应状态
   */
  headerDetailRowPersistChange (rowPersist: 'U' | 'I' | '') {
    const dr = this.dataSet.rows[0]
    if (dr) {
      dr.rowPersist = rowPersist
    }
  }

  headerDetailStatusNew (): void {
    if (this.$options.name !== 'LwForm') {
      warnEventNotSupported(layoutStatus.HEADER_DETAIL_NEW, this.component.is)
      return
    }
    this.status = layoutStatus.HEADER_DETAIL_NEW
    LayoutModule.updateLayoutStatus({ layoutName: this.encodedLayoutName, status: layoutStatus.HEADER_DETAIL_NEW })
    this.$nextTick(() => {
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.detailStatusChange`, layoutStatus.HEADER_DETAIL_NEW)
      // 通知其它具有相同 dataSet 但是没有 query 或者 onRetrieve 的组件进入头明细状态
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveStatusChange`, layoutStatus.HEADER_DETAIL_NEW)
    })
    this.component.afterNew && this.runRunnableContent('afterNew', { args: new Args(this.context, { row: this.dataSet.rows[0] }) })
  }

  handleStatusCancel (): void {
    if ([layoutStatus.EDIT, layoutStatus.NEW].includes(this.status)) {
      // 关闭因为自身组件打开的 Popup 弹窗
      eventbus.$emit(`${this.encodedLayoutName}.close-popup-layout`, () => 0)
      this.status = layoutStatus.VIEW
    } else if ([layoutStatus.EXPRESS_EDIT, layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
      // 当前状态为头明细编辑时，需要特殊处理
      if ([layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)) {
        // 存在 this.detailDataSets 说明是头，需要通知明细组件重置
        if (this.detailDataSets) {
          for (const dataSet of this.detailDataSets) {
            emitLayoutEvent(`${dataSet.detailDataSetName}.reset`, { isForceReset: true })
          }
        } else if (this.component.headerDataSet) {
          // 存在 this.component.headerDataSet 说明是明细，需要通知头组件重置
          emitLayoutEvent(`${this.component.headerDataSet}.reset`, { isForceReset: true })
        }
        // 通知其它具有相同 dataSet 但是没有 query 或者 onRetrieve 的组件退出头明细状态
        emitLayoutEvent(`${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveResetStatus`, { isForceReset: true })
      }
      this.handleDataRowsReset()
      this.headerDetailRowPersistChange('')
      this.status = layoutStatus.VIEW
    }
  }

  handleDataRowsReset (): void {
    const { quitEditing, resetRows } = this as any
    _.isFunction(quitEditing) && quitEditing()
    _.isFunction(resetRows) && resetRows({ isForceReset: true })
  }

  handleDataSetDelete (...args: any[]) {
    if ((this as any).disabled) return
    closeAllErrorMessages()
    const dataRow = this.context.getOrCreateDataSet(this.dataSetName)?.getRow(0)
    if (this.component.onDelete) {
      // 如果定义了 onDelete 事件，则有开发者 自行完成 delete 逻辑
      // 同时 before 和 after 的逻辑也由开发者在 onDelete 完成，平台不再调用 before 和 after 的相关逻辑
      this.runRunnableContent('onDelete', { args: new Args(this.context, { row: (this as any).dataRow, getCurrentDataSet: this.getCurrentDataSet }) })
      return
    }
    const proceed = () => {
      // 删除之前先弹框确认是否删除
      const param: any = {
        message: this.$i18n('core', 'client.tips.is-delete'),
        callback: (action: string) => {
          if (action === 'confirm') {
            // 点击确定之后进行删除操作
            const data = {
              id: dataRow?.getOriginalData('id'),
              version: dataRow?.getOriginalData('version')
            }
            this.doDelete([data])
          }
        },
        // TODO 这个在这里应该没有区分的必要
        distinguishCancelAndClose: true
      }
      logwire.ui.confirm(param)
    }
    this.beforeBehaviorCommon('beforeDelete', proceed)
  }

  doDelete (dataArr: Array<any>) {
    const layoutName = this.editLayoutName || this.layoutName
    const data = {
      query: this.query,
      selectedRows: dataArr
    }
    doDelete({
      layoutName,
      namespace: this.context.getNamespace(),
      queryName: this.query,
      data
    }).then(res => {
      this.component.afterDelete && this.runRunnableContent('afterDelete', { args: new Args(this.context) })
      logwire.ui.closeCurrentLayout({})
    }).catch(e => {
      console.log(e)
    })
  }

  /**
   * 执行 save 事件时，检查相关的 dataSet 下是否存在上传中的组件
   * 如果基类组件上有 editLayout 和 editDataSet ，那么要检查这个目标 dataSet
   */
  checkUploadingFieldsExist () {
    let { editLayout } = this.component
    let targetLayoutName = this.encodedLayoutName
    let targetDataSetName = EDIT_LAYOUT_DATASET_NAME
    if (editLayout && this.editDataSet) {
      if (!editLayout.includes('.')) {
        editLayout = this.context.getNamespace() + '.' + editLayout
      }
      targetLayoutName = editLayout
      targetDataSetName = this.editDataSet
    }
    const uploadingFields = LayoutModule.data[targetLayoutName]?.systemState[targetDataSetName]?.uploadingFields
    if (uploadingFields?.length) {
      const fields = uploadingFields.map(o => {
        return { field: o, title: '' }
      })
      eventbus.$emit(getEventName(targetLayoutName, targetDataSetName, 'getFieldsTitle'), fields, (fields: { title: string, field: string }[]) => {
        logwire.ui.message.warning && logwire.ui.message.warning(getGlobalI18nMessage('core', 'client.tips.wait-for-uploading', fields.map(o => o.title).join(',')))
      })
      return true
    }
  }

  handleDataSetSave (payload?: Partial<{ rows: any[], mode: string, op: string, cbFromPopup: () => void, defaultAfterSave: () => void }>) {
    // getTrigger 返回三种状态： insert  insert-and-new update
    const { rows = null, mode = '', op = 'save', cbFromPopup = () => 0, defaultAfterSave = console.log } = payload || {}
    closeAllErrorMessages()
    if (this.checkUploadingFieldsExist()) {
      return
    }
    const getTrigger = () => {
      if (op === operationType.SAVE_AND_NEW) {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP.saveAndNew
      } else {
        return EDIT_LAYOUT_SAVE_TRIGGER_MAP[mode]
      }
    }
    const dataRow = rows?.[0] || this.dataSet.rows[0]
    delete dataRow.state
    if (this.component.onSave) {
      this.runRunnableContent('onSave', { args: new Args(this.context, { row: dataRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }) })
      return
    }
    // TODO 处理保存
    const proceed = async () => {
      // 假如有校验数据的函数，先执行数据校验的函数
      // 考虑到有可能没有数据校验函数的情况，所以只有数据校验的函数明确返回 false，才表示数据校验不通过
      // 当存在 rows 的情况，说明是由弹出抽屉触发的保存事件，由于弹出抽屉已经做了校验，这里就不再进行校验
      if (!rows) {
        // 多个 form 共用一个 dataSetName 的时候，保存事件只会触发其中一个写了 query 或者 onSave 的 form 的保存
        // 保存的时候会将当前的 dataSet 中的数据全部携带进行保存，所以这里需要触发所有同名 dataSet 的校验
        const formComponents: Array<Vue> = []
        emitLayoutEvent(`${this.dataSetName}.get-same-dataSet-form`, formComponents)
        for (const form of formComponents) {
          const result = await (form as any).validateData?.()
          if (result === false) return
        }
        const result = await (this as any).validateData?.()
        if (result === false) return
      }
      const rowPersistMode = [layoutStatus.NEW, layoutStatus.HEADER_DETAIL_NEW].includes(this.status) ? rowPersist.INSERT : rowPersist.UPDATE
      dataRow.rowPersist = rowPersistMode
      this.doSave({ data: dataRow, mode: this.status, saveAndNew: op, cbFromPopup, defaultAfterSave, getTrigger })
    }
    // 如果存在 beforeSave，则不再主动调用平台默认的保存逻辑，需要开发者在 beforeSave 中手动调用 args.proceed()
    if (this.component.beforeSave) {
      const args = new Args(this.context, { row: dataRow, proceed })
      this.runRunnableContent('beforeSave', { args })
    } else {
      proceed()
    }
  }

  // 一般不会有 defaultAfterSave 只有表单等控件默认的快速编辑保存才会有这个方法
  doSave (payload:{ data: DataRow, mode: string, saveAndNew: string, cbFromPopup?: () => void, defaultAfterSave?: () => void, getTrigger?: () => void }) {
    const { data, mode = '', saveAndNew = 'save', cbFromPopup, defaultAfterSave, getTrigger } = payload || {}
    // 准备 save 接口需要的格式
    // TODO 需要一个区分是否明细编辑的标志
    const dataListForSave = getDataListForSave(this.dataSetName, this.query, [data], this.encodedLayoutName, this.detailDataSets)
    dataListForSave.headerDetailSave = this.detailDataSets?.length > 0 && [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.status)
    doSave({ layoutName: this.editLayoutName || this.layoutName, namespace: this.context.getNamespace(), queryName: this.query, data: dataListForSave })
      .then(res => {
        // 保存成功以后 再去通知明细表 清除掉自己所记录的 editRows
        if (this.detailDataSets && _.isArray(this.detailDataSets)) {
          for (const detailDataSet of this.detailDataSets) {
            const { detailDataSetName } = detailDataSet
            emitLayoutEvent(`${detailDataSetName}.clearEditRows`)
          }
        }
        const updatedCurrentData = data.currentData
        const currentData = { ...updatedCurrentData, ...res.data.data[this.dataSetName].records[0] }
        // 保存后重置表单的状态
        const { resetStatus } = this as any
        // 如果是 save and new 需要保留原来 layout 的 new 状态
        if (saveAndNew !== operationType.SAVE_AND_NEW) {
          // _.isFunction(resetStatus) && resetStatus()
          emitLayoutEvent(`${this.dataSetName}.resetStatus`)
        }

        if ([layoutStatus.NEW, layoutStatus.HEADER_DETAIL_NEW].includes(mode as layoutStatus)) {
        // TODO 当前页面下其他的dataset都清空是否合理？
          const datasetNames = Object.keys(LayoutModule.data[this.encodedLayoutName].dataSet)
          datasetNames.forEach(dsName => {
            LayoutModule.resetDataSet({
              layoutName: this.encodedLayoutName,
              dataSetName: dsName
            })
          })
          // 替换dataset
          LayoutModule.loadLayoutDataSet({ layoutName: this.encodedLayoutName, data: Object.assign({ rows: [currentData] }, { dataSetName: this.dataSetName }) })
          this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: { currentData } as DataRow, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
          // 这里取 dataSet 中的 id
          const ds = LayoutModule.data[this.encodedLayoutName].dataSet[this.dataSetName].rows[0]
          const id = ds.currentData.id
          if (this.popupLayout) {
            // 刷新 params.id ，让头明细的明细在查询时可以根据最新的 id 去查
            if (LayoutModule.data[this.encodedLayoutName]?.params) {
              Vue.set(LayoutModule.data[this.encodedLayoutName].params as Record<string, any>, 'id', id)
            } else {
              Vue.set(LayoutModule.data[this.encodedLayoutName], 'params', { id })
            }
          } else if (saveAndNew !== operationType.SAVE_AND_NEW) {
            // 非弹出框中，带新的 id 刷新地址栏
            const href = location.href.substring(0, location.href.indexOf('/layout'))
            location.href = `${href}/layout/${this.layoutName}?id=${id}`
          }
          if (saveAndNew === operationType.SAVE_AND_NEW && this.component.afterNew) {
            // 保存并新增时候再次触发 afterNew 事件,为了可以在再次新增的时候设置 默认值
            const getEditingRow = () => this.context.getOrCreateDataSet(this.editDataSet).getRow(0)
            this.runRunnableContent('afterNew', { args: new Args(this.context, { getEditingRow }) })
          }
        } else {
          // 更新targetRow数据
          const targetRow = this.dataSet.rows[0]
          targetRow.currentData = currentData
          targetRow.originalData = _.cloneDeep(currentData)
          // 执行表单快速编辑保存的默认逻辑
          delete targetRow.rowPersist
          defaultAfterSave && defaultAfterSave()
          this.component.afterSave && this.runRunnableContent('afterSave', { args: new Args(this.context, { row: data, getTrigger, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
        }
        if (saveAndNew === operationType.SAVE_AND_NEW) {
          const { editLayout } = this.component
          const { editDataSet } = this
          const newPopupOrPanelLayoutName = editLayout
            ? editLayout.includes('.')
              ? editLayout
              : `${this.context.getNamespace()}.${editLayout}`
            : this.layoutName
          const setRowIntoPopupDataSet = (row: DataRow, layoutParams: Record<string, any>) => {
          // 将经过事件处理的 row 作为 DataRow 保存起来
            LayoutModule.loadLayoutDataSet({
              layoutName: newPopupOrPanelLayoutName,
              data: {
                dataSetName: editDataSet,
                rows: [row]
              }
            })
          }
          // 新增并下一条时，重置 DataSet 信息
          const allSameDataSetLayoutFields = this.getSameDataSetLayout().filter(o => !!o.field).map(o => ({ field: o.field as string }))
          this.throughDataSetNewEvents(allSameDataSetLayoutFields, setRowIntoPopupDataSet)
        } else {
          // 有可能 save 事件是由 PopupLayout.vue 或者 Panel.vue 触发的，他们需要在完成保存操作后触发对应的回调
          if (cbFromPopup) {
            cbFromPopup()
          }
        }

        // retrieve 在最后执行，保证如果是头明细结构， params.id 已经被赋值了
        if (this.detailDataSets?.length) {
          for (const dataSet of this.detailDataSets) {
            emitLayoutEvent(`${dataSet.detailDataSetName}.retrieve`)
            emitLayoutEvent(`${dataSet.detailDataSetName}.resetStatus`, { isForceReset: true })
          }
        }
      }, e => { console.error(e) })
  }

  async getFieldsTitle (fields: Array<Record<string, string>>, cb: (f: any) => void) {
    const is = this.component.is
    let loopObj: Array<any> = []
    if (is === 'lw-table') {
      loopObj = this.component.fields
    } else if (is === 'lw-form' || is === 'lw-input-form') {
      loopObj = this.component.components || []
    } else if (is === 'lw-list') {
      const queryMeta = this.queryMeta || []
      loopObj = (queryMeta as Array<any>).map(m => {
        m.field = m.name
        return m
      })
    } else {
      return
    }
    fields.forEach(field => {
      const f = loopObj.find((f: any) => f.field && f.field === field.field)
      let title = f.title
      if (title.startsWith(attributeSymbol.I18N)) {
        title = logwire.ui.getI18nMessage(getPlainAttributeValue(title, attributeSymbol.I18N))
      }
      field.title = title
    })
    cb(fields)
  }

  handleAfterSearched () {
    this.afterSearched = true
  }

  created (): void {
    const { encodedLayoutName, dataSetName, component } = this
    const { query, onRetrieve, onDelete, onSave } = component
    if (query || onRetrieve) {
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'retrieve'), this.handleDataSetRetrieve, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'new'), this.handleDataSetNew, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'edit'), this.handleDataSetEdit, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'expressEdit'), this.handleDatasetExpressEdit, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'headerDetailEdit'), this.handleDatasetHeaderDetailEdit, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'headerDetailNew'), this.handleDatasetHeaderDetailNew, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'headerDetailStatusNew'), this.headerDetailStatusNew, ['query', 'onRetrieve'])
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'cancel'), this.handleStatusCancel, ['query', 'onRetrieve'])
    } else {
      // 如果没有指定查询的方法，那么认为初始时就经过查询，如果用户不自己填充数据，那么就应该显示无数据提示
      this.afterSearched = true
    }
    if (query || onDelete) {
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'delete'), this.handleDataSetDelete, ['query', 'onDelete'])
    }
    if (query || onSave) {
      this.registerUniqueEvent(getEventName(encodedLayoutName, dataSetName, 'save'), this.handleDataSetSave, ['query', 'onSave'])
      // generateUpdatedRows 方法在存在头明细编辑的组件中有自己的实现，比如 Form、Table， 用于头数据组件获取明细数据组件的数据
      eventbus.$on(getEventName(encodedLayoutName, dataSetName, 'generateUpdatedRows'), (this as any).generateUpdatedRows)
    }
    eventbus.$on(getEventName(encodedLayoutName, dataSetName, 'reset'), this.handleDataRowsReset)
    eventbus.$on(getEventName(encodedLayoutName, dataSetName, 'getFieldsTitle'), this.getFieldsTitle)
    // 注册一次性事件 afterSearched, 用来表示该表格完成过一次查询，希望在没有查询之前，不要显示 "无数据" 的图标
    eventbus.$once(getEventName(encodedLayoutName, dataSetName, 'afterSearched'), this.handleAfterSearched)
    if (query) {
      const layoutName = this.editLayoutName || this.layoutName
      getQueryMeta({ layoutName, namespace: this.context.getNamespace(), queryName: this.query })
        .then(res => {
          this.queryMeta = res.data.data
          LayoutModule.setQueryMetaData({
            layoutName: this.layoutName,
            queryName: query,
            queryMetaData: res.data.data
          })
        }, e => { console.error(e) })
    }
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.resetStatus`, (this as any).resetStatus)
    // 详情页面，点击 保存并新增 后，需要刷新页面，然后重新调用 ds.new事件
    sessionStorage[SCRIPT_SAVE_AND_NEW_EVENT] && emitLayoutEvent(sessionStorage[SCRIPT_SAVE_AND_NEW_EVENT])
    delete sessionStorage[SCRIPT_SAVE_AND_NEW_EVENT]
  }

  beforeDestroy (): void {
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.retrieve`, this.handleDataSetRetrieve)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.new`, this.handleDataSetNew)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.edit`, this.handleDataSetEdit)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.expressEdit`, this.handleDatasetExpressEdit)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.headerDetailEdit`, this.handleDatasetHeaderDetailEdit)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.headerDetailNew`, this.handleDatasetHeaderDetailNew)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.headerDetailStatusNew`, this.headerDetailStatusNew)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.delete`, this.handleDataSetDelete)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.save`, this.handleDataSetSave)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.cancel`, this.handleStatusCancel)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.generateUpdatedRows`, (this as any).generateUpdatedRows)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.resetStatus`, (this as any).resetStatus)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.reset`, this.handleDataRowsReset)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.getFieldsTitle`, this.getFieldsTitle)
  }

  initRetrieve () {
    if (this.retrieveOnLayoutLoad && (this.query || this.component.onRetrieve)) {
      this.$nextTick(() => {
        this.handleDataSetRetrieve()
      })
    }
    if (this.query) {
      LayoutModule.layoutDataSetQueryInfo({ layoutName: this.encodedLayoutName, dataSetName: this.dataSetName, queryName: this.query })
    }
  }
}

export default QueriableComponent
