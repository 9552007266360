



































import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { LocalModule } from '@/store/modules/local'
import { attributeType } from '@/utils/const'
import ChoiceBase from '@/components/layout/form-item/choice/ChoiceBase'

@Component({ name: 'LwChoice' })
class LwChoice extends Mixins(FormControlComponent, ChoiceBase) {
  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    } else if (this.form.asQuickEditForm) {
      return false
    }
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    const originalValue = row.originalData[this.component.field]
    if (this.multiple) {
      if (!originalValue) return this.inputValue.length
      return this.inputValue?.toString() !== originalValue?.toString()
    }
    return this.inputValue !== originalValue
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.displayValue
  }
}
export default LwChoice
