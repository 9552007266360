import { Component } from 'vue-property-decorator'
import QueriableComponent from '@/components/layout/QueriableComponent'
import { LayoutModule } from '@/store/modules/layout'
import _ from 'lodash'
import { IConditionGroup } from '@/types/data'
import { attributeType } from '@/utils/const'
import { formatMasterFilter, getEventName } from '@/utils/data'
import eventbus from '@/utils/event'
import Args from '@/models/Args'

type ComponentType = 'table' | 'list' | 'timeline' | 'echarts' | 'pivotTable'

@Component
class QueriableByFilterComponent extends QueriableComponent {
  get queryMasterFilter (): IConditionGroup | undefined {
    const result = this.getFinalAttributeValue('queryMasterFilter', { types: [attributeType.OBJECT_ARRAY, attributeType.OBJECT] })
    return formatMasterFilter(result)
  }

  get queryParams (): Array<Record<string, any>> | void { return this.getFinalAttributeValue('queryParams', { type: attributeType.OBJECT }) }

  // created (): void {
  //   const { query } = this.$route
  //   const { id } = query
  //   if (!_.isFinite(id) || !this.query) return
  //   const layoutName = this.editLayoutName || this.layoutName
  //   // 当 url 中含有 id 字段并且设置了 query 属性时，按照 ID 进行 query
  //   LayoutModule.loadDataById({
  //     layoutName,
  //     encodedLayoutName: this.encodedLayoutName,
  //     namespace: this.context.getNamespace,
  //     query: this.component.query,
  //     dataSetName: this.dataSetName,
  //     id
  //   })
  // }

  handleDataSetRetrieveForQueryByFilterComponent (type: ComponentType, retrieveType: 'retrieve' | 'paging' = 'retrieve') {
  // type === 'table' && (this.editRows = [])
    if (this.queryMasterFilter) {
      LayoutModule.addDataSetMasterFilter({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        masterFilter: this.queryMasterFilter
      })
    }
    let queryParams
    if (this.component.queryParams) {
      queryParams = this.runRunnableContent('queryParams')
      queryParams && LayoutModule.addDataSetQueryParams({
        layoutName: this.encodedLayoutName,
        dataSetName: this.dataSetName,
        queryParams
      })
    }
    // 项目自定义查询, context中可以获取quickFilter等条件
    if (this.component.onRetrieve) {
      const getPageParams = () => {
        const tempPageNo = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].tempPageNo
        const tempPageSize = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].tempPageSize
        const pageNo = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].pageNo
        const pageSize = LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName].pageSize
        return { pageNo: tempPageNo || pageNo, pageSize: tempPageSize || pageSize }
      }

      const applyDataToCurrentDataSet = ({ rows, total, aggregation }: { rows: Array<Record<string, any>>, total: number, aggregation: any }) => {
        LayoutModule.LOAD_TABLE_FILTER_DATA({
          layoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          pageSize: total,
          pageNo: 1, // PageNo 是随便设的，onRetrieve 时不关心这个值
          rows
        })

        LayoutModule.LOAD_TABLE_COUNT_DATA({
          layoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          countState: '',
          total,
          aggregation
        })

        // 项目开发者自己查询完数据，自己调用了 apply 方法，这时认为当前表格经历了一个查询
        const eventName = getEventName(this.encodedLayoutName, this.dataSetName, 'afterSearched')
        eventbus.$emit(eventName)
      }

      // 返回当前查询是 retrieve 还是 paging，由开发者自行判断如何书写查询
      const getRetrieveType = () => {
        return retrieveType || 'retrieve'
      }

      this.runRunnableContent('onRetrieve', {
        args: new Args(this.context, {
          applyDataToCurrentDataSet,
          getCurrentDataSet: this.getCurrentDataSet,
          getRetrieveType,
          getPageParams
        })
      })
      // onRetrieve 的时候重置 对应 dataSet 的 selection 和 selectALl
      if (LayoutModule.data[this.encodedLayoutName].systemState[this.dataSetName]) {
        LayoutModule.setTableSelectAll({
          layoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          selectAll: false
        })
        LayoutModule.setTableSelection({
          layoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          selection: []
        })
      }
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.addTableSearch`)
    } else {
      const proceed = () => {
        const retrieveCallback = () => {
          this.component.afterRetrieve && this.runRunnableContent('afterRetrieve', { args: new Args(this.context, { getCurrentDataSet: this.getCurrentDataSet }) })
          const eventName = getEventName(this.encodedLayoutName, this.dataSetName, 'afterSearched')
          eventbus.$emit(eventName)
        }
        // 由于后端接口中只要字段值为空，就不会把字段返回，将导致页面响应式出现问题。因此将layout的字段也传过去作初始化
        let layoutFields
        const layoutName = this.editLayoutName || this.layoutName
        type === 'table' && (layoutFields = this.component.fields.map((f: any) => f.field))
        LayoutModule.loadTableData({
          layoutName,
          encodedLayoutName: this.encodedLayoutName,
          dataSetName: this.dataSetName,
          queryName: this.query,
          layoutFields,
          namespace: this.context.getNamespace(),
          retrieveCallback,
          retrieveType
        })
      }
      if (this.component.beforeRetrieve) {
        this.runRunnableContent('beforeRetrieve', { args: new Args(this.context, { proceed, getCurrentDataSet: this.getCurrentDataSet }), noWarning: false })
      } else {
        proceed()
      }
      eventbus.$emit(`${this.encodedLayoutName}.${this.dataSetName}.addTableSearch`)
    }
  }
}

export default QueriableByFilterComponent
