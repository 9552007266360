import { Vue, Component } from 'vue-property-decorator'
import { DataRow } from '@/types/data'
import { LayoutComponent } from '@/types/layout'
import { rowPersist, unprocessableComponentList } from '@/utils/const'
import ColumnContent from '@/components/layout/basic/table-v2/ColumnContent'
import { ColumnInfo } from 'vxe-table'
import { set } from 'lodash'
import { attribute2Boolean } from '@/utils/layout'

const cellWhiteSpace = 46

@Component
class ColumnWidth extends ColumnContent {
  // 自适应列宽
  makeColumnWidthFitContent (rows: DataRow[], columns: LayoutComponent[]) {
    columns.forEach((column) => {
      delete column.width
      column.textWidth = 60
    })

    const valuableRows = []

    for (const row of rows) {
      if (row.rowPersist === rowPersist.DELETE) continue
      this.generateColumns(row, columns)
      valuableRows.push(row)
    }

    return valuableRows
  }

  calculateColumnWidth (column: ColumnInfo) {
    // 如果某列不具备 width 属性，才通过自适应计算列宽
    if (!column.width) {
      // 没有列表数据且未设置width时，按 头部title + 内边距24 + 排序icon + 误差1 显示宽度
      const sortWidth = column.sortable ? 26 : 0
      const headerWidth = this.getTextWidth(column.title, '600 14px') + 24 + sortWidth + 1
      // 计算列宽 = 内容宽度  + this.cellWhiteSpace
      // 固定内容宽度的组件：lw-switch lw-checkbox
      // tableColumnWidth: 是组件基类层面上的属性，用来表示用户定义的组件在表格中的初始列宽，而一旦表格保存宽度数据后，则使用存储的
      let textWidth = column.tableColumnWidth || column.textWidth || 60
      if (column.is === 'lw-switch') {
        // switch 内容宽度 40
        textWidth = 40 + cellWhiteSpace
      } else if (column.is === 'lw-checkbox') {
        // checkbox 内容宽度 16
        textWidth = 16 + cellWhiteSpace
      } else if (column.is === 'lw-image') {
        // 由于 image 组件本身就有 width 属性，会和 table 列的 width 冲突，所以添加 imageRealWidth 属性记录下 image 的 width 属性
        // 图片自身的默认值在 LwImage.vue 中设置
        textWidth = 16 + cellWhiteSpace
        if (column.tableColumnWidth) {
          textWidth = column.tableColumnWidth
        }
      }
      // 限制最大宽度 400
      column.width = Math.min(Math.max(headerWidth, textWidth, 60), 400)
    } else {
      if (column.is === 'lw-image') {
        // 如果已经存在 c.imageRealWidth, 说明在获取了已存储的表格设置后，对字段进行过赋值 width 操作
        if (!column.imageRealWidth) {
          const columnWidth = Number(column.width.toString().replace('px', ''))
          column.imageRealWidth = columnWidth
          column.width = columnWidth + 10
          if (column.tableColumnWidth) {
            column.width = column.tableColumnWidth
          }
        }
      }
    }
  }

  generateColumns (row: DataRow, columns: LayoutComponent[], contentOnly?: boolean) {
    if (!row || !columns) return
    const content = {}
    for (const column of columns) {
      // 无论如何，单元格内容需要计算
      const useOriginComponent = attribute2Boolean(column.fullContextAwareAsTableColumn) === true || unprocessableComponentList.includes(column.is)
      let cellContent: any = {}
      const oldContent = row.__table__?.content[column.field] || {}
      if (!useOriginComponent) {
        cellContent = this.getColumnCellContent(row, column)
      }
      set(content, column.field, { useOriginComponent, ...oldContent, ...cellContent })
      if (contentOnly || column.width) continue
      let textWidth = 60
      const { value } = cellContent
      if (!['lw-switch', 'lw-checkbox', 'lw-image', 'lw-rate'].includes(column.is)) {
        const isLabel = column.is === 'lw-label'
        const isTags = column.is === 'lw-tags'
        textWidth = this.getTextWidth(value, `400 ${isLabel ? column.fontSize : (isTags ? 12 : 14)}px `) + cellWhiteSpace
      }
      column.textWidth = Math.max(column.textWidth || 0, textWidth)
    }
    set(row, '__table__.content', content)
  }

  getTextWidth (text: string, font: string) {
    const { fontFamily } = getComputedStyle(document.documentElement)
    const finalFont = font + ' ' + fontFamily
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context!.font = finalFont
    let width = 0
    if (text && text.includes('\n')) {
      const textArr = text.split('\n')
      textArr.forEach(item => {
        width = Math.max(width, context!.measureText(item).width)
      })
    } else {
      width = context!.measureText(text).width
    }
    return Math.ceil(width)
  }
}

export default ColumnWidth
