import { LayoutModule } from '@/store/modules/layout'
import { getOriginalLayoutForOutsideComponent } from '@/utils/layout'
import logwire from '.'

export default {
  /**
   * 判断当前角色在此页面是否拥有某个 resourceCode 权限
   */
  canAccessLayoutResource (resourceCode: string) {
    if (logwire.store.getUser().admin) return true
    const currentLayout = getOriginalLayoutForOutsideComponent()
    let accessibleResources = LayoutModule.resource[currentLayout]?.metaData?.accessibleResources || []
    accessibleResources = accessibleResources.map((resourceCode: string) => {
      return resourceCode.substring(resourceCode.indexOf(':') + 1)
    })
    return accessibleResources.includes(resourceCode)
  }
}
