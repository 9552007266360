
































import DatasetBoundComponent from '@/components/layout/DatasetBoundComponent'
import { Component, Watch } from 'vue-property-decorator'
import eventbus from '@/utils/event'
import { checkValueIsEmpty, getEventName } from '@/utils/data'
import { attribute2Numbers, attribute2Number, warnPaginationTotalByError, emitLayoutEvent } from '@/utils/layout'
import { LayoutModule } from '@/store/modules/layout'
import { attributeType, totalByType } from '@/utils/const'
import logwire from '@/logwire'
import { doAction, doSave, queryByFilter } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import _ from 'lodash'
import { saveUserSettings } from '@/utils/common'

@Component({ name: 'LwPagination' })
export default class LwPagination extends DatasetBoundComponent {
  pageSizes!: number[]
  visibleWithTable = true
  getAccurateCountFlag = false

  get countLoading () {
    return LayoutModule.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.counting || false
  }

  get pageNo () {
    return this.$store.state.layout.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.pageNo
  }

  get pageSize () {
    return this.$store.state.layout.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.pageSize
  }

  get totalForPagination () {
    return this.$store.state.layout.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.total
    // 20230718 现在 getTotalBy 为 try_estimate 时只表示模糊查询，还是可以有分页的
  }

  get total () {
    let result = this.$store.state.layout.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.total
    if (result === -1) {
      result = 0
    }
    return result
  }

  get content () {
    return this.getFinalAttributeValue('content', { types: [attributeType.STRING, attributeType.NUMBER] })
  }

  get layout () {
    const result = ['total', 'sizes', 'prev', 'pager', 'next', 'jumper']
    if (this.getTotalBy !== totalByType.CONUT) {
      result[0] = 'slot'
    }
    return result.join(',')
  }

  get getTotalBy (): totalByType {
    const storedTotalBy = this.$store.state.layout.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.getTotalBy
    const aggregationFields = LayoutModule.data[this.encodedLayoutName]?.systemState[this.dataSetName]?.aggregationFields
    if (aggregationFields && aggregationFields.length && (storedTotalBy === totalByType.TRY_ESTIMATE || storedTotalBy === totalByType.FORCE_NONE)) {
      warnPaginationTotalByError()
    }
    let result = totalByType.CONUT
    if (this.component.getTotalBy) {
      result = this.component.getTotalBy
    }
    return storedTotalBy || result
  }

  handleSizeChange (val: number) : void {
    saveUserSettings(this.layoutName, this.dataSetName, { page_size: val })
    LayoutModule.setTempTablePageSize({ layoutName: this.layoutName, dataSetName: this.dataSetName, tempPageSize: val })
    LayoutModule.setTempTablePageNo({ layoutName: this.layoutName, dataSetName: this.dataSetName, tempPageNo: undefined })
    eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'retrieve'))
  }

  handleCurrentChange (val: number) : void {
    LayoutModule.setTempTablePageSize({ layoutName: this.layoutName, dataSetName: this.dataSetName, tempPageSize: undefined })
    LayoutModule.setTempTablePageNo({ layoutName: this.layoutName, dataSetName: this.dataSetName, tempPageNo: undefined })
    eventbus.$emit(getEventName(this.encodedLayoutName, this.dataSetName, 'paging'), { pageNo: val })
  }

  doVisible (visible: boolean) {
    this.visibleWithTable = visible
  }

  getAccurateCount () {
    this.getAccurateCountFlag = true

    LayoutModule.setTableTotalBy({ layoutName: this.layoutName, dataSetName: this.dataSetName, getTotalBy: totalByType.CONUT })
    LayoutModule.setTablePageNo({ layoutName: this.layoutName, dataSetName: this.dataSetName, pageNo: 1 })

    emitLayoutEvent(`${this.dataSetName}.retrieve`)
  }

  pageCount () {
    // elementui 源码中 total 的 优先级是高于 pageCount 的。所以在有 total 的时候 pageCount 设置 null 也没事
    const result = Math.ceil(this.total / this.pageSize)
    return result
  }

  setSettingPageSize (settingPageSize: string) {
    if (settingPageSize !== this.pageSize) {
      LayoutModule.setTablePageSize({
        layoutName: this.layoutName,
        dataSetName: this.dataSetName,
        pageSize: settingPageSize
      })
    }
  }

  created () {
    // todo 文档注明两个属性拒绝写动态
    const { pageSizes, defaultPageSize } = this.component
    this.pageSizes = checkValueIsEmpty(pageSizes) ? [20, 50, 100, 200] : attribute2Numbers(pageSizes)
    const pageSize = checkValueIsEmpty(defaultPageSize)
      ? this.pageSizes[0]
      : this.pageSizes.includes(attribute2Number(defaultPageSize) as number)
        ? attribute2Number(defaultPageSize)
        : this.pageSizes[0]
    this.$nextTick(() => {
      LayoutModule.setTablePageSize({
        layoutName: this.layoutName,
        dataSetName: this.dataSetName,
        pageSize: pageSize
      })
      LayoutModule.setTableTotalBy({
        layoutName: this.layoutName,
        dataSetName: this.dataSetName,
        getTotalBy: this.getTotalBy
      })
    })
    // 当表格进入快速编辑状态时，当前组件隐藏
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.visible`, this.doVisible)
    // 当 user_table_setting 表中保存了 pageSize 信息，则触发更新
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.change-pageSize`, this.setSettingPageSize)
  }

  beforeDestroy () {
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.visible`, this.doVisible)
  }
}
