
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import TimeBase from './TimeBase'

@Component({
  name: 'TableCellTime'
})
class TableCellTime extends Mixins(TableCellBase, TimeBase) {}
export default TableCellTime
