import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { LoadingLevel } from '@/http'
import _ from 'lodash'
import { Vue } from 'vue-property-decorator'
import eventbus from '@/utils/event'
import { LayoutModule } from './layout'

export interface AppState {
  appLoading: boolean;
  currentLayout: string;
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements AppState {
  appLoading = false
  selectLoading = true
  loadingLayoutNameArr:Array<string> = []
  loadingTime = 0
  selectLoadingTime = 0
  layoutStacks: string[] = []
  currentEventOwnerUId = '' // 当前事件的所属者组件的 uid
  processingEventOwnerUIds: Array<number> = [] //  当前正在处理任务中的组件 uid
  uiWebSocketListener: Record<string, Record<string, string>> = { open: {}, close: {}, customMessage: {} } // 全局注册的通知监听事件

  get currentLayout () {
    return this.layoutStacks.slice().pop() || ''
  }

  @Mutation
  private UPDATE_APP_LOADING_STATUS (payload: Record<string, any>): void {
    const { level, status, layoutName } = payload
    if (level === LoadingLevel.APP) {
      this.appLoading = status
    } else if (level === LoadingLevel.CONTENT) {
      if (status) {
        !this.loadingLayoutNameArr.includes(layoutName) && this.loadingLayoutNameArr.push(layoutName)
      } else {
        this.loadingLayoutNameArr.splice(this.loadingLayoutNameArr.indexOf(layoutName), 1)
      }
    } else if (level === LoadingLevel.SELECT) {
      this.selectLoading = status
    }
  }

  @Action
  updateAppLoadingStatus (payload: Record<string, any>): void {
    this.UPDATE_APP_LOADING_STATUS(payload)
  }

  @Mutation
  private UPDATE_APP_LOADING_TIME (loadingTime: number): void {
    this.loadingTime = loadingTime
  }

  @Action
  updateAppLoadingTime (payload: number): void {
    this.UPDATE_APP_LOADING_TIME(payload)
  }

  @Mutation
  private UPDATE_SELECT_LOADING_TIME (selectLoadingTime: number): void {
    this.selectLoadingTime = selectLoadingTime
  }

  @Action
  updateSelectLoadingTime (payload: number): void {
    this.UPDATE_SELECT_LOADING_TIME(payload)
  }

  @Mutation
  private PUSH_LAYOUT_INTO_STACKS (layoutName: string): void {
    this.layoutStacks.push(layoutName)
  }

  @Action
  pushLayoutIntoStacks (payload: string): void {
    this.PUSH_LAYOUT_INTO_STACKS(payload)
  }

  @Mutation
  private POP_LAYOUT_IN_STACKS (): void {
    this.layoutStacks.pop()
  }

  @Action
  popLayoutInStacks (): void {
    this.POP_LAYOUT_IN_STACKS()
  }

  @Action
  async closeAllStackLayouts () {
    for (let index = this.layoutStacks.length - 1; index >= 0; index--) {
      await this.closeTargetPopupLayout(this.layoutStacks[index])
      LayoutModule.removeLayoutDataRoot(this.layoutStacks[index])
    }
  }

  async closeTargetPopupLayout (payload: { encodedLayoutName: string, params?: Record<string, any> }): Promise<any>;
  async closeTargetPopupLayout (payload: string): Promise<any>;
  @Action
  async closeTargetPopupLayout (payload: any) {
    let encodedLayoutName = ''
    let params = {}
    if (payload instanceof Object) {
      encodedLayoutName = payload.encodedLayoutName
      params = payload.params
    } else {
      encodedLayoutName = payload
    }
    return new Promise((resolve, reject) => {
      // TODO: 验证下如果 promise 不结束，会不会导致内存泄漏
      eventbus.$emit(`${encodedLayoutName}.close-target-layout`, { proceed: resolve, params })
    })
  }

  @Mutation
  private UPDATE_APP_CURRENT_EVENT_OWNER (uid: string): void {
    this.currentEventOwnerUId = uid
  }

  @Action
  updateAppCurrentEventOwner (uid: string): void {
    this.UPDATE_APP_CURRENT_EVENT_OWNER(uid)
  }

  @Mutation
  private UPDATE_PROCESSING_EVENT_OWNER ({ uid = -1, type = 'add' }): void {
    type === 'add'
      ? this.processingEventOwnerUIds.push(uid)
      : this.processingEventOwnerUIds.splice(this.processingEventOwnerUIds.indexOf(uid), 1)
  }

  @Action
  updateProcessingEventOwner (payload: Record<string, any>): void {
    this.UPDATE_PROCESSING_EVENT_OWNER(payload)
  }

  @Action
  setAppWebSocketListener (payload: any) : void {
    this.SET_APP_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private SET_APP_WEBSOCKET_LISTENER ({ type, name, customType } : any) : void {
    this.uiWebSocketListener[type][name] = customType || name
  }

  @Action
  removeAppWebSocketListener (payload: any) : void {
    this.REMOVE_APP_WEBSOCKET_LISTENER(payload)
  }

  @Mutation
  private REMOVE_APP_WEBSOCKET_LISTENER ({ type, name } : any) : void {
    const listeners = this.uiWebSocketListener?.[type]
    if (!listeners) return
    delete listeners[name]
  }
}

export const AppModule = getModule(App)
