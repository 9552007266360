import { LayoutContext, LayoutFunction } from '@/types/layout'
import logwire from '@/logwire'
import Args from '@/models/Args'
import locale from 'element-ui/lib/locale'
import { LocalModule } from '@/store/modules/local'
import { doAction, queryByFilter } from '@/http/api'
import _ from 'lodash'
import localeCustom from 'element-ui/src/locale'
import { getCheckedArchiveStorage } from './data'
import { getLayoutNameOutsideComponent } from './layout'
import { LayoutModule } from '@/store/modules/layout'
import eventbus from './event'
import { AppModule } from '@/store/modules/app'
import {
  audioType,
  imgType,
  IntuitiveColorAllList,
  IntuitiveColorMainList,
  SemanticColorMainList,
  SemanticColorSchemes,
  showPreviewType,
  videoType
} from '@/utils/const'

declare const window: Window & { common?: Record<string, LayoutFunction> }

/**
 * 以 try catch 的形式去执行一段脚本
 * @param {string} javascriptString 要执行的脚本
 * @param {object} param 额外的参数，由于作用域的问题，在脚本中使用某一变量时，需要需要将其传入并以 param.xxx 的形式使用
 * */
export function tryEval (javascriptString: string, param?: Record<string, any>): any {
  try {
    // eslint-disable-next-line no-eval
    return eval(javascriptString)
  } catch (error) {
    // TODO 处理错误
    console.error(error)
  }
}

/**
 * 以 try catch 的形式去执行一个函数
 * @param {LayoutFunction} func layout.js 中的某个函数
 * @param {Args} args 函数执行需要的参数
 * @param {Array<string>} params 使用者自己传的参数
 * */
export function tryRunFunction (func: LayoutFunction, args: Args, params: Array<string> = [], isNullArgs = false): any {
  try {
    const namespace = args.getContext()?.getNamespace()
    // 临时挂载一个 common 到 window 上，以便函数内可以使用 common.xxx 去调用
    // 这个 common 指向的是当前 namespace 下的通用方法合集
    window.common = logwire.common[namespace]
    // eslint-disable-next-line no-useless-call
    const resArgs = isNullArgs ? null : args
    const result = func(resArgs, ...params)
    // 函数执行结束后移除这个 common 对象
    delete window.common
    return result
  } catch (error) {
    // TODO 处理错误
    console.error(error)
  }
}

// 某些函数的运行不需要传入 args
export function tryRunFunctionWithOutArgs (func: (p: Record<string, any>) => void, args: Args, params: Record<string, any>): any {
  try {
    const namespace = args.getContext()?.getNamespace()
    // 临时挂载一个 common 到 window 上，以便函数内可以使用 common.xxx 去调用
    // 这个 common 指向的是当前 namespace 下的通用方法合集
    window.common = logwire.common[namespace]
    const result = func(params)
    // 函数执行结束后移除这个 common 对象
    delete window.common
    return result
  } catch (error) {
    // TODO 处理错误
    console.error(error)
  }
}

export function getUuid (): string {
  const tempUrl = URL.createObjectURL(new Blob())
  const uuid = tempUrl.toString() // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
  URL.revokeObjectURL(tempUrl)
  return uuid.substr(uuid.lastIndexOf('/') + 1).replace(/-/g, '')
}

export function getMenu (menus: Array<Record<string, any>>, code: string): Record<string, any> | null {
  let result = null
  for (const menu of menus) {
    if (menu?.code === code) return menu

    if (menu.items?.length > 0) {
      result = getMenu(menu.items, code)
      if (result) return result
    }
  }
  return result
}

export function getQuery (queryString: string): Record<string, any> {
  if (!queryString) return {}
  const pairs = queryString.split('&')
  const query = {}
  for (const pair of pairs) {
    const [queryName, queryValue] = pair.split('=')
    query[queryName] = queryValue
  }
  return query
}

function pad (number: number) {
  return ('0' + number).slice(-2)
}

export function formatDuration (duration: number) {
  const hours = Math.floor(duration / 1000 / 60 / 60)
  const minutes = Math.floor(duration / 1000 / 60)
  const second = Math.floor(duration / 1000 - minutes * 60)
  return `${pad(hours)}:${pad(minutes)}:${pad(second)}`
}

export function setLocale (lang = '') {
  if (!navigator.languages) return
  let language = lang
  /**
   * 这里的懒加载，会在用到该语言文件的时候去加载
   * import() 进来的每个语言文件都单独打成一个 chunk 文件，并且这些 js 文件是 prefetch 的
   * 理论上这样可以节省不必要的 js 加载
   * 但是在实际使用过程中发现，会有数十个细小的 js 文件去加载，即使是 preftch 的文件，频繁的请求也不利于性能
   * 故而将所有语言文件打入一个 chunk，即使单个文件变大，但是减少了很多次请求
   * */
  const setElementLocale = (language: string) => {
    return import(
      /* webpackMode: "lazy-once" */
      `element-ui/lib/locale/lang/${language}`
      )
      .then(module => {
        locale.use(module.default)
        localeCustom.use(module.default)
      })
  }
  setElementLocale(language)
    .catch(_ => {
      language = language.substring(0, language.indexOf('-'))
      setElementLocale(language)
        .catch(_ => {
          language = navigator.languages[0]
          setElementLocale(language)
            .catch(_ => {
              setElementLocale('zh-CN')
            })
        })
    })
}

/**
 * 保存用户表格设置
 * @param layoutName layoutName
 * @param dataSetName dataSetName
 * @param settings 设置对象
 * @param successCallback 成功回调
 * @param failCallback 失败回调
 * @param anonymous 是否匿名访问页面，对于匿名访问页面，将数据保存在浏览器，而不是服务器
 */
export function saveUserSettings (layoutName: string, dataSetName: string, settings = {}, successCallback?: (res: Record<string, any>) => void, failCallback?: (err: Record<string, any>) => void, anonymous ?: boolean, silent?: boolean) {
  let dataForSave = {
    // user_id: LocalModule.user.id,
    table_control: layoutName + '.' + dataSetName
  }
  dataForSave = _.merge(dataForSave, settings)
  if (anonymous) {
    try {
      const userSettingStorageKey = 'userSetting.' + dataForSave.table_control
      window.localStorage.setItem(userSettingStorageKey, JSON.stringify(dataForSave))
      successCallback && successCallback(dataForSave)
    } catch (err) {

    }
  } else {
    doAction({ layoutName: layoutName, namespace: 'core', actionName: 'core.update-user-table-settings', data: dataForSave }, { silent })
      .then((res) => {
        successCallback && successCallback(res)
      })
      .catch((err) => {
        failCallback && failCallback(err)
      })
  }
}

export function getUserSettings (layoutName: string, dataSetName: string, successCallback?: (res: Record<string, any>) => void, failCallback?: (err: Record<string, any>) => void, anonymous?: boolean) {
  if (anonymous) {
    const dataForSave = {
      table_control: layoutName + '.' + dataSetName
    }
    const userSettingStorageKey = 'userSetting.' + dataForSave.table_control
    const settingStr = window.localStorage.getItem(userSettingStorageKey)
    try {
      const setting = JSON.parse(settingStr as string)
      const data = { rows: [setting] }
      successCallback && successCallback(data)
    } catch (err) {
      failCallback && failCallback(err)
    }
  } else {
    const data = {
      queryParams: {
        table_control: layoutName + '.' + dataSetName
      },
      getTotalBy: 'auto',
      pageNo: 1,
      pageSize: -1
    }
    queryByFilter({ layoutName: layoutName, namespace: 'core', queryName: 'core.user_table_setting_search', filter: data })
      .then((res) => {
        successCallback && successCallback(res.data.data)
      })
      .catch((err) => {
        failCallback && failCallback(err)
      })
  }
}
export function getArchiveStorageAppend () {
  let result = ''
  const archiveStorage = getCheckedArchiveStorage()
  if (archiveStorage) {
    result += '&__archive_datasource=' + archiveStorage
  }
  return result
}

export function _addUiWebSocketListener (type: string, listenerName: string, callback: () => void, customType?: string) {
  const currentLayout = getLayoutNameOutsideComponent()
  // 判断是否已经存在同名监听事件，有则移除前一个注册的同名事件
  const listeners = LayoutModule.data[currentLayout].uiWebSocketListener?.[type]
  if (listeners && Object.keys(listeners).includes(listenerName)) {
    eventbus.$off(`${currentLayout}.webSocket-${type}-${listenerName}`)
  } else {
    // 将事件名存储到页面的 resource 中
    LayoutModule.setUiWebSocketListener({ layoutName: currentLayout, type, name: listenerName, customType })
  }
  eventbus.$on(`${currentLayout}.webSocket-${type}-${listenerName}`, callback)
}

export function _removeUiWebSocketListener (type: string, listenerName: string) {
  const currentLayout = getLayoutNameOutsideComponent()
  eventbus.$off(`${currentLayout}.webSocket-${type}-${listenerName}`)
  LayoutModule.removeUiWebSocketListener({ layoutName: currentLayout, type, name: listenerName })
}

export function _addAppWebSocketListener (type: string, listenerName: string, callback: () => void, customType?: string) {
  // 判断是否已经存在同名监听事件，有则移除前一个注册的同名事件
  const listeners = AppModule.uiWebSocketListener?.[type]
  if (listeners && Object.keys(listeners).includes(listenerName)) {
    eventbus.$off(`webSocket-${type}-${listenerName}`)
  } else {
    // 将事件名存储到页面的 resource 中
    AppModule.setAppWebSocketListener({ type, name: listenerName, customType })
  }
  eventbus.$on(`webSocket-${type}-${listenerName}`, callback)
}

export function _removeAppWebSocketListener (type: string, listenerName: string) {
  eventbus.$off(`webSocket-${type}-${listenerName}`)
  AppModule.removeAppWebSocketListener({ type, name: listenerName })
}

export function getColor (colorScheme: string) {
  let color
  if (SemanticColorMainList.includes(colorScheme) && colorScheme !== SemanticColorSchemes.SECONDARY) color = 'var(--' + colorScheme + '-6)'
  if (colorScheme === SemanticColorSchemes.PRIMARY) color = 'var(--theme-6)'
  if (IntuitiveColorMainList.includes(colorScheme)) color = 'var(--' + colorScheme + '-c)'
  if (IntuitiveColorAllList.includes(colorScheme)) color = 'var(--' + colorScheme + ')'
  return color
}

export function getColorValueByScheme (colorScheme: string) {
  const scheme = getColor(colorScheme)
  if (!scheme) return
  const colorName = scheme.replace(/var\(--/g, '').replace(/\)/g, '')
  const theme = logwire.config.get('theme') || {}
  const { colors } = theme
  return colors[colorName]
}

// 区分文件类型
export function isTypeFile (file: any, type = showPreviewType.IMAGE) {
  let types = imgType
  if (type === showPreviewType.VIDEO) {
    types = videoType
  } else if (type === showPreviewType.AUDIO) {
    types = audioType
  }
  let flag = false
  const fileType = getFileType(file)
  if (types.includes(fileType)) flag = true
  return flag
}

export function getFileType (file: any) {
  const fileType = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
  return fileType.toLowerCase()
}
