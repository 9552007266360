
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import NumberBase from './NumberBase'

@Component({
  name: 'TableCellNumber'
})
class TableCellNumber extends Mixins(TableCellBase, NumberBase) {}
export default TableCellNumber
