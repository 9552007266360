














































































































import { Component, Provide, Vue, ProvideReactive, InjectReactive, Inject } from 'vue-property-decorator'
import eventbus from '@/utils/event'
import { LayoutContext, PopupLayoutConfig } from '@/types/layout'
import {
  EDIT_LAYOUT_POPUP_NAME_SUFFIX,
  layoutStatus,
  operationType,
  popupLayoutType,
  SCRIPT_SAVE_AND_NEW_EVENT, drawerCommand
} from '@/utils/const'
import { getUuid, tryRunFunction, tryRunFunctionWithOutArgs } from '@/utils/common'
import _ from 'lodash'
import { LayoutModule } from '@/store/modules/layout'
import Args from '@/models/Args'
import logwire from '@/logwire'
import { clearSpecialLayoutNameOutsideComponent, emitLayoutEvent, isSymbol, setLayoutForOutsideComponent, setSpecialLayoutNameOutsideComponent } from '@/utils/layout'
import { calculateDrawersPosition } from '@/utils/dom'
import { DataRow, DataSet as IDataSet } from '@/types/data'
import { checkEditDataSetBeforePopupDialogClose, formatDataRow } from '@/utils/data'
import { ElForm } from 'element-ui/types/form'
import LwInputForm from '@/components/layout/containers/InputForm.vue'
import { ElDialog } from 'element-ui/types/dialog'
import { ElDrawer } from 'element-ui/types/drawer'

/**
 * 对于 PopupLayout 页面，具有两个 layoutName 值，分别是注入的 layoutName(encodeLayoutName), 一个是 popupConfig.layoutName
 * 其中注入的 layoutName(encodeLayoutName) 常用于数据的绑定、显示, 这样内部的 lw-form 组件可以使用当前页面的 layoutName 查询多语言，方法等等
 * 而 popupConfig.layoutName 常用于传递给内部的 Layout 组件来渲染对应页面(如果是 editLayout)，以及执行保存操作时，传递给 save 事件，用来完成保存成功后的关闭回调，关闭当前弹窗
 */

@Component({ name: 'PopupLayout' })
class PopupLayout extends Vue {
  popupLayoutConfig = {} as PopupLayoutConfig
  appendToBody = true
  destroyOnClose = true
  operationType: operationType = operationType.NONE
  layoutTitle = ''
  drawerResizeFlag = false
  startResizePositionX: number | null = null
  resizeDom!: HTMLElement
  resizeDomWidth = 0
  resolve!: (param: any) => void
  validateInstances: LwInputForm[] = []

  // 当前属性在所有 PopupLayout 内唯一。如果存在的是一个 Layout, 那么记录这个 Layout 的 encodeLayoutName; 如果不存在一个 Layout, 那么赋予一个随机值
  popupLayoutFlag = 'Popup-' + getUuid()

  $refs!: {
    dialogLayout: ElDialog & { handleClose: () => void }; // 有一些方法在 element 实现里有，但是在类型声明里没有
    drawerLayout: ElDrawer & { closeDrawer: () => void };
    layout: any;
    form: any;
  }

  get headerTheme (): any { return logwire.store.getConfig('headerTheme') }

  get isHeaderDetailEditing (): boolean {
    return [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW].includes(this.popupLayoutConfig.sourceLayoutStatus)
  }

  get drawerSize (): string {
    // 抽屉宽度默认 50%
    let size = '50%'
    if (this.popupLayoutConfig.width) {
      size = this.popupLayoutConfig.width.replace('%', '')
      size = parseInt(size) + '%'
    }
    return size
  }

  // 如果传递的 popupConfig 参数具有 layouts 属性，并且不具有 editLayout 属性，则认为应该根据 layouts 的组件来渲染
  get isPopupShowLayoutsComponents (): boolean {
    return !!this.popupLayoutConfig.layouts?.length && !this.popupLayoutConfig.editLayout
  }

  @Inject() context!: LayoutContext

  @InjectReactive() layoutName!: string

  @InjectReactive() encodedLayoutName!: string

  // 如果是平台的表单编辑操作，并且有 editLayout 属性则返回，否则返回 null
  @ProvideReactive() get editLayoutName (): string | null {
    return this.popupLayoutConfig.isEditLayout ? this.popupLayoutConfig.editLayout : null
  }

  @ProvideReactive() get popupLayoutStatus (): string {
    return this.popupLayoutConfig.layoutStatus || ''
  }

  @ProvideReactive() evaluatingBase = 'editRow'

  @Provide() popupSetFormInstance = this.setFormInstance

  @Provide() popupLayout = true

  /*
  * 只有在调用平台默认的新增、编辑操作时，出现的弹窗中才会有这个方法的使用
  * op: 参数值为
  * save: 默认仅保存
  * saveAndNew: 保存并新增
  * updateAndNext： 保存并下一条
  * */
  async doEditLayoutOp (op: operationType, proceed?: () => void) : Promise<void> {
    const { sourceDataSetName, sourceLayoutName, editLayout, layoutStatus, editDataSet } = this.popupLayoutConfig
    this.operationType = op
    // 拿到当前 PopupLayout 里的数据，可能是 editLayout 也可能是当前Layout的平台默认编辑
    const rows = LayoutModule.data[editLayout]?.dataSet?.[editDataSet]?.rows || LayoutModule.data[this.encodedLayoutName]?.dataSet?.[editDataSet]?.rows
    // 表单校验
    for (const form of this.validateInstances) {
      const result = await form.validateData()
      if (result === false) return
    }
    // 保存操作中，需要还原 DataSet 状态
    // 这里的还原是跟关闭检查对应的，为了避免关闭时又提示有数据编辑未保存，提前将状态清空
    if ([operationType.SAVE, operationType.UPDATE_AND_NEXT, operationType.SAVE_AND_NEW].includes(op)) {
      if (editLayout) {
        LayoutModule.updateLayoutEditingDataSet({ layoutName: editLayout, dataSet: '' })
      } else {
        LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
      }
    }
    const cbFromPopup = () => {
      if (proceed) {
        proceed()
      } else { // 没有 proceed 说明是点击了按钮
        this.closePopupLayout()
      }
    }
    // 触发源页面的保存事件
    eventbus.$emit(`${sourceLayoutName}.${sourceDataSetName}.save`, { rows, mode: layoutStatus, op, cbFromPopup })
  }

  setLayoutTitle (layoutTitle: string) {
    const { isEditLayout, layoutStatus: status, layoutName, editLayout } = this.popupLayoutConfig
    this.layoutTitle = ''
    if (isEditLayout) {
      // 快速编辑 layout 是平台生成的
      // 根据 layoutStatus 取 title
      if (layoutTitle) {
        this.layoutTitle = layoutTitle
      } else if (layoutStatus.NEW === status) {
        // 新增
        this.layoutTitle = this.$i18n('core', 'client.common.new')
      } else if (layoutStatus.EDIT === status) {
        // 编辑
        this.layoutTitle = this.$i18n('core', 'client.common.edit')
      } else if (layoutStatus.VIEW === status) {
        // 查看
        this.layoutTitle = this.$i18n('core', 'client.common.view')
      }
    } else if (layoutName) {
      this.layoutTitle = layoutTitle
    }
  }

  doEditLayoutCancel () : void {
    this.operationType = operationType.CANCEL
    // 当前页面肯定关闭
    this.closePopupLayout()
  }

  // 触发 popup opened 的时候，内部的组件已经渲染完成了，所以内部如果有 editLayout 时，则已经根据未知的 dataSetName 创建了数据
  popupLayoutOpened () : void {
    const { afterLayoutLoadEvent } = this.popupLayoutConfig
    if (afterLayoutLoadEvent) {
      // 留一些间隙时间保证当前的动画结束了
      setTimeout(() => emitLayoutEvent(afterLayoutLoadEvent), 50)
    }
  }

  handleShowPopupLayout (config: PopupLayoutConfig): void {
    /**
     * 判断是否需要创建一个带后缀的 data 节点
     * 在 layout.data 下存在一个同名的节点并且是一个 popupLayout 时需要创建一个带后缀的节点
     * 保证即使两个窗口打开了同一个 layout也能正常使用
     * */
    config.createNewLayoutDataWithSuffix = Object.prototype.hasOwnProperty.call(LayoutModule.data, config.layoutName)
    this.popupLayoutConfig = config
    // 如果打开的是默认编辑弹窗，那么需要创建该 DataSet
    if (config.dataRow && config.editDataSet && this.isPopupShowLayoutsComponents) {
      if (!LayoutModule.data[this.encodedLayoutName]) {
        LayoutModule.loadLayoutDataRoot(this.encodedLayoutName)
      }
      // 如果当前页面内已有该 DataSet ，提示用户
      if (LayoutModule.data[this.encodedLayoutName]?.dataSet?.[config.editDataSet]) {
        console.warn(`[PopupLayout] 当前 Layout 【${this.encodedLayoutName}】 内已经有名为【${config.editDataSet}】 的DataSet，请重新命名`)
      }
      LayoutModule.loadLayoutDataSet({
        layoutName: this.encodedLayoutName,
        data: {
          dataSetName: config.editDataSet,
          rows: [config.dataRow]
        }
      })
    }
    this.validateInstances = []
    // 由平台生成的 layout 没有 title，只能根据页面状态使用 “编辑” 或者 “新增” 作为 title
    if (config?.layouts?.length) {
      this.setLayoutTitle('')
    }
  }

  closePopupLayout (): void {
    if (this.popupLayoutConfig.type === popupLayoutType.DIALOG) {
      this.$refs.dialogLayout && this.$refs.dialogLayout.handleClose()
    } else if (this.popupLayoutConfig.type === popupLayoutType.DRAWER) {
      this.$refs.drawerLayout && this.$refs.drawerLayout.closeDrawer()
    }
  }

  // 关闭时有可能弹窗都没有打开，type 属性为 undefined, 而几个 refs 都是存在的，因为只是用 visible 控制弹窗显示与否，组件本身是存在的
  closeAllPopupLayout (): void {
    this.closePopupLayout()
  }

  // 关闭不带遮罩的弹窗、抽屉
  closePopupWithoutModal (payload: {
    layoutName: string,
    hasPopupWithOutModal: boolean,
    resolve: () => void,
    reject: () => void
  }) {
    if (this.popupLayoutConfig.modal === false) {
      payload.hasPopupWithOutModal = true
      if (this.popupLayoutConfig.layoutName === payload.layoutName) {
        payload.reject()
      } else {
        this.handleClosePopupLayout(payload.resolve)
      }
    }
  }

  // 本方法主要检查是否平台默认的编辑流程中，存在未保存的数据
  checkExistEditData (proceed: () => void): void {
    const { sourceLayoutName, sourceDataSetName, isHeaderInfo } = this.popupLayoutConfig
    const initOperationType = this.operationType
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    if ([operationType.SAVE, operationType.SAVE_AND_NEW].includes(this.operationType)) {
      // 如果在searchLayout页面，地址栏中有id参数，而且点击searchbar中的编辑详情按钮，这个时候点击了保存就会导致地址栏的错误刷新，因此增加判断当前是不是弹出层
      this.operationType = operationType.NONE
      if (this.$route.query?.id && isHeaderInfo && !(this.$parent as any).popupLayout) {
        if (initOperationType === operationType.SAVE_AND_NEW) {
          sessionStorage[SCRIPT_SAVE_AND_NEW_EVENT] = `${sourceDataSetName}.new`
        }
        const ds = LayoutModule.data[sourceLayoutName].dataSet[sourceDataSetName].rows[0]
        const id = ds.currentData.id
        const href = location.href.substring(0, location.href.indexOf('/layout'))
        location.href = `${href}/layout/${sourceLayoutName}?id=${id}`
        return
      }
    }
    const onFinalClose = proceed
    /**
     * 关闭前进行检查，有几种情况
     * 1. 如果打开了平台默认的表单编辑功能，并且指定了 editLayout ，那么要检查这个 editLayout 上是否存在编辑中的 DataSet
     * 2. 如果使用了平台默认的表单编辑功能，并且没有指定 editLayout, 那么就要检查源 encodedLayoutName
     * 3. 如果使用 openLayoutInDialog 的 api，那么使用 popupLayoutFlag, 这个值就是内部 Layout 回写的 encodedLayoutName
     */
    const targetLayoutName = this.popupLayoutConfig.isEditLayout
      ? this.popupLayoutConfig.editLayout
        ? this.popupLayoutConfig.editLayout
        : this.encodedLayoutName
      : this.popupLayoutFlag // 如果 openLayoutInDialog 的打开的，那么就不需要检查
    checkEditDataSetBeforePopupDialogClose({
      layoutName: targetLayoutName,
      editDataSet: this.popupLayoutConfig.editDataSet,
      isEditLayout: this.popupLayoutConfig.isEditLayout,
      onClose: onFinalClose,
      async onSave () {
        if (vm.popupLayoutConfig.isEditLayout) {
          vm.doEditLayoutOp(operationType.SAVE, proceed)
        } else {
          vm.operationType = operationType.SAVE
          const editingDataSet = LayoutModule.data[vm.popupLayoutFlag]?.editingDataSet // editingDataSet 是携带了命名空间，layout名称的
          if (!editingDataSet) return
          // 拿到当前 PopupLayout 里的数据，可能是 editLayout 也可能是当前Layout的平台默认编辑
          const rows = LayoutModule.data[vm.popupLayoutFlag]?.dataSet?.[editingDataSet]?.rows
          // 表单校验
          for (const form of vm.validateInstances) {
            const result = await form.validateData()
            if (result === false) return
          }
          const cbFromPopup = () => {
            onFinalClose()
          }
          // 触发源页面的保存事件
          eventbus.$emit(`${editingDataSet}.save`, { rows, mode: layoutStatus.EDIT, op: vm.operationType, cbFromPopup })
        }
      }
    })
  }

  popupLayoutOpen (): void {
    this.popupLayoutConfig.type === 'drawer' && this.calculateDrawersPosition(drawerCommand.OPEN)
  }

  popupLayoutClose (): void {
    // 如果页面在onOpen时被修改，则需要在关闭时重置缓存
    _.isFunction(this.popupLayoutConfig.handleCache) && this.popupLayoutConfig.handleCache()
    // populayout 关闭的时候解绑当前 layout 绑定的相关事件，避免重复绑定
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // 对于平台内部，打开 EditLayout 时的 onClose 回调
    if (this.popupLayoutConfig.isEditLayout && this.popupLayoutConfig.onClose) {
      const params = { operationType: this.operationType }
      tryRunFunctionWithOutArgs(this.popupLayoutConfig.onClose, new Args(this.context), params)
    }
    this.popupLayoutConfig = {} as PopupLayoutConfig
    this.$refs.layout && this.$refs.layout.unbindAllPrevLayoutEvents(this.$refs.layout.encodedLayoutName)
  }

  popupLayoutClosed (isDrawer?: boolean) {
    // 关闭弹窗后重置 popupLayoutFlag，防止先打开的 Layout 弹窗影响了后续的默认弹窗
    this.popupLayoutFlag = 'Popup-' + getUuid()
    this.$nextTick(() => {
      setTimeout(() => {
        if (isDrawer) this.calculateDrawersPosition(drawerCommand.CLOSE)
        _.isFunction(this.resolve) && this.resolve('')
      }, 0)
    })
  }

  calculateDrawersPosition (command: drawerCommand) {
    calculateDrawersPosition(this, { command })
  }

  startResizeDrawer (e: MouseEvent) {
    this.drawerResizeFlag = true
    this.startResizePositionX = e.clientX
    let parentDom = e.target as HTMLElement
    while (parentDom && (parentDom.classList && !parentDom.classList.contains('el-drawer') && !parentDom.classList.contains('lw-unmasked-drawer'))) {
      parentDom = parentDom.parentNode as HTMLElement
    }
    if (parentDom) {
      this.resizeDom = parentDom
      this.resizeDomWidth = parentDom.clientWidth
      let originalTransion = ''
      if (parentDom.classList.contains('el-drawer')) {
        originalTransion = parentDom.style.transition
        parentDom.style.transition = 'unset'
      }
      window.addEventListener('mousemove', this.resizeFn)
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', this.resizeFn)
        parentDom.style.transition = originalTransion
      })
    }
  }

  resizeFn (e: MouseEvent) {
    const moveDistance = (this.startResizePositionX || 0) - e.clientX
    const width = this.resizeDomWidth + moveDistance
    this.resizeDom.style.width = width + 'px'
  }

  setFormInstance (vm: LwInputForm) {
    this.validateInstances.push(vm)
  }

  setEncodedLayoutName (encodedLayoutNameInCurrentLayout: string) {
    this.popupLayoutFlag = encodedLayoutNameInCurrentLayout
  }

  handleClosePopupLayout (proceed: () => void) {
    const finalProceed = () => {
      this.popupLayoutConfig.type = ''
      proceed()
    }
    // 当前组件显示了弹窗
    if (this.popupLayoutConfig.type === popupLayoutType.DIALOG || this.popupLayoutConfig.type === popupLayoutType.DRAWER) {
      // 当前组件显示了保存按钮，说明是来自表格或表单的编辑操作
      const config = this.popupLayoutConfig
      if (config.layoutStatus !== 'view' || (config.dataRow && config.editDataSet && this.isPopupShowLayoutsComponents)) {
        // 此时检查是否存在未编辑的数据
        this.checkExistEditData(finalProceed)
        return
      }
    }
    finalProceed()
  }

  // 关闭自身的弹窗
  handleBeforeClosePopup (proceed: () => void) {
    // 如果在 Element.Dialog.beforeClose 阶段中，type 值仍然存在，说明是手动点击关闭图标
    // 因为通过内部 Layout 关闭时，会先将其设置为空
    if (this.popupLayoutConfig.type === 'dialog' || this.popupLayoutConfig.type === 'drawer') {
      if (!this.popupLayoutFlag.startsWith('Popup-')) {
        // 如果内部具有Layout，那么通过关闭内部 Layout 的方式来关闭本弹窗
        eventbus.$emit(`${this.popupLayoutFlag}.close-target-layout`, { proceed })
      } else {
        // 如果内部不具备 Layout, 那么要仍然要检查未保存数据
        this.handleClosePopupLayout(proceed)
      }
    } else {
      // 如果是因为内部 Layout 关闭导致本弹窗关闭，那么认为已经经历了 beforePopupLayoutClose 阶段，直接通过
      proceed()
    }
  }

  // 从内部 Layout 传来事件通知 Popup 关闭
  handleCloseLayoutInPopup (proceed: () => void) {
    const finalProceed = () => {
      this.operationType = operationType.NONE
      this.popupLayoutConfig.type = ''
      proceed()
    }
    if (this.popupLayoutConfig.isEditLayout && this.popupLayoutConfig.layoutStatus !== 'view') {
      this.checkExistEditData(finalProceed)
    } else {
      finalProceed()
    }
  }

  created (): void {
    eventbus.$on('close-popup-without-modal', this.closePopupWithoutModal)
    eventbus.$on('close-all-popup-layout', this.closeAllPopupLayout)
    eventbus.$on(`${this.encodedLayoutName}.show-popup-layout`, this.handleShowPopupLayout)
    eventbus.$on(`${this.encodedLayoutName}.close-popup-layout`, this.handleClosePopupLayout)
  }

  beforeDestroy (): void {
    eventbus.$off('close-popup-without-modal', this.closePopupWithoutModal)
    eventbus.$off('close-all-popup-layout', this.closeAllPopupLayout)
    eventbus.$off(`${this.encodedLayoutName}.show-popup-layout`, this.handleShowPopupLayout)
    eventbus.$off(`${this.encodedLayoutName}.close-popup-layout`, this.handleClosePopupLayout)
  }
}

export default PopupLayout

