



















































import { Component, InjectReactive, Prop, Watch } from 'vue-property-decorator'
import { AssociatedContext, LayoutComponent, LayoutContext } from '@/types/layout'
import { DataRow } from '@/types/data'
import ColumnContent from '@/components/layout/basic/table-v2/ColumnContent'
import { getArchiveStorageAppend, getColor } from '@/utils/common'

import { getCsrfToken } from '@/utils/data'
import eventbus from '@/utils/event'
import { layoutStatus, unprocessableComponentList } from '@/utils/const'
import { runRunnableContent } from '@/utils/layout'
import Args from '@/models/Args'
import { isArray } from 'lodash'

@Component
export default class TableCell extends ColumnContent {
  @Prop() row!: DataRow
  @Prop() content!: any
  @Prop() component!: LayoutComponent
  @Prop() context!: LayoutContext
  @Prop() associatedContext!: AssociatedContext
  @InjectReactive() popupLayoutStatus!: string
  @InjectReactive() accessibleResourcesWithEdit!: boolean

  get disabled () {
    if (this.popupLayoutStatus === layoutStatus.VIEW) {
      return true
    }
    // 不具备编辑权限应该只能看
    if (!this.accessibleResourcesWithEdit) {
      return true
    }
    // table 行是否可用rowEnabled
    if (this.row._rowEnabled === false) {
      return true
    }
    // 表格为禁用状态时，component.enabled 为 false
    return this.component.enabled === false || this.tableCell.enabled === false
  }

  get visible () {
    return this.tableCell.visible === undefined ? true : this.tableCell.visible
  }

  get displayElement () {
    return this.tableCell.href ? 'a' : 'div'
  }

  get classList () {
    const classList = []
    const { href, rowChanged } = this.tableCell
    if (href) classList.push('underline')
    if (rowChanged) classList.push('row-changed')
    if (this.component.onClick) classList.push('clickable')
    return classList
  }

  get tableCell () {
    const result = this.content[this.component.field]
    return result
  }

  get style () {
    const { fontColorScheme, backColorScheme } = this.tableCell

    return {
      color: getColor(fontColorScheme) || '',
      backgroundColor: getColor(backColorScheme) || ''
    }
  }

  handleClick (evt: MouseEvent) {
    if (this.component.onClick) {
      evt.preventDefault()
      const args = new Args(this.context, { row: this.row })
      runRunnableContent('onClick', this.component, args, this.associatedContext)
    }
  }

  handleMouseEnter () {
    if (!this.disabled && ![...unprocessableComponentList, 'lw-timestamp'].includes(this.component.is)) {
      const editIcon = document.createElement('i')
      editIcon.setAttribute('class', 'iconfont icon-bianjixiao table-edit-icon')
      editIcon.onclick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.$emit('quick-edit')
      }

      const resetIcon = document.createElement('i')
      resetIcon.setAttribute('class', 'iconfont icon-chexiao table-edit-icon')
      resetIcon.onclick = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.$emit('data-row-reset')
      }
      this.$el.appendChild(editIcon)
      const changed = this.tableCell.rowChanged
      if (changed) {
        this.$el.insertBefore(resetIcon, editIcon)
      }
    }
  }

  handleMouseLeave () {
    const icons = this.$el.querySelectorAll('.table-edit-icon')
    if (icons?.length) {
      for (const icon of icons) {
        this.$el.removeChild(icon)
      }
    }
  }

  getFileName (value: string) {
    return value.split('|').map(o => {
      return o.replace(/(.*)\?.*?$/, '$1')
    }).join(',')
  }

  handleClickPreviewFile () {
    const namespace = this.context.getNamespace()
    const urls = (this.tableCell.value as string).split('|').filter(url => url !== '').map(item => {
      const [name, src] = item.split('?code=')
      const url = `/api/open/${namespace}/core.document-preview?name=${name}&code=${src}`
      return url
    })
    eventbus.$emit('preview-file', urls)
  }

  getColors (colors: string | string[] | undefined) {
    return isArray(colors) ? colors : colors?.split(',')
  }

  getIcons (icons: string | string[] | undefined) {
    return isArray(icons) ? icons : icons?.split(',')
  }

  mounted () {
    this.$el.addEventListener('mouseenter', this.handleMouseEnter)
    this.$el.addEventListener('mouseleave', this.handleMouseLeave)
    // 这里是为了防止横向滚动时，其他非 table-cell 的组件颜色粘连
    const setColor = () => {
      let el: any = this.$el
      while (!el.classList.contains('vxe-body--column') || el.nodeName === 'td') {
        el = el.parentNode
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      el.style.backgroundColor = ''
    }
    setColor()
  }

  beforeDestroy () {
    this.$el.removeEventListener('mouseenter', this.handleMouseEnter)
    this.$el.removeEventListener('mouseleave', this.handleMouseLeave)
  }
}
