import { attributeType } from '@/utils/const'
import { IDynamicAttr } from '@/types/table'
import { INumberContentStruct } from '@/components/layout/form-item/number/formatter'

export interface IRateStruct {
  maxScore: number;
  lowThreshold: number;
  highThreshold: number;
  colors: Array<string>;
  voidColor: string;
  disabledVoidColor: string;
  iconClasses: Array<string>;
  voidIconClass: string;
  disabledVoidIconClass: string;
  allowHalf: boolean;
  texts: Array<string>;
  textColor: string;
  value: any;
}

export const staticAttrs: Array<keyof IRateStruct | IDynamicAttr<keyof IRateStruct>> = [
  { key: 'maxScore', type: attributeType.NUMBER },
  { key: 'lowThreshold', type: attributeType.NUMBER },
  { key: 'highThreshold', type: attributeType.NUMBER },
  { key: 'colors', type: attributeType.STRING_ARRAY },
  'voidColor',
  'disabledVoidColor',
  { key: 'iconClasses', type: attributeType.STRING_ARRAY },
  'voidIconClass',
  'disabledVoidIconClass',
  { key: 'allowHalf', type: attributeType.BOOLEAN }
]

export const dynamicAttrs: Array<keyof IRateStruct | IDynamicAttr<keyof IRateStruct>> = [
  { key: 'texts', type: attributeType.STRING_ARRAY },
  'textColor'
]

export const formatContent = (struct: IRateStruct) => {
  return struct.value
}

export default {
  staticAttrs,
  dynamicAttrs
}
