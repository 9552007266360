import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import {
  getChoice,
  getI18nMessages,
  getUserMenu,
  updateFavoriteMenu,
  getUserInfoAction,
  getNotifications,
  getClientConfig,
  getTheme,
  getArchiveStorages,
  getChoiceDimension, getGatewayProperties
} from '@/http/api'
import { getMenu, setLocale } from '@/utils/common'
import { Vue } from 'vue-property-decorator'
import { removeLoginLayoutFromStorage } from '@/utils/layout'
import { Message, Notifications, Todo, Notification } from '@/types/common'
import { notificationHandledFlag, notificationType, socketMessageType } from '@/utils/const'
import logwire from '@/logwire'
import { saveCsrfToken } from '@/utils/data'

interface I18nPayload {
  [namespace: string]: Record<string, string>;
}

export interface LocalState {
  choices: Record<string, unknown>;
  dataSource: Record<string, unknown>;
  favoriteMenus: Array<Record<string, unknown>>;
  i18n: I18nPayload;
  menus: Array<Record<string, unknown>>;
  searchExpressions: Array<Record<string, unknown>>;
  user: Record<string, unknown>;
  notifications: Notifications;
  gatewayProperties: Record<string, unknown>;
  archiveStorages: Array<Record<string, unknown>>;
  customState: Record<string, unknown>; // 通过 logwire.store.setState 的状态存放在此
}

@Module({ dynamic: true, store, name: 'local' })
class Local extends VuexModule implements LocalState {
  choices = {}
  dataSource = {}
  favoriteMenus = [] as Array<Record<string, any>>
  i18n = {} as I18nPayload
  menus = [] as Array<Record<string, any>>
  searchExpressions = []
  user = {} as Record<string, any>
  config = {} as Record<string, any>
  notifications = {} as Notifications
  archiveStorages = [] as Array<Record<string, any>>
  dimensionChoices = {}
  gatewayProperties = {}

  customState = {}

  @Mutation
  LOAD_I18N_MESSAGES (payload: I18nPayload) {
    this.i18n = payload
  }

  @Action
  loadI18nMessages (payload: I18nPayload) {
    this.LOAD_I18N_MESSAGES(payload)
  }

  @Action
  async getI18nMessageAction () {
    const res = await getI18nMessages()
    this.loadI18nMessages(res.data.data)
  }

  @Mutation
  private LOAD_USER_FAVORITE_MENUS (payload: Array<Record<string, any>>) {
    this.favoriteMenus = payload
    // 在这里将 favorite_menus 的内容在 menu 中打上标记
    this.favoriteMenus.forEach(m => {
      const menu = getMenu(this.menus, m.code)
      menu && (menu.favoriteMenu = true)
    })
  }

  // 收藏菜单拖拽排序时候调用了这里
  @Action
  loadUserFavoriteMenus ({ favoriteMenus, update }: Record<string, any>) {
    favoriteMenus && this.LOAD_USER_FAVORITE_MENUS(favoriteMenus)
    update && this.updateFavoriteMenu()
  }

  @Mutation
  private CANCEL_FAVORITE_MENU (payload: Record<string, any>) {
    this.favoriteMenus = this.favoriteMenus.filter(menu => menu.code !== payload.code)
    const menu = getMenu(this.menus, payload.code)
    menu && (menu.favoriteMenu = false)
  }

  @Action
  cancelFavoriteMenu (payload: Record<string, any>) {
    this.CANCEL_FAVORITE_MENU(payload)
    this.updateFavoriteMenu()
  }

  @Mutation
  private COLLECT_MENU (payload: Record<string, any>) {
    this.favoriteMenus.push(payload)
    const menu = getMenu(this.menus, payload.code)
    menu && (menu.favoriteMenu = true)
  }

  @Action
  collectMenu (payload: Record<string, any>) {
    this.COLLECT_MENU(payload)
    this.updateFavoriteMenu()
  }

  @Action
  updateFavoriteMenu () {
    const data: Array<Record<string, any>> = []
    this.favoriteMenus.forEach(m => {
      data.push(m.code)
    })
    updateFavoriteMenu({ data })
  }

  @Mutation
  private LOAD_CLIENT_CONFIG (payload: Record<string, unknown>) {
    this.config = Object.assign({}, this.config, payload)
  }

  @Action
  loadClientConfig (payload: Record<string, unknown>) {
    this.LOAD_CLIENT_CONFIG(payload)
  }

  @Mutation
  private LOAD_LOGO ({ logoUrl }: Record<string, unknown>) {
    (this.config as any).logoUrl = logoUrl
  }

  @Action
  loadLogo (payload: Record<string, unknown>) {
    this.LOAD_LOGO(payload)
  }

  @Mutation
  private UPDATE_CLIENT_CONFIG (payload: Record<string, any>) {
    const { name, value } = payload
    Vue.set(this.config, name, value)
  }

  @Action
  updateClientConfig (payload: Record<string, any>) {
    this.UPDATE_CLIENT_CONFIG(payload)
  }

  @Mutation
  private UPDATE_CUSTOM_STATE (payload: Record<string, any>) {
    const { name, value } = payload
    Vue.set(this.customState, name, value)
  }

  @Action
  updateCustomState (payload: Record<string, any>) {
    this.UPDATE_CUSTOM_STATE(payload)
  }

  @Mutation
  private CLEAR_LOCAL_MODULE (): void {
    this.choices = {}
    this.dataSource = {}
    this.favoriteMenus = []
    this.menus = []
    this.searchExpressions = []
    this.user = {}
    this.notifications = {} as Notifications
    this.archiveStorages = []
    this.dimensionChoices = {}
    removeLoginLayoutFromStorage()
  }

  @Action
  clearLocalModule (): void {
    this.CLEAR_LOCAL_MODULE()
  }

  @Mutation
  private LOAD_USER (payload: Record<string, unknown>) {
    this.user = payload
  }

  @Action
  loadUser () {
    getUserInfoAction()
      .then(res => {
        this.LOAD_USER(res.data.data)
      })
  }

  @Mutation
  private GET_OR_CREATE_DIMENSION_CHOICES (payload: Record<string, unknown>) {
    this.dimensionChoices = Object.assign({}, this.dimensionChoices, payload)
  }

  @Action
  getOrCreateDimensionChoices (dimension: string) {
    // 查找名称是否已经存在
    if (this.dimensionChoices[dimension]) return
    this.GET_OR_CREATE_DIMENSION_CHOICES({ [dimension]: [] })
    getChoiceDimension(dimension)
      .then(res => {
        this.GET_OR_CREATE_DIMENSION_CHOICES(res.data.data)
      })
  }

  @Mutation
  private LOAD_NECESSARY_PROFILES (payload: { user: Record<string, any>, menus: Record<string, any>, choices: Array<Record<string, any>>, archiveStorages: Array<Record<string, any>> }): void {
    const { user, menus, choices, archiveStorages } = payload
    this.user = user
    this.menus = menus.menus.filter((o: any) => o.authorizingPurpose !== true)
    this.choices = choices
    this.archiveStorages = archiveStorages
  }

  @Action
  async loadNecessaryProfiles (callback: () => void): Promise<void> {
    const userRes = await getUserInfoAction()
    const { csrfToken } = userRes.data.data
    csrfToken && saveCsrfToken(csrfToken)
    const [menuRes, choiceRes, archiveRes] = await Promise.all([getUserMenu(), getChoice(), getArchiveStorages()])
    this.LOAD_NECESSARY_PROFILES({ user: userRes.data.data, menus: menuRes.data.data, choices: choiceRes.data.data, archiveStorages: archiveRes.data.data })
    this.loadUserFavoriteMenus({ favoriteMenus: menuRes.data.data.favoriteMenus || [], update: false })
    callback()
  }

  @Mutation
  LOAD_NOTIFICATIONS (payload: Notifications): void {
    this.notifications = payload
  }

  @Action
  loadNotifications (): void {
    getNotifications().then(res => {
      this.LOAD_NOTIFICATIONS(res.data.data)
    })
  }

  @Mutation
  UPDATE_NOTIFICATION (notification: Notification): void {
    const { type } = notification
    const { notifications } = this
    const isMessage = [socketMessageType.NEW_MESSAGE, socketMessageType.READ_MESSAGE].includes(type as socketMessageType)
    const typedNotification = isMessage ? notifications.latest_messages : notifications.latest_todo
    const { MESSAGE_READ, TODO_HANDLED } = notificationHandledFlag
    if ([MESSAGE_READ, TODO_HANDLED].includes(type as notificationHandledFlag)) {
      const index = typedNotification.findIndex(ntc => ntc.id === notification.id)
      typedNotification.splice(index, 1)
      isMessage
        ? notifications.message_count = notifications.message_count > 0 ? --notifications.message_count : 0
        : notifications.todo_count = notifications.todo_count > 0 ? --notifications.todo_count : 0
    } else {
      typedNotification.pop()
      typedNotification.unshift(notification)
      isMessage
        ? ++notifications.message_count
        : ++notifications.todo_count
    }
  }

  @Action
  updateNotification (payload: Notification): void {
    this.UPDATE_NOTIFICATION(payload)
  }

  /**
   * 用户登录后需要重新抓取主题色
   */
  @Action
  async getThemeAction (): Promise<void> {
    const { data: { data } } = await getClientConfig()
    const appearance = data['core.appearance.theme']
    const themeRes = await getTheme(appearance)
    logwire.store.setConfig('theme', themeRes.data.data[0])
    logwire.store.setConfig('headerTheme', JSON.parse(data['core.appearance.theme-color-for-header']))
    logwire.store.setConfig('menuTheme', JSON.parse(data['core.appearance.theme-color-for-menu']))
    setLocale(data['core.i18n.language'])
  }

  @Mutation
  private LOAD_GATEWAY_PROPERTIES (payload: Record<string, any>): void {
    this.gatewayProperties = payload
  }

  @Action
  loadGatewayProperties (): void {
    getGatewayProperties()
      .then(res => {
        this.LOAD_GATEWAY_PROPERTIES(res.data.data)
      })
  }
}

export const LocalModule = getModule(Local)
