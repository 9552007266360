<template>
  <div
    :class="['el-input', 'el-input--small',
             {
               'is-disabled': disabled,
               'el-input-group': $slots.prepend || $slots.append,
               'el-input-group--append': $slots.append,
               'el-input--suffix': true
             }]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div
      class="el-input-group__prepend"
      v-if="$slots.prepend"
    >
      <slot name="prepend"></slot>
    </div>
    <input
      v-if="formatEnabled"
      class="el-input__inner"
      type="text"
      :style="inputStyle"
      :placeholder="placeholder"
      :disabled="disabled"
      ref="cleaveNumber"
      @input="isFocus = true"
      @blur="handleBlur"
      @change="handleChange"
      @focus="handleFocus"
    />
    <input
      v-else
      class="el-input__inner"
      type="number"
      :style="inputStyle"
      :placeholder="placeholder"
      :value="displayValue"
      :disabled="disabled"
      ref="cleaveNumber"
      step="any"
      @input="handleInput"
      @blur="handleBlur"
      @change="handleChange"
      @focus="handleFocus"
    />
    <!-- 内容清空后置 -->
    <span
      v-if="showClear"
      class="el-input__suffix"
    >
      <span class="el-input__suffix-inner">
        <i
          class="el-input__icon el-icon-circle-close el-input__clear"
          @mousedown.prevent
          @click="clear"
        ></i>
      </span>
    </span>
    <div
      class="el-input-group__append"
      v-if="$slots.append"
    >
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import Cleave from 'cleave.js'
import { ceil } from '@/utils/layout'
import _ from 'lodash'

export default {
  name: 'LwCleaveNumber',
  props: {
    value: [String, Number],
    placeholder: String,
    disabled: Boolean,
    size: String,
    formatEnabled: {
      type: Boolean,
      default: false
    },
    decimalScale: {
      type: Number,
      default: 0
    },
    thousandsGroupStyle: {
      type: String,
      default: 'thousand'
    },
    positiveOnly: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Boolean,
      default: false
    },
    rowValue: {
      type: Object,
      default: null
    },
    inputStyle: String
  },

  data () {
    return {
      cleave: null,
      oldValue: '',
      displayValue: '',
      isFocus: false,
      lastValidValue: '', // 最后一次输入的有效值
      hovering: false
    }
  },

  mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    this.lastValidValue = this.oldValue = this.value
    if (this.formatEnabled) {
      const input = this.$refs.cleaveNumber
      const options = {
        numeral: true,
        numeralThousandsGroupStyle: this.thousandsGroupStyle,
        numeralDecimalScale: this.decimalScale,
        numeralPositiveOnly: this.positiveOnly,
        onValueChanged: (e) => {
          const rawValue = e.target.rawValue
          let emitValue = rawValue
          if (emitValue !== '') {
            emitValue = Number(emitValue)
          }
          self.isFocus && self.$emit('input', emitValue)
        }
      }
      if (this.percentage) {
        options.prefix = '%'
        options.tailPrefix = true
        options.onValueChanged = (e) => {
          const rawValue = e.target.rawValue
          let emitValue = rawValue.replace('%', '')
          if (emitValue !== '') {
            emitValue = Number(emitValue)
          }
          self.isFocus && self.$emit('input', emitValue)
        }
      }
      this.cleave = new Cleave(input, options)
      this.cleave.setRawValue(this.fillZero(this.value))
      this.decimalScale && (this.oldValue = this.fillZero(this.value))
    } else {
      this.displayValue = this.value
    }
    this.updateIconOffset()
  },

  updated () {
    this.$nextTick(this.updateIconOffset)
  },

  watch: {
    value (val, oldVal) {
      if (this.formatEnabled && !this.isFocus) {
        // 使用cleave格式化时，如果通过js片段进行赋值，则需要isFocus判断位，否则将重复执行导致光标位置频繁变动
        this.cleave.setRawValue(this.fillZero(val))
      } else {
        this.displayValue = val
      }
    }
  },

  beforeDestroy () {
    this.formatEnabled && this.cleave.destroy()
  },

  methods: {
    updateIconOffset () {
      this.calcIconOffset('suffix')
    },
    calcIconOffset (place) {
      const elList = [].slice.call(this.$el.querySelectorAll(`.el-input__${place}`) || [])
      if (!elList.length) return
      let el = null
      for (let i = 0; i < elList.length; i++) {
        if (elList[i].parentNode === this.$el) {
          el = elList[i]
          break
        }
      }
      if (!el) return
      const pendantMap = {
        suffix: 'append',
        prefix: 'prepend'
      }

      const pendant = pendantMap[place]
      if (this.$slots[pendant]) {
        el.style.transform = `translateX(${place === 'suffix' ? '-' : ''}${this.$el.querySelector(`.el-input-group__${pendant}`).offsetWidth}px)`
      } else {
        el.removeAttribute('style')
      }
    },
    handleInput (event) {
      // 处理input type=number 在IE不兼容
      const inputValue = event.target.value
      // 输入负号 -，inputValue为空字符串
      if (inputValue !== '' && !isNaN(Number(inputValue))) {
        this.$emit('input', Number(inputValue))
        this.lastValidValue = inputValue
      } else if (this.lastValidValue !== '' && inputValue === '') {
        this.$emit('input', '')
        this.lastValidValue = ''
      }
    },
    handleFocus () {
      this.isFocus = true
    },
    handleBlur (event) {
      this.isFocus = false
      let rawValue
      // this.$parent 为 ElFormItem，调用其validate方法可以触发element的表单校验
      this.$parent.validate('blur')
      if (this.formatEnabled) {
        rawValue = this.cleave.getRawValue()
        this.cleave.setRawValue(this.fillZero(rawValue))
      } else {
        rawValue = event.target.value
        if (isNaN(Number(rawValue))) {
          event.target.value = this.lastValidValue
          rawValue = this.lastValidValue
        }
      }
      rawValue = Number(rawValue)
      if (this.rowValue !== null) {
        this.$emit('blur', { value: rawValue, rowValue: this.rowValue })
      } else {
        this.$emit('blur')
      }
      if (this.rowValue !== null && rawValue !== this.oldValue) {
        this.$emit('row-value-change', { value: rawValue, rowValue: this.rowValue })
      }
      this.oldValue = rawValue
    },
    fillZero (originalValue) {
      if (originalValue === null || originalValue === undefined || originalValue === '') return ''
      if (originalValue === 0) return 0
      originalValue = originalValue.toString()
      // 对于输入0 0.0的情况，视作无效处理，光标移出都不会补零
      if (Number(originalValue) && this.formatEnabled) {
        const splitArray = originalValue.split('.')
        if (splitArray.length !== 1) {
          const decimalVal = splitArray[1]
          if (decimalVal.length < this.decimalScale) {
            const suffixLength = this.decimalScale - decimalVal.length
            const suffixZero = new Array(suffixLength).fill(0).join('')
            originalValue = `${originalValue}${suffixZero}`
          } else {
            // 对于数据库中数据进行四舍五入处理，比方保存在数据库中是五位小数，显示仅显示两位
            /* eslint-disable */
            originalValue = ceil(originalValue, decimalVal, this.decimalScale) ? _.ceil(new Number(originalValue), this.decimalScale)
             : _.floor(new Number(originalValue), this.decimalScale)
          }
        }
        if (originalValue.toString().split('.').length === 1) {
          if (this.decimalScale !== 0) {
            const suffixZero = new Array(this.decimalScale).fill(0).join('')
            originalValue = `${originalValue}.${suffixZero}`
          }
        }
      }
      return originalValue
    },
    handleChange () {
      // 如果不设置 isFocus，在 formatEnable=true 时在 onchange 事件中使用 js 赋值失败
      this.isFocus = false
      this.$emit('change')
    },
    clear () {
      const input = this.$refs.cleaveNumber
      input.value = ''
      this.$emit('input', '')
    }
  },

  computed: {
    showClear () {
      const nativeInputValue = this.value === null || this.value === undefined ? '' : String(this.value)
      return (this.isFocus || this.hovering) && nativeInputValue
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
</style>
