








import BaseComponent from '../BaseComponent'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { getUuid } from '@/utils/common'
import { LayoutComponent } from '@/types/layout'
import Dynamic from '@/models/Dynamic'
import eventbus from '@/utils/event'
import frag from 'vue-frag'
import DataRow from '@/models/DataRow'
import { DataRow as IDataRow } from '@/types/data'
import Args from '@/models/Args'
import _ from 'lodash'
import BaseComponentWithRow from '../mixin/baseComponentWithRow'

@Component({ name: 'LwDynamic', directives: { frag } })
class LwDynamic extends Mixins(BaseComponent, BaseComponentWithRow) {
  components: Array<LayoutComponent> = []
  show = true

  get name (): string {
    return this.component.name
  }

  get computedComponents (): Array<LayoutComponent> {
    if (!this.component.components) return []
    const components = this.runRunnableContent('components', { args: this.argsWithRow })
    return components?.map((component: LayoutComponent) => {
      component.id = getUuid()
      return component.component
    })
  }

  get renderComponents (): Array<LayoutComponent> {
    return this.component.name
      ? this.components
      : this.computedComponents
  }

  // 监听每次动态组件的改动，判断外层如果是form,则替换dynamic里的组件到form中
  @Watch('components', { immediate: true, deep: true })
  watchComponents (components : Array<LayoutComponent>) : void {
    const sup: any = this.$parent?.$parent
    if (sup.$options.name === 'LwForm') {
      sup.component.components = sup.component.components.filter((item:any) => item.DynamicName !== this.component.name)
      const start = sup.component.components.findIndex((e: any) => e.name === this.component.name)
      const componentsCopy = _.cloneDeep(components)
      sup.component.components.splice(start + 1, 0, ...componentsCopy.map(item => {
        Vue.set(item, 'DynamicName', this.component.name)
        return item
      }))
      this.show = false
      sup.prepareForm()
    }
  }

  getDynamic (dynamic: Record<string, any>) : void {
    dynamic.instance = new Dynamic(this.components)
  }

  created (): void {
    eventbus.$on(`${this.encodedLayoutName}.${this.name}.getDynamic`, this.getDynamic)
  }

  beforeDestroy (): void {
    eventbus.$off(`${this.encodedLayoutName}.${this.name}.getDynamic`, this.getDynamic)
  }
}
export default LwDynamic
