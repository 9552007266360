



























































import { Component, Prop } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import _ from 'lodash'
import logwire from '@/logwire'
import { doAction, doSave } from '@/http/api'
import { LocalModule } from '@/store/modules/local'
import draggable from 'vuedraggable'
import { saveUserSettings } from '@/utils/common'
import { TableSetting } from '@/types/table'

@Component({ name: 'fields-display', components: { draggable } })
class FieldsDisplay extends BaseComponent {
  @Prop() dialogVisible !: boolean
  @Prop() settingDate !: TableSetting
  @Prop() dataSetName: any

  handleClose () {
    this.$emit('closeDisplayDialog')
  }

  save () {
    // 保存到 user_table_settings 表中
    const displayFieldsJson = JSON.stringify(this.settingDate.display_fields.map((item) => _.pick(item, ['name', 'display', 'freeze'])))
    saveUserSettings(this.layoutName, this.dataSetName,
      {
        display_fields_enabled: this.settingDate.display_fields_enabled,
        display_fields_json: displayFieldsJson
      },
      (res) => {
        // 设置成功之后不刷新页面
        this.$emit('saveDisplayDialog', res)
      }
    )
  }

  showAll () {
    for (const item of this.settingDate.display_fields) {
      item.display = true
    }
    this.$forceUpdate()
  }

  hideAll () {
    for (const item of this.settingDate.display_fields) {
      item.display = false
    }
    this.$forceUpdate()
  }

  changeDisplayFields () {
    this.$forceUpdate()
  }

  changeDisplayFreeze (item: TableSetting['display_fields'][number]) {
    const index = this.settingDate.display_fields.indexOf(item)
    if (item.freeze) { // 冻结某一列，则自动把上方的列都冻结
      for (let i = 0; i < this.settingDate.display_fields.length; i++) {
        if (i < index) {
          this.settingDate.display_fields[i].freeze = true
        }
      }
    } else {
      for (let i = 0; i < this.settingDate.display_fields.length; i++) {
        if (i > index) {
          this.settingDate.display_fields[i].freeze = false
        }
      }
    }
  }

  // 拖拽结束后，按顺序遍历，从第一个非冻结列开始，将以下的列都设置为非冻结列
  handleDragEnd () {
    let unfreezeLineFound = false
    for (const item of this.settingDate.display_fields) {
      if (item.freeze !== true && unfreezeLineFound === false) {
        unfreezeLineFound = true
      }
      if (unfreezeLineFound) {
        item.freeze = false
      }
    }
  }
}
export default FieldsDisplay
