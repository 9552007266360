






































import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import { attributeType } from '@/utils/const'
import logwire from '@/logwire'
import TimeBase from '@/components/layout/form-item/time/TimeBase'

@Component({ name: 'LwTime' })
class LwTime extends Mixins(FormControlComponent, TimeBase) {
  valueFormat = 'HH:mm:ss'
  get selectStart (): string { return this.getFinalAttributeValue('selectStart', { args: this.args }) }

  get selectEnd (): string { return this.getFinalAttributeValue('selectEnd', { args: this.args }) }

  get selectStep (): string { return this.getFinalAttributeValue('selectStep', { args: this.args }) }

  get selectMax (): string { return this.getFinalAttributeValue('selectMax', { args: this.args }) }

  get selectMin (): string { return this.getFinalAttributeValue('selectMin', { args: this.args }) }

  get selectableRange (): Array<string> { return this.getFinalAttributeValue('selectableRange', { args: this.args, type: attributeType.STRING_ARRAY }) }

  get options (): Record<string, any> {
    if (this.type === 'select') {
      return {
        start: this.selectStart,
        end: this.selectEnd,
        step: this.selectStep,
        maxTime: this.selectMax,
        minTime: this.selectMin
      }
    }
    return {
      selectableRange: this.selectableRange
    }
  }

  get displayFormat (): string {
    const format = logwire.store.getConfig('core.i18n.time-format') === '24-hour'
      ? 'HH:mm:ss'
      : 'hh:mm:ss A'
    return format
  }
}
export default LwTime
