

















import { Vue, Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import RateBase from '@/components/layout/form-item/rate/RateBase'

@Component({})
export default class TableCellRate extends Mixins(TableCellBase, RateBase) {

}
