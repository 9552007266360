import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from 'element-ui/src/utils/date-util'
import logwire from '@/logwire'

import formatter, { dateFormatter } from '@/components/layout/form-item/date/formatter'
const DATE_TYPES = ['year', 'month', 'date', 'daterange']

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get type (): string {
    const type = this.getFinalAttributeValue('type', { args: this.args })
    return DATE_TYPES.includes(type) ? type : 'date'
  }

  get displayFormat (): string {
    return dateFormatter(this.type)
  }

  get inputValue (): any {
    const { field } = this.component
    const dataRow = this.form.dataRow
    const value = dataRow[field]
    return formatter.formatValue({ value, type: this.type })
  }

  set inputValue (value: any) {
    this.form.dataRow[this.component.field] = value
  }

  // 根据首选项
  get displayValue (): string {
    return formatter.formatContent({ value: this.inputValue, type: this.type })
  }
}

export default DateBase
