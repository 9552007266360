<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellUploadFile from './TableCellUploadFile.vue'
import UploadFile from './UploadFile.vue'

@Component({ name: 'LwUploadFile', components: { TableCell: TableCellUploadFile, FormItem: UploadFile } })
class LwUploadFile extends FormControlBase {}
export default LwUploadFile
</script>
