








import { Component } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { LayoutComponent } from '@/types/layout'
import Step from '../inner/Step.vue'
import { attributeType } from '@/utils/const'

@Component({ name: 'LwSteps', components: { Step } })
export default class LwSteps extends BaseComponent {
  // 当前处于处理中状态的步骤的序号。必须填写一段脚本，序号从 1 开始
  get processingStep (): number { return this.getFinalAttributeValue('processingStep', { type: attributeType.NUMBER }) }

  get childGroup (): Array<LayoutComponent> {
    return this.component.steps || []
  }

  get defaultColorScheme () {
    return (index : number, type: string) => {
      const step = index + 1
      const defaultColorArr = type === 'icon' ? ['processedStepIconColorScheme', 'processingStepIconColorScheme', 'unprocessedStepIconColorScheme'] : ['processedStepTitleColorScheme', 'processingStepTitleColorScheme', 'unprocessedStepTitleColorScheme']
      if (step < this.processingStep) {
        return this.getFinalAttributeValue(defaultColorArr[0]) || 'success'
      } else if (step === this.processingStep) {
        return this.getFinalAttributeValue(defaultColorArr[1]) || 'primary'
      } else if (step > this.processingStep) {
        return this.getFinalAttributeValue(defaultColorArr[2]) || 'gray'
      }
    }
  }
}
