import { Component, Vue } from 'vue-property-decorator'
import formatter from './formatter'
import { warnAttributeMissing } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import { attributeType } from '@/utils/const'
import { Option } from '@/components/layout/form-item/choice/ChoiceBase'

@Component
class RadioBase extends Vue {
  [propName: string]: any;

  get options (): Array<Option> {
    const namespace = this.context.getNamespace()
    const { category } = this.component
    if (!category) {
      warnAttributeMissing('category', this.component.is)
    }
    let namespacedCategory = category
    if (!category.includes('.')) {
      namespacedCategory = `${namespace}.${category}`
    }
    let choices = LocalModule.choices[namespacedCategory] || []
    // 根据 dimension 属性显示下拉内容 LocalModule.dimensionChoices
    const dimension = this.component.dimension
    if (dimension) {
      const dimensionChoices = LocalModule.dimensionChoices[dimension]
      choices = choices.filter((choice: Option) => dimensionChoices?.includes(choice.value))
    }
    const filteredChoices = this.getFinalAttributeValue('filteredChoices', { args: this.args, type: attributeType.STRING_ARRAY })
    return filteredChoices
      ? choices.filter((choice: Option) => filteredChoices.includes(choice.value))
      : choices
  }

  get displayValue (): string {
    const { inputValue, options } = this
    const result = formatter.formatContent({ value: inputValue, options })
    return result
  }

  mounted () {
    const dimension = this.component.dimension
    if (!dimension) return
    LocalModule.getOrCreateDimensionChoices(dimension)
  }
}

export default RadioBase
