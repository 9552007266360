
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import DateBase from './DateBase'

@Component({
  name: 'TableCellDate'
})
class TableCellDate extends Mixins(TableCellBase, DateBase) {}
export default TableCellDate
