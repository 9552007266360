import ResizeObserver from 'resize-observer-polyfill'
import { checkValueIsEmpty } from '@/utils/data'
import { attribute2Number, getLayoutNameOutsideComponent, percent2decimal } from './layout'
import { BASE_FONT_SZIE, CALCULATE_HEIGHT_ELEMENTS_CLASS_NAME, drawerCommand } from '@/utils/const'
import { Message } from 'element-ui'
import _, { floor, isNumber } from 'lodash'
import eventbus from './event'
import Vue from 'vue'
import { messageInstancesIdMap } from '@/logwire/ui'

const resizeHandler = function (entries: Array<Record<string, any>>) {
  for (const entry of entries) {
    const listeners = entry.target.__resizeListeners__ || []
    if (listeners.length) {
      listeners.forEach((fn: () => void) => {
        fn()
      })
    }
  }
}
/* istanbul ignore next */
export const addResizeListener = function (element: any, fn: () => void) {
  if (!element.__resizeListeners__) {
    element.__resizeListeners__ = []
    element.__ro__ = new ResizeObserver(resizeHandler)
    element.__ro__.observe(element)
  }
  if (element.__resizeListeners__.indexOf(fn) === -1) {
    element.__resizeListeners__.push(fn)
  }
}

/* istanbul ignore next */
export const removeResizeListener = function (element: any, fn: () => void) {
  if (!element || !element.__resizeListeners__) return
  element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1)
  if (!element.__resizeListeners__.length) {
    element.__ro__.disconnect()
  }
}

export const appendBodyStyle = function (style: string, id: string) : void {
  const hasStyle = document.getElementById(id)
  if (hasStyle) return
  const styleScript = document.createElement('style')
  styleScript.type = 'text/css'
  styleScript.id = id
  if ((styleScript as any).styleSheet) {
    (styleScript as any).styleSheet.cssText = style
  } else {
    styleScript.innerHTML = style
  }
  document.getElementsByTagName('head')[0].appendChild(styleScript)
}

export const jsonStyle2String = function (jsonStyle: Record<string, any>) : string {
  const s = []
  for (const i in jsonStyle) {
    s.push(i + ':' + jsonStyle[i])
  }
  return s.join(';')
}

export const findTargetDom = function (target: EventTarget, tagName: string) : any {
  let parentNode: any = target
  while (parentNode.tagName !== tagName) {
    parentNode = parentNode.parentNode
  }
  return parentNode
}

export function getScrollParent (element: HTMLElement): HTMLElement {
  const parent = element.parentNode
  if (!parent) {
    return element
  }
  if (parent === document) {
    // FireFox 浏览器的 scroll 相关属性在 documentElement 上面
    if (document.body.scrollTop || document.body.scrollLeft) {
      return document.body
    } else {
      return document.documentElement
    }
  }
  if (
    ['scroll', 'auto'].indexOf(getStyleComputedProperty(parent as HTMLElement, 'overflow')) !== -1 ||
    ['scroll', 'auto'].indexOf(getStyleComputedProperty(parent as HTMLElement, 'overflow-x')) !== -1 ||
    ['scroll', 'auto'].indexOf(getStyleComputedProperty(parent as HTMLElement, 'overflow-y')) !== -1
  ) {
    return parent as HTMLElement
  }
  return getScrollParent(element.parentNode as HTMLElement)
}

export function getStyleComputedProperty (element: HTMLElement, property: string): string {
  const css = window.getComputedStyle(element, null)
  return css[property]
}

export function setDocumentTitle (title: string) {
  document.title = title
  if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 0)
    }
    document.body.appendChild(i)
  }
}

/**
 * 事件委托
 * 对符合条件的每一个父节点进行事件委托
 * @param {HTMLElement} parentNode 委托节点 类名 或者 id
 * @param {HTMLElement} node 被委托节点 类名 或者 id
 * @param {String} event
 * @param {Function} 回调函数
 */

export function onEventDelegation (parentNode: string, node: string, event: string, callback: (e: any) => any) {
  const classReg = /^\./
  const idReg = /^#/
  let matchRule = 'tag'
  if (classReg.test(node)) {
    matchRule = 'class'
    node = node.replace(classReg, '')
  } else if (idReg.test(node)) {
    matchRule = 'id'
    node = node.replace(idReg, '')
  }
  document.querySelectorAll(parentNode)
    .forEach(p => {
      p.addEventListener(event, (e) => {
        const target = e.target as any
        if (!target) return
        // 被委托对象在冒泡阶段也可以触发回调
        let parentNode = target
        while (parentNode && parentNode !== p) {
          if (matchRule === 'id' && parentNode.id === node) {
            callback(e)
            break
          } else if (matchRule === 'class' && parentNode.classList.contains(node)) {
            callback(e)
            break
          } else if (matchRule === 'tag' && parentNode.tagName.toLocaleLowerCase() === node) {
            callback(e)
            break
          }
          parentNode = parentNode.parentNode
        }
      })
    })
}

export function getTransform (dom: HTMLElement) {
  let x = 0
  let y = 0
  const transform = window.getComputedStyle(dom).transform
  if (transform !== 'none') {
    const paramString = transform.match(/\((.+)\)/)?.[1] || ''
    if (paramString) {
      const paramArr = paramString.split(',').map(p => p.trim())
      x = parseInt(paramArr[4] || '0')
      y = parseInt(paramArr[5] || '0')
    }
  }
  return {
    x,
    y
  }
}

function dragMessageBoxAndDialog (targetDom: string, ignoreDom: string, containDom: string, wrapperDom: string) {
  ignoreDom = ignoreDom.replace(/^(\.|#)/, '')
  containDom = containDom.replace(/^(\.|#)/, '')
  wrapperDom = wrapperDom.replace(/^(\.|#)/, '')
  onEventDelegation('body', targetDom, 'mousedown', function (e) {
    e.preventDefault()
    let parentNode = e.target
    while (parentNode) {
      // 被忽略的 dom ，则不触发拖动
      if (parentNode.classList.contains(ignoreDom)) return
      // 找到 拖动容器
      if (parentNode.classList.contains(containDom)) {
        break
      }
      parentNode = parentNode.parentNode
    }
    if (!parentNode) return

    // 记录鼠标点击时的位置
    const startX = e.pageX
    const startY = e.pageY
    // 记录初始 transform 的值
    let { x: initX, y: initY } = getTransform(parentNode)

    const wrapper = parentNode.parentNode
    // 如果容器外不是指定 wrapper 就不用往下执行了
    if (!wrapper.classList.contains(wrapperDom)) {
      return
    }
    // 拖拽时候避免出现滚动条
    wrapper.style.overflow = 'hidden'
    const mousemoveHandle = (event: MouseEvent) => {
      event.preventDefault()
      const x = event.pageX
      const y = event.pageY
      const moveX = x - startX + initX
      const moveY = y - startY + initY
      parentNode.style.transform = `translate(${moveX}px, ${moveY}px)`
    }
    const mouseupHandle = () => {
      parentNode.removeEventListener('mouseup', mouseupHandle)
      parentNode.removeEventListener('mouseout', mouseoutHandle)
      wrapper.removeEventListener('mousemove', mousemoveHandle)
    }
    const mouseoutHandle = (event: MouseEvent) => {
      event.preventDefault()
      parentNode.removeEventListener('mouseout', mouseoutHandle)
      wrapper.removeEventListener('mousemove', mousemoveHandle)
    }

    // 监听 wrapper 防止拖拽速度过快，造成鼠标在 容器外导致拖拽停止
    wrapper.addEventListener('mousemove', mousemoveHandle)
    parentNode.addEventListener('mouseup', mouseupHandle)
    // parentNode.addEventListener('mouseout', mouseoutHandle)

    const observerOptions = {
      attributes: true
    }

    const observer = new MutationObserver(function (mutationList) {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style' && (mutation.target as HTMLElement).style.display === 'none') {
          // wrapper 隐藏以后 重置位置
          initX = 0
          initY = 0
          parentNode.style.transform = ''
          parentNode.removeEventListener('mousemove', mousemoveHandle)
          parentNode.removeEventListener('mouseup', mouseupHandle)
          parentNode.removeEventListener('mouseout', mouseoutHandle)
        }
      })
    })
    observer.observe(wrapper, observerOptions)
  })
}

/**
 * 为 el-messageBox 和 el-dialog 绑定拖动事件
 */
export function dragFnWithMessageAndDialog () {
  // MessageBox 和 DiaLog 只允许拖动头部，如果没有 header，则没有拖动功能
  dragMessageBoxAndDialog('.el-message-box__header', '.el-message-box__headerbtn', '.el-message-box', '.el-message-box__wrapper')
  dragMessageBoxAndDialog('.el-dialog__header', '.el-dialog__headerbtn', '.el-dialog', '.el-dialog__wrapper')
  // 任务窗口不适合使用该方法，因为 wrapper 被理解为右下角的按钮区域，导致鼠标移出弹窗后就无法触发 wrapper 的 move 事件了
}

export function px2rem (px: number): string {
  let result = ''
  if (px) {
    result = (px / BASE_FONT_SZIE) + 'rem'
  }
  return result
}

export function getPercentageValue (value: number, percentage: string | number) {
  if (isNumber(percentage)) return value
  const percent = parseInt(percentage)
  if (!percentage.endsWith('%') || !isFinite(percent)) return value
  return floor(percent / 100 * value)
}

/**
 * table, list, tree 的高度计算，如果新增组件用此函数计算，请在 const.ts 中 CALCULATE_HEIGHT_ELEMENTS_CLASS_NAME 内添加 该组件使用高度的元素的 className
 * 数值代表 像素单位
 * 百分比代表占据当前窗口剩余高度的百分比，弹窗或者抽屉中代表 弹窗或者抽屉中剩余高度的百分比
 */
export function calculateHeight (height = '100%', dom: HTMLElement, minHeight: number, maxHeight: number, rowsObj?: Record<string, any>): string | number {
  let result: string | number = 500
  if (height.endsWith('%')) {
    result = tableAutoHeight(height, dom)
    // 有时候计算出来携带很多小数，导致后续渲染时出现 1px 的滚动条，通过取整数值来尝试避免这个问题
    result = Math.floor(result)
  } else if (height === 'auto') {
    if (rowsObj) {
      // table
      const { rowsLength, rowHeight, headerHeight } = rowsObj
      result = rowsLength * rowHeight + headerHeight
    } else {
      result = 'unset'
    }
  } else {
    result = attribute2Number(height)
  }
  if (result !== 'unset') {
    if (minHeight && result < minHeight) {
      result = minHeight
    } else if (maxHeight && result > maxHeight) {
      result = maxHeight
    }
  }
  return result
}

function tableAutoHeight (height: string, dom: HTMLElement): number {
  // 先看当前元素是否处于隐藏状态，如果是，就不计算，直接返回 0
  try {
    if (isHidden(dom)) return 0
    // 再看是否在弹窗或者抽屉中
    let parentELement = dom.parentNode as HTMLElement
    let isInDialog = false
    let isInDrawer = false
    let wrapHeight = window.innerHeight
    while (parentELement && (!isInDialog || !isInDrawer)) {
      if (parentELement.classList) {
        if (parentELement.classList.contains('el-dialog__body')) {
          isInDialog = true
          wrapHeight = parseInt(window.getComputedStyle(parentELement).maxHeight.replace('px', ''))
          break
        } else if (parentELement.classList.contains('el-drawer__body')) {
          isInDrawer = true
          wrapHeight = parentELement.clientHeight
          break
        }
      }
      parentELement = parentELement.parentNode as HTMLElement
    }
    // 如果不在 弹窗 或者 抽屉中, 父级盒子取最外层 lw-content
    if (!isInDialog && !isInDrawer) {
      parentELement = document.getElementsByClassName('lw-content')[0] as HTMLElement
    } else {
      // 抽屉或者弹窗 ，取 抽屉/弹窗 中的最外层的 lw-content
      parentELement = (parentELement.getElementsByClassName('lw-content')[0] || parentELement) as HTMLElement
      if (parentELement.querySelector('.panel-wrapper')) {
        // 这里是 panel 内部的容器
        parentELement = parentELement.querySelector('.panel-wrapper') as HTMLElement
      }
    }
    // 找到 父级盒子的 最后一个子元素
    // 如果找到的最后一个节点是非 元素节点 或者当前元素节点被隐藏了，就向前找一个节点
    let lastChild = parentELement.lastChild
    while (lastChild && (lastChild.nodeType !== 1 || isHidden(lastChild as HTMLElement) || !['static', 'relative'].includes(getComputedStyle(lastChild as HTMLElement).position))) {
      lastChild = lastChild?.previousSibling
    }
    lastChild = lastChild || parentELement

    // 计算出最后一个子元素的底部 和 父级盒子顶部 之间的距离
    // 计算方式：
    // 1. 获取 父级盒子顶部 距离视窗顶部的距离
    // 2. 获取最后一个子元素底部距离视窗顶部的距离
    // 3. 做差计算出两者之间的距离
    let distance = (lastChild as HTMLElement).getBoundingClientRect().bottom - parentELement.getBoundingClientRect().top
    if (!isInDialog && !isInDrawer) {
      distance = (lastChild as HTMLElement).getBoundingClientRect().bottom
    }
    // 这里的 distance 实际还包含着 list、table、tree 的高度
    // 记录这些元素的 top 和 bottom，以防元素水平排
    const positionArr: Array<Record<string, number>> = []
    CALCULATE_HEIGHT_ELEMENTS_CLASS_NAME.forEach((className: string) => {
      parentELement.querySelectorAll(className).forEach(ele => {
        if (!isHidden(ele as HTMLElement)) {
          const top = ele.getBoundingClientRect().top
          const bottom = ele.getBoundingClientRect().bottom
          let isOverlap = false
          for (const position of positionArr) {
            // 如果某个元素的水平方向的位置 和 之前记录过的元素在水平方向上有重叠，则将 两者合并
            const isTopOverlap = position.top <= top && top <= position.bottom
            const isBottomOverlap = position.top <= bottom && bottom <= position.bottom
            if (isTopOverlap || isBottomOverlap) {
              isOverlap = true
            }
            /**
             * 三种重合场景
             * top 代表 dom 的顶部距离视窗顶部的距离，也就是 dom 顶部的 y 坐标
             * bottom 代表 dom 的底部距离视窗顶部的距离，也就是 dom 底部的 y 坐标
             * 1. top 小于之前记录的 top，并且 bottom 大于之前记录的 top 但是小于之前记录的 bottom
             * 2. top 大于之前所记录的 top 并且小于之前所记录的 bottom，并且 bottom 大于 之前记录的 bottom
             * 3. top 小于之前记录的 top，并且 bottom 大于之前记录的 bottom
             */
            if (isTopOverlap && bottom > position.bottom) {
              position.bottom = bottom
              break
            } else if (isBottomOverlap && top < position.top) {
              position.top = top
              break
            } else if (top < position.top && bottom > position.bottom) {
              isOverlap = true
              position.top = top
              position.bottom = bottom
              break
            }
          }
          if (!isOverlap) {
            positionArr.push({
              top,
              bottom
            })
          }
        }
      })
    })
    positionArr.forEach(position => {
      distance -= (position.bottom - position.top)
    })
    // 计算得到 弹出框内的剩余高度
    let remainingHeight = wrapHeight - distance
    const lastChildMarginBottom = parseInt(window.getComputedStyle(lastChild as HTMLElement, null).marginBottom.replace('px', ''))
    const contentPaddingBottom = parentELement.classList.contains('lw-content') ? parseInt(window.getComputedStyle(parentELement, null).paddingBottom.replace('px', '')) : 0
    const contentMarginBottom = parentELement.classList.contains('lw-content') ? parseInt(window.getComputedStyle(parentELement, null).marginBottom.replace('px', '')) : 0
    remainingHeight = remainingHeight - lastChildMarginBottom - contentPaddingBottom - contentMarginBottom
    return remainingHeight * percent2decimal(height)
  } catch (error) {
    console.error(error)
    return 500
  }
}

/*
 * 事件绑定
 *
 * @method bind
 * @param  {dom||window}   elem        需要绑定的dom节点或window对象
 * @param  {String}        event       绑定的事件名称
 * @param  {Function}      handler     事件处理方法
 */
export function bind (elem: any, event: any, handler: (...args: any[]) => void) {
  if (elem && elem !== 'undefined' && event && handler) {
    event = event === 'mousewheel' ? ((document as any).onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll') : event

    if ((document as any).attachEvent) { // if IE (and Opera depending on user setting)
      elem.attachEvent('on' + event, handler)
    } else { // WC3 browsers
      elem.addEventListener(event, handler, false)
    }
  }
}

/*
 * 移除事件绑定
 *
 * @method unbind
 * @param  {dom||window}   elem         需要移除绑定的dom节点或window对象
 * @param  {String}        event        绑定的事件名称
 * @param  {Function||Array<Function>}  handler    事件处理方法，可以为数组
 */
export function unbind (elem: any, event: any, handler: (...args: any[]) => void) {
  if (elem && elem !== 'undefined' && event && handler) {
    event = event === 'mousewheel' ? ((document as any).onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll') : event

    let handlers = []
    if (Array.isArray(handler) && handler.length > 0) {
      handlers = handler
    } else {
      handlers.push(handler)
    }

    if ((document as any).removeEventListener) {
      handlers.forEach(e => {
        elem.removeEventListener(event, e, false)
      })
    } else {
      handlers.forEach(e => {
        elem.removeEventListener('on' + event, e)
      })
    }
  }
}

// has class
export function hasClass (el: any, cls: string) {
  if (!el || !cls) return false
  if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.')
  if (el.classList) {
    return el.classList.contains(cls)
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
  }
}

// add class
export function addClass (el: any, cls: any) {
  if (!el || !cls) return

  if (el.classList) {
    el.classList.add(cls)
  } else {
    const clsArr = el.className.split(' ')

    if (clsArr.indexOf(cls) === -1) {
      el.className += ' ' + cls
    }
  }
}

// remove class
export function removeClass (el: any, cls: string) {
  if (!el || !cls) return

  if (el.classList) {
    el.classList.remove(cls)
  } else {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    el.className = el.className.replace(reg, ' ') // For IE9 and earlier
  }
}

/* 获取当前元素的left、top偏移
 *   left：元素最左侧距离文档左侧的距离
 *   top:元素最顶端距离文档顶端的距离
 *   right:元素最右侧距离文档右侧的距离
 *   bottom：元素最底端距离文档底端的距离
 *   right2：元素最左侧距离文档右侧的距离
 *   bottom2：元素最底端距离文档最底部的距离
 * */
export function getViewportOffset (element: any) {
  const doc = document.documentElement
  const box = typeof element.getBoundingClientRect !== 'undefined' ? element.getBoundingClientRect() : 0
  const scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
  const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  const offsetLeft = box.left + window.pageXOffset
  const offsetTop = box.top + window.pageYOffset

  const left = offsetLeft - scrollLeft
  const top = offsetTop - scrollTop

  return {
    left: left,
    top: top,
    right: window.document.documentElement.clientWidth - box.width - left,
    bottom: window.document.documentElement.clientHeight - box.height - top,
    right2: window.document.documentElement.clientWidth - left,
    bottom2: window.document.documentElement.clientHeight - top
  }
}

// 判断 dom 对象是否自身隐藏或者 在一个 display: none 的元素中
export function isHidden (element: HTMLElement): boolean {
  if (element.style.display === 'none') return true
  let result = true
  let offsetParent = element.offsetParent
  while (offsetParent) {
    if (window.getComputedStyle(offsetParent).position === 'fixed') {
      offsetParent = offsetParent.parentElement
    } else {
      offsetParent = (offsetParent as HTMLElement).offsetParent
    }
    if (offsetParent && offsetParent.tagName.toLowerCase() === 'body') {
      // 如果能一直找到 body ,就说明不是隐藏元素
      result = false
      break
    }
  }
  return result
}

// 获取最高层级的 z-index
export function getMaxZIndex (step = 1) {
  const arr = [...document.querySelectorAll('*')].map(e => {
    if (e.clientWidth !== 0 && e.clientHeight !== 0) {
      return parseInt(window.getComputedStyle(e).zIndex) || 0
    } else {
      return 0
    }
  })
  return arr.length ? Math.max(...arr) + step : 0
}

export function calculateDrawersPosition (vueComponent: any, { command }: any) {
  const config = vueComponent.popupLayoutConfig || vueComponent.panelConfig
  let drawers = document.querySelectorAll('.drawerLayout')
  drawers = [...drawers].filter(d => {
    return [...(d as HTMLElement).classList].some(c => c.endsWith('__open')) || d.querySelector('.el-drawer__open')
  }) as any
  const currentDrawer = config.modal === false ? vueComponent.$refs?.drawerLayoutWithoutModal?.$el : vueComponent.$refs?.drawerLayout?.$el
  let drawersArray = drawers ? [...drawers, currentDrawer] : [currentDrawer]
  drawersArray = drawersArray.filter(d => d)
  let targetWidth: number
  // 反转一下，让抽屉从上层开始遍历
  drawersArray.reverse().forEach((drawer, index) => {
    // 这里的 width 理论上肯定有值的，保险起见给个默认值
    const drawerItem = drawer.classList.contains('lw-unmasked-drawer') ? (drawer as HTMLElement) : drawer.querySelector('.el-drawer') as HTMLElement
    if (drawerItem) {
      if (command === drawerCommand.OPEN) {
        if (index === 0) {
          // 首次打开时 this 还不存在
          targetWidth = parseInt((vueComponent.drawerSize || '50%').replace('%', ''))
          drawerItem.style.width = targetWidth + '%'
        } else {
          let widthPrecent = drawerItem.style.width || '50%'
          if (widthPrecent.endsWith('px')) {
            widthPrecent = (parseInt(widthPrecent.replace('px', '')) / window.innerWidth * 100).toFixed(0).toString() + '%'
          }
          const width = parseInt(widthPrecent.replace('%', ''))
          // 记录每次变化前的 宽度值, 首次打开 index = 0 的不需要记录
          const dataSize = drawer.getAttribute('data-size')
          drawer.setAttribute('data-size', (dataSize ? dataSize + ',' + widthPrecent : widthPrecent))
          if ((width - targetWidth) < 10) {
            let result = targetWidth + 10
            result = result > 80 ? 80 : result
            drawerItem.style.width = result + '%'
            targetWidth = result
          } else if (width > 80) {
            drawerItem.style.width = '80%'
            targetWidth = 80
          }
        }
      } else if (command === drawerCommand.CLOSE && index > 0) {
        // index = 0 是当前就要被关闭的元素，所以不用管他的宽度
        const dataSizeArr = drawer.getAttribute('data-size')?.split(',') as Array<string>
        drawerItem.style.width = dataSizeArr?.pop() || '50%'
        drawer.setAttribute('data-size', dataSizeArr?.join(',') || '')
      }
    }
  }, vueComponent)
}

export function closeAllErrorMessages () {
  const MAX_LOOPING_TIME = 100
  let count = 0
  for (const instance of messageInstancesIdMap.values()) {
    instance.close()
    count++
    if (count > MAX_LOOPING_TIME) {
      console.warn('[Logwire Warning] 关闭错误弹窗时超出100次遍历, 可能陷入无限循环')
      break
    }
  }
}
