import { Component, Vue } from 'vue-property-decorator'
import formatter from './formatter'
import { attributeForce2Boolean } from '@/utils/layout'

@Component
class TextBase extends Vue {
  [propName: string]: any;
  get prepend (): string { return this.getFinalAttributeValue('prepend', { args: this.args }) }

  get append (): string { return this.getFinalAttributeValue('append', { args: this.args }) }

  get password (): boolean { return attributeForce2Boolean('password', this.component, false) }

  get displayValue (): string {
    const { inputValue: value, prepend, append } = this
    const result = formatter.formatContent({ value, prepend, append, password: this.password })
    return result
  }
}

export default TextBase
