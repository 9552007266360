import { DataRow as IDataRow, DataSet as IDataSet } from '@/types/data'
import DataSet from '@/models/DataSet'
import { Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { rowPersist } from '@/utils/const'
import eventbus from '@/utils/event'

export default class DataRow {
  #row!: IDataRow

  // DataRow 上提供一个只读的 data 属性用于返回 #row 便于开发者调试
  get row (): IDataRow {
    return _.cloneDeep(this.#row)
  }

  set row (params: IDataRow) {
    console.warn('The property "data" is read-only')
  }

  constructor (row: IDataRow) {
    this.#row = row
  }

  getOriginalData (fieldName: string): any {
    return this.#row.originalData[fieldName]
  }

  getData (fieldName: string): any {
    // 标志解析这个脚本的时候,里面的方法拿到的都是转义后的数据
    const result = this.getDataWithoutEscaped(fieldName)
    return result
  }

  getDataWithoutEscaped (fieldName: string) {
    return this.#row.currentData[fieldName]
  }

  getDataEscaped (fieldName: string): any {
    function dataEscaped (params: any) {
      // <key value> 或者 array
      if (_.isObject(params)) {
        for (const p in params) {
          params[p] = dataEscaped(params[p])
        }
      }
      if (_.isString(params)) {
        return _.escape(params)
      }
      return params
    }
    return dataEscaped(this.#row.currentData[fieldName])
  }

  /**
   *
   * @param fieldName
   * @param value
   * @param rowPersistAware 当传入 false 时，则希望通过 setData 设置的值，在未保存关闭弹窗时不提示
   */
  setData (fieldName: string, value: any, rowPersistAware?: boolean): void {
    if (value === undefined) value = null
    if (!this.#row.rowPersist) {
      this.#row.rowPersist = rowPersistAware === false ? '' : rowPersist.UPDATE
    }
    // 没有某一个字段的情况下才使用 Vue.set
    if (this.#row.currentData[fieldName]) {
      this.#row.currentData[fieldName] = value
    } else {
      Vue.set(this.#row.currentData, fieldName, value)
    }
    // 以前这里是用 !rowPersist 判断，但是表格新增操作时，rowPersist 为 I，那设置了 aware 为 false 后，还是会有提示
    // 认为违背了 rowPersistAware 参数的意义
    if (rowPersistAware === false) {
      if (this.#row.originalData[fieldName]) {
        this.#row.originalData[fieldName] = value
      } else {
        Vue.set(this.#row.originalData, fieldName, value)
      }
    }
    // 通知 table 记录这次的变动
    // 这里拿不到 layoutName 和 dataSetName, 所以会去通知全部的 table 组件,由 table 去判断这一条数据是不是自己的，如果是自己的，则对这条变动进行记录
    eventbus.$emit('dataSet-row-field-changed', { row: this.#row, rowPersistAware })
  }

  getOrCreateSubDataset (subDatasetName: string): DataSet {
    const data = this.#row.subDataSets[subDatasetName]
    if (!data) {
      Vue.set(this.#row.subDataSets, subDatasetName, {})
    }
    return new DataSet(this.#row.subDataSets[subDatasetName])
  }

  setSubDataset (subDatasetName: string, dataSet: IDataSet | DataSet): void {
    if (dataSet instanceof DataSet) {
      dataSet = dataSet.dataSet
    }
    Vue.set(this.#row.subDataSets, subDatasetName, dataSet)
  }

  removeSubDataset (subDatasetName: string): void {
    delete this.#row.subDataSets[subDatasetName]
  }

  getIndex (): number {
    // TODO 返回自身的索引
    return 0
  }

  getRowState (stateName: string): any {
    // state 有可能为空
    return this.#row.state?.[stateName]
  }

  setRowState (stateName: string, value: any): void {
    if (!this.#row.state) {
      Vue.set(this.#row, 'state', {})
    }
    Vue.set((this.#row as any).state, stateName, value)
  }

  getRowPersist (): rowPersist | undefined | string {
    return this.#row.rowPersist
  }

  select (select: boolean) {
    Vue.set(this.#row, 'isSelected', select)
    eventbus.$emit('dataSet-row-selected-changed', { row: this.#row, select })
  }

  isSelected () : boolean {
    return this.#row.isSelected
  }
}
