import { DataSet as IDataSet, DataRow as IDataRow, IConditionGroup } from '@/types/data'
import DataRow from '@/models/DataRow'
import _ from 'lodash'
import { calculation, formatDataRow, formatMasterFilter, getQueryFilter, getSelection } from '@/utils/data'
import { LayoutModule } from '@/store/modules/layout'
import { calculationType, conditionType, connector, filterOperator, rowPersist, totalByType } from '@/utils/const'
import eventbus from '@/utils/event'
import Vue from 'vue'
import Condition from './Condition'
import ConditionGroup from './ConditionGroup'
import KeywordFilter, { keywordFilter } from './KeyWordFilter'
import QuickFilter, { quickFilter } from './QuickFilter'
import PartitionFilter from './PartitionFilter'
import { getLayoutNameOutsideComponent, warnDeprecatedApi } from '@/utils/layout'

interface filter {
  keywordFilter?: keywordFilter
  pageSize: number
  pageNo: number
  getTotalBy: totalByType
  queryParams?: Record<string, any>
  masterFilter?: IConditionGroup
  advancedFilter?: IConditionGroup
  quickFilter?: quickFilter
  partitionFilter?: { beginDate: string, endDate: string, name: string }
  orderBy: Array<{ field: string, order: 'esc' | 'desc' }>
}

export default class DataSet {
  readonly #dataSet!: IDataSet
  readonly #layoutName!: string

  currentSequence = 0

  // DataSet 上提供一个只读的 data 属性用于返回 #dataSet 便于开发者调试
  get dataSet (): IDataSet {
    return JSON.parse(JSON.stringify(this.#dataSet))
  }

  set dataSet (params: IDataSet) {
    console.warn('The property "data" is read-only')
  }

  constructor (dataSet: IDataSet, layoutName = '') {
    this.#dataSet = dataSet
    this.#layoutName = layoutName

    // 有可能进来的数据自身带有了 rowSequence, 那么如果 DataSet 再从 1 开始就可能导致重复赋值
    if (dataSet.rows?.length > 0) {
      if (dataSet.rows.some(item => item.rowSequence === undefined)) {
        console.warn('[DataSet] rows 存在部分数据没有 rowSequence 属性')
      }
      this.currentSequence = Math.max(...dataSet.rows.map(o => o.rowSequence || 0)) + 1
    } else {
      this.currentSequence = 1
    }
  }

  private getSystemState () {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]
  }

  applyObjectsToDataSet (datas: any[], pageParams?: Partial<{ pageNo: number, pageSize: number }>) {
    this.clear()
    datas.forEach(item => {
      const dataRow = formatDataRow(item)
      dataRow.rowPersist = ''
      dataRow.rowSequence = this.getRowSequence()
      this.#dataSet.rows.push(dataRow)
    })

    if (pageParams?.pageNo) LayoutModule.setTablePageNo({ layoutName: this.#layoutName, dataSetName: this.getDataSetName(), pageNo: pageParams.pageNo })
    if (pageParams?.pageSize) LayoutModule.setTablePageSize({ layoutName: this.#layoutName, dataSetName: this.getDatasetName(), pageSize: pageParams.pageSize })
  }

  appendObjectsToDataSet (datas: any[]) {
    datas.forEach(item => {
      const dataRow = formatDataRow(item)
      dataRow.rowPersist = ''
      dataRow.rowSequence = this.getRowSequence()
      this.#dataSet.rows.push(dataRow)
    })
  }

  getRowSequence (): number {
    this.currentSequence++
    return this.currentSequence
  }

  // 单词拼写错误，应该是 getDataSetName,保留方法仅为了适配
  getDatasetName (): string {
    warnDeprecatedApi('DataSet.getDatasetName', 'DataSet.getDataSetName')
    return this.getDataSetName()
  }

  getDataSetName (): string {
    return this.#dataSet.dataSetName
  }

  getQueryByFilterPayload () {
    return getQueryFilter(this.getSystemState())
  }

  getRows (): Array<DataRow> {
    return this.#dataSet?.rows?.map(row => new DataRow(row))
  }

  getRowsAsData (): Array<Record<string, any>> {
    return this.#dataSet?.rows?.map(row => _.cloneDeep(row))
  }

  getRow (index: number): DataRow {
    const row = this.#dataSet?.rows?.[index]
    // typescript 上返回 DataRow ，让平台开发者少了一次判断，项目开发者还是要做 undefiend 判断的
    return row
      ? new DataRow(row)
      : undefined as unknown as DataRow
  }

  addRow (rowPersistAware?: boolean, rowStatus?: ''): DataRow {
    const dataRow = formatDataRow({})
    dataRow.rowPersist = rowStatus || (rowPersistAware === false ? '' : rowPersist.INSERT)
    dataRow.rowSequence = this.getRowSequence()
    if (!this.#dataSet.rows) Vue.set(this.#dataSet, 'rows', [])
    this.#dataSet.rows.push(dataRow)
    rowPersistAware !== false && eventbus.$emit(`${this.#layoutName}.${this.getDataSetName()}.dataSet-row-changed`, { row: dataRow })
    return new DataRow(dataRow)
  }

  insertRow (index: number, rowPersistAware?: boolean): DataRow {
    const dataRow = formatDataRow({})
    dataRow.rowPersist = rowPersistAware === false ? '' : rowPersist.INSERT
    dataRow.rowSequence = this.getRowSequence()
    if (!this.#dataSet.rows) Vue.set(this.#dataSet, 'rows', [])
    this.#dataSet.rows.splice(index, 0, dataRow)
    rowPersistAware !== false && eventbus.$emit(`${this.#layoutName}.${this.getDataSetName()}.dataSet-row-changed`, { row: dataRow })
    return new DataRow(dataRow)
  }

  removeRow (dr: DataRow, rowPersistAware ?: boolean): void;
  removeRow (index: number, rowPersistAware?: boolean): void;
  removeRow (...params: any): void {
    const [param1, rowPersistAware] = params
    let index
    if (param1 instanceof DataRow) {
      index = this.#dataSet.rows.findIndex(o => o.rowSequence === param1.row.rowSequence)
    } else {
      index = param1
    }
    if (rowPersistAware === false) {
      this.#dataSet.rows.splice(index, 1)
    } else {
      const row = this.#dataSet.rows[index]
      row.rowPersist = rowPersist.DELETE
      eventbus.$emit(`${this.#layoutName}.${this.getDataSetName()}.dataSet-row-changed`, { row, index })
    }
  }

  clear (): void {
    Vue.set(this.#dataSet, 'rows', [])
  }

  setTotal (total: number): void {
    LayoutModule.LOAD_TABLE_COUNT_DATA({ total, layoutName: this.#layoutName, dataSetName: this.#dataSet.dataSetName })
  }

  getTotal () {
    return LayoutModule.data[this.#layoutName].systemState[this.#dataSet.dataSetName]?.total
  }

  getPageSize (): number {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.pageSize as number
  }

  getPageNo (): number {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.pageNo as number
  }

  getGetTotalBy () {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.getTotalBy
  }

  getOrderBy () {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.orderBy
  }

  /** @deprecated 在 b17 版本中应该被废弃 */
  setAggregation (aggregation: any) {
    warnDeprecatedApi('DataSet.setAggregation', 'DataSet.setAggregationValue')
    LayoutModule.LOAD_TABLE_COUNT_DATA({ aggregation, layoutName: this.#layoutName, dataSetName: this.#dataSet.dataSetName })
  }

  /** @deprecated 在 b17 版本中应该被废弃 */
  getAggregationRow (cb: (fields: any) => void): void {
    warnDeprecatedApi('DataSet.getAggregationRow', 'DataSet.getAggregationValue')
    // 从 store 中获取所配置 aggregationFields 聚合字段 值的形式为 [{ method: 'sum', field: '' }]
    const fields = _.cloneDeep(LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.aggregationFields)
    if (fields?.length === 0) return
    // 从对应组件中获取这些字段的 title 值,返回的 title 是国际化之后的值
    const callback = (fields: any) => {
      if (this.getSelectAll()) {
        // 跨页全选的时候的合计信息是由后端计算放回的，放在 store 中的 aggregationRow 字段下
        const aggregationRow = LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.aggregationRow
        if (!aggregationRow) return null
        Object.keys(aggregationRow).forEach(aggregationField => {
          const field = aggregationField.substring(aggregationField.indexOf('__') + 2)
          fields.find((f: Record<string, any>) => f.field === field).value = aggregationRow[aggregationField]
        })
      } else {
        const { selectedRows } = this.getSelection()
        const selectLength = selectedRows.length
        selectedRows.forEach((r: any, i: number) => {
          fields.forEach((f: any) => {
            const method = f.method
            f.value = f.value || 0
            if (method === 'sum') {
              // 求和,一项一项相加
              f.value = calculation([f.value, Number(r[f.field] || '0')], calculationType.SUM)
            } else if (method === 'avg') {
              // 求平均,(每一项 / 项数) 相加
              f.value = calculation([f.value, calculation([Number(r[f.field] || '0'), selectLength], calculationType.DIVISION)], calculationType.SUM)
            } else if (method === 'min') {
              if (i === 0) {
                f.value = Number(r[f.field])
              } else {
                f.value = Math.min(f.value, Number(r[f.field] || '0'))
              }
            } else if (method === 'max') {
              if (i === 0) {
                f.value = Number(r[f.field])
              } else {
                f.value = Math.max(f.value, Number(r[f.field] || '0'))
              }
            }
          })
        })
      }
      // fields 此时的数据结构是 [{ method: 'sum', field: '', value: ** }]
      cb(fields)
    }
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.getFieldsTitle`, fields, callback)
  }

  clearAggregation () {
    LayoutModule.LOAD_TABLE_COUNT_DATA({
      layoutName: this.#layoutName,
      dataSetName: this.#dataSet.dataSetName,
      aggregation: {}
    })
  }

  /**
   * @param aggregationField sum__weight 的格式
   * @param value
   */
  setAggregationValue (aggregationField: string, value: number) {
    const aggregationRow = LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.aggregationRow || {}
    LayoutModule.LOAD_TABLE_COUNT_DATA({
      layoutName: this.#layoutName,
      dataSetName: this.dataSet.dataSetName,
      aggregation: { ...aggregationRow, [aggregationField]: value }
    })
  }

  // sum__weight 这种形式的参数
  // 返回值只返回后端传递来的 聚合结果，不去和当前勾选行关联
  getAggregationValue (aggregationField: string) {
    return LayoutModule.data[this.#layoutName].systemState?.[this.#dataSet.dataSetName]?.aggregationRow?.[aggregationField]
  }

  /**
   * 如果点击的是 选择全部，发送过去的应该是 { query: '', filters: {...}} 结构
   * 否则，发送的过去的是 { query: '', selectedRows: [{id: xx, name： xx ...}, ...]}，仅仅是currentData内容
   */
  getSelection () : Record<string, any> {
    return getSelection(this.#layoutName, this.#dataSet.dataSetName)
  }

  getSelectedRows (): Array<DataRow> {
    return this.#dataSet.rows.filter(dr => dr.isSelected).map(o => new DataRow(o))
  }

  getSelectAll () : boolean {
    return LayoutModule.data[this.#layoutName]?.systemState?.[this.getDataSetName()]?.selectAll || false
  }

  isSelectAll (): boolean {
    return LayoutModule.data[this.#layoutName]?.systemState?.[this.getDataSetName()]?.selectAll || false
  }

  getDataSetStatus (): string {
    const payload = {
      status: ''
    }
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.getDataSetStatus`, payload)
    return payload.status
  }

  // ========================================= Filter ===============================================

  getKeywordFilter (): KeywordFilter {
    const ds = this.getSystemState()
    if (ds.keywordFilter) {
      return new KeywordFilter(ds.keywordFilter)
    } else {
      LayoutModule.loadKeywordFilters({
        layoutName: this.#layoutName,
        dataSetName: this.getDataSetName(),
        keywordFilter: { value: '', matchMode: filterOperator.LIKE, fields: [] }
      })
      return new KeywordFilter(this.getSystemState().keywordFilter)
    }
  }

  getQuickFilter (): QuickFilter {
    const ds = this.getSystemState()
    if (ds.quickFilter) {
      return new QuickFilter(ds.quickFilter)
    } else {
      LayoutModule.loadQuickFilters({
        layoutName: this.#layoutName,
        dataSetName: this.getDataSetName(),
        quickFilter: {
          data: {},
          fields: []
        }
      })
      return new QuickFilter(this.getSystemState().quickFilter)
    }
  }

  getAdvancedFilter (): ConditionGroup {
    const ds = this.getSystemState()
    if (ds.advancedFilter) {
      return new ConditionGroup(ds.advancedFilter)
    } else {
      LayoutModule.loadAdvancedFilter({
        layoutName: this.#layoutName,
        dataSetName: this.getDataSetName(),
        advancedFilter: {}
      })
      return new ConditionGroup(this.getSystemState().advancedFilter)
    }
  }

  getPartitionFilter (): PartitionFilter {
    const ds = this.getSystemState()
    if (ds.partitionFilter) {
      return new PartitionFilter(ds.partitionFilter)
    } else {
      LayoutModule.loadPartitionFilter({
        layoutName: this.#layoutName,
        dataSetName: this.getDataSetName(),
        partitionFilter: {}
      })
      return new PartitionFilter(this.getSystemState().partitionFilter)
    }
  }

  clearFilter () {
    LayoutModule.clearFilter({ layoutName: this.#layoutName, dataSetName: this.#dataSet.dataSetName })
  }

  retrieve (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.retrieve`, params)
  }

  new (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.new`, params)
  }

  edit (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.edit`, params)
  }

  expressEdit (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.expressEdit`, params)
  }

  headerDetailNew (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.headerDetailNew`, params)
  }

  headerDetailEdit (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.headerDetailEdit`, params)
  }

  cancel (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.cancel`, params)
  }

  save (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.save`, params)
  }

  delete (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.delete`, params)
  }

  paging (params: any) {
    eventbus.$emit(`${this.#layoutName}.${this.#dataSet.dataSetName}.paging`, params)
  }
}
