




























































import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator'
import { LocalModule } from '@/store/modules/local'
import Socket from '@/models/Socket'
import Notification from '@/models/Notification'
import { LayoutContext } from '@/types/layout'
import { attributeSymbol, notificationHandledFlag, socketMessageType } from '@/utils/const'
import _ from 'lodash'
import { Task } from '@/types/common'
import VirtualList from '@/components/VirtualList.vue'
import { formatDuration } from '@/utils/common'
import { AppModule } from '@/store/modules/app'
import eventbus from '@/utils/event'
import logwire from '@/logwire'
import { emitLayoutEvent, getI18nContent, isSymbol } from '@/utils/layout'
import { getTransform } from '@/utils/dom'

@Component({ name: 'NotificationMonitor', components: { VirtualList } })
class NotificationMonitor extends Vue {
  mousedownHandler !: (evt: MouseEvent) => void

  tasks: Array<Task> = []
  showTasks = false
  currentTimestamp = new Date().getTime()
  timer:any = null
  finishedTaskIdArr: Array<string> = [] // 由于后端并不能保证 task 100% 的这条信息和 finish 的这条信息到达先后顺序，（rocketmq并不严格保持消息的顺序性）所以这里用一个长度为 10 的数组记录一下已完成的 task id

  get username (): number|undefined {
    return LocalModule.user.username
  }

  get processingTasks (): Array<Task> {
    return this.tasks.filter(task => task.current !== task.max)
  }

  @Watch('username')
  handleUserIdChange (nextName: string|undefined, prevName: string|undefined): void {
    if (nextName && !prevName) {
      this.initSocketConnection()
    }
  }

  @Watch('showTasks')
  handleTaskDisplay (status: boolean): void {
    if (status) {
      this.timer = setInterval(() => {
        this.currentTimestamp = new Date().getTime()
      }, 1000)
      this.handleBindMouseListener()
    } else {
      const dragHeader = this.$el.querySelector('.lw-task-monitor_title') as HTMLElement
      if (dragHeader instanceof HTMLElement) {
        dragHeader.removeEventListener('mousedown', this.mousedownHandler)
      }
      clearInterval(this.timer)
      this.timer = null
    }
  }

  getI18nContent (content: string, namespace: string) {
    // 如果没设置namespace，则使用core
    namespace = namespace || 'core'
    if (isSymbol(content, attributeSymbol.I18N)) {
      return getI18nContent(content, namespace)
    }
    return content
  }

  getCostTime (createTimestamp: number) {
    let costTime = 0
    if (createTimestamp) {
      costTime = this.currentTimestamp - createTimestamp
      costTime = costTime < 0 ? 0 : costTime
    }
    return formatDuration(costTime)
  }

  getLatestNotifications () {
    // TODO
  }

  initSocketConnection () {
    const socketInstance = Socket.getInstance()
    socketInstance.onOpen(() => {
      LocalModule.loadNotifications()
    })
    socketInstance.onMessage(this.messageHandle)
  }

  messageHandle ({ data }: { data: any }) {
    // @ 开头说明是心跳信息
    if (!data.startsWith('@')) {
      data = JSON.parse(data)
      const { NEW_MESSAGE, READ_MESSAGE, NEW_TODO, HANDLE_TODO, ONGOING_TASK, FINISH_TASK } = socketMessageType
      if ([NEW_MESSAGE, READ_MESSAGE, NEW_TODO, HANDLE_TODO].includes(data.type)) {
        this.handleNotification(data)
      } else if ([ONGOING_TASK, FINISH_TASK].includes(data.type)) {
        this.handleTask(data)
      } else {
        this.handleAction(data)
      }
    }
  }

  handleAction (data: any) {
    // const { currentEventOwnerUId } = AppModule
    // if (data.data.traceId === currentEventOwnerUId) {
    eventbus.$emit('update-processing-action', data)
    // }
  }

  handleTask (data: any) {
    const calculatePercentage = (task: Task) => {
      const { max, current } = task
      task.percent = current ? Math.round(current / max * 100) : 0
    }
    const taskFromData = data.data
    // 当 data 是字符串代表的是某个 task 完成了
    if (_.isString(taskFromData)) {
      const index = this.tasks.findIndex(task => task.id === taskFromData)
      this.tasks.splice(index, 1)
      if (this.finishedTaskIdArr.length > 10) {
        this.finishedTaskIdArr.splice(0, 1)
      }
      this.finishedTaskIdArr.push(taskFromData)
    } else {
      if (this.finishedTaskIdArr.includes(taskFromData.id)) return
      const existTask = this.tasks.find(task => task.id === taskFromData.id)
      calculatePercentage(taskFromData)
      // 存在相同的任务则更新，否则新增
      existTask ? Object.assign(existTask, taskFromData) : this.tasks.push(taskFromData)
    }
  }

  handleNotification (data: any) {
    const { context, associatedContext } = this.$parent as any
    // 当 data 是数字代表的是某个消息或者待办完成了
    if (_.isFinite(data.data)) {
      LocalModule.updateNotification({ data: data.data, type: data.type } as any)
      // 收到HANDLE_TODO之后的操作，刷新消息通知页面
      const { HANDLE_TODO } = socketMessageType
      if (data.type === HANDLE_TODO) emitLayoutEvent('todoDataSet.retrieve')
    } else {
      // eslint-disable-next-line no-new
      new Notification(data, context, associatedContext)
      LocalModule.updateNotification(data.data)
    }
  }

  handleBindMouseListener () {
    const dragContainer = this.$el.querySelector('.lw-task-monitor_contain') as HTMLElement
    const dragHeader = this.$el.querySelector('.lw-task-monitor_title') as HTMLElement
    const mousedownHandler = (evt: MouseEvent) => {
      const startX = evt.pageX
      const startY = evt.pageY
      const { x: initX, y: initY } = getTransform(dragContainer)
      const mousemoveHandler = (event: MouseEvent) => {
        const x = event.pageX
        const y = event.pageY
        const moveX = x - startX + initX
        const moveY = y - startY + initY
        dragContainer.style.transform = `translate(${moveX}px, ${moveY}px)`
      }
      const mouseupHandler = (evt: MouseEvent) => {
        document.body.removeEventListener('mouseup', mouseupHandler)
        document.body.removeEventListener('mousemove', mousemoveHandler)
      }
      document.body.addEventListener('mousemove', mousemoveHandler)
      document.body.addEventListener('mouseup', mouseupHandler)
    }
    dragHeader.addEventListener('mousedown', mousedownHandler)
    this.mousedownHandler = mousedownHandler
  }

  mounted () {
    // NotificationMonitor 在 Layout.vue 被 v-if = 'showFrame' 控制，所以每次切换 layout 时候会导致 NotificationMonitor 被销毁再重新生成
    if (this.username) {
      this.initSocketConnection()
    }
  }

  beforeDestroy () {
    if (this.username) {
      const socketInstance = Socket.getInstance()
      socketInstance.removeMessageHandle(this.messageHandle)
    }
  }
}
export default NotificationMonitor
