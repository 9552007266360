import Vue from 'vue'
import VueRouter, { RawLocation, RouteConfig } from 'vue-router'
import Layout from '../views/Layout.vue'
import Page404 from '../views/404.vue'
import eventbus from '@/utils/event'
import { AppModule } from '@/store/modules/app'

/**
 * vue-router push 相同的地址会抛出错误，但不影响使用
 * 重写 push 方法，将抛出的错误吞掉
 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location: RawLocation, onResolve?: any, onReject?: any) {
  if (onResolve || onReject) {
    return routerPush.call(this, location, onResolve, onReject)
  }
  return (routerPush.call(this, location) as any).catch((e: any) => e)
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/layout/:layoutName',
    name: 'layout',
    component: Layout
  },
  {
    path: '/404',
    name: '404',
    component: Page404
  },
  {
    path: '*',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 点击浏览器的前进后退，需要将当前页面的所有弹出层销毁
router.beforeEach(function (to, from, next) {
  if (to.params?.layoutName !== from.params?.layoutName) {
    AppModule.closeAllStackLayouts().then(() => {
      next()
    })
  } else {
    next()
  }
})

export default router
