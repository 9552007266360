
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import DateTimeBase from './DateTimeBase'

@Component({
  name: 'TableCellDateTime'
})
class TableCellDateTime extends Mixins(TableCellBase, DateTimeBase) {}
export default TableCellDateTime
