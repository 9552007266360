import { Component, InjectReactive } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import { DataSet as IDataSet } from '@/types/data'
import { LayoutModule } from '@/store/modules/layout'
import eventbus from '@/utils/event'
import logwire from '@/logwire'
import { EDIT_LAYOUT_DATASET_NAME } from '@/utils/const'

@Component
class DatasetBoundComponent extends BaseComponent {
  get dataSetName (): string { return this.component.dataSet }
  get subDataSetName (): string { return this.component.subDataSet }

  get dataSet (): IDataSet { return LayoutModule.data[this.encodedLayoutName]?.dataSet?.[this.dataSetName] }

  /** 以下情况lw-form的数据来自于当前行
   * 展开行是表单且表单数据来自于当前行
   * 双击表格单元格进入快速编辑模式
   */
  get parentRow (): any {
    let parent = this.$parent
    const options: any = parent?.$options
    let parentName = options.name
    while (parent && !['PopupLayoutOfSelect', 'ExpandRowWrapper', 'QuickEditForTable'].includes(parentName)) {
      parent = parent.$parent
      parentName = parent?.$options?.name
    }
    return (parent as any)?.parentRow
  }

  getDataSetStatus (paylout: { status: string }) {
    const status = (this as any).status
    if (status) {
      paylout.status = status
    }
  }

  created (): void {
    this.parentRow ? this.checkAttributeSettled('subDataSet', this.subDataSetName)
      : this.checkAttributeSettled('dataSet', this.dataSetName)
    // 在组件创建之时发现还没有同名 dataSet 便创建一个
    if (!this.dataSet && this.dataSetName) {
      LayoutModule.loadLayoutDataSet({
        layoutName: this.encodedLayoutName,
        data: Object.assign({} as IDataSet, { dataSetName: this.dataSetName })
      })
    }
    const { headerDataSet } = this.component
    if (headerDataSet) {
      LayoutModule.setDetailDataSets({
        layoutName: this.encodedLayoutName,
        headerDataSetName: headerDataSet,
        dataSetName: this.dataSetName,
        queryName: this.component.query
      })
      // handleHeaderEdit 方法在 QueriableComponent 中，用于头数据组件通知明细数据组件进入头明细编辑状态
      eventbus.$on(`${this.encodedLayoutName}.${headerDataSet}.detailStatusChange`, (this as any).handleHeaderEdit)
    }
    // 场景，开发者将同一个 dataSet 分了多个 lw-form 组件展示，但是只有一个 form 可以配置 query 或者 onRetrieve
    // 这个时候，如果想要触发所有的 form 都进入头明细状态是做不到的，所以提供这个事件
    if (!this.component.query && !this.component.onRetrieve) {
      eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveStatusChange`, (this as any).handleHeaderEdit)
      eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveResetStatus`, (this as any).resetStatus)
    }
    // 只有 PopupLayout 或者 Panel 中的组件，允许设置 dataSetName 为 editingRow
    if (!(this.panelLayout || this.popupLayout) && this.dataSetName === EDIT_LAYOUT_DATASET_NAME) {
      console.error(this.$i18n('core', 'client.common.editingRow-limit'))
    }
    eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.getDataSetStatus`, this.getDataSetStatus)
  }

  beforeDestroy (): void {
    const { headerDataSet } = this.component
    eventbus.$off(`${this.encodedLayoutName}.${headerDataSet}.detailStatusChange`, (this as any).handleHeaderEdit)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.getDataSetStatus`, this.getDataSetStatus)
    this.component.headerDataSet && eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveStatusChange`, (this as any).handleHeaderEdit)
    eventbus.$off(`${this.encodedLayoutName}.${this.dataSetName}.sameDataSetWithoutQueryOrOnRetrieveResetStatus`, (this as any).resetStatus)
  }
}

export default DatasetBoundComponent
