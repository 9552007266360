import { downloadFile, emitLayoutEvent, getLayoutNameOutsideComponent } from '@/utils/layout'
import eventbus from '@/utils/event'
import { LayoutModule } from '@/store/modules/layout'
import _ from 'lodash'
import { DataForSave, DataListForSave, DataRow } from '@/types/data'
import { getFileNameFromResponse, getSelection, getSelectionByDataRows } from '@/utils/data'
import logwire from '.'
import { doOpenApi } from '@/http/api'
import { LayoutFunction } from '@/types/layout'
import axios, { AxiosResponse } from 'axios'
import DataRowObj from '@/models/DataRow'
import { LoadingLevel } from '@/http'
import { AppModule } from '@/store/modules/app'
declare const window: Window & { common?: Record<string, LayoutFunction> }
interface NetworkParams{
  url: string;
  data?: Record<string, unknown>;
  config?: Record<string, unknown>;
  successCallback: (response: any) => void;
  failCallback: (error: any) => void;
}
interface OpenApiParams{
  name: string;
  data?: Record<string, any>;
  silent?: boolean;
  loadingLevel?: LoadingLevel,
  successCallback?: (responseBody: Record<string, unknown>, response: AxiosResponse) => void;
  failCallback?: (errorBody: Record<string, unknown>, error: any) => void;
  completeCallback?: () => void;
  options?: Record<string, any>;
}
export interface ActionParams{
  code?: string;
  name?: string;
  layoutName?: string;
  data?: Record<string, any>;
  silent?: boolean;
  loadingLevel?: LoadingLevel,
  successCallback?: (responseBody: Record<string, unknown>, response: AxiosResponse) => void;
  failCallback?: (error: Record<string, unknown>) => void;
  completeCallback?: () => void;
  options?: Record<string, any>;
}
export interface SaveActionParams extends ActionParams{
  dataSets: string[];
  params?: Record<string, any>;
}

export interface UploadFileActionParams extends ActionParams{
  files: Array<File> | File;
  params?: Record<string, any>;
}

type SelectionParams = {
  code?: string;
  name?: string;
  subName?: string;
  dataSetName: string;
  dataRows?: Array<DataRowObj>
  params?: Record<string, any>;
  loadingLevel: LoadingLevel,
  successCallback?: (responseBody: Record<string, unknown>, response: AxiosResponse) => void;
  failCallback?: (error: Record<string, unknown>) => void;
  completeCallback?: () => void;
}

type reportActionParam = {
  processName: string;
  dataSetName: string;
  paperFormat?: string;
  queryName?: string;
  reportTemplate: string;
  reportTitle: string;
  reportOutputType: string;
  loadingLevel: LoadingLevel,
  successCallback?: (responseBody: Record<string, unknown>, response: AxiosResponse) => void;
  failCallback?: (error: Record<string, unknown>) => void;
  completeCallback?: () => void;
}

type printDocumentActionParam = {
  documentCode: string;
  paperFormat: string
}

type exportExcelActionParam = {
  dataSetName: string;
  queryName?: string;
  templateName: string;
  outputFilename: string;
  processName: string;
  async?: boolean;
  timeFormat?: string;
  dateFormat?: string;
  withZoneOffset?: boolean;
  language: string;
  loadingLevel: LoadingLevel,
  successCallback?: (responseBody: Record<string, unknown>, response: AxiosResponse) => void;
  failCallback?: (error: Record<string, unknown>) => void;
  completeCallback?: () => void;
}

/**
 * 发送一个 action
 * @param {string} actionName action 名称
 * */
function doAction (actionName: string): void;
/**
 * 发送一个 action
 * TODO 之后版本对 code 形式进行废除，code 方式不写入 文档中
 * 兼容版本处理，同时支持 code 形式和 name 的形式
 * 优先级 code 高于 name
 * @param {object} param 参数，包括 name | code，data，successCallback, failCallback
 * */
function doAction (param: ActionParams): void;
function doAction (param: ActionParams | string): void {
  let actionParams = param
  if (_.isString(param)) actionParams = { name: param }
  const currentLayout = getLayoutNameOutsideComponent()

  eventbus.$emit(`${currentLayout}.do-action`, actionParams as Record<string, any>)
}

export default {
  /**
   * get 请求
   * @param {string} url 请求 url
   * @param {object} data 数据
   * @param {function} successCallback 成功后的回调，参数为 data
   * @param {function} failCallback 失败后的回调，参数为 error
   * */
  get ({ url, data, config, successCallback, failCallback }: NetworkParams): void {
    axios.get(url, { params: data, ...config })
      .then(res => {
        successCallback && successCallback(res)
      })
      .catch(e => {
        failCallback && failCallback(e)
      })
  },
  /**
   * post 请求
   * 参数同 get
   * */
  post ({ url, data, config, successCallback, failCallback }: NetworkParams): void {
    axios.post(url, data, config)
      .then(res => {
        successCallback && successCallback(res)
      })
      .catch(e => {
        failCallback && failCallback(e)
      })
  },
  doOpenApi ({ name, data, loadingLevel, successCallback, failCallback, completeCallback, silent, options }: OpenApiParams) {
    const layoutName = getLayoutNameOutsideComponent()
    const namespace = layoutName.split('.')[0]
    if (_.isFunction(failCallback)) {
      silent = true
    }
    doOpenApi({
      name,
      layoutName,
      data
    }, { silent, loadingLevel, ...options })
      .then(response => {
        window.common = logwire.common[namespace]
        const { success } = logwire.ui.message
        _.isFunction(successCallback)
          ? successCallback(response.data, response)
          : response.data && success && success(response.data.message || '')
      })
      .catch(errorInfo => {
        window.common = logwire.common[namespace]
        const { error } = logwire.ui.message
        _.isFunction(failCallback)
          ? failCallback(errorInfo.response.data, errorInfo)
          : error && error(errorInfo.response.data.message || '')
      })
      .finally(() => {
        delete window.common
        _.isFunction(completeCallback) && completeCallback()
      })
  },

  doAction,

  doSaveAction (saveParams: SaveActionParams): void {
    const { code, name, dataSets, params, loadingLevel, successCallback, failCallback, completeCallback } = saveParams
    const currentLayout = getLayoutNameOutsideComponent()
    const dataList: Array<DataForSave> = []
    let headerDetailSave = false
    for (const dataSet of dataSets) {
      const data: {
        recordList: Array<DataRow>
        queryName: string
        dataSetName: string
        headerDetailSave?: boolean
      } = {
        recordList: [],
        queryName: '',
        dataSetName: dataSet,
        headerDetailSave: false
      }
      // 是否是头明细保存 headerDetailSave 需要由 form 或者 table 组件本身来进行判断
      eventbus.$emit(`${currentLayout}.${dataSet}.generateUpdatedRows`, data)
      if (data.headerDetailSave) headerDetailSave = true
      delete data.headerDetailSave
      data.recordList.length > 0 && dataList.push(data)
    }
    const successCallbackMerge = function (res: any, response: AxiosResponse) {
      for (const dataSet of dataSets) {
        emitLayoutEvent(`${dataSet}.retrieve`)
        emitLayoutEvent(`${dataSet}.clearEditRows`)
      }
      setTimeout(() => {
        // 这里需要等到 clearEditRows 等的相关 watch 执行后再去执行
        // 场景：lw-table 中修改了某几行数据，table 的 editRows 记录了当前变动，并且把标记了当前页面这个 dataSet 处于编辑状态
        // 然后用 doSaveAction 去拿 table 的这几行数据做保存，保存成功后，在 retrieve 和 clearEditRows 中都做了 editRows = [] 的动作，对 editRows 做了 watch 监控
        // 发现 editRows 长度为 0 以后会取消掉当前页面的编辑状态，但是这时候在 successCallback 里面做了页面跳转，由于 watch 中的语句还没有执行， 导致此时页面会出现跳转提示
        _.isFunction(successCallback) && successCallback(res, response)
      })
    }
    doAction({ code, name, data: { params, dataList, headerDetailSave }, loadingLevel, successCallback: successCallbackMerge, failCallback, completeCallback })
  },

  doSelectedRowsAction (param: SelectionParams) : void {
    const { code, name, dataSetName, dataRows, params, loadingLevel, successCallback, failCallback, completeCallback } = param
    const currentLayout = getLayoutNameOutsideComponent()
    let selection:Record<string, any> = {}
    if (dataRows) {
      selection = getSelectionByDataRows(currentLayout, dataSetName, dataRows)
    } else {
      selection = getSelection(currentLayout, dataSetName)
    }

    doAction({ code, name, data: { params, ...selection }, loadingLevel, successCallback, failCallback, completeCallback })
  },

  async doReportAction (param: reportActionParam, silent: boolean) {
    const { dataSetName, loadingLevel, successCallback, failCallback, completeCallback } = param
    const currentLayout = getLayoutNameOutsideComponent()
    let queryName = param.queryName
    if (!queryName) {
      queryName = LayoutModule.data[currentLayout]?.systemState[dataSetName]?.queryName || ''
    }
    let name = `core.report:${queryName}`
    let options: Record<string, any> = { responseType: 'blob' }
    let deviceId = null
    if (silent) {
      name = `core.silent-print-report-on-device-id:${queryName}`
      options = {}
      try {
        const result = await axios.get('http://localhost:1699/api/get-device-id')
        deviceId = result.data.deviceId
      } catch (error) {
        console.error(error)
        return
      }
    }
    const selection = getSelection(currentLayout, dataSetName)
    const finalSuccessCallback = (data: any, response: AxiosResponse) => {
      _.isFunction(successCallback) && successCallback(data, response)
      // pdf 可供预览，而 excel 无法在线预览，直接下载
      if (!silent) {
        if (param.reportOutputType === 'pdf') {
          const reader = new FileReader()
          reader.onload = function (evt) {
            try {
              const result = evt.target?.result as string || ''
              const json = JSON.parse(result)
              const layoutName = getLayoutNameOutsideComponent()
              const strings = layoutName.split('.')
              const namespace = strings.length > 1 ? strings[0] : 'core'
              const transformUrl = (param: string) => {
                const arr = param.split('?code=')
                if (arr.length === 2) {
                  return `/api/open/${namespace}/core.document-preview?name=${arr[0]}&code=${arr[1]}`
                } else if (arr.length > 2) { // 说明name 里有 ?code= 的结构
                  const code = arr.pop()
                  const name = arr.join('')
                  return `/api/open/${namespace}/core.document-preview?name=${name}&code=${code}`
                } else { // 没有 ?code= 的结构，默认认为是传递的 code
                  return `/api/open/${namespace}/core.document-preview?name=${param}&code=${param}`
                }
              }
              const url = transformUrl(json.data)
              eventbus.$emit('print-data', url)
            } catch (err) {
              eventbus.$emit('print-data', data)
            }
          }
          reader.readAsText(data)
        } else {
          if (response.headers['content-disposition']) {
            const disposition = response.headers['content-disposition'] as string
            const filename = disposition.replace(/.*?UTF-8''/, '')
            downloadFile(data, filename)
          } else if (response.headers['report-filename']) {
            const filename = response.headers['report-filename']
            downloadFile(data, filename)
          } else {
            const filename = param.reportTitle
            downloadFile(data, filename)
          }
        }
      }
    }
    doAction({
      name,
      data: {
        params: {
          ..._.pick(param, ['processName', 'reportTemplate', 'reportTitle', 'reportOutputType', 'paperFormat']),
          deviceId
        },
        ...selection
      },
      loadingLevel,
      options,
      successCallback: finalSuccessCallback,
      failCallback,
      completeCallback
    })
  },

  doSilentPrintReportOnLocalDeviceAction (param: reportActionParam): void {
    this.doReportAction(param, true)
  },

  async doSilentPrintDocumentOnLocalDeviceAction (param: printDocumentActionParam) {
    const { data: { deviceId } } = await axios.get('http://localhost:1699/api/get-device-id')
    doAction({
      name: 'core.silent-print-document-on-device-id',
      data: {
        ...param,
        deviceId
      }
    })
  },

  doExportExcelAction (param: exportExcelActionParam, silent: boolean): void {
    const { dataSetName, outputFilename, async, loadingLevel, successCallback, failCallback, completeCallback } = param
    const currentLayout = getLayoutNameOutsideComponent()
    let queryName = param.queryName
    if (!queryName) {
      queryName = LayoutModule.data[currentLayout]?.systemState[dataSetName]?.queryName || ''
    }
    const name = `core.export-excel:${queryName}`
    const selection = getSelection(currentLayout, dataSetName)
    const finalSuccessCallback = (responseBody: any, response: AxiosResponse) => {
      _.isFunction(successCallback) && successCallback(responseBody, response)
      if (!silent && !async) {
        let fileName = outputFilename
        if (!fileName) {
          fileName = getFileNameFromResponse(response)
        }
        downloadFile(responseBody, fileName)
      }
    }
    doAction({
      name,
      data: {
        params: param,
        ...selection
      },
      loadingLevel,
      options: { responseType: 'blob' },
      successCallback: finalSuccessCallback,
      failCallback,
      completeCallback
    })
  },

  doSimpleExportExcelAction (param: exportExcelActionParam, silent: boolean): void {
    const { dataSetName, outputFilename, async, loadingLevel, successCallback, failCallback, completeCallback } = param
    const currentLayout = getLayoutNameOutsideComponent()
    let queryName = param.queryName
    if (!queryName) {
      queryName = LayoutModule.data[currentLayout]?.systemState[dataSetName]?.queryName || ''
    }
    const name = `core.simple-export-excel:${queryName}`
    const selection = getSelection(currentLayout, dataSetName)
    if (!selection.filters && selection.selectedRows?.length === 0) {
      const { error } = logwire.ui.message
      error && error(logwire.ui.getI18nMessage('client.tips.have-not-select', [], 'core'))
      return
    }
    const payload = {
      fields: []
    }
    eventbus.$emit(`${currentLayout}.${dataSetName}.getSimpleExportExcelFields`, payload)
    const finalSuccessCallback = (data: any, response: AxiosResponse) => {
      _.isFunction(successCallback) && successCallback(data, response)
      if (!silent && !async) {
        let fileName = outputFilename
        if (!fileName) {
          fileName = getFileNameFromResponse(response)
        }
        downloadFile(data, fileName)
      }
    }
    doAction({
      name,
      data: {
        params: {
          ...param,
          fields: payload.fields
        },
        ...selection
      },
      loadingLevel,
      options: { responseType: 'blob' },
      successCallback: finalSuccessCallback,
      failCallback,
      completeCallback
    })
  },

  // 下载文件 action
  doDownloadFileAction (param: ActionParams & { outputFilename: string }) {
    const { options, successCallback, outputFilename } = param
    const finalSuccessCallback = (data: any, response: AxiosResponse) => {
      let fileName = outputFilename
      if (!fileName) {
        fileName = getFileNameFromResponse(response)
      }
      _.isFunction(successCallback) && successCallback(data, response)
      downloadFile(data, fileName)
    }
    param.options = { ...options, responseType: 'blob' }
    param.successCallback = finalSuccessCallback
    doAction(param)
  },

  // 预览文件 action
  doPreviewFileAction (param: ActionParams) {
    const { successCallback } = param
    const finalSuccessCallback = (data: any, response: AxiosResponse) => {
      _.isFunction(successCallback) && successCallback(data, response)
      if (Object.prototype.hasOwnProperty.call(data, 'data')) {
        if (data.data instanceof Array || typeof data.data === 'string') { // 如果返回字符串或数组
          logwire.ui.previewDocument(data.data)
        }
      } else {
        eventbus.$emit('preview-file', data)
      }
    }
    param.successCallback = finalSuccessCallback
    doAction(param)
  },

  doUploadFileAction (param: UploadFileActionParams) {
    const { files, params } = param
    const formData = new FormData()
    if (_.isArray(files)) {
      files.forEach((f, i) => {
        formData.append(`file${i + 1}`, f)
      })
    } else {
      formData.append('file1', files)
    }
    if (params) {
      // 郑乐超要求：如果传递 param 为字符串，则不再序列化
      if (typeof params === 'string') {
        formData.append('params', params)
      } else {
        formData.append('params', JSON.stringify(params))
      }
    }
    doAction({
      ...param,
      data: formData,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  }
}
