import { Select } from "element-ui";
import scrollIntoView from 'element-ui/src/utils/scroll-into-view';

if (Select['watch'] instanceof Object) {
  if (Select['watch']['options'] instanceof Function) {
    Select['watch']['options'] = function () {
      if (this.$isServer) return;
      this.$nextTick(() => {
        this.broadcast('ElSelectDropdown', 'updatePopper');
      });
      if (this.multiple) {
        this.resetInputHeight();
      }
      // 1858, lw-select 可以对下拉选项分页，点击分页时，会导致 Input 失焦，让激活文字变化
      // let inputs = this.$el.querySelectorAll('input');
      // if ([].indexOf.call(inputs, document.activeElement) === -1) {
      //   this.setSelected();
      // }
      if (this.defaultFirstOption && (this.filterable || this.remote) && this.filteredOptionsCount) {
        this.checkDefaultFirstOption();
      }
    }
  }
}

if (Select['methods'] instanceof Object) {
  if (Select['methods']['scrollToOption'] instanceof Function) {
    Select['methods']['scrollToOption'] = function (option :any) {
      const target = Array.isArray(option) && option[0] ? option[0].$el : option.$el;
      if (this.$refs.popper && target) {
        // var menu = this.$refs.popper.$el.querySelector('.el-select-dropdown__wrap');
        // 由于在 hasNewOperation 元素上添加了最大高度，所以滚动条出现在了 hasNewOperation 上，scrollIntoView 的目标也就应该替换
        const menu = this.$refs.popper.$el.querySelector('.hasNewOperation');
        menu && scrollIntoView(menu, target);
      }
    }
  }
  /**
   * 2828 议题内，为了区分 “点击 el-select 清除图标” 和 “点击 FiltersForTable.vue 内的清除图标” 的情况
   * 修改 el-select 的方法，让 selectedLabel 先被清空，再触发事件；而点击 FitersForTable 图标时 selectedLabel 仍然有值
   */
  if (Select['methods']['deleteSelected'] instanceof Function) {
    const oldDeleteSelected = Select['methods']['deleteSelected']
    Select['methods']['deleteSelected'] = function (event: any) {
      this.selectedLabel = ''
      oldDeleteSelected.call(this, event)
    }
  }
}

export default {
  install: function(Vue: any) {
    Vue.component(Select.name, Select);
  }
}
