





















import { Component, Watch } from 'vue-property-decorator'
import QueriableByFilterComponent from '@/components/layout/QueriableByFilterComponent'
import { LayoutModule } from '@/store/modules/layout'
import { attribute2Number, getGlobalI18nMessage } from '@/utils/layout'
import { cloneDeep, first, last, uniqBy } from 'lodash'
import { addResizeListener, calculateHeight, getPercentageValue, removeResizeListener } from '@/utils/dom'
import { attributeType } from '@/utils/const'
import { isPositiveInteger } from '@/utils/data'
import { Bind, Debounce } from 'lodash-decorators'
import { DataRow } from '@/types/data'
import Args from '@/models/Args'
import { IDimension, Sort, SortIcon } from '@/types/pivot-table'
import { getColorValueByScheme } from '@/utils/common'
import { apiClientResource } from '@/http/api'
import { LocalModule } from '@/store/modules/local'

const decimalScale = 10000

@Component({ name: 'LwPivotTable' })
export default class PivotTable extends QueriableByFilterComponent {
  options = {}
  tableHeight: any = 0
  tableInstance: any = null
  style = {}
  sortRules = {}

  get width () {
    const width = attribute2Number(this.component.width) || 0
    return width
  }

  get height () {
    let result = '100%'
    if (this.component.height) {
      result = this.getFinalAttributeValue('height')
    }
    return result
  }

  get minHeight () {
    let result = 150
    if (this.component.minHeight) {
      result = parseInt(this.component.minHeight.replace('px', ''))
    }
    return result
  }

  get maxHeight () {
    let result = 2000
    if (this.component.maxHeight) {
      result = parseInt(this.component.maxHeight.replace('px', ''))
    }
    return result
  }

  get totalConfig () {
    return this.getFinalAttributeValue('totalConfig', { type: attributeType.OBJECT }) || {}
  }

  get rowFields () {
    return this.getFinalAttributeValue('rowFields', { type: attributeType.STRING_ARRAY }) || []
  }

  get columnFields () {
    return this.getFinalAttributeValue('columnFields', { type: attributeType.STRING_ARRAY }) || []
  }

  get valueFields () {
    return this.getFinalAttributeValue('valueFields', { type: attributeType.STRING_ARRAY }) || []
  }

  @Watch('dataSet.rows', { deep: true })
  handleRowsChange (rows: DataRow[]) {
    const option = this.getPivotTableOption()
    this.tableInstance && this.tableInstance.updateOption(option)
  }

  // 表格渲染需要固定的宽高，所以渲染前先根据组件属性、父级组件大小计算宽高并且设置给容器
  setContainerRect (resize = false) {
    let width = this.width
    const container = document.getElementById('container')
    let height = this.tableHeight
    const isWidthPositiveInteger = isPositiveInteger(width)
    if (container && (!isWidthPositiveInteger || (resize && this.tableInstance))) {
      const parent: any = container.parentNode || container
      const { paddingLeft, paddingRight, paddingBottom, paddingTop } = getComputedStyle(parent)
      const elementWidth = parent.clientWidth - parseInt(paddingLeft) - parseInt(paddingRight)
      if (elementWidth) width = getPercentageValue(elementWidth, width)
      height = this.tableHeight - parseInt(paddingBottom) - parseInt(paddingTop)
    }
    width = isWidthPositiveInteger ? Math.min(+this.width, +width) : width
    this.style = { width: `${width}px`, height: `${height}px` }
  }

  getPivotTableFields (): any {
    const queryMeta = this.queryMeta || []
    const meta: IDimension[] = queryMeta.map((m: any) => ({ dimensionKey: m.name, title: m.title, choiceCategory: m.choiceCategory }))

    const rows: IDimension[] = []
    const columns: IDimension[] = []

    for (const m of meta) {
      m.width = 'auto'
      if (m.choiceCategory) {
        m.headerFormat = ((choiceCategory: string) => {
          return (title: string) => {
            const choices = LocalModule.choices[choiceCategory]
            const choice = choices?.find((c: Record<string, string>) => c.value === title)
            return choice?.title || title
          }
        })(m.choiceCategory)
      }
      const rowIndex = this.rowFields.indexOf(m.dimensionKey)
      if (rowIndex > -1) {
        rows.splice(rowIndex, 0, m)
        continue
      }

      const columnIndex = this.columnFields.indexOf(m.dimensionKey)
      if (columnIndex > -1) {
        columns.splice(columnIndex, 0, m)
      }
    }

    return { rows, columns, meta }
  }

  getPivotTableAggregations (meta: IDimension[]): any {
    const aggregationRules = []
    for (const rule of this.valueFields) {
      const result = rule.split('__')
      const field = result.length > 1 ? result[1] : result[0]
      const aggregationType = result.length > 1 ? result[0] : undefined

      const indicator = meta.find((i: any) => i.dimensionKey === field)

      aggregationRules.push({
        dimensionKey: rule,
        indicatorKey: rule,
        title: aggregationType ? `${getGlobalI18nMessage('core', 'client.common.' + aggregationType)}${indicator?.title}` : (indicator?.title || field),
        field,
        minWidth: 120,
        headerIcon: 'sort_normal',
        aggregationType: aggregationType?.toUpperCase()
      })
    }

    return aggregationRules
  }

  getPivotTableOption (): any {
    const data = cloneDeep(this.dataSet.rows || [])
    // const data = []

    const { rows, columns, meta } = this.getPivotTableFields()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const aggregations = this.getPivotTableAggregations(meta)
    const indicators = [...aggregations].map(i => {
      return {
        ...i,
        format: (value: string | number) => {
          const finalValue = +value / decimalScale
          const result = this.component.valueFormatter
            ? this.runRunnableContent('valueFormatter', {
              args: new Args(this.context, {
                getCurrentCell () {
                  return {
                    value: finalValue,
                    valueField: i.indicatorKey
                  }
                }
              }
              )
            }) : finalValue
          return result
        },
        style: {
          bgColor (args: any) {
            return self.getColorByCellArgs(args, 'cellBackColorScheme')
          },
          color (args: any) {
            const color = self.getColorByCellArgs(args, 'cellFontColorScheme')
            return color || '#333'
          }
        }
      }
    })

    const fields = uniqBy(indicators, 'field')

    // 简单解决小数精度失精的问题
    data.forEach((d: any) => {
      fields.forEach((i) => {
        const value = d.currentData[i.field]
        d.currentData[i.field] = +value * decimalScale
      })
    })

    const VTable = (window as any).VTable

    const defaultHeaderStyle = {
      fontWeight: 500
    }

    const theme = VTable.themes.DEFAULT.extends({
      scrollStyle: {
        width: 8,
        visible: 'always'
      },
      headerStyle: defaultHeaderStyle,
      rowHeaderStyle: defaultHeaderStyle,
      cornerHeaderStyle: defaultHeaderStyle
    })

    const staticOption = {
      enableDataAnalysis: true,
      widthMode: 'adaptive',
      corner: {
        titleOnDimension: 'row',
        headerStyle: {
          textStick: true
        }
      },
      theme,
      tooltip: { isShowOverflowTextTooltip: true }
    }

    const option = {
      rows,
      columns,
      indicators,
      records: data.map(d => d.currentData),
      dataConfig: {
        aggregationRules: aggregations,
        totals: this.totalConfig
      },
      ...staticOption
    }

    if (this.component.beforeSetOption) {
      this.runRunnableContent('beforeSetOption', { args: new Args(this.context, { getOption () { return option } }) })
    }
    return option
  }

  getColorByCellArgs (args: any, attribute: string) {
    const { value, cellHeaderPaths } = args
    const { colHeaderPaths, rowHeaderPaths } = cellHeaderPaths
    const valueIndicator = last(colHeaderPaths) as IDimension
    const valueField = valueIndicator?.indicatorKey
    const row = last(rowHeaderPaths) as any
    const col = first(colHeaderPaths) as any
    const isRowSubTotal = row?.value === (this.totalConfig?.row?.subTotalLabel || '小计')
    const isColSubTotal = col?.value === (this.totalConfig?.col?.subTotalLabel || '小计')
    const isRowGrandTotal = row?.value === (this.totalConfig?.row?.grandTotalLabel || '总计')
    const isColGrandTotal = col?.value === (this.totalConfig?.col?.grandTotalLabel || '总计')
    const getCurrentCell = () => {
      return {
        valueField,
        value,
        isRowSubTotal,
        isColSubTotal,
        isRowGrandTotal,
        isColGrandTotal
      }
    }
    const colorScheme = this.component[attribute] ? this.runRunnableContent(attribute, {
      args: new Args(this.context, {
        getCurrentCell
      })
    }) : undefined
    const color = getColorValueByScheme(colorScheme)
    return color
  }

  handleDataSetRetrieve (): void {
    this.handleDataSetRetrieveForQueryByFilterComponent('pivotTable')
  }

  calculateHeight () {
    this.tableHeight = calculateHeight(this.height, this.$el as any, this.minHeight, this.maxHeight)
  }

  @Bind()
  @Debounce(100)
  handleResize () {
    this.$nextTick(() => {
      this.calculateHeight()
      this.setContainerRect(true)
      this.initPivotTable()
    })
  }

  created () {
    LayoutModule.loadDataSetFilters({
      layoutName: this.encodedLayoutName,
      dataSetName: this.dataSetName
    })

    this.initRetrieve()
  }

  initPivotTable () {
    const container = this.$el
    if (!container) return
    const VTable = (window as any).VTable
    const { PivotTable } = VTable
    const { PIVOT_SORT_CLICK } = PivotTable.EVENT_TYPE
    const option = this.getPivotTableOption()
    if (!option?.records?.length) return
    this.tableInstance = new PivotTable(container, option)
    this.tableInstance.on(PIVOT_SORT_CLICK, (e: any) => {
      const sortTarget = last(option.rows) as IDimension
      const sortField = sortTarget?.dimensionKey
      const sortIndicator = last(e.dimensionInfo) as IDimension
      const query = e.dimensionInfo.slice(0, e.dimensionInfo.length - 1).map((d: any) => d.value)
      const order = e.order === Sort.Asc ? Sort.Desc : e.order === Sort.Desc ? Sort.Normal : Sort.Asc
      const icon = order === Sort.Asc ? SortIcon.SortDownward : order === Sort.Desc ? SortIcon.SortUpward : SortIcon.SortNormal
      const rules = [
        {
          sortField,
          sortByIndicator: sortIndicator.indicatorKey,
          sortType: order.toUpperCase(),
          query
        }
      ]
      option.indicators.forEach((i: IDimension) => {
        i.headerIcon = i.indicatorKey === sortIndicator.indicatorKey ? icon : SortIcon.SortNormal
      })
      this.tableInstance.updateOption({
        ...option,
        dataConfig: {
          ...option.dataConfig,
          sortRules: order !== 'normal' && rules
        }
      })
      this.tableInstance.updatePivotSortState([{ dimensions: e.dimensionInfo, order }])
    })
  }

  mounted () {
    this.calculateHeight()
    addResizeListener(this.$el.parentNode, this.handleResize)

    // 因为使用 npm 的包工程打包会报错，看起来是 typescript 版本冲突导致的，鉴于无法贸然升级工程 typescript 版本，所以采用 script 标签加载 vTable
    const id = 'vtable-script'
    const scriptElement = document.querySelector(`#${id}`)
    if (!scriptElement) {
      const script = document.createElement('script')
      script.src = `${apiClientResource}?path=core/resource-browser/vtable.min.js&resourceType=resource-browser`
      script.id = id
      document.body.append(script)

      script.onload = () => {
        this.initPivotTable()
      }
    } else {
      this.initPivotTable()
    }
  }

  beforeDestroy () {
    removeResizeListener(this.$el.parentNode, this.handleResize)
    // eslint-disable-next-line no-unused-expressions
    this.tableInstance?.release()
  }
}
