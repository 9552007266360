import { Option } from '@/components/layout/form-item/choice/ChoiceBase'
import { compact, isArray } from 'lodash'
import { getOptions } from '@/components/layout/form-item/choice/formatter'

export interface IRadioContentStruct {
  value: any;
  namespace?: string;
  category?: string;
  dimension?: string;
  options?: Option[];
}

export const staticAttrs: Array<keyof IRadioContentStruct> = ['category', 'dimension']

const formatContent = (struct: IRadioContentStruct) => {
  const { namespace = '', category = '', dimension = '', options, value } = struct
  const finalOptions = options || getOptions(namespace, category, dimension)
  if (!value) return ''
  const values = isArray(value) ? value : value.split(',')
  return compact(finalOptions.map(option => {
    return values.includes(option.value) ? option.title : undefined
  })).join(',')
}

export default {
  formatContent,
  staticAttrs
}
