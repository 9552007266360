




































































import { Component, Inject, InjectReactive, Prop, Provide, ProvideReactive, Vue } from 'vue-property-decorator'
import eventbus from '@/utils/event'
import { AssociatedContext, LayoutComponent, LayoutContext, PanelConfig } from '@/types/layout'
import { calculateDrawersPosition } from '@/utils/dom'
import Args from '@/models/Args'
import { operationType, drawerCommand, popupLayoutType, EDIT_LAYOUT_DATASET_NAME, attributeType, layoutStatus } from '@/utils/const'
import logwire from '@/logwire'
import { LayoutModule } from '@/store/modules/layout'
import _ from 'lodash'
import { DataRow } from '@/types/data'
import { checkEditDataSetBeforePopupDialogClose, formatDataRow } from '@/utils/data'
import { getFinalAttributeValue, proceedAfterEditingDataSetCheck, runRunnableContent, setLayoutForOutsideComponent, warnAttributeMissing } from '@/utils/layout'
import LwInputForm from '../containers/InputForm.vue'
import { ElDialog } from 'element-ui/types/dialog'
import { ElDrawer } from 'element-ui/types/drawer'

/**
 * 原先这里是继承自 BaseComponent，因为 panel 组件会作为组件结构从后端返回
 * 但是希望将它和 PopupLayout 并列，成为 "容纳 BaseComponent 组件" 的结构，并且提供 popupLayoutStatus 注入属性，供内部组件区分是否在弹窗容器内
 * */
@Component({ name: 'LwPanel' })
export default class LwPanel extends Vue {
  @Prop() component!: LayoutComponent;

  @Inject() context!: LayoutContext;
  @Inject() associatedContext!: AssociatedContext;

  @InjectReactive() encodedLayoutName!: string;
  @InjectReactive() layoutName!: string;

  @ProvideReactive() get popupLayoutStatus (): string {
    return this.panelConfig.panelStatus || ''
  }

  @Provide() popupSetFormInstance = this.setFormInstance
  @Provide() panelLayout = true

  panelConfig = {} as PanelConfig
  drawerResizeFlag = false
  startResizePositionX: number | null = null
  validateInstances: LwInputForm[] = []

  resizeDom!: HTMLElement
  resizeDomWidth = 0
  resolve!: (params: any) => void
  operationType!: operationType

  $refs !: {
    dialogLayout: ElDialog & { handleClose: () => void }; // 有一些方法在 element 实现里有，但是在类型声明里没有
    drawerLayout: ElDrawer & { closeDrawer: () => void };
  }

  get width () {
    return this.panelConfig.width || '50%'
  }

  get title (): string {
    return this.getFinalAttributeValue('title', { args: new Args(this.context) })
  }

  get drawerSize (): string {
    // 抽屉宽度 30% ~ 80%，默认 50%，10 的倍数
    let size = '50%'
    if (this.panelConfig.width) {
      size = this.panelConfig.width.replace('%', '')
      const _size = parseInt(size)
      if (_size > 80) {
        size = '80%'
      } else if (_size < 30) {
        size = '30%'
      } else if (_size % 10 !== 0) {
        size = _size - (_size % 10) + '%'
      } else {
        size += '%'
      }
    }
    return size
  }

  @ProvideReactive() get panelLayoutStatus (): string {
    return this.panelConfig.panelStatus || ''
  }

  checkExistEditData (proceed: () => void) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    const proceedForBeforeCloseEvent = async () => {
      // beforeClose 中如果有弹窗刚刚被确认，可能导致 check 阶段中的 MessageBox 虽然被触发，但是页面不会有弹窗，所以需要等待下一次渲染
      await this.$nextTick()
      if (this.panelConfig.isEditPanel) {
        checkEditDataSetBeforePopupDialogClose({
          layoutName: this.encodedLayoutName,
          editDataSet: this.panelConfig.editDataSet,
          isEditLayout: this.panelConfig.isEditPanel,
          onClose: () => {
            proceed()
          },
          onSave () {
            vm.doEditPanelOp(operationType.SAVE, proceed)
          }
        })
      } else { // 用户自定义 panel，无法知道该 panel 内的 DataSet 是否应该销毁，所以不进行检查
        proceed()
      }
    }
    if (this.component.beforeClose) {
      this.runRunnableContent('beforeClose', { args: new Args(this.context, { proceed: proceedForBeforeCloseEvent }) })
    } else {
      proceedForBeforeCloseEvent()
    }
  }

  closePanel (): void {
    var isDrawer = this.panelConfig.type === 'drawer'
    const sourceDataSetName = this.panelConfig.sourceDataSetName
    const layoutName = this.encodedLayoutName
    this.validateInstances = []
    setTimeout(() => {
      // panel 在关闭之后销毁了内部元素，项目开发者可能会在 beforeClose 中触发 panel 中的组件的事件，类似于 table 的 retrieve 事件
      // table 的 retrieve 事件中，对自己内部的变量 editRows 做了 watch 监听，监听的回调中重置了页面状态，但是如果直接销毁掉table，那对于 editRows 的监听就不会执行了
      // 为了避免这种情况，所以关闭方法整体放在一个异步中执行
      this.panelConfig.type = ''
      this.panelClosed(isDrawer)

      if (this.panelConfig.isEditPanel) {
        LayoutModule.updateLayoutStatus({ layoutName, status: layoutStatus.VIEW })
      }
    })
  }

  handleClosePanelByName (panelName: string) {
    if (!panelName.includes('.')) {
      panelName = `${this.encodedLayoutName}.${panelName}`
    }
    const name = `${this.encodedLayoutName}.${this.component.name}`
    if (name !== panelName) return
    if (this.panelConfig.type === popupLayoutType.DIALOG) {
      // 弹窗关闭
      this.$refs.dialogLayout && this.$refs.dialogLayout.handleClose()
    } else {
      // 抽屉关闭
      this.$refs.drawerLayout && this.$refs.drawerLayout.closeDrawer()
    }
  }

  panelClosed (isDrawer: boolean) {
    if (isDrawer) this.calculateDrawersPosition(drawerCommand.CLOSE)
    _.isFunction(this.resolve) && this.resolve('')
  }

  // 需要以同步的方式取关闭 抽屉的时候用到
  closePanelWithPromise (): Promise<any> {
    return new Promise(resolve => {
      this.resolve = resolve
      if (this.panelConfig.type) {
        this.handleClosePanelByName(`${this.encodedLayoutName}.${this.component.name}`)
      } else {
        resolve('')
      }
    })
  }

  showPanel (param: PanelConfig) : void {
    const name = `${this.encodedLayoutName}.${this.component.name}`
    if (name === param.name) {
      this.panelConfig = param
      this.$nextTick(function () {
        setTimeout(() => {
          this.calculateDrawersPosition(drawerCommand.OPEN)
        }, 0)
        this.afterOpen()
      })
      // 如果打开 panel 时传递了 dataRow ，认为 panel 内部的组件需要的是这个 dataRow
      if (param.dataRow && param.editDataSet) {
        LayoutModule.loadLayoutDataSet({
          layoutName: this.encodedLayoutName,
          data: {
            dataSetName: param.editDataSet,
            rows: [param.dataRow]
          }
        })
      }
    }
  }

  calculateDrawersPosition (command: drawerCommand) {
    calculateDrawersPosition(this, { command })
  }

  startResizeDrawer (e: MouseEvent) {
    this.drawerResizeFlag = true
    this.startResizePositionX = e.clientX
    let parentDom = e.target as HTMLElement
    while (parentDom && (parentDom.classList && !parentDom.classList.contains('el-drawer') && !parentDom.classList.contains('lw-unmasked-drawer'))) {
      parentDom = parentDom.parentNode as HTMLElement
    }
    if (parentDom) {
      this.resizeDom = parentDom
      this.resizeDomWidth = parentDom.clientWidth
      let originalTransion = ''
      if (parentDom.classList.contains('el-drawer')) {
        originalTransion = parentDom.style.transition
        parentDom.style.transition = 'unset'
      }
      window.addEventListener('mousemove', this.resizeFn)
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', this.resizeFn)
        parentDom.style.transition = originalTransion
      })
    }
  }

  resizeFn (e: MouseEvent) {
    const moveDistance = (this.startResizePositionX || 0) - e.clientX
    const width = this.resizeDomWidth + moveDistance
    this.resizeDom.style.width = width + 'px'
  }

  // 关闭不带遮罩的弹窗、抽屉
  async closePopupWithoutModal (payload: {
    layoutName: string,
    hasPopupWithOutModal: boolean,
    resolve: () => void,
    reject: () => void
  }) {
    if (this.panelConfig.modal === false) {
      payload.hasPopupWithOutModal = true
      await this.closePanelWithPromise()
      payload.resolve()
    }
  }

  afterOpen () {
    this.component.afterOpen && this.runRunnableContent('afterOpen')
  }

  async doEditPanelOp (op: operationType, proceed?: () => void) {
    let { sourceDataSetName, panelStatus, editDataSet } = this.panelConfig
    this.operationType = op
    editDataSet = editDataSet || EDIT_LAYOUT_DATASET_NAME
    const rows = LayoutModule.data[this.encodedLayoutName].dataSet[editDataSet].rows
    // 头明细新增或者编辑状态的时候，table 中的明细数据保存是逻辑保存,所以保存以后并没有 id 值，此时再对某一行进行修改，因为没有 id，所以 table 并不能判断出到底修改的是哪一行
    // 所以将 rowKey 传过去用来识别
    rows[0].rowKey = this.panelConfig?.dataRow?.rowKey
    for (const form of this.validateInstances) {
      const result = await form.validateData()
      if (result === false) return
    }

    if ([operationType.SAVE, operationType.UPDATE_AND_NEXT, operationType.SAVE_AND_NEW].includes(op)) {
      LayoutModule.updateLayoutEditingDataSet({ layoutName: this.encodedLayoutName, dataSet: '' })
    }

    const cbFromPopup = () => {
      if (proceed) { // 有 proceed 说明已经在关闭的检查流程中了
        proceed()
      } else {
        this.handleClosePanelByName(`${this.encodedLayoutName}.${this.component.name}`)
      }
    }
    // 触发源页面的保存事件
    eventbus.$emit(`${this.encodedLayoutName}.${sourceDataSetName}.save`, { rows, mode: panelStatus, op, cbFromPopup })
  }

  doEditPanelCancel () {
    this.handleClosePanelByName(this.component.name)
  }

  /**
   * 检查属性是否正确设置
   * @param {string} attributeName 属性名
   * @param {string} attributeValue 属性值
   * */
  checkAttributeSettled (attributeName: string, attributeValue: any): boolean {
    _.isUndefined(attributeValue) && warnAttributeMissing(attributeName, this.component.is)
    return _.isUndefined(attributeValue)
  }

  runRunnableContent (attributeName: string, param?: { args?: Args, noWarning?: boolean }): any {
    // 设置当前脚本运行时的 layoutName
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    // 没有特殊的需求，那么 args 则默认只带有 context
    const args = param?.args || new Args(this.context)
    const noWarning = param?.noWarning || false
    return runRunnableContent(attributeName, this.component, args, this.associatedContext, noWarning)
  }

  getFinalAttributeValue (attributeName: string, param?: any): any {
    setLayoutForOutsideComponent({ layoutName: this.layoutName, encodedLayoutName: this.encodedLayoutName })
    const args = param?.args || new Args(this.context)
    const types = [attributeType.STRING]
    return getFinalAttributeValue(attributeName, this.component, args, this.associatedContext, types)
  }

  setFormInstance (vm: LwInputForm) {
    this.validateInstances.push(vm)
  }

  handleBeforeClosePanel (proceed: () => void) {
    // 当前组件显示了弹窗
    if (this.panelConfig.type === popupLayoutType.DIALOG || this.panelConfig.type === popupLayoutType.DRAWER) {
      const finalProceed = () => {
        this.operationType = operationType.NONE
        this.panelConfig.type = ''
        proceed()
      }
      // 此时检查是否存在未编辑的数据
      this.checkExistEditData(finalProceed)
    } else {
      proceed()
    }
  }

  created (): void {
    this.checkAttributeSettled('name', this.component.name)
    eventbus.$on(`${this.encodedLayoutName}.close-panel`, this.handleClosePanelByName)
    eventbus.$on(`${this.encodedLayoutName}.show-panel`, this.showPanel)
    eventbus.$on('close-popup-without-modal', this.closePopupWithoutModal)
    // close-panel 事件以前被用来让项目开发者关闭 panel, 而 close-popup-panel 用来让平台开发者关闭 panel
    eventbus.$on(`${this.encodedLayoutName}.${this.component.name}.close-popup-panel`, this.handleBeforeClosePanel)
  }

  beforeDestroy (): void {
    eventbus.$off(`${this.encodedLayoutName}.close-panel`, this.handleClosePanelByName)
    eventbus.$off(`${this.encodedLayoutName}.show-panel`, this.showPanel)
    eventbus.$off('close-popup-without-modal', this.closePopupWithoutModal)
  }
}

