import BaseComponent from '@/components/layout/BaseComponent'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import Args from '@/models/Args'
import { formatDataRow, getFormDataRow } from '@/utils/data'
import {
  attributeType
} from '@/utils/const'
import BaseComponentWithRow from '@/components/layout/mixin/baseComponentWithRow'
import { getColor } from '@/utils/common'

@Component
class FormControlComponent extends Mixins(BaseComponent, BaseComponentWithRow) {
  // 快速编辑表格项组件时，this.form 不具有 dataSet 属性，它是一个非常规 Form 组件。常规 Form 组件中，肯定具有 this.form.dataSet
  get args () : Args { return new Args(this.context, { row: getFormDataRow(this.form) || formatDataRow(this.form.dataRow), evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') }) }

  get visible (): boolean {
    return this.component.visible
      ? this.getFinalAttributeValue('visible', { args: this.args, type: attributeType.BOOLEAN })
      : true
  }

  get href () {
    return this.getFinalAttributeValue('href', { args: this.args })
  }

  get target () {
    return this.getFinalAttributeValue('target', { args: this.args })
  }

  get fontColorScheme () {
    return this.getFinalAttributeValue('fontColorScheme', { args: this.args })
  }

  get backColorScheme () {
    return this.getFinalAttributeValue('backColorScheme', { args: this.args })
  }

  @Watch('fontColorScheme', { immediate: true })
  handleFontColorSchemeChange (newScheme: string) {
    // 没值就给 color 空值，沿用 css 文件中设置的颜色值
    const color = getColor(newScheme) || ''
    const setColor = () => {
      // 当有字段名称时，真正展示的内容会被放置在 display-field-value 这个类名元素底下
      if (this.component.field) {
        const el = this.$el.querySelector('.display-field-value')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el && (el.style.color = color)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$el.style.color = color
      }
    }
    this.$nextTick(() => {
      setColor()
    })
  }

  @Watch('backColorScheme', { immediate: true })
  handleBackColorSchemeChange (newScheme: string) {
    const color = getColor(newScheme) || ''
    const setColor = () => {
      if (this.tableRow) {
        let el: any = this.$el
        while (!el.classList.contains('vxe-body--column') || el.nodeName === 'td') {
          el = el.parentNode
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el.style.backgroundColor = color
        return
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$el.style.backgroundColor = color
    }
    this.$nextTick(() => {
      setColor()
    })
  }
}

export default FormControlComponent
