


























import { Component, Mixins } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import RateBase from '@/components/layout/form-item/rate/RateBase'
import { attributeForce2Boolean } from '@/utils/layout'
import { attributeType } from '@/utils/const'

@Component({ name: 'LwRate' })
class LwRate extends Mixins(FormControlComponent, RateBase) {
  get showText () { return attributeForce2Boolean('showText', this.component, false) }

  get textColor () { return this.getFinalAttributeValue('textColor', { args: this.args }) }

  get texts () { return this.getFinalAttributeValue('text', { args: this.args, type: attributeType.STRING_ARRAY }) }
}

export default LwRate
