import { Option } from '@/components/layout/form-item/choice/ChoiceBase'
import { LocalModule } from '@/store/modules/local'
import { compact, isArray } from 'lodash'
import { IDynamicAttr } from '@/types/table'
import { attributeType } from '@/utils/const'

export interface IChoiceContentStruct {
  value: any;
  namespace?: string;
  category?: string;
  dimension?: string;
  options?: Option[];
  multiple?: boolean;
}

export const dynamicAttrs: Array<keyof IChoiceContentStruct | IDynamicAttr<keyof IChoiceContentStruct>> = [{ key: 'multiple', type: attributeType.BOOLEAN }]
export const staticAttrs: Array<keyof IChoiceContentStruct> = ['category', 'dimension']

export const getOptions = (namespace: string, category: string, dimension: string): Option[] => {
  let namespacedCategory = category
  if (!category.includes('.')) {
    namespacedCategory = `${namespace}.${category}`
  }
  let choices = LocalModule.choices[namespacedCategory] || []
  // 根据 dimension 属性显示下拉内容 LocalModule.dimensionChoices

  if (dimension) {
    const dimensionChoices = LocalModule.dimensionChoices[dimension]
    choices = choices.filter((choice: Option) => dimensionChoices?.includes(choice.value))
  }

  return choices
}

const formatValue = (struct: IChoiceContentStruct) => {
  const { multiple, value } = struct
  return multiple
    ? value
      ? value.split(',')
      : []
    : value
}

const formatContent = (struct: IChoiceContentStruct) => {
  const { namespace = '', category = '', dimension = '', options, value } = struct
  const finalOptions = options || getOptions(namespace, category, dimension)
  if (!value) return ''
  const values = isArray(value) ? value : value.split(',')
  return compact(finalOptions.map(option => {
    return values.includes(option.value) ? option.title : undefined
  })).join(',')
}

export default {
  formatValue,
  formatContent,
  staticAttrs,
  dynamicAttrs
}
