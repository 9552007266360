











































































import { Component, Vue, Watch } from 'vue-property-decorator'
import BaseComponent from '@/components/layout/BaseComponent'
import LwTabPane from './TabPane.vue'
import LwBadge from '../inner/Badge.vue'
import Args from '@/models/Args'
import eventbus from '@/utils/event'
import { upperFirst } from 'lodash'
import { addResizeListener, removeResizeListener } from '@/utils/dom'

type tabPane = {
  name: string,
  enabled?: boolean | string,
  visible?: boolean | string
}

type activeBarStyle = {
  width?: string,
  height?: string,
  transform?: string
}

@Component({ name: 'LwTabs', components: { LwTabPane, LwBadge } })
export default class LwTabs extends BaseComponent {
  childrenTabPanesComponents: Array<Vue & tabPane> = []
  activeIndex = 0
  activeBarStyle: activeBarStyle = {}
  scrollStatus = { scrollable: false, prev: 0, next: true }
  navOffset = 0

  get tabPanes () {
    const { components } = this.component
    return components?.filter(c => c.is === 'lw-tab-pane') || []
  }

  get activeTabName () { return this.getFinalAttributeValue('activeTabName') }

  get tabPosition () {
    let result = 'top'
    const { tabPosition } = this.component
    if (tabPosition) {
      result = this.getFinalAttributeValue('tabPosition')
      const positionArr = ['top', 'right', 'bottom', 'left']
      result = positionArr.indexOf(result) === -1 ? 'top' : result
    }
    return result
  }

  get sizeName () {
    return ['top', 'bottom'].indexOf(this.tabPosition) !== -1 ? 'width' : 'height'
  }

  get navStyle () {
    const dir = ['top', 'bottom'].includes(this.tabPosition) ? 'X' : 'Y'
    return {
      transform: `translate${dir}(-${this.navOffset}px)`
    }
  }

  get tabPositionClass () {
    return {
      horizontal: this.tabPosition === 'top' || this.tabPosition === 'bottom',
      vertical: this.tabPosition === 'left' || this.tabPosition === 'right',
      top: this.tabPosition === 'top',
      right: this.tabPosition === 'right',
      bottom: this.tabPosition === 'bottom',
      left: this.tabPosition === 'left'
    }
  }

  @Watch('activeTabName')
  activeTabNameChange (newName: string) {
    const proceed = () => {
      this.setActiveIndex()
      this.activeIndexChange()
    }
    this.handleBeforeTabSwitch(proceed, newName)
  }

  @Watch('childrenTabPanesComponents')
  childrenTabPanesChange () {
    this.setActiveIndex()
  }

  @Watch('activeIndex')
  activeIndexChange () {
    // 重新计算 lw-tabs__active-bar 的 style
    this.getActiveBarStyle()
    this.$nextTick(function () {
      // 由 tabpane 组件触发事件，让 tabpane 组件下的所有后代组件 重新结算 activeBar 的样式
      (this.$refs['tabPaneContent' + this.activeIndex]?.[0] as any).updateActiveBarStyle()
    })
  }

  setActiveIndex () {
    const tabName = this.activeTabName
    // 初始化时候 childrenTabPanesComponents 还没有进行赋值
    // 所以再监听 childrenTabPanesComponents 对 activeIndex 进行赋值
    const enabledIndexArr: Array<number> = []
    let flag = false
    for (let i = 0; i < this.childrenTabPanesComponents.length; i++) {
      if (this.childrenTabPanesComponents[i].enabled && this.childrenTabPanesComponents[i].visible) {
        enabledIndexArr.push(i)
        if (this.childrenTabPanesComponents[i].name === tabName) {
          this.activeIndex = i
          flag = true
          break
        }
      }
    }
    // 没有匹配到 tabName 则选择第一个可用的 tab
    if (!flag) {
      this.activeIndex = enabledIndexArr[0]
    }

    setTimeout(() => {
      this.scrollToActiveTab()
    }, 0)
  }

  getActiveBarStyle () {
    this.$nextTick(function () {
      if (!this.$refs['tabPaneTitle' + this.activeIndex]?.[0]) return
      const propName = upperFirst(this.sizeName)
      let size = (this.$refs['tabPaneTitle' + this.activeIndex]?.[0] as HTMLElement)?.[`client${propName}`]
      let distance = 0
      const isHorizontal = ['top', 'bottom'].includes(this.tabPosition)
      if (isHorizontal) {
        size += 40
      }
      this.$el.querySelectorAll('.lw-tab__item').forEach((h, i) => {
        if (i < this.activeIndex) {
          const { parentNode: node } = h
          distance += node?.[`client${propName}`]
          if (isHorizontal) {
            distance += 40
          }
        }
      })
      const transform = `translate${isHorizontal ? 'X' : 'Y'}(${distance}px)`
      this.activeBarStyle = {
        [this.sizeName]: size + 'px',
        transform
      }
    })
  }

  onTabClick (index: number): void {
    // Args 上提供一个 getTabName 方法返回点击的 tabName
    // TODO
    // enabled 为 false 的是否提供点击事件，目前未提供
    if (!this.childrenTabPanesComponents[index].enabled) return

    const targetTabName = this.childrenTabPanesComponents[index].name
    const getTabName = () => targetTabName
    const args = new Args(this.context, { getTabName, getCurrentTabName: this.getCurrentTabName })
    if (this.component.onTabClick) {
      this.runRunnableContent('onTabClick', { args })
    }

    if (this.activeIndex === index) return

    const proceed = () => {
      this.activeIndex = index
    }

    this.handleBeforeTabSwitch(proceed, targetTabName)
  }

  getCurrentTabName () {
    return this.childrenTabPanesComponents[this.activeIndex].name
  }

  handleBeforeTabSwitch (proceed: () => void, targetTabName: string) {
    const getTabName = () => targetTabName
    if (this.component.beforeTabSwitch) {
      const argsBeforeSwitch = new Args(this.context, { proceed, getTabName, getCurrentTabName: this.getCurrentTabName })
      this.runRunnableContent('beforeTabSwitch', { args: argsBeforeSwitch })
    } else {
      proceed()
    }
  }

  scrollPrev () {
    const { sizeName, navOffset } = this
    const containerSize = this.$refs.navScroll?.[`offset${upperFirst(sizeName)}`]

    if (!navOffset) return

    const newOffset = navOffset > containerSize
      ? navOffset - containerSize
      : 0

    this.navOffset = newOffset
  }

  scrollNext () {
    const { sizeName, navOffset } = this

    const navSize = this.$refs.nav?.[`offset${upperFirst(sizeName)}`]
    const containerSize = this.$refs.navScroll?.[`offset${upperFirst(sizeName)}`]

    if (navSize - navOffset <= containerSize) return

    const newOffset = navSize - navOffset > containerSize * 2
      ? navOffset + containerSize
      : (navSize - containerSize)

    this.navOffset = newOffset
  }

  scrollToActiveTab () {
    if (!this.scrollStatus.scrollable) return
    const nav = this.$refs.nav as HTMLElement
    const activeTab = this.$el.querySelector('.is-active')
    if (!activeTab) return
    const navScroll = this.$refs.navScroll as HTMLElement
    const isHorizontal = ['top', 'bottom'].includes(this.tabPosition)
    const activeTabBounding = activeTab.getBoundingClientRect()
    const navScrollBounding = navScroll.getBoundingClientRect()
    const maxOffset = isHorizontal
      ? nav.offsetWidth - navScrollBounding.width
      : nav.offsetHeight - navScrollBounding.height
    const currentOffset = this.navOffset
    let newOffset = currentOffset

    if (isHorizontal) {
      if (activeTabBounding.left < navScrollBounding.left) {
        newOffset = currentOffset - (navScrollBounding.left - activeTabBounding.left)
      }
      if (activeTabBounding.right > navScrollBounding.right) {
        newOffset = currentOffset + activeTabBounding.right - navScrollBounding.right
      }
    } else {
      if (activeTabBounding.top < navScrollBounding.top) {
        newOffset = currentOffset - (navScrollBounding.top - activeTabBounding.top)
      }
      if (activeTabBounding.bottom > navScrollBounding.bottom) {
        newOffset = currentOffset + (activeTabBounding.bottom - navScrollBounding.bottom)
      }
    }
    newOffset = Math.max(newOffset, 0)
    this.navOffset = Math.min(newOffset, maxOffset)
  }

  update () {
    if (!this.$refs.nav) return
    const {
      sizeName,
      navOffset
    } = this
    const navSize = this.$refs.nav[`offset${upperFirst(sizeName)}`]
    const containerSize = this.$refs.navScroll?.[`offset${upperFirst(sizeName)}`]
    if (containerSize < navSize) {
      this.scrollStatus.scrollable = true
      this.scrollStatus.prev = navOffset
      this.scrollStatus.next = navOffset + containerSize < navSize
      if (navSize - navOffset < containerSize) {
        this.navOffset = navSize - containerSize
      }
    } else {
      this.scrollStatus.scrollable = false
      if (navOffset > 0) {
        this.navOffset = 0
      }
    }
  }

  mounted () {
    this.getActiveBarStyle()
    addResizeListener(this.$el, this.update)
    eventbus.$on('lw-tabs.update-active-bar-style', (parent: Vue) => {
      // 解决tabs 嵌套时，activeBar 样式问题
      // 接收事件后遍历祖先节点，看时候是当前节点的祖先触发的
      // 如果是，说明此 tabs 所在的 tab 页被激活，
      // 重新计算 activeBar 的样式
      let node = this as Vue
      let flag = false
      while (node.$parent) {
        if ((node.$parent as any)._uid === (parent as any)._uid) {
          flag = true
          break
        }
        node = node.$parent
      }
      if (flag) {
        this.getActiveBarStyle()
      }
    })
    setTimeout(() => {
      this.scrollToActiveTab()
    }, 0)
  }

  updated () {
    this.update()
  }

  beforeDestroy () {
    removeResizeListener(this.$el, this.update)
  }
}
