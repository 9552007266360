

























import { Component, Watch } from 'vue-property-decorator'
import FormControlComponent from '@/components/layout/FieldBoundComponent'
import logwire from '@/logwire'
import { queryByFilter } from '@/http/api'
import { attributeType, filterOperator, layoutStatus } from '@/utils/const'
import { checkValueIsEmpty, forEachOfTreeData, formatDataRow, formatMasterFilter, getFormDataRow } from '@/utils/data'
import { attribute2Number } from '@/utils/layout'
import Args from '@/models/Args'
import _ from 'lodash'
import ConditionGroup from '@/models/ConditionGroup'
import Condition from '@/models/Condition'

@Component({ name: 'LwCascader' })
export default class LwCascader extends FormControlComponent {
  levels = attribute2Number(this.component.levels) || 5
  cascaderData = []
  selectedOptions: Array<any> = []
  selectedOptionsCopy: any = []
  dynamicProps = {
    value: 'value',
    label: 'label',
    children: 'children'
  }

  currentNode: any
  reverseLevel = attribute2Number(this.component.levels) || 5
  dataFromBelow = [] // 存放从最底层开始获取的副本
  isReverse = false // 标记当前是否处于反查期间
  $refs!: {
    cascader: any
  }

  get selectedItemLabel (): string {
    let result = ''
    const arr: Array<string> = []
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    forEachOfTreeData(this.cascaderData, 'children', (data) => {
      if (self.selectedOptions.indexOf(data.value) !== -1) {
        arr.push(data.label)
      }
    })
    // forEachOfTreeData 是先从底层开始遍历，所以 arr 拿到的是一个倒序的 选择数组
    result = arr.reverse().join('/')
    return result
  }

  mounted () {
    const val = this.inputValue
    // 有数据的情况下，不应该发下面的请求；由于有多页面状态，无法根据url中是否有id，因此根据Status判断
    !val && this.form.status !== layoutStatus.VIEW && this.getData(this.buildParams(1), (datas: any) => {
      if (this.levels > 1) this.parseData(datas, 1)
      this.cascaderData = datas
    })
  }

  /**
   * 由于现有的dataset是根据页面生成，不再是数据获取后才显示组件。
   * 因此难以根据是否有数据来反向查找，现行的办法只能根据是否有id判断
   * 为啥不监听 inputValue? 原因是也就是在第一次的时候才进行反查操作
  */
  @Watch('form.dataRow.id', { deep: true, immediate: true })
  handleReverseData (val: any, oldData: any) : void {
    if (val === oldData) return
    const target = this.inputValue
    // 比方新增保存后，该字段没有赋值，需要清空之前的操作
    this.currentNode = null
    this.selectedOptions = []
    if (val && target) {
      val !== oldData && this.reverseAssignOptions(target)
    }
  }

  @Watch('inputValue')
  handleInputValueChange (newVal: any) {
    this.$nextTick(() => {
      if (!this.isReverse) {
        // 在非编辑并且非反查阶段， 认为也就是 在取消或者保存编辑的时候
        // 此时 通过 inputValue 同步 selectedOptions
        const arr: Array<any> = []
        let targetValue = newVal
        let level = Number(this.levels)
        if (newVal) {
          forEachOfTreeData(this.cascaderData, 'children', (data) => {
            if (data.value === targetValue) {
              arr.push(data.value)
              if (level === 1) return
              const parentIdFieldInDropDown = this.component[`parentIdFieldInDropDown${level}`]
              targetValue = data[parentIdFieldInDropDown]
              level--
            }
          })
        }
        this.selectedOptions = arr.reverse()
      }
    })
  }

  resetValue (): void {
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return
    const { field } = this.component
    const value = row.originalData[field]
    this.$set(this.form.dataRow, field, value)
    if (value && !this.isReverse) {
      this.currentNode = null
      this.selectedOptions = []
      this.reverseLevel = attribute2Number(this.component.levels) || 5
      this.reverseAssignOptions(value)
    }
  }

  parseData (datas: [], level:number) {
    datas.map((item: any, i) => {
      item.children = []
      item.cascadeLevel = level
    })
  }

  reverseAssignOptions (inputValue: any) : void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const attrs = self.component
    const level = (self.reverseLevel || self.levels) as number
    if (inputValue) {
      const mergeParams = this.buildParams(level, inputValue)
      self.getData(mergeParams, (datas: any) => {
        if (datas.length === 0) {
          // 反向查找的时候，如果数据库中数据被删除，则初始化第一级
          self.getData(self.buildParams(1), (datas: any) => {
            if (self.levels > 1) self.parseData(datas, 1)
            self.cascaderData = datas
          })
          self.isReverse = false
          return
        }
        self.selectedOptionsCopy.push(inputValue)
        const parentKey = attrs[`parentIdFieldInDropDown${level}`]
        const parentValue = datas[0][parentKey]
        const currentIdKey = attrs[`valueFieldInDropDown${level}`]
        const siblingParams = this.buildParams(level, parentValue)
        self.getData(siblingParams, (siblings: any) => {
          siblings.forEach((item: any) => { item.cascadeLevel = level })
          if (self.reverseLevel !== self.levels) {
            const preVal = self.dataFromBelow[0][attrs[`parentIdFieldInDropDown${level + 1}`]]
            siblings.forEach((item: any, idx: number) => {
              if (item[currentIdKey] === preVal) {
                item.children = self.dataFromBelow
              }
            })
          }
          if (self.levels > self.reverseLevel) {
            // eslint-disable-next-line no-prototype-builtins
            siblings.forEach((item: any) => { if (!item.hasOwnProperty('children')) item.children = [] })
          }
          self.dataFromBelow = siblings
          if (self.reverseLevel === 1) {
            self.selectedOptions = self.selectedOptionsCopy.reverse()
            self.cascaderData = self.dataFromBelow
            self.selectedOptionsCopy = self.dataFromBelow = []
          }
          if (self.reverseLevel > 0) {
            (self.reverseLevel as number)--
            self.reverseAssignOptions(parentValue)
          }
        }, true, true)
      }, true)
    }
  }

  // 选择有效时
  handleItemChange (val: string | any[]) : void {
    // 清空的时候不做任何操作
    if (val.length === 0) {
      this.form.dataRow[this.field] = ''
      const args = new Args(this.context, { row: getFormDataRow(this.form), getSelectedOption: () => [], evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
      this.component.onChange && this.runRunnableContent('onChange', { args })
      return
    } else {
      this.form.dataRow[this.field] = val[val.length - 1]
    }
    this.findCurNode(val[val.length - 1], this.cascaderData, val.length)
    // 当前点选的下拉项
    const selected = JSON.parse(JSON.stringify(this.currentNode))
    this.refactorLevelData(selected)
    let selectedPath = JSON.parse(JSON.stringify(this.cascaderData))
    selectedPath = this.findSelectedPath(JSON.parse(JSON.stringify(val)), selectedPath)
    // 完整的选择路径，包括每一项的所有内容， 例如 [{ label: '中国',value: 1, xxx }, { label: '江苏',value: 1, xxx }, { label: '南京',value: 1, xxx }]
    selectedPath.forEach((p: any) => this.refactorLevelData(p))
    // 执行onChange
    const getSelectedOption = () => selectedPath
    const args = new Args(this.context, { row: getFormDataRow(this.form), getSelectedOption, evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    this.component.onChange && this.runRunnableContent('onChange', { args })
  }

  findSelectedPath (val: any, levelData: any) {
    const result: Array<any> = []
    while (val.length) {
      let selectedData: any
      levelData.forEach((data: any) => {
        if (data.value === val[0]) {
          selectedData = data
        }
      })
      result.push(selectedData)
      val.splice(0, 1)
      result.push(...this.findSelectedPath(val, selectedData.children))
    }
    return result
  }

  // 去掉层级数据的children属性
  refactorLevelData (Obj: { [x: string]: any }) {
    Obj && delete Obj.children
  }

  // 判断是否有缓存
  findCurNode (val: any, children: any[], level: number) {
    children.forEach(child => {
      if (child.value === val && child.cascadeLevel === level) {
        this.currentNode = child
        return false
      }
      if (child.children && child.children.length) {
        this.findCurNode(val, child.children, level)
      }
    })
  }

  buildParams (level: number, parentData = null) {
    const c = this.component
    return {
      dropDownQuery: c[`dropDownQuery${level}`],
      dropDownRetrieveAction: c[`dropDownRetrieveAction${level}`],
      labelFieldInDropDown: c[`labelFieldInDropDown${level}`],
      valueFieldInDropDown: c[`valueFieldInDropDown${level}`],
      parentIdFieldInDropDown: c[`parentIdFieldInDropDown${level}`],
      parentData: parentData,
      currentData: this.currentNode?.value,
      dropDownMasterFilter: `dropDownMasterFilter${level}`,
      dropDownQueryParams: `dropDownQueryParams${level}`
    }
  }

  lazyLoad (val: string | any[]) {
    if (val) this.findCurNode(val[val.length - 1], this.cascaderData, val.length)
    if (val.length === 1 && this.levels > 1) {
      this.getData(this.buildParams(2), (datas: any) => {
        if (this.levels > 2) this.parseData(datas, 2)
        this.currentNode.children = datas
      })
    } else if (val.length === 2 && this.levels > 2) {
      this.getData(this.buildParams(3), (datas: any) => {
        if (this.levels > 3) this.parseData(datas, 3)
        this.currentNode.children = datas
      })
    } else if (val.length === 3 && this.levels > 3) {
      this.getData(this.buildParams(4), (datas: any) => {
        if (this.levels > 4) this.parseData(datas, 4)
        this.currentNode.children = datas
      })
    } else if (val.length === 4 && this.levels > 4) {
      this.getData(this.buildParams(5), (datas: any) => {
        this.currentNode.children = datas
      })
    }
  }

  // 没值并且在展开下拉框的时候，需要调用接口去获取数据
  handleVisibleChange (visibleStatus: boolean) {
    if ((!this.cascaderData || this.cascaderData.length === 0) && visibleStatus) {
      !this.inputValue && this.getData(this.buildParams(1), (datas: any) => {
        if (this.levels > 1) this.parseData(datas, 1)
        this.cascaderData = datas
      })
    }
  }

  /*
   * queryObj: 查询条件
   * reverse：是否进行反向查找
   * querySiblings: 查询兄弟节点数据
  */
  getData (queryObj: any = {}, cb: any, reverse = false, querySiblings = false) {
    if (reverse) this.isReverse = true
    if (this.currentNode && this.currentNode.children.length > 0) {
      this.isReverse = false
      return
    }
    const { dropDownRetrieveAction, dropDownQuery, labelFieldInDropDown, valueFieldInDropDown, dropDownMasterFilter, dropDownQueryParams, parentIdFieldInDropDown, parentData, currentData } = queryObj
    const body: any = {
      getTotalBy: 'count',
      pageNo: 1,
      pageSize: -1
    }
    let masterFilter = this.getFinalAttributeValue(dropDownMasterFilter, {
      args: this.args,
      type: attributeType.OBJECT
    })
    masterFilter = formatMasterFilter(masterFilter)
    if (masterFilter) {
      body.masterFilter = masterFilter
    }

    const queryParams = this.getFinalAttributeValue(dropDownQueryParams, {
      args: this.args,
      type: attributeType.OBJECT_ARRAY
    })

    if (queryParams) {
      body.queryParams = queryParams
    }

    if (reverse) {
      // 第一层没有 parentId
      if (parentIdFieldInDropDown) {
        // 有 parentIdFieldInDropDown 代表 查询的不是第一级内容
        const conditionGroup = new ConditionGroup()
        const condition = new Condition(querySiblings ? parentIdFieldInDropDown : valueFieldInDropDown, filterOperator.EQ, parentData)
        conditionGroup.add(body.masterFilter, condition)
        body.masterFilter = conditionGroup.build()
      } else {
        if (parentData) {
          // 反推的时候，查询本条数据用id 字段, 查询兄弟节点，以 parentIdFieldInDropDown 作为过滤字段
          const conditionGroup = new ConditionGroup()
          const condition = new Condition(valueFieldInDropDown, filterOperator.EQ, parentData)
          conditionGroup.add(body.masterFilter, condition)
          body.masterFilter = conditionGroup.build()
        }
      }
    } else {
      // 第一层没有 parentId
      if (parentIdFieldInDropDown) {
        const conditionGroup = new ConditionGroup()
        const condition = new Condition(parentIdFieldInDropDown, filterOperator.EQ, currentData)
        conditionGroup.add(body.masterFilter, condition)
        body.masterFilter = conditionGroup.build()
      }
    }
    const successCallback = (res: Record<string, any>) => {
      const rows = res.data.rows
      for (const index in rows) {
        const item = rows[index]
        const value = item.value
        if (!checkValueIsEmpty(value)) {
          item.value = value
        } else {
          item.value = item[valueFieldInDropDown]
        }
        item.label = item._html || item[labelFieldInDropDown]
      }
      cb && cb(rows)
      this.isReverse = false
    }
    const layoutName = this.editLayoutName || this.layoutName
    dropDownRetrieveAction
      ? logwire.network.doAction({ name: dropDownRetrieveAction, data: body, silent: true, successCallback })
      : queryByFilter({ layoutName, namespace: this.context.getNamespace(), queryName: dropDownQuery, filter: body }, { silent: true })
        .then(res => {
          successCallback(res.data)
        }, (e: any) => { console.error(e) })
  }

  getDisplayValue (displayValue: any) {
    displayValue[this.component.field] = this.selectedItemLabel
  }
}
