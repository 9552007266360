
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import RadioBase from './RadioBase'

@Component({
  name: 'TableCellRadio'
})
class TableCellText extends Mixins(TableCellBase, RadioBase) {}
export default TableCellText
