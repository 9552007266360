



















































import { Component, InjectReactive, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
import _, { isArray } from 'lodash'
import { LayoutModule } from '@/store/modules/layout'
import { filterGroupOperator, fieldOperations, fieldAttrs, filterGroupOperatorList, dateOperators } from '@/utils/const'
import { getUuid } from '@/utils/common'
import BaseComponent from '../../BaseComponent'
import { getI18nContent } from '@/utils/layout'
import { formatDate } from 'element-ui/src/utils/date-util'
import { date2DateTime, dateTime2Date } from '@/utils/data'
import component from '@/models/Component'

type Condition = {
  betweenClass?: string
  label: string
  value: any
  type: 'condition'
  layouts: any
  is: string
  field: string
  operator: filterGroupOperator
}

@Component({ name: 'FilterRow' })
class FilterRow extends BaseComponent {
  @Prop() filter !: Condition
  @Prop({ default: 0 }) filterIndex!: number
  @Prop() index !: number // row 在 group 内的序号

  @ProvideReactive() evaluatingBase = 'advancedFilter'

  fieldOptions: any = []
  operatorOptions: any = []
  filterDataSet = {}

  @InjectReactive() filterLayoutName!: string
  @InjectReactive() filterDataSetName!: string
  @InjectReactive() evaluateScriptInTableControls!: boolean

  handleRowCommand (command: string) {
    command === 'delete' && this.$emit('deleteRow')
    command === 'toGroup' && this.$emit('changeRowToGroup')
  }

  created () {
    // 根据showFields是空，来判定dataset对应的是list，字段标题只能用 queryMetadata 提供的来源于 Model 的 title
    let options = null
    if (LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields) {
      options = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].showFields.filter((item: { is: string }) => item.is !== 'lw-upload-file')
    } else {
      const queryName = LayoutModule.data[this.filterLayoutName].systemState[this.filterDataSetName].queryName as string
      options = LayoutModule.resource[this.filterLayoutName].queryMeta[queryName]
      // 处理 list 的 options ,使其和table返回的数据一致
      options = options.map((item: any) => {
        item.field = item.name
        item.is = 'lw-text'
        return _.pick(item, ['is', 'field', 'title'])
      })
    }
    // 合并 field 相同的项 并将每一项的title国际化
    options = _.unionBy(options, 'field')
    options = options.map((item: any) => {
      if (item.title) {
        item.i18nTitle = getI18nContent(item.title)
      }
      return item
    })
    this.fieldOptions = options
    const firstOption = this.fieldOptions[0]
    if (!Object.keys(fieldOperations).includes(firstOption.is)) {
      firstOption.is = 'lw-text'
    }
    const layouts = this._initLayouts(firstOption)
    let initRow = {}
    if (!this.filter.field) {
      initRow = {
        layouts,
        field: firstOption.field,
        operator: filterGroupOperator.EQ,
        value: null,
        is: firstOption.is
      }
    }
    // 国际化操作符
    this.operatorOptions = filterGroupOperatorList.map((item: any) => {
      const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
      return data
    })
    this.filter = Object.assign(this.filter, initRow)
    // searchDs改名__advancedFilterDs，为了兼容在此之前创建的过滤组，取出来后修改dataset名称
    this.filter.layouts[0].dataSet = '__advancedFilterDs'
    this.formatSavedFilter()
  }

  mounted () {
    const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
    const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
    // 编辑复制过滤组时，对between操作符进行处理
    // if ([filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(this.filter.operator)) {
    //   searchRow.setData(this.filter.layouts[0].components[0].field, this.filter.value[0])
    //   // searchRow.setData(this.filter.layouts[0].components[1].field, this.filter.value[1])
    //   return
    // }
    // select组件还有设置显示字段，后面拼接_label
    if (this.filter.is === 'lw-select') {
      searchRow.setData(this.filter.layouts[0].id + '_label', this.filter.label)
    }
    searchRow.setData(this.filter.layouts[0].id, this.filter.value)
  }

  /** README 2023-08-29
   * 这里为什么格式化已经保存的过滤组？
   * 原因是早先在 date、dateTime 的操作符为 之间、日期之间时，使用了两个日期选择控件
   * 这带来了两个问题：
   * 1.无法做前后控件日期限制：比如结束日期必须在结束日期之后
   * 2.由于两个组件之间没有关联，所以在保存完之后重新打开，组件在获取值上出现了问题，比如在 之间、日期之间 切换的时候，无法准确的获取哪个是开始时间，哪个是结束时间
   * 为了解决以上问题，故而将两个组件换成了一个组件，并且将 type 设置为 'daterange' 或者  'datetimerange'
   * 但是由于已经保存的过滤组中 date、dateTime 等字段有两个组件，所以在打开时重新格式化过滤组
   * 相关议题 #2236 #2168
   * */
  formatSavedFilter () {
    const { layouts, is, operator } = this.filter
    const { components } = layouts[0]
    if ([filterGroupOperator.DATE_BETWEEN, filterGroupOperator.BETWEEN].includes(operator)) {
      const component = components[0]
      component.type = component.is === 'lw-date' ? 'daterange' : 'datetimerange'
      if (components.length > 1) {
        components.pop()
        // delete this.filter.betweenClass
        delete layouts[0].columns
      }
    }
  }

  changeField (currentField: any) {
    this.filter.betweenClass = ''
    const component = this.fieldOptions.find((item: { field: any }) => item.field === currentField)
    const layouts = this._initLayouts(component)
    this.$set(this.filter, 'is', component.is)
    this.$set(this.filter, 'layouts', layouts)
    this.$set(this.filter, 'operator', filterGroupOperator.EQ)
    this.$forceUpdate()
  }

  changeOperator (currentOperator: any) {
    // lw-text 特殊情况： 操作符为可选,排除时，后面为lw-textarea
    this.$set(this.filter.layouts[0].components[0], 'enabled', 'true')
    if (this.filter.is === 'lw-text') {
      if ([filterGroupOperator.IN, filterGroupOperator.NOT_IN].includes(currentOperator)) {
        this.$set(this.filter.layouts[0].components[0], 'is', 'lw-textarea')
      } else {
        this.$set(this.filter.layouts[0].components[0], 'is', 'lw-text')
      }
      const id = this.filter.field + '_' + getUuid()
      const originalId = this.filter.layouts[0].id
      this.$set(this.filter.layouts[0].components[0], 'field', id)
      this.$set(this.filter.layouts[0], 'id', id)
      // 还需要改变dataset中的值
      const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
      const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
      searchRow.setData(this.filter.layouts[0].id, searchRow.getData(originalId))
    }
    this.filter.betweenClass = ''
    // 如果操作符是之间，则出现两个区间日期选择
    if (['lw-date', 'lw-date-time', 'lw-timestamp'].includes(this.filter.is)) {
      // 还需要改变dataset中的值
      const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
      const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
      const id = this.filter.field + '_' + getUuid()

      const originalId = this.filter.layouts[0].id
      const date = searchRow.getData(originalId)

      // 统一当成数组处理
      let finalDate = isArray(date) ? date : [date]
      finalDate = finalDate.map(date => {
        const result = dateOperators.includes(currentOperator) ? dateTime2Date(date) : date2DateTime(date)
        return result || '' // 这里给空字符串是因为 null 会被解析成 Invalid Date 导致日期选择器会出现默认日期
      })
      // this.$set(this.filter.layouts[0], 'id', id)
      const component = this.filter.layouts[0].components[0]
      const is = this.filter.is === 'lw-date'
        ? 'lw-date'
        : dateOperators.includes(currentOperator) ? 'lw-date' : 'lw-date-time'
      const isOneOfBetweenOperator = [filterGroupOperator.BETWEEN, filterGroupOperator.DATE_BETWEEN].includes(currentOperator)
      const type = isOneOfBetweenOperator
        ? is === 'lw-date' ? 'daterange' : 'datetimerange'
        : is === 'lw-date' ? 'date' : 'datetime'

      this.$set(component, 'is', is)
      this.$set(component, 'type', type)

      // 之间和非之间操作符数据格式不一致，一个是数组，一个是字符串
      const targetDate = isOneOfBetweenOperator
        // 长度小于 2 说明由非之间操作符变更而来，需要补足一位
        ? finalDate.length < 2 ? [...finalDate, finalDate[0]] : finalDate
        : finalDate[0]
      this.filter.betweenClass = isOneOfBetweenOperator ? 'filter-row-between' : ''
      searchRow.setData(originalId, targetDate)
    }
    // 如果操作符是为空或者不为空，则value输入框置为不可编辑
    if ([filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY].includes(currentOperator)) {
      this.$set(this.filter.layouts[0].components[0], 'enabled', 'false')
      const searchDs: any = this.context.getOrCreateDataSet('__advancedFilterDs')
      const searchRow = searchDs.getRow(0) || searchDs.addRow(false)
      searchRow.setData(this.filter.layouts[0].id, null)
    }
    this.$forceUpdate()
  }

  getOperators (component: any) {
    if (component.is === 'lw-choice' && component.multiple) {
      this.operatorOptions = fieldOperations[component.is + '-multiple'].map((item: any) => {
        const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
        return data
      })
    }
    this.operatorOptions = fieldOperations[component.is].map((item: any) => {
      const data = { value: item, label: this.$i18n('core', 'client.advanced-filter.operator.' + item) }
      return data
    })
  }

  _initLayouts (component: any) {
    if (component.is === 'lw-timestamp') component.is = 'lw-date-time'
    const id = component.field + '_' + getUuid()
    const initComponent = _.pick(_.cloneDeep(component), fieldAttrs['lw-form-item'].concat(fieldAttrs[component.is]))
    // 过滤掉initComponent中有 {eval} {function} {common} 的属性
    if (!this.evaluateScriptInTableControls) {
      for (const key in initComponent) {
        if (initComponent[key].includes('{eval}') || initComponent[key].includes('{function}') || initComponent[key].includes('{common}')) {
          delete initComponent[key]
        }
        if (['displayTemplate', 'dropDownRowTemplate', 'dropDownHeaderTemplate'].includes(key) && initComponent[key].includes('getCurrentRow')) {
          delete initComponent[key]
        }
      }
    }
    const layouts = [{
      id,
      is: 'lw-input-form',
      dataSet: '__advancedFilterDs',
      components: [
        Object.assign(initComponent,
          {
            field: id,
            enabled: 'true'
          })
      ]
    }]
    // this.getOperators(component)
    return layouts
  }
}

export default FilterRow
