





















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { getFinalAttributeValue } from '@/utils/layout'
import { AssociatedContext, LayoutComponent, LayoutContext } from '@/types/layout'
import Args from '@/models/Args'
import { attributeType, IntuitiveColorAllList, IntuitiveColorMainList, SemanticColorMainList, SemanticColorSchemes } from '@/utils/const'

@Component({ name: 'Step' })
export default class Step extends Vue {
  @Inject() associatedContext!: AssociatedContext;
  @Inject() context!: LayoutContext;
  @Prop() component!: LayoutComponent
  @Prop() defaultIconColorScheme!: string
  @Prop() defaultTitleColorScheme!: string

  get title () {
    return this.getFinalAttributeValue('title')
  }

  get icon () {
    return this.getFinalAttributeValue('icon')
  }

  get iconColorScheme () {
    const colorScheme = this.getFinalAttributeValue('iconColorScheme') || this.defaultIconColorScheme
    return this.getFullColorName(colorScheme)
  }

  get titleColorScheme () {
    const colorScheme = this.getFinalAttributeValue('titleColorScheme') || this.defaultTitleColorScheme
    return this.getFullColorName(colorScheme)
  }

  get childGroup (): Array<LayoutComponent> {
    return this.component.components || []
  }

  getFullColorName (colorScheme: string) {
    let color = 'var(--gray-c)'
    if (colorScheme === SemanticColorSchemes.PRIMARY) {
      color = 'var(--theme-6)'
    } else if (colorScheme === SemanticColorSchemes.SECONDARY) {
      color = 'var(--gray-6)'
    } else if (SemanticColorMainList.includes(colorScheme)) {
      color = 'var(--' + colorScheme + '-6)'
    } else if (IntuitiveColorMainList.includes(colorScheme)) {
      color = 'var(--' + colorScheme + '-c)'
    } else if (IntuitiveColorAllList.includes(colorScheme)) {
      color = 'var(--' + colorScheme + ')'
    }
    return color
  }

  getFinalAttributeValue (name: string) {
    const args = new Args(this.context)
    const types = [attributeType.STRING]
    return getFinalAttributeValue(name, this.component, args, this.associatedContext, types)
  }
}
