/* eslint-disable standard/no-callback-literal */
import { getLogwireAuthorizeUrl, getLogwireFeedbackConfig, getLogwireToken, getLogwireTokenByCode, getUserInfoAction, getVersion, refreshLogwireToken } from '@/http/api'
import logwire from '@/logwire'
import { LogwireC } from '@/vue'
import { AxiosResponse } from 'axios'

interface LogwireResponse<T> {
  data: {
    code: number;
    traceId: string;
    message: string;
    messageType: 'error' | 'success';
    stackTrace?: any;
  };

  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

interface FeedbackResponse {
  feedbackEnabled: boolean
  clientId: string
}

let promise: Promise<LogwireC.FeedbackInstance>
let promiseConfig: Promise<AxiosResponse<FeedbackResponse>>
// eslint-disable-next-line prefer-promise-reject-errors
let promiseReady: Promise<LogwireC.FeedbackInstance>
const clientError: LogwireC.ClientError = []
const serverError: LogwireC.ServerError = []
const serverException : LogwireC.ServerError = []

export function initClientError () {
  const oldConsoleError = console.error
  console.error = function (err) {
    if (err instanceof Error) {
      setClientError(err)
    }
    oldConsoleError(err)
  }
}

export function setClientError (err: Error) {
  try {
    const client = clientError.find(o => o.content === err.message)
    if (client) {
      client.count++
      client.eventTime = (+new Date()).toString()
    } else {
      clientError.push({
        eventTime: (+new Date()).toString(),
        content: err.message,
        position: err.stack || '',
        uid: '',
        count: 1
      })
      if (clientError.length > 5) {
        clientError.shift()
      }
    }
  } catch (e) {}
}

export function setServiceError (err: LogwireResponse<any>) {
  try {
    let serverArray: LogwireC.ServerError
    if (err.status === 200) {
      serverArray = serverException
    } else {
      serverArray = serverError
    }
    const server = serverArray.find(o => o.content === err.data?.message)
    if (server) {
      server.count++
      server.eventTime = (+new Date()).toString()
    } else {
      const requestStartTime = err.config.headers?.['X-DATE'] // 请求发起时间
      const responseStartTime = +new Date() // 收到响应时间
      const tripTime = +responseStartTime - +requestStartTime // responseStartTime 和requestStartTime的时间差，单位ms
      const isNeedRequest = ['application/x-www-form-urlencoded', 'application/json', 'text/xml'].some(o => (err.headers?.['content-type']?.includes(o)))
      let requestBody = {}
      if (typeof err.config.data === 'string') {
        try { requestBody = JSON.parse(err.config.data) } catch (e) { requestBody = err.config.data || {} }
      }
      let responseBody = {}
      if (typeof err.request?.response === 'string') {
        try { responseBody = JSON.parse(err.request.response) } catch (e) { responseBody = err.request.response || {} }
      }
      serverArray.push({
        eventTime: (+new Date()).toString(),
        count: 1,
        content: err.data?.message,
        requestBody: isNeedRequest ? requestBody : undefined,
        responseBody: isNeedRequest ? responseBody : undefined,
        basic: {
          httpStatus: err.status?.toString(), //  状态码
          method: err.config.method, //  请求类型
          requestContentType: err.headers?.['content-type'], // request headers的content-type
          responseContentType: err.headers?.['content-type'], // response headers的content-type
          requestStartTime: getFormatTimeString(new Date(requestStartTime)),
          responseStartTime: getFormatTimeString(new Date(responseStartTime)),
          tripTime: tripTime ? tripTime.toString() : '0',
          url: err.config.url, //  请求url
          uid: '', // uid
          traceId: err.data?.traceId // 后端返回的traceId
        }
      })
      if (serverArray.length > 5) {
        serverArray.shift()
      }
    }
  } catch (e) {}
}

function getFormatTimeString (time: Date) {
  const appendZero = (num: number) => {
    return num.toString().length === 1 ? '0' + num.toString() : num.toString()
  }
  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const day = time.getDate()
  const hour = time.getHours()
  const min = time.getMinutes()
  const sec = time.getSeconds()
  return `${year}-${appendZero(month)}-${appendZero(day)} ${appendZero(hour)}:${appendZero(min)}:${appendZero(sec)}`
}

/**
 * 获取当前用户反馈配置
 */
export async function getFeedbackConfig (): typeof promiseConfig {
  if (promiseConfig) return promiseConfig
  promiseConfig = getLogwireFeedbackConfig()
  return promiseConfig
}

/**
 * 获取协同云反馈对象实例，在 FeedbackMonitor 中 created 生命周期会被加载一次，为了获取 todo 数量，其他情况都是手动调用
 */
export async function getFeedbackInstance (): Promise<LogwireC.FeedbackInstance> {
  if (promise) return promise

  if (!document.querySelector('#coopwire-feedback-js')) {
    const coopwireFeedbackJs = logwire.store.getConfig('coopwireBaseUrl') + '/open/index.js?v=1.0&callback=onLoad'
    const coopwireFeedbackCss = logwire.store.getConfig('coopwireBaseUrl') + '/open/index.css'
    const script = document.createElement('script')
    script.setAttribute('src', coopwireFeedbackJs)
    script.setAttribute('nonce', 'logwire')
    script.setAttribute('id', 'coopwire-feedback-js')
    const link = document.createElement('link')
    link.setAttribute('href', coopwireFeedbackCss)
    link.setAttribute('rel', 'stylesheet')

    return new Promise((resolve, reject) => {
      script.onload = function () {
        resolve(getFeedbackInstance())
      }

      document.head.appendChild(script)
      document.head.appendChild(link)
    })
  } else if (!window.LC?.Feedback) {
    return new Promise((resolve, reject) => {
      const script = document.querySelector('#coopwire-feedback-js') as HTMLScriptElement
      script.onload = function () {
        resolve(getFeedbackInstance())
      }
    })
  }

  const resVersion = await getVersion()
  const { data: { data: backendVersionData } } = resVersion

  const resUser = await getUserInfoAction()

  const feedback = new window.LC.Feedback({
    baseUrl: logwire.store.getConfig('coopwireBaseUrl'),
    systemInfo: {
      version: backendVersionData
    },
    systemType: '05',
    userInfo: {
      uid: resUser.data.data.id,
      email: resUser.data.data.email,
      username: resUser.data.data.username
    },
    type: 'feedback',
    themeColor: '#2e75e6',
    clientError: clientError,
    serverException: serverException,
    serverError: serverError,
    getClientId () {
      getFeedbackConfig().then(res => {
        feedback.setClientId(res.data.clientId)
      })
    },
    getApplicationToken () {
      getLogwireToken().then(res => {
        feedback.setApplicationToken(res.data)
      })
    },
    getRefreshToken (accessToken, refreshToken) {
      refreshLogwireToken({ data: { refresh_token: refreshToken } }).then(res => {
        feedback.setLCRefreshToken(res.data)
      })
    },
    handleClickLoginButton () {
      getLogwireAuthorizeUrl({ data: { currentUrl: window.location.href } }).then(res => {
        // 计算居中
        const width = screen.availWidth
        const left = (width - 660) / 2
        const newTabWindow = window.open(res.data.url, '_blank', 'popup=true,width=660,height=791,left=' + left)
        if (newTabWindow) {
          window.addEventListener('message', evt => {
            if (evt.origin === window.location.origin) {
              let data = evt.data
              if (typeof data === 'string' && data.length) {
                data = JSON.parse(data)
                getLogwireTokenByCode({ data: { code: data.code, state: data.state } }).then(res => {
                  feedback.setLCAccountToken(res.data)
                })
              }
            }
          })
        }
      })
    }
  })

  promise = new Promise((resolve, reject) => {
    resolve(feedback)
  })
  promiseReady = new Promise((resolve, reject) => {
    feedback.on('complete', () => {
      if (window.location.search && window.location.search.match(/fid=(.*?)/)) {
        const fid = window.location.search.match(/fid=(.*?)&/)
          ? /fid=(.*?)&/.exec(window.location.search)?.[1]
          : /fid=(.*)/.exec(window.location.search)?.[1]
        if (fid) {
          feedback.showDetailPage(fid)
        }
      }
      resolve(feedback)
    })
  })

  return promise
}

export function getFeedbackInstanceReady () {
  return promiseReady
}
