import Args from '@/models/Args'
import { LwForm } from '@/types/form'
import { formatDataRow } from '@/utils/data'
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseComponentWithRow extends Vue {
  @Prop() tableRow!: any
  currentRowInParent!: any
  @InjectReactive() evaluatingBase!: string
  context!: any

  get form (): LwForm { // lw-label 用在 lw-form 的时候
    if (this.tableRow) {
      return { dataRow: this.tableRow.currentData } as LwForm
    }
    let parent = this.$parent
    const options: any = parent?.$options
    let parentName = options.name
    while (parent && parentName !== 'LwForm' && parentName !== 'LwInputForm') {
      parent = parent?.$parent
      const options: any = parent?.$options
      parentName = options?.name
    }
    return parent as unknown as LwForm
  }

  get dataRow () {
    let result = this.currentRowInParent
    if (this.form) {
      result = this.form.dataRow
    }
    return result
  }

  get argsWithRow () {
    let args = new Args(this.context)
    if (this.dataRow) {
      args = new Args(this.context, { row: formatDataRow(this.dataRow), evaluatingBase: this.evaluatingBase ? this.evaluatingBase : (this.tableRow ? 'tableRow' : 'editRow') })
    }
    return args
  }
}
