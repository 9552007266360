import { formatDate } from 'element-ui/src/utils/date-util'
import logwire from '@/logwire'

export interface IDateContentStruct {
  value: any;
  type?: string;
}

const DATE_TYPES = ['year', 'month', 'date', 'daterange']

export const dateFormatter = (type?: string) => {
  if (type === 'year') return 'yyyy'
  let format = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  if (type === 'month') {
    format = logwire.store.getConfig('core.i18n.year-month-format') || 'yyyy-MM'
  }
  return format
}

export const dynamicAttrs: Array<keyof IDateContentStruct> = ['type']

const formatValue = (params: IDateContentStruct) => {
  const { type = 'date', value } = params
  const finalType = DATE_TYPES.includes(type) ? type : 'date'
  const isDateRange = finalType === 'daterange'
  return value
    ? isDateRange
      ? value.map((dateTime: string) => new Date(dateTime))
      : new Date(value)
    : isDateRange
      ? []
      : value
}

const formatContent = ({ value, type }: IDateContentStruct) => {
  return formatDate(value, dateFormatter(type))
}

export default {
  formatValue,
  formatContent,
  dynamicAttrs
}
