import { Component, Watch, Prop } from 'vue-property-decorator'
import { attributeType, calculationType, layoutStatus } from '@/utils/const'
import { calculation } from '@/utils/data'
import { marked } from 'marked'
import logwire from '@/logwire'
import _ from 'lodash'
import HelpText from '@/components/layout/inner/HelpText.vue'
import FormDisplayText from '@/components/layout/inner/FormDisplayText.vue'
import eventbus from '@/utils/event'
import FormControlComponent from '@/components/layout/FormControlComponent'

@Component({ components: { HelpText, FormDisplayText } })
class FieldBoundComponent extends FormControlComponent {
  @Prop() rowEnabled!: boolean
  @Prop() asFilterForm!: boolean
  @Prop() dataSetName!: string

  get title (): string { return this.getFinalAttributeValue('title', { args: this.args }) }

  get label (): string { return this.getFinalAttributeValue('title', { args: this.args }) }

  get field (): string { return this.component.field }

  get required (): boolean {
    return this.getFinalAttributeValue('required', { args: this.args, type: attributeType.BOOLEAN })
  }

  get disabled () : boolean {
    // 过滤组中的表单组件应不受权限和其他因素控制，全部都是可编辑的
    if (this.evaluatingBase === 'advancedFilter') {
      return false
    }
    if (this.form.asQuickEditForm) {
      return false
    }
    if (this.form.parentRow) {
      return true
    }
    // 在弹出层或者抽屉处于 view 状态下的元素置为 disabled
    if (this.popupLayoutStatus === layoutStatus.VIEW) {
      return true
    }
    // 不具备编辑权限应该只能看
    if (!this.accessibleResourcesWithEdit && !this.form.asFilterForm) {
      return true
    }
    // table 行是否可用rowEnabled
    if (this.tableRow && !this.rowEnabled) {
      return true
    }

    return this.component.enabled
      ? !this.getFinalAttributeValue('enabled', { args: this.args, type: attributeType.BOOLEAN })
      : false
  }

  get placeholder (): string {
    return this.getFinalAttributeValue('placeholder', { args: this.args })
  }

  get rules (): Record<string, any> | void {
    return this.required ? {
      required: this.visible && this.required,
      message: this.$i18n('core', 'client.form.is-required', this.title),
      trigger: 'blur'
    } : undefined
  }

  get inputValue (): any {
    const dataRow = this.form.dataRow
    return dataRow[this.field]
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = value
  }

  get valueChanged (): boolean {
    // input-form 中不需要撤销按钮
    if (this.form.$options?.name === 'LwInputForm') {
      return false
    } else if (this.form.asQuickEditForm) {
      return false
    }

    const row = this.form.dataSet?.rows?.[0]
    if (!row) return false
    if (this.$options?.name === 'LwNumber' && (this as any).formatEnabled && (this as any).percentage && this.inputValue) {
      // LwNumber 控件在 某些情况下界面上展示的值和 存在 Vuex 中的值并不相同
      const result = calculation([this.inputValue, 100], calculationType.DIVISION)
      // eslint-disable-next-line eqeqeq
      return result != row.originalData[this.component.field]
    } else if (this.$options?.name === 'LwTime' && (this as any).type === 'select') {
      // 如果是 select 类型， 则 inputValue 只会显示小时分钟, 而 originalData 中具有秒数
      return !row.originalData[this.component.field]?.startsWith(this.inputValue)
    } else {
      return this.inputValue !== row.originalData[this.component.field]
    }
  }

  get wrapperComponent (): string {
    return this.tableRow ? 'div' : 'el-form-item'
  }

  get formEditing (): boolean {
    if (this.form.status) {
      return [layoutStatus.HEADER_DETAIL_EDIT, layoutStatus.HEADER_DETAIL_NEW, layoutStatus.EXPRESS_EDIT].includes(this.form.status)
    } else {
      return false
    }
  }

  get autoFocus (): boolean {
    let result = false
    if (this.component.autoFocus) {
      result = this.getFinalAttributeValue('autoFocus', { args: this.args, type: attributeType.BOOLEAN })
    }
    return result
  }

  get help (): string {
    let result = ''
    if (this.component.help) {
      result = this.getFinalAttributeValue('help', { args: this.args })
      result = _.template(result)({ args: this.args, logwire })
      result = marked(result)
    }
    return result
  }

  @Watch('inputValue')
  handleInputValueChange (value: any, oldValue?: any): void {
    // 在表格中时，由于表格的快速编辑不会影响其他内容，所以需要手动调用 onChange
    if (this.tableRow && oldValue) {
      if (this.$options.name !== 'LwSelect' &&
        this.$options.name !== 'LwDate' && // lw-date 的 inputValue 是 new Date 对象，每次都是新对象
        this.$options.name !== 'LwDateTime' // lw-date-time 的 inputValue 是 new Date 对象，每次都是新对象
      ) {
        this.handleChange(value)
      }
    }
    // LwInputForm 不需要状态 一直处于编辑状态
    if (!this.form ||
      this.form.showFormOperations ||
      this.form.asFilterForm ||
      !this.form.$options ||
      this.form.$options.name === 'LwInputForm'
    ) return
    this.form.handleFieldChange && this.form.handleFieldChange(this.component.field, value)
  }

  /**
   * 如果用户在 beforeEdit 时通过 DataRow.setData 修改数据，则需要将该字段标为编辑字段
   * 但是以前只对 valueChanged 做监听, 导致组件被创建值 valueChanged 就已经是 true 了，除非再次修改到原值，不然无法触发监听
   */
  @Watch('valueChanged', { immediate: true })
  @Watch('form', { immediate: true })
  handleChangedStatusChange (): void {
    this.form && this.form.updateEditedFields && this.form.updateEditedFields(this.component.field, this.valueChanged)
  }

  resetValue (): void {
    const row = this.form.dataSet?.rows?.[0]
    if (!row) return
    const { field } = this.component
    const value = row.originalData[field]
    this.$set(this.form.dataRow, field, value)
  }

  handleEnter (e: any): void {
    this.component.onEnter && this.runRunnableContent('onEnter')
  }

  handleBlur (e: any): void {
    this.component.onBlur && this.runRunnableContent('onBlur')
  }

  handleFocus (e: any): void {
    this.component.onFocus && this.runRunnableContent('onFocus')
  }

  handleChange (value: any) :void {
    // 快速编辑内的 表单元素就不需要响应 change 事件了,否则 导致 change 事件被触发两次
    if (this.form.asQuickEditForm) {
      return
    }
    this.component.onChange && this.runRunnableContent('onChange', { args: this.args })
  }

  handleInput () {
    this.component.onInput && this.runRunnableContent('onInput', { args: this.args })
  }

  // 通知table 单元格弹出快速编辑框
  handleQuickEdit () {
    this.$emit('quick-edit')
  }

  // 通知单元格内容回撤
  handleDataRowReset () {
    this.$emit('data-row-reset')
  }

  mounted () {
    if ((this.autoFocus || this.form.asQuickEditForm) && !this.tableRow) {
      setTimeout(() => {
        // TODO 全部类型都要获取焦点，还是只有输入框才需要获取
        let input = this.$el.querySelector('input') || this.$el.querySelector('textarea')
        input && input.focus()
        input = null
      }, 0)
    }
    if (this.asFilterForm) {
      eventbus.$on(`${this.encodedLayoutName}.${this.dataSetName}.${this.component.field}.getDisplayValue`, this.getDisplayValue)
    }
  }

  getDisplayValue (displayValue: any) {
    // do nothing
  }
}
export default FieldBoundComponent
