import { checkValueIsEmpty } from '@/utils/data'
import { numeralFormat } from '@/utils/number'
import { isString } from 'lodash'
import { attribute2Boolean } from '@/utils/layout'
import { IDynamicAttr } from '@/types/table'
import { attributeType } from '@/utils/const'
import { isNumber } from 'xe-utils'

export interface INumberContentStruct {
  formatEnabled?: boolean;
  positiveOnly?: boolean;
  decimalScale?: number;
  thousandsGroupStyle?: string;
  percentage?: boolean;
  prepend?: string;
  append?: string;
  value: string | number | null;
}
// 'formatEnabled', 'positiveOnly', 'decimalScale', 'percentage' 几个属性理论上是静态属性，但是由于其属性值的特殊性，并且后段可能并没有处理成对应的属性值类型，所以当成动态属性处理
const dynamicAttrs: Array<keyof INumberContentStruct | IDynamicAttr<keyof INumberContentStruct>> = [
  'prepend',
  'append',
  { key: 'formatEnabled', type: attributeType.BOOLEAN },
  { key: 'positiveOnly', type: attributeType.BOOLEAN },
  { key: 'percentage', type: attributeType.BOOLEAN },
  { key: 'decimalScale', type: attributeType.NUMBER }
]
const staticAttrs: Array<keyof INumberContentStruct> = ['thousandsGroupStyle']

const formatValue = (struct: INumberContentStruct) => {
  const { value, formatEnabled, percentage } = struct
  if (formatEnabled && percentage && value) return +value * 100
  return value
}

const formatContent = (struct: INumberContentStruct) => {
  const { formatEnabled, positiveOnly, decimalScale, thousandsGroupStyle, percentage, prepend = '', append = '', value } = struct
  let result: any = value
  if (checkValueIsEmpty(value)) return ''
  if (formatEnabled && isNumber(value)) {
    if (positiveOnly) result = Number(value.toString().replace('-', ''))
    result = numeralFormat(result, decimalScale, thousandsGroupStyle, percentage)
  }
  result = `${prepend}${result}${append}`
  return result
}

export default {
  formatContent,
  formatValue,
  dynamicAttrs,
  staticAttrs
}
