export interface IDimension {
  dimensionKey: string;
  indicatorKey?: string;
  title: string;
  width?: string;
  showSort?: boolean;
  format?: (value: number|string) => any;
  headerIcon?: string;
  choiceCategory?: string;
  headerFormat?: (title: string) => any;
}

export enum AggregationType {
  SUM = 'SUM',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX'
}

export enum Sort {
  Normal = 'normal',
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortIcon {
  SortUpward = 'sort_upward',
  SortDownward = 'sort_downward',
  SortNormal = 'sort_normal'
}
