








import { Component, Vue } from 'vue-property-decorator'
import eventbus from '@/utils/event'

type dataObject = {
  data: any;
  visible: boolean;
}

@Component({ name: 'DataPrinter' })
class DataPrinter extends Vue {
  dataObjects: Array<dataObject> = []

  handleDataPrint (data: Blob | string) {
    if (typeof data === 'string') {
      this.dataObjects.push({ data, visible: true })
    } else {
      this.dataObjects.push({ data: URL.createObjectURL(data), visible: true })
    }
  }

  handlePreviewClose (ob: dataObject) {
    // 关闭之后移除该 blob
    ob.visible = false
    this.dataObjects.splice(this.dataObjects.indexOf(ob), 1)
  }

  created () {
    eventbus.$on('print-data', this.handleDataPrint)
  }
}
export default DataPrinter
