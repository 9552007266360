<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellSwitch from './TableCellSwitch.vue'
import Switch from './Switch.vue'

@Component({ name: 'LwSwitch', components: { TableCell: TableCellSwitch, FormItem: Switch } })
class LwSwitch extends FormControlBase {}
export default LwSwitch
</script>
