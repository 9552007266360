import _ from 'lodash'
import logwire from '@/logwire'
import { attributeType } from '@/utils/const'
import { LayoutContext } from '@/types/layout'
import Args from '@/models/Args'
import { DataRow } from '@/types/data'
import { IDynamicAttr } from '@/types/table'

export interface ISelectContentStruct {
  displayTemplate?: string;
  displayContent?: string;
  displayField?: string;
  value?: string;
  context?: LayoutContext;
  row: DataRow;
  args?: Args;
}

export const dynamicAttrs: Array<keyof ISelectContentStruct | IDynamicAttr<keyof ISelectContentStruct>> = [{ key: 'displayContent', type: [attributeType.STRING, attributeType.STRING_ARRAY] }]

export const staticAttrs: Array<keyof ISelectContentStruct> = ['displayField', 'displayTemplate']

const formatContent = (struct: ISelectContentStruct) => {
  const { displayTemplate, displayContent, displayField, context, row, args: propArgs } = struct
  let label: string | string[] = ''
  if (displayTemplate) {
    const args = propArgs || new Args(context as LayoutContext, { row })
    label = _.template(displayTemplate)({ args, logwire })
    return _.unescape(label)
  } else if (displayContent) {
    label = displayContent
  } else if (displayField) {
    label = row.currentData[displayField]
  }
  return label
}

export default {
  formatContent,
  dynamicAttrs,
  staticAttrs
}
