<script>
import { Component } from 'vue-property-decorator'
import FormControlBase from '../FormControlBase.vue'
import TableCellRadio from './TableCellRadio.vue'
import FormRadio from './Radio.vue'

@Component({ name: 'LwRadio', components: { TableCell: TableCellRadio, FormItem: FormRadio } })
class LwRadio extends FormControlBase {}
export default LwRadio
</script>
