import { Component, Vue } from 'vue-property-decorator'
import { formatDate } from '@/utils/data'
import { warnAttributeMissing } from '@/utils/layout'
import { LocalModule } from '@/store/modules/local'
import { attributeType } from '@/utils/const'
import _ from 'lodash'
import formatter, { getOptions } from './formatter'

export interface Option {
  title: string;
  value: string;
  [propName: string]: any;
}

@Component
class ChoiceBase extends Vue {
  [propName: string]: any;

  get multiple (): boolean {
    return this.getFinalAttributeValue('multiple', { args: this.args, type: attributeType.BOOLEAN })
  }

  /**
   * 先从当前 namespace 下的 choices 中取，没有再从 core 下的 choices 中取
   * */
  get options (): Array<Option> {
    const namespace = this.context.getNamespace()
    const { category } = this.component
    if (!category) {
      warnAttributeMissing('category', this.component.is)
    }
    const choices = getOptions(namespace, category, this.component.dimension)
    const filteredChoices = this.getFinalAttributeValue('filteredChoices', { args: this.args, type: attributeType.STRING_ARRAY })
    return filteredChoices
      ? choices.filter((choice: Option) => filteredChoices.includes(choice.value))
      : choices
  }

  get inputValue (): any {
    const dataRow = this.form.dataRow
    const value = dataRow[this.component.field]
    return formatter.formatValue({ value, multiple: this.multiple })
  }

  set inputValue (value) {
    this.form.dataRow[this.component.field] = this.multiple ? value.join(',') : value
  }

  get displayValue (): string {
    const { inputValue, options } = this
    const result = formatter.formatContent({ value: inputValue, options })
    return result
  }

  mounted () {
    const dimension = this.component.dimension
    if (!dimension) return
    LocalModule.getOrCreateDimensionChoices(dimension)
  }
}

export default ChoiceBase
