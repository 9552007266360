import { AppModule } from '@/store/modules/app'
import { LoadingLevel } from '@/http'

export interface ILoadingConfig {
  level?: LoadingLevel;
  timer?: number;
}

class Toast {
  loadings: ILoadingConfig[] = []
  minLoadingTime = 300
  delayLoadingTime = 300

  updateLoadingStatus (status: boolean, level?: LoadingLevel): void {
    let classNames = ['.action-processing']
    if (LoadingLevel.SELECT === level) classNames = ['.lw-select-dropdown__loading .el-loading-mask', '.lw-select-dropdown__empty-loading .el-loading-mask']
    for (const className of classNames) {
      const dom = document.querySelector(className)
      dom && dom.classList[status ? 'add' : 'remove']('show')
    }
  }

  showLoading (config: ILoadingConfig) {
    let { level } = config
    // 默认都是 app 级别的 loading
    level = level || LoadingLevel.APP
    AppModule.updateAppLoadingStatus({ level, status: true })
    let loadingTimer

    // app 和 select 级别的 loading 需要延时处理
    if ([LoadingLevel.APP, LoadingLevel.SELECT].includes(level)) {
      loadingTimer = setTimeout(() => {
        this.updateLoadingStatus(true, level as LoadingLevel)
        // 开始计时
        if (LoadingLevel.APP === level) if (!AppModule.loadingTime) AppModule.updateAppLoadingTime(Date.now())
        if (LoadingLevel.SELECT === level) if (!AppModule.selectLoadingTime) AppModule.updateSelectLoadingTime(Date.now())
      }, this.delayLoadingTime)
      this.loadings.push({ timer: loadingTimer, level })
    }

    return loadingTimer
  }

  hideLoading (config: ILoadingConfig): void {
    let { timer, level } = config
    // 默认都是 app 级别的 loading
    level = level || LoadingLevel.APP
    // 非 app 和 select 级别的 loading 不需要处理延时操作
    if (![LoadingLevel.APP, LoadingLevel.SELECT].includes(level as LoadingLevel)) {
      AppModule.updateAppLoadingStatus({ level, status: false })
      return
    }
    const { loadings, minLoadingTime } = this
    if (timer) {
      clearTimeout(timer)
      const index = loadings.findIndex(o => o.timer === timer)
      index !== -1 && loadings.splice(index, 1)
    }
    let startTime = AppModule.loadingTime
    if (LoadingLevel.SELECT === level) startTime = AppModule.selectLoadingTime
    let time = 0
    if (startTime) {
      const diff = Date.now() - startTime
      if (diff < minLoadingTime) time = minLoadingTime - diff
    }
    // 放在异步中执行是为了避免在刚请求完又发起请求的情况下两次 loading 的状况
    setTimeout(() => {
      // 没有带 loading 的接口处于 pending 状态 则关闭 loading
      if (loadings.filter(o => o.level === level).length === 0) {
        // 结束计时
        if (LoadingLevel.APP === level) AppModule.updateAppLoadingTime(0)
        if (LoadingLevel.SELECT === level)AppModule.updateSelectLoadingTime(0)
        AppModule.updateAppLoadingStatus({ level, status: false })
        this.updateLoadingStatus(false, level as LoadingLevel)
      }
    }, time)
  }

  clearLoading () {
    this.loadings = []
    // 结束计时
    AppModule.updateAppLoadingTime(0)
    AppModule.updateAppLoadingStatus({ level: LoadingLevel.NONE, status: false })
    this.updateLoadingStatus(false)
  }

  clearSelectLoading () {
    AppModule.updateAppLoadingStatus({ level: LoadingLevel.SELECT, status: false })
    this.loadings = this.loadings.filter(item => item.level !== LoadingLevel.SELECT)
  }
}

export default new Toast()
