/* eslint-disable camelcase */
import { DataRow } from '@/types/data'
import { ColumnInfo } from 'vxe-table'
import { attributeType } from '@/utils/const'

/* eslint-disable camelcase */
export type TableSetting = {
  user_id: string
  table_control: string
  display_fields_enabled: boolean
  all_fields: {
    display: boolean
    freeze: boolean,
    name: string
    title: string
  }[],
  display_fields: {
    display: boolean,
    freeze: boolean,
    index?: number,
    name: string,
    title: string
  }[],
  display_fields_json: string | null // { name: string, display: boolean }[]
  sort_enabled: boolean
  sort: any[],
  sort_json: string | null
  fields_width_json: string | null,
  changed_fields_width: any[],
  page_size: number | null,
  default_advanced_filter_id: string | null
}

export interface ITableRowArgs {
  row: DataRow;
  index: number;
}

export interface ITableCommonArgs {
  event?: any;
  $event?: any;
  column?: ColumnInfo;
  expanded?: boolean;
  row: DataRow;
}

export interface ITableRowConfig {
  isHover: boolean;
  keyField: string;
}

export interface ITableRadioConfig {
  [propName: string]: any;
}

export interface ITableCheckboxConfig {
  checkField: string;
}

export interface ITableVirtualScrollConfig {
  gt: number;
  oSize: number;
}

export interface ISetting {
  user_id: string;
  table_control: string;
  display_fields_enabled: boolean;
  all_fields: any[];
  display_fields: any[];
  display_fields_json: string | null;
  sort_enabled: boolean;
  sort: any[];
  sort_json: string | null;
  fields_width_json: string | null;
  changed_fields_width: any[];
  page_size: number | null;
  default_advanced_filter_id: string | null;
}

export type ColumnGroup = { type: 'groupColumn', title: string, children: any[] }
export type ColumnItem = { type: 'column', column: ColumnInfo }
export type RenderColumns = Array<ColumnGroup | ColumnItem>

export enum SelectionMode {
  None = 'none',
  Single = 'single',
  Multiple = 'multiple'
}

export enum TableOperation {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Clone = 'clone',
  New = 'new',
  More = 'more'
}

export enum TableOperationControl {
  Viewable = 'viewable',
  Editable = 'editable',
  Newable = 'newable',
  Deletable = 'deletable',
  Cloneable = 'cloneable'
}

export const tableOperationControls = [
  TableOperationControl.Viewable,
  TableOperationControl.Editable,
  TableOperationControl.Newable,
  TableOperationControl.Deletable,
  TableOperationControl.Cloneable
]

export const tableOperationMap = new Map([
  [TableOperationControl.Viewable, TableOperation.View],
  [TableOperationControl.Editable, TableOperation.Edit],
  [TableOperationControl.Newable, TableOperation.New],
  [TableOperationControl.Deletable, TableOperation.Delete],
  [TableOperationControl.Cloneable, TableOperation.Clone]
])

export enum SortType {
  None = 'none',
  Desc = 'desc',
  Asc = 'asc'
}

export interface ITableCellContent {
  text: string;
  href?: string;
  target?: string;
  fontColorScheme?: string;
  backColorScheme?: string;
  enabled?: boolean;
  visible?: boolean;
  rowChanged?: boolean;
}

export interface IDynamicAttr<T> {
  key: T;
  type: attributeType | attributeType[];
}

export const commonProps: Array<keyof ITableCellContent | IDynamicAttr<keyof ITableCellContent>> = ['href', 'target', 'fontColorScheme', 'backColorScheme', { key: 'visible', type: attributeType.BOOLEAN }, { key: 'enabled', type: attributeType.BOOLEAN }]

export interface ITableExtraStruct {
  showMoreOperation: boolean;
  allOperation: any[];
  content: Record<string, ITableCellContent>;
}

export const initTableExtraStruct: ITableExtraStruct = {
  showMoreOperation: false,
  allOperation: [],
  content: {}
}
