export enum popupLayoutType {
  DIALOG = 'dialog',
  DRAWER = 'drawer'
}

export enum attributeSymbol {
  VARIABLE = '{variable}',
  FUNCTION = '{function}',
  EVAL = '{eval}',
  COMMON = '{common}',
  COMMONWITHNULLARGS = '{commonWithNullArgs}',
  I18N = '{i18n}'
}

export enum filterOperator {
  EQ = 'eq',
  NE = 'ne',
  GT = 'gt',
  LT = 'lt',
  LE = 'le',
  LIKE = 'like',
  NOT_LIKE = 'nlike',
  LEFT_LIKE = 'llike',
  REGEXP_LIKE = 'rlike',
  IN = 'in',
  BETWEEN = 'between',
  SUB_QUERY = 'subQuery',
  IS_NULL = 'isNull'
}

export enum filterGroupOperator {
  EQ = 'eq', // 等于
  NOT_EQ = 'ne', // 不等于
  CONTAIN = 'like', // 包含
  NOT_CONTAIN = 'nlike', // 不包含
  LEFT_CONTAIN = 'llike', // 左包含
  RIGHT_CONTAIN = 'rlike', // 右包含
  EMPTY = 'isNull', // 为空
  NOT_EMPTY = 'isNotNull', // 不为空
  IN = 'in', // 可选
  NOT_IN = 'notIn', // 排除
  LT = 'lt', // 小于
  GT = 'gt', // 大于
  LE = 'le', // 小于等于
  GE = 'ge', // 大于等于
  BETWEEN = 'between',
  DATE_EQ = 'dateEq',
  DATE_GE = 'dateGe',
  DATE_GT = 'dateGt',
  DATE_LE = 'dateLe',
  DATE_LT = 'dateLt',
  DATE_BETWEEN = 'dateBetween',
}

export enum attributeType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  NUMBER_ARRAY = 'numbers', // 例如 pageSizes='20,50,100'
  STRING_ARRAY = 'strings', // 例如 fields='code,name'
  ARRAY = 'array',
  OBJECT = 'object',
  OBJECT_ARRAY = 'objects'
}

export enum rowPersist {
  DELETE = 'D',
  UPDATE = 'U',
  INSERT = 'I'
}

export enum layoutStatus {
  NEW = 'new',
  VIEW = 'view',
  EDIT = 'edit',
  EXPRESS_EDIT = 'expressEdit',
  HEADER_DETAIL_EDIT = 'headerDetailEdit',
  HEADER_DETAIL_NEW = 'headerDetailNew',
  ERROR = 'error'
}

export enum scriptResult {
  NOT_RUNNABLE = 'NotRunnable',
  CANCELED = 'canceled'
}

export const SCRIPT_CANCELED_FLAG = '__logwire_script_canceled__'

// js 函数运行时的 layout
export const SCRIPT_ENVIRONMENT = '__logwire_script_environment__'

export const SCRIPT_ORIGINAL_ENVIRONMENT = '__logwire_script_original_environment__'

// js 函数运行时的 layout，比较特殊，一般用于某些脚本需要指定某个 layout 作为运行环境时使用
export const SCRIPT_SPECIAL_ENVIRONMENT = '__logwire_script_special_environment__'

export const SCRIPT_SAVE_AND_NEW_EVENT = '__logwire_save_and_new_event__'

export const APP_LOGIN_LAYOUT_NAME = '__logwire_app_login_layout_name__'

export const START_ESCAPE_FLAG = '__logwire_start_escape_flag__'

export const CSRF_TOKEN = '_csrf_token_'

export const CHECKED_ARCHIVE_STORAGE = '_checked_archive_storage__'

export const SCREEN_SIZE_BREAK_POINTS = [600, 960, 1264, 1904]

export const EDIT_LAYOUT_DATASET_NAME = 'editingRow'

export const EXPRESS_EDIT_LAYOUT_DATASET_NAME = '__expressEditingRow__'

export const EDIT_LAYOUT_POPUP_NAME_SUFFIX = '__copy' // editLayout弹出层的页面名称后缀

export const TABLE_RELATED_DATASET_NAME = 'currentRow'

export const SYSTEM_DB_RESERVED_FIELDS = ['update_timestamp', 'insert_timestamp', 'version', 'id', 'insert_user_id', 'update_user_id']

export const QUICK_FILTER_ATTRIBUTE_MAP = {
  quickFilterOperator: 'operator',
  quickFilterSubQuery: 'subQueryName',
  quickFilterSubQueryFilterField: 'subQueryFilterField',
  quickFilterSubQueryFilterOperator: 'subQueryFilterOperator'
}

export enum operationType {
  SAVE = 'save',
  SAVE_AND_NEW = 'saveAndNew',
  UPDATE_AND_NEXT = 'updateAndNext',
  CANCEL = 'cancel',
  NONE = 'none'
}

export const EDIT_LAYOUT_SAVE_TRIGGER_MAP = {
  new: 'insert',
  edit: 'update',
  saveAndNew: 'insert-and-new',
  updateAndNext: 'update-and-next'
}

export enum notificationType {
  MESSAGE = 'message',
  TODO = 'todo'
}

export enum notificationHandledFlag {
  MESSAGE_READ = 'read_message',
  TODO_HANDLED = 'handle_todo'
}

export enum socketMessageType {
  NEW_MESSAGE = 'new_message',
  READ_MESSAGE = 'read_message',
  NEW_TODO = 'new_todo',
  HANDLE_TODO = 'handle_todo',
  ONGOING_TASK = 'ongoing_task',
  FINISH_TASK = 'finish_task',
  ONGOING_ACTION = 'ongoing_action',
  FINISH_ACTION = 'finish_action',
  CANCEL_ACTION = 'cancel_action',
  CUSTOM='custom'
}

export enum environmentType {
  DEV = 'dev',
  UAT = 'uat',
  PROD = 'prod'
}

export enum drawerCommand {
  OPEN = 'open',
  CLOSE = 'close'
}

export const BASE_FONT_SZIE = 78

export const CALCULATE_HEIGHT_ELEMENTS_CLASS_NAME = ['.lw-table--content', '.lw-list', '.lw-tree', '.lw-pivot-table']

export enum connector {
  AND = 'and',
  OR = 'or'
}

export enum conditionType {
  GROUP = 'group',
  CONDITION = 'condition'
}

export enum totalByType {
  CONUT = 'count',
  TRY_ESTIMATE = 'try-estimate',
  FORCE_NONE = 'force-none'
}

export enum calculationType {
  SUM = 'sum',
  SUBTRACT = 'subtract',
  MULTIPLICATION = 'multiplication',
  DIVISION = 'division'
}

export const filterGroupOperatorList = ['eq', 'ne', 'like', 'nlike', 'llike', 'rlike', 'isNull', 'isNotNull', 'in', 'notIn', 'lt', 'gt', 'le', 'ge', 'between', 'dateEq', 'dateLt', 'dateLe', 'dateGt', 'dateGe', 'dateBetween']

export const fieldOperations = {
  // lw-text 特殊情况： 可选,排除
  'lw-text': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.CONTAIN, filterGroupOperator.NOT_CONTAIN,
    filterGroupOperator.LEFT_CONTAIN, filterGroupOperator.RIGHT_CONTAIN, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.IN, filterGroupOperator.NOT_IN, filterGroupOperator.LT, filterGroupOperator.GT, filterGroupOperator.LE, filterGroupOperator.GE],
  'lw-textarea': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.CONTAIN, filterGroupOperator.NOT_CONTAIN,
    filterGroupOperator.LEFT_CONTAIN, filterGroupOperator.RIGHT_CONTAIN, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.IN, filterGroupOperator.NOT_IN, filterGroupOperator.LT, filterGroupOperator.GT, filterGroupOperator.LE, filterGroupOperator.GE],
  'lw-number': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.LT, filterGroupOperator.GT, filterGroupOperator.LE, filterGroupOperator.GE],
  'lw-select': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-auto-complete': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-cascader': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-recursive': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  // 区分单选 和 多选的lw-choice
  'lw-choice': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-radio': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-choice-multiple': [filterGroupOperator.CONTAIN, filterGroupOperator.NOT_CONTAIN, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY],
  'lw-checkbox': [filterGroupOperator.EQ],
  'lw-switch': [filterGroupOperator.EQ],
  'lw-date': [filterGroupOperator.EQ, filterGroupOperator.BETWEEN, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.GE, filterGroupOperator.LE, filterGroupOperator.GT, filterGroupOperator.LT],
  'lw-time': [filterGroupOperator.EQ, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.GE, filterGroupOperator.LE, filterGroupOperator.GT, filterGroupOperator.LT],
  'lw-date-time': [filterGroupOperator.DATE_EQ, filterGroupOperator.DATE_BETWEEN, filterGroupOperator.DATE_GE, filterGroupOperator.DATE_LE, filterGroupOperator.DATE_GT, filterGroupOperator.DATE_LT, filterGroupOperator.EQ, filterGroupOperator.BETWEEN, filterGroupOperator.NOT_EQ, filterGroupOperator.EMPTY, filterGroupOperator.NOT_EMPTY,
    filterGroupOperator.GE, filterGroupOperator.LE, filterGroupOperator.GT, filterGroupOperator.LT]
}

export const dateOperators = [
  filterGroupOperator.DATE_EQ,
  filterGroupOperator.DATE_GE,
  filterGroupOperator.DATE_GT,
  filterGroupOperator.DATE_LT,
  filterGroupOperator.DATE_LE,
  filterGroupOperator.DATE_BETWEEN
]

export const fieldAttrs = {
  'lw-form-item': ['field', 'is', 'title'],
  'lw-text': ['placeholder', 'maxLength', 'prepend', 'append'],
  'lw-textarea': ['placeholder', 'maxLength', 'rows'],
  'lw-number': ['prepend', 'append', 'formatEnabled', 'positiveOnly', 'decimalScale', 'thousandsGroupStyle', 'percentage'],
  'lw-select': ['dropDownQuery', 'onDropDownRetrieve', 'dropDownPageSizes', 'keywordFieldsInDropDown', 'dropDownMasterFilter',
    'displayTemplate', 'displayField', 'valueFieldInDropDown', 'labelFieldInDropDown', 'dropDownRowTemplate', 'dropDownHeaderTemplate', 'onChange'],
  'lw-auto-complete': ['dropDownQuery', 'onDropDownRetrieve', 'dropDownPageSizes', 'keywordFieldsInDropDown', 'dropDownMasterFilter',
    'valueFieldInDropDown', 'dropDownRowTemplate', 'dropDownHeaderTemplate'],
  'lw-cascader': ['levels',
    'dropDownQuery1', 'dropDownRetrieveAction1', 'dropDownMasterFilter1', 'valueFieldInDropDown1', 'labelFieldInDropDown1',
    'dropDownQuery2', 'dropDownRetrieveAction2', 'dropDownMasterFilter2', 'valueFieldInDropDown2', 'labelFieldInDropDown2', 'parentIdFieldInDropDown2',
    'dropDownQuery3', 'dropDownRetrieveAction3', 'dropDownMasterFilter3', 'valueFieldInDropDown3', 'labelFieldInDropDown3', 'parentIdFieldInDropDown3',
    'dropDownQuery4', 'dropDownRetrieveAction4', 'dropDownMasterFilter4', 'valueFieldInDropDown4', 'labelFieldInDropDown4', 'parentIdFieldInDropDown4',
    'dropDownQuery5', 'dropDownRetrieveAction5', 'dropDownMasterFilter5', 'valueFieldInDropDown5', 'labelFieldInDropDown5', 'parentIdFieldInDropDown5'
  ],
  'lw-recursive': ['dropDownQuery', 'dropDownRetrieveAction', 'dropDownMasterFilter', 'valueFieldInDropDown', 'labelFieldInDropDown', 'parentIdFieldInDropDown'],
  'lw-choice': ['category', 'multiple', 'filteredChoices', 'dimension'],
  'lw-radio': ['category', 'filteredChoices', 'dimension'],
  'lw-checkbox': [],
  'lw-switch': [],
  'lw-date': ['type'],
  'lw-time': ['type', 'selectStart', 'selectEnd', 'selectStep', 'selectMin', 'selectMax', 'selectableRange'],
  'lw-date-time': ['defaultTime']
}

export enum SemanticColorSchemes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error'
}

export enum IntuitiveColorSchemes {
  red = 'red',
  orange = 'orange',
  yellow = 'yellow ',
  lime = 'lime',
  green = 'green',
  turquoise = 'turquoise',
  cyan = 'cyan',
  cobalt = 'cobalt ',
  blue = 'blue',
  violet = 'violet',
  magenta = 'magenta ',
  crimson = 'crimson',
  gray = 'gray'
}

export const SemanticColorMainList = ['primary', 'secondary', 'success', 'warn', 'error']

export const IntuitiveColorMainList = ['red', 'orange', 'yellow', 'lime', 'green', 'turquoise', 'cyan', 'cobalt', 'blue', 'violet', 'magenta', 'crimson', 'gray']

export const IntuitiveColorAllList = [
  'red-a', 'red-b', 'red-c', 'red-d', 'red-e',
  'orange-a', 'orange-b', 'orange-c', 'orange-d', 'orange-e',
  'yellow-a', 'yellow-b', 'yellow-c', 'yellow-d', 'yellow-e',
  'lime-a', 'lime-b', 'lime-c', 'lime-d', 'lime-e',
  'green-a', 'green-b', 'green-c', 'green-d', 'green-e',
  'turquoise-a', 'turquoise-b', 'turquoise-c', 'turquoise-d', 'turquoise-e',
  'cyan-a', 'cyan-b', 'cyan-c', 'cyan-d', 'cyan-e',
  'cobalt-a', 'cobalt-b', 'cobalt-c', 'cobalt-d', 'cobalt-e',
  'blue-a', 'blue-b', 'blue-c', 'blue-d', 'blue-e',
  'violet-a', 'violet-b', 'violet-c', 'violet-d', 'violet-e',
  'magenta-a', 'magenta-b', 'magenta-c', 'magenta-d', 'magenta-e',
  'crimson-a', 'crimson-b', 'crimson-c', 'crimson-d', 'crimson-e',
  'gray-a', 'gray-b', 'gray-c', 'gray-d', 'gray-e'
]

export const IntuitiveColorLightListWithWhiteFont = [
  'red-a', 'red-b', 'red-c', 'red-d',
  'orange-a', 'orange-b', 'orange-c',
  'yellow-a', 'yellow-b',
  'lime-a', 'lime-b',
  'green-a', 'green-b', 'green-c',
  'turquoise-a', 'turquoise-b', 'turquoise-c',
  'cyan-a', 'cyan-b', 'cyan-c',
  'cobalt-a', 'cobalt-b', 'cobalt-c', 'cobalt-d',
  'blue-a', 'blue-b', 'blue-c', 'blue-d',
  'violet-a', 'violet-b', 'violet-c', 'violet-d',
  'magenta-a', 'magenta-b', 'magenta-c', 'magenta-d',
  'crimson-a', 'crimson-b', 'crimson-c', 'crimson-d',
  'gray-a', 'gray-b', 'gray-c', 'gray-d'
]

export const IntuitiveColorLightListWithBlackFont = [
  'red-e',
  'orange-d', 'orange-e',
  'yellow-c', 'yellow-d', 'yellow-e',
  'lime-c', 'lime-d', 'lime-e',
  'green-d', 'green-e',
  'turquoise-d', 'turquoise-e',
  'cyan-d', 'cyan-e',
  'cobalt-e',
  'blue-e',
  'violet-e',
  'magenta-e',
  'crimson-e',
  'gray-e'
]

export const IntuitiveColorDarkListWithWhiteFont = [
  'red-b', 'red-c', 'red-d', 'red-e',
  'orange-c', 'orange-d', 'orange-e',
  'yellow-d', 'yellow-e',
  'lime-d', 'lime-e',
  'green-c', 'green-d', 'green-e',
  'turquoise-c', 'turquoise-d', 'turquoise-e',
  'cyan-c', 'cyan-d', 'cyan-e',
  'cobalt-b', 'cobalt-c', 'cobalt-d', 'cobalt-e',
  'blue-b', 'blue-c', 'blue-d', 'blue-e',
  'violet-b', 'violet-c', 'violet-d', 'violet-e',
  'magenta-b', 'magenta-c', 'magenta-d', 'magenta-e',
  'crimson-b', 'crimson-c', 'crimson-d', 'crimson-e',
  'gray-b', 'gray-c', 'gray-d', 'gray-e'
]

export const IntuitiveColorDarkListWithBlackFont = [
  'red-a',
  'orange-a', 'orange-b',
  'yellow-a', 'yellow-b', 'yellow-c',
  'lime-a', 'lime-b', 'lime-c',
  'green-a', 'green-b',
  'turquoise-a', 'turquoise-b',
  'cyan-a', 'cyan-b',
  'cobalt-a',
  'blue-a',
  'violet-a',
  'magenta-a',
  'crimson-a',
  'gray-a'
]

export const commonSemanticPartitions = {
  TODAY: '1d',
  LAST_WEEK: '1w',
  LAST_MONTH: '1m',
  LAST_3_MONTH: '3m',
  LAST_6_MONTH: '6m',
  LAST_YEAR: '1y'
}

export enum filterType {
  PARTITION = 'partition',
  ADVANCED = 'advanced',
  KEYWORDS = 'keywords',
  QUICK = 'quick'
}

export enum attributeRunType {
  ONLY_SCRIPT = 'onlyScript',
  ONLY_STATIC = 'onlyStatic',
  AUTO = 'auto'
}

export enum RequestError {
  RecordNotFound = 'Record not found'
}

export enum UnprocessableComponent {
  Tags = 'lw-tags',
  Label = 'lw-label',
  Image = 'lw-image'
}

export const unprocessableComponentList = [UnprocessableComponent.Tags, UnprocessableComponent.Label, UnprocessableComponent.Image]

// 文件类型
export const enum showPreviewType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio'
}

export const imgType = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'webp', 'svg']

export const videoType = ['mp4', 'ogg', 'webm', '3gp', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'rmvb']

export const audioType = ['mp3', 'wav', 'aac', 'wma', 'm4a', 'flac', 'aiff', 'ape', 'dsd ']

export const STORAGE_RECORD_TIME = '__logwire_storage_record_time__'
