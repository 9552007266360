import { Vue, Component } from 'vue-property-decorator'
import { DataRow } from '@/types/data'
import { AssociatedContext, LayoutComponent, LayoutContext } from '@/types/layout'

import ChoiceFormatter from '../../form-item/choice/formatter'
import TextFormatter from '../../form-item/text/formatter'
import DateFormatter from '../../form-item/date/formatter'
import DateTimerFormatter from '../../form-item/dateTime/formatter'
import NumberFormatter from '../../form-item/number/formatter'
import TimeFormatter from '../../form-item/time/formatter'
import TimestampFormatter from '../../form-item/timestamp/formatter'
import SelectFormatter from '../../form-item/select/formatter'
import RadioFormatter from '../../form-item/radio/formatter'
import RateFormatter from '../../form-item/rate/formatter'
import { isFunction, isString, isUndefined } from 'lodash'
import Args from '@/models/Args'
import { getFinalAttributeValue } from '@/utils/layout'
import { commonProps } from '@/types/table'

const formatterMap = {
  'lw-date': DateFormatter,
  'lw-date-time': DateTimerFormatter,
  'lw-choice': ChoiceFormatter,
  'lw-text': TextFormatter,
  'lw-number': NumberFormatter,
  'lw-time': TimeFormatter,
  'lw-timestamp': TimestampFormatter,
  'lw-select': SelectFormatter,
  'lw-radio': RadioFormatter,
  'lw-rate': RateFormatter
}

export interface IStructParams {
  row: DataRow;
  column: LayoutComponent;
  context: LayoutContext;
  associatedContext: AssociatedContext;
}

@Component
class ColumnContent extends Vue {
  // 根据组件展示内容需要的属性获取行内对应属性计算出的动态结果
  // 动态属性和静态属性区分，避免响应不合理的属性计算
  getStruct ({ row, column, context, associatedContext }: IStructParams) {
    const { dynamicAttrs = [], staticAttrs = [] } = formatterMap[column.is] || {}

    // 动态属性
    let struct = dynamicAttrs.concat(commonProps)?.reduce((finnal: Record<string, any>, currentKey: any) => {
      // 有对应的属性再去计算，尽可能减少计算消耗
      const key = isString(currentKey) ? currentKey : currentKey.key
      const type = isString(currentKey) ? undefined : currentKey.type
      if (!isUndefined(column[key])) {
        const result = getFinalAttributeValue(
          key,
          column,
          new Args(context, { row }),
          associatedContext,
          type
        )
        finnal[key] = result
      }
      return finnal
    }, {})

    // 静态属性
    struct = staticAttrs?.reduce((finnal: Record<string, any>, currentKey: string) => {
      // 有对应的属性再去计算，尽可能减少计算消耗
      if (!isUndefined(column[currentKey])) {
        finnal[currentKey] = column[currentKey]
      }
      return finnal
    }, struct)

    return struct
  }

  getTableCell (params: IStructParams) {
    const { column, row, context } = params
    let value = row.currentData[column.field]
    const { is } = column
    const formatter = formatterMap[is] || {}
    // 根据行和列计算出所需要的属性结构
    const struct = this.getStruct(params)
    // 少数组件可能会用到 namespace
    const namespace = context.getNamespace()
    // 部分组件 inputValue 会有特殊处理逻辑
    const { formatValue, formatContent } = formatter
    value = isFunction(formatValue) ? formatValue({ value, context, ...struct }) : value
    const result = isFunction(formatContent) ? formatContent({ ...column, value, namespace, context, row, ...struct }) : value
    return {
      value: result,
      ...struct
    }
  }

  getColumnCellContent (row: DataRow, column: LayoutComponent) {
    // 这里会逐列根据组件以及组件属性，获取属性最后的动态计算结果
    return this.getTableCell({ column, row, context: (this as any).context, associatedContext: (this as any).associatedContext })
  }
}

export default ColumnContent
