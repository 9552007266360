
























import { Component, InjectReactive, Provide, ProvideReactive } from 'vue-property-decorator'
import Vue from 'vue'
import { EXPRESS_EDIT_LAYOUT_DATASET_NAME } from '@/utils/const'
import { LayoutModule } from '@/store/modules/layout'
import { formatDataRow } from '@/utils/data'
import { LwForm } from '@/types/form'

@Component({ name: 'QuickEditForTable' })
export default class QuickEditForTable extends Vue {
  @ProvideReactive() evaluatingBase = 'tableRow'
  @InjectReactive() encodedLayoutName!: string;

  editField = ''
  checked = false
  updateRowsLength = 0
  component: any = null

  $refs!: {
    form: LwForm
  }

  get formComponent () {
    if (!this.component) return {}
    return {
      is: 'lw-form',
      dataSet: EXPRESS_EDIT_LAYOUT_DATASET_NAME,
      components: [this.component]
    }
  }

  get editingRow () {
    return LayoutModule.data[this.encodedLayoutName].dataSet[EXPRESS_EDIT_LAYOUT_DATASET_NAME]?.rows[0]
  }

  show (payload: { component: any, updateRowsLength: number, currentData: Record<string, any> }) {
    const { component, updateRowsLength, currentData } = payload
    this.component = component
    this.editField = this.component.field
    this.updateRowsLength = updateRowsLength
    const editingData = JSON.parse(JSON.stringify(currentData))
    LayoutModule.loadLayoutDataSet({
      layoutName: this.encodedLayoutName,
      data: {
        dataSetName: EXPRESS_EDIT_LAYOUT_DATASET_NAME,
        rows: [formatDataRow(editingData)]
      }
    })
  }

  hide () {
    LayoutModule.removeDataSet({ layoutName: this.encodedLayoutName, dataSetName: EXPRESS_EDIT_LAYOUT_DATASET_NAME })
    this.component = null
    this.editField = ''
    this.updateRowsLength = 0
  }

  async handleOperate (type: string) {
    if (type === 'confirm') {
      // 表单校验
      await this.$refs.form.validateData()
    }
    // handle-operate 事件内，只有 type === confirm 时才会触发赋值
    this.$emit('handle-operate', { batch: this.checked, type, val: this.editingRow.currentData[this.editField], row: this.editingRow.currentData })
    this.checked = false
  }
}
