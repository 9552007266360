import { isBoolean, isNil } from 'lodash'
import { attributeForce2Boolean } from '@/utils/layout'

export interface ITextContentStruct {
  value: any;
  prepend: string;
  append: string;
  password: boolean;
}

export const dynamicAttrs: Array<keyof ITextContentStruct> = ['prepend', 'append']

export const staticAttrs: Array<keyof ITextContentStruct> = ['password']

const formatContent = (struct: ITextContentStruct) => {
  let { value, prepend = '', append = '', password = false, ...component } = struct

  if (!isBoolean(password)) {
    password = attributeForce2Boolean('password', { ...component, password }, false)
  }

  const result = `${prepend}${isNil(value) ? '' : value}${append}`

  return password
    ? value
      ? '***'
      : ''
    : result
}

export default {
  formatContent,
  dynamicAttrs,
  staticAttrs
}
