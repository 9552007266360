import { formatDate } from 'element-ui/src/utils/date-util'
import logwire from '@/logwire'

export interface IDateContentStruct {
  value: any;
  type?: string;
}

const DATE_TYPES = ['datetime', 'datetimerange']

export const dynamicAttrs: Array<keyof IDateContentStruct> = ['type']

export const getDateTimeFormat = () => {
  const formatDate = logwire.store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  const formatTime = logwire.store.getConfig('core.i18n.time-format') === '24-hour'
    ? 'HH:mm:ss'
    : 'hh:mm:ss A'
  return formatDate + ' ' + formatTime
}

const formatValue = (params: IDateContentStruct) => {
  const { type = 'datetime', value } = params
  const finalType = DATE_TYPES.includes(type) ? type : 'datetime'
  const isDateTimeRange = finalType === 'datetimerange'
  return value
    ? isDateTimeRange
      ? value.map((dateTime: string) => new Date(dateTime))
      : new Date(value)
    : isDateTimeRange
      ? []
      : value
}

const formatContent = ({ value }: IDateContentStruct) => {
  return formatDate(value, getDateTimeFormat())
}

export default {
  formatValue,
  formatContent,
  dynamicAttrs
}
