/* eslint-disable standard/no-callback-literal */
import { PanelConfig } from '@/types/layout'
import router from '@/router'
import { getUserDashboard } from '@/http/api'
import eventbus from '@/utils/event'
import {
  getDecodedName,
  getGlobalI18nMessage,
  getLayoutNameOutsideComponent,
  getNamespacedLayoutName,
  proceedAfterEditingDataSetCheck,
  replaceParams,
  toLoginLayout, warnDeprecatedApi
} from '@/utils/layout'
import { LayoutModule } from '@/store/modules/layout'
import LayoutArgs from '@/models/LayoutArgs'
import { popupLayoutType } from '@/utils/const'
import Args from '@/models/Args'
import { Message, MessageBox } from 'element-ui'
import _ from 'lodash'
import logwire from '.'
import { LocalModule } from '@/store/modules/local'
import * as Echarts from 'echarts'
import { ElMessageComponent, ElMessageOptions, MessageType } from 'element-ui/types/message'
import { _addUiWebSocketListener, _removeUiWebSocketListener } from '@/utils/common'
import Toast from '@/utils/toast'
import { LoadingLevel } from '@/http'
import { AppModule } from '@/store/modules/app'

interface RouteParams{
  layoutName: string;
  onClose?: (args: Args) => void;
  onOpen?: (args: Args) => void;
  params?: Record<string, any>;
  [propName: string]: any;
}

interface AlertParams{
  title?: string;
  message: string;
  distinguishCancelAndClose?: boolean
}

interface ConfirmParamsWithCallback extends AlertParams {
  cancelButtonText?: string, // 可选参数，取消按钮的文本
  confirmButtonText?: string, // 可选参数，保存按钮的文本
  abandonButtonText?: string // 可选参数，放弃按钮的文本
  callback: (content: 'confirm' | 'cancel' | 'abandon') => void;
}

interface AlertParamsWithCallback extends AlertParams{
  callback: (content: string) => void;
}

function getLayoutArgs (layoutConfig: any): RouteParams {
  let config = {} as RouteParams
  // 假如只写了一个 layoutName
  if (typeof layoutConfig === 'string') {
    config = { layoutName: layoutConfig }
  } else if (layoutConfig instanceof LayoutArgs) { // 使用 ctx.newLayoutArgs 生成的实例
    config.layoutName = layoutConfig.getLayoutName()
    config.onClose = layoutConfig.getOnClose()
    config.onOpen = layoutConfig.getOnOpen()
    config.params = layoutConfig.getParams()
    config.width = layoutConfig.getWidth()
    config.modal = layoutConfig.getModal()
  } else {
    // 直接传符合要求的对象，一般用于内部代码
    config = layoutConfig
  }
  config.layoutName = getNamespacedLayoutName(config.layoutName)
  return config
}

function getPanelParam (param: any): Record<string, any> {
  let config = {} as PanelConfig
  // 假如只写panel的名称
  if (typeof param === 'string') {
    config = { name: param }
  } else {
    // 直接传符合要求的对象，一般用于内部代码
    config = param
  }
  config.name = getLayoutNameOutsideComponent() + `.${config.name}`
  return config
}

/**
 * 在弹出框中打开一个页面
 * @param {string} layoutName 需要打开的 layout 名
 * */
function openLayoutInDialog (layoutName: string): void;
/**
 * 在弹出框中打开一个页面
 * @param {RouteParams} param 打开 layout 相关参数，这种参数一般只有内部代码会使用
 * */
function openLayoutInDialog (param: RouteParams): void;
/**
 * 在弹出框中打开一个页面
 * @param {LayoutArgs} param 打开 layout 相关参数，使用 ctx 生成的标准 layoutArgs
 * */
function openLayoutInDialog (param: LayoutArgs): void;
function openLayoutInDialog (param: any): any {
  const callback = () => {
    const layoutArgs = getLayoutArgs(param)
    const sourceLayoutName = getLayoutNameOutsideComponent()
    if (layoutArgs.modal === false) {
      // 要确保先关闭存在的无蒙版的弹窗或抽屉，再打开一个新的无蒙版的弹窗或抽屉
      new Promise((resolve, reject) => {
        const payload = {
          layoutName: sourceLayoutName,
          hasPopupWithOutModal: false,
          resolve,
          reject
        }
        eventbus.$emit('close-popup-without-modal', payload)
        if (!payload.hasPopupWithOutModal) { // 如果为 false ，说明没有一个 Popup 是无蒙版的
          resolve('')
        }
      }).then(() => {
        eventbus.$emit(`${sourceLayoutName}.show-popup-layout`, Object.assign({ type: popupLayoutType.DIALOG, sourceLayoutName: getLayoutNameOutsideComponent() }, layoutArgs))
      }).catch(() => {
        const { error } = message
        error && error(logwire.ui.getI18nMessage('client.tips.only-one-unmasked-popup-can-exist', [], 'core'))
      })
    } else {
      eventbus.$emit(`${sourceLayoutName}.show-popup-layout`, Object.assign({ type: popupLayoutType.DIALOG, sourceLayoutName: getLayoutNameOutsideComponent() }, layoutArgs))
    }
  }
  callback()
}

/**
 * 在抽屉中打开一个页面
 * @param {string} layoutName 需要打开的 layout 名
 * */
function openLayoutInDrawer (layoutName: string): void;
/**
 * 在抽屉中打开一个页面
 * @param {RouteParams} param 打开 layout 相关参数
 * */
function openLayoutInDrawer (param: RouteParams): void;
/**
 * 在抽屉中打开一个页面
 * @param {LayoutArgs} param 打开 layout 相关参数，使用 ctx 生成的标准 layoutArgs
 * */
function openLayoutInDrawer (param: LayoutArgs): void;
function openLayoutInDrawer (param: any): any {
  const callback = () => {
    const layoutArgs = getLayoutArgs(param)
    const sourceLayoutName = getLayoutNameOutsideComponent()
    if (layoutArgs.modal === false) {
      // 要确保先关闭存在的无蒙版的弹窗或抽屉，再打开一个新的无蒙版的弹窗或抽屉
      new Promise((resolve, reject) => {
        const payload = {
          layoutName: sourceLayoutName,
          hasPopupWithOutModal: false,
          resolve,
          reject
        }
        eventbus.$emit('close-popup-without-modal', payload)
        if (!payload.hasPopupWithOutModal) { // 如果为 false ，说明没有一个 Popup 是无蒙版的
          resolve('')
        }
      }).then(() => {
        eventbus.$emit(`${sourceLayoutName}.show-popup-layout`, Object.assign({ type: popupLayoutType.DRAWER, sourceLayoutName }, layoutArgs))
      }).catch(() => {
        const { error } = message
        error && error(logwire.ui.getI18nMessage('client.tips.only-one-unmasked-popup-can-exist', [], 'core'))
      })
    } else {
      eventbus.$emit(`${sourceLayoutName}.show-popup-layout`, Object.assign({ type: popupLayoutType.DRAWER, sourceLayoutName }, layoutArgs))
    }
  }
  callback()
}

/**
 * 参数可以是字符串，或者 { name：panelName, width: px 或者 百分比 }
 */
function openPanelInDrawer (panelName: string) : void
function openPanelInDrawer (param: any) : void {
  const callback = () => {
    const currentLayout = getLayoutNameOutsideComponent()
    const args = getPanelParam(param)
    /**
     * 没有遮罩的抽屉 不论是 panel 还是 layout 都只允许打开一个
     * 每次打开前会先关掉之前打开的无遮罩抽屉
     * 为了防止关闭掉当前的 layout,会将当前 layout 的名字传递出去
     */
    if (args.modal === false) {
      const layoutName = getLayoutNameOutsideComponent()
      // 要确保先关闭再打开
      new Promise((resolve, reject) => {
        const payload = {
          layoutName,
          hasPopupWithOutModal: false,
          resolve,
          reject
        }
        eventbus.$emit('close-popup-without-modal', payload)
        if (!payload.hasPopupWithOutModal) {
          resolve('')
        }
      }).then(() => {
        eventbus.$emit(`${currentLayout}.show-panel`, Object.assign({ type: popupLayoutType.DRAWER, sourceLayoutName: getLayoutNameOutsideComponent() }, args))
      }).catch(() => {
        const { error } = message
        error && error(logwire.ui.getI18nMessage('client.tips.only-one-unmasked-popup-can-exist', [], 'core'))
      })
    } else {
      eventbus.$emit(`${currentLayout}.show-panel`, Object.assign({ type: popupLayoutType.DRAWER, sourceLayoutName: getLayoutNameOutsideComponent() }, args))
    }
  }
  callback()
}

function openPanelInDialog (panelName: string) : void;
function openPanelInDialog (panelName: PanelConfig) : void;
function openPanelInDialog (param: any) : void {
  const callback = () => {
    const currentLayout = getLayoutNameOutsideComponent()
    const args = getPanelParam(param)
    if (args.modal === false) {
      // 要确保先关闭再打开
      new Promise((resolve, reject) => {
        const payload = {
          layoutName: currentLayout,
          hasPopupWithOutModal: false,
          resolve,
          reject
        }
        eventbus.$emit('close-popup-without-modal', payload)
        if (!payload.hasPopupWithOutModal) {
          resolve('')
        }
      }).then(() => {
        eventbus.$emit(`${currentLayout}.show-panel`, Object.assign({ type: popupLayoutType.DIALOG, sourceLayoutName: getLayoutNameOutsideComponent() }, args))
      }).catch(() => {
        const { error } = message
        error && error(logwire.ui.getI18nMessage('client.tips.only-one-unmasked-popup-can-exist', [], 'core'))
      })
    } else {
      eventbus.$emit(`${currentLayout}.show-panel`, Object.assign({ type: popupLayoutType.DIALOG, sourceLayoutName: getLayoutNameOutsideComponent() }, args))
    }
  }
  callback()
}

/**
 * 切换页面
 * @param {object} layoutName 跳转的 layout
 * */
function pushSwitch (layoutName: string): void;
/**
 * 切换页面
 * @param {RouteParams} param 参数对象
 * */
function pushSwitch (param: RouteParams): void;
/**
 * 切换页面
 * @param {LayoutArgs} param 使用 ctx.newLayoutArgs 生成的 layout 参数
 * */
function pushSwitch (param: LayoutArgs): void;
function pushSwitch (param: any): void {
  const currentLayout = getLayoutNameOutsideComponent()
  const callback = () => {
    const layoutConfig = getLayoutArgs(param)
    const { currentRoute } = router
    const { params: { layoutName }, query } = currentRoute
    const config = { name: 'layout', params: { layoutName: layoutConfig.layoutName }, query: layoutConfig.params }

    Message.closeAll() // 切换页面时，关闭所有 Message 弹窗
    // 登录页跳转到其他页面采用 replace 的形式
    param.noHistory
      ? router.replace(config)
      : router.push(config)
  }
  // 通知当前 layout，准备离开了，将后续的逻辑传递过去进行处理
  eventbus.$emit(`${currentLayout}.leave`, { callback })
}

/* 以下几个方法用法参考 element 的 Message 和 MessageBox */
function alert (message: string): void;
function alert (options: AlertParams): void;
function alert (param: any): void {
  typeof param === 'string'
    ? MessageBox.alert(param)
    : MessageBox.alert(param.message)
}

/**
 * 增加了三元操作符，但是没有修改文档，仅用于用户授权页，由平台自己控制是否弹出“未保存确认”的弹窗
 * 如果哪天项目人员有三元操作的要求，再考虑暴露文档以及确定名称
 * @param message
 */
function confirm (message: string): void;
function confirm (options: ConfirmParamsWithCallback): void;
function confirm (param: ConfirmParamsWithCallback | string): void {
  const opts: ConfirmParamsWithCallback = param instanceof Object
    ? { ...param, callback: (result) => { MessageBox.close(); param.callback && param.callback(result) } }
    : { message: param, callback: () => MessageBox.close() }
  const h = eventbus.$createElement
  MessageBox({
    message: h('div', { class: 'lw-confirm-box' }, [
      h('div', { class: 'lw-confirm-title' }, [
        h('div', { class: 'lw-confirm-message' }, opts.title),
        h('i', { class: 'el-icon-close', on: { click: () => opts.callback('cancel') } })
      ]),
      h('div', { class: 'lw-confirm-message' }, [
        opts.message || undefined
      ]),
      h('div', { class: 'lw-confirm-operations' }, [
        h('button', { class: 'el-button el-button--primary el-button--mini', on: { click: () => opts.callback('confirm') } }, opts.confirmButtonText || getGlobalI18nMessage('core', 'client.common.confirm')),
        opts.abandonButtonText && h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: () => opts.callback('abandon') } }, opts.abandonButtonText),
        h('button', { class: 'el-button el-button--secondary el-button--mini', on: { click: () => opts.callback('cancel') } }, opts.cancelButtonText || getGlobalI18nMessage('core', 'client.common.cancel'))
      ])
    ]),
    showConfirmButton: false
  })
}

function prompt (message: string): void;
function prompt (options: AlertParamsWithCallback): void;
function prompt (param: any): void {
  typeof param === 'string'
    ? MessageBox.prompt(param)
    : MessageBox.prompt(param.message, param)
}

type ErrorMessageOptions = { type: 'error', message: string, duration?: number, stackTrace?: string, traceId?: string, request?: string }
type NormalMessageOptions = { type: Exclude<MessageType, 'error'> | undefined, message: string, duration?: number }
interface Message {
  (message: string): void;
  (options: ErrorMessageOptions | NormalMessageOptions): void;
  (param: any): void;
  success?: Message;
  warning?: Message;
  info?: Message;
  error?: Message;
}

export const timerIdMap: Map<string, number[]> = new Map()

export const messageInstancesIdMap: Map<string, ElMessageComponent & { id: string }> = new Map()
const message: Message = function (param: ErrorMessageOptions | NormalMessageOptions | string): void {
  let option = {} as ElMessageOptions
  let requestUrl: string | undefined
  // 普通提示
  if (_.isString(param)) {
    option.message = param
  }
  if (_.isObject(param)) {
    option = { ...param }
    if (param.type === 'error') {
      // error 类型的消息不允许自动关闭，必须手动关闭
      const { message: messageInfo, stackTrace, traceId, request } = param
      requestUrl = request
      if (requestUrl) {
        const existInstance = messageInstancesIdMap.get(requestUrl) || messageInstancesIdMap.get(messageInfo)
        if (existInstance) {
          existInstance.close()
        }
      }
      option.duration = 0
      // 如果 error 信息携带有 堆栈信息等详情，则展示详情按钮
      const h = eventbus.$createElement
      option.onClose = function (vm: ElMessageComponent) {
        messageInstancesIdMap.delete(_.get(vm, 'id') as unknown as string)
        messageInstancesIdMap.delete(messageInfo)
        if (requestUrl) messageInstancesIdMap.delete(requestUrl)
      }
      let detailText = getGlobalI18nMessage('core', 'client.common.detail')
      if (detailText === 'client.common.detail') detailText = 'Details'
      option.message = h('div', { class: 'el-message__content error' }, [
        h('div', { style: { paddingRight: '10px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }, messageInfo as string),
        h('span', {
          on: {
            click: () => eventbus.$emit('show-error-detail', {
              message: messageInfo,
              stackTrace,
              traceId
            })
          }
        },
        detailText)
      ])
    }
  }
  // 所有信息都带 关闭按钮
  option.showClose = true
  option.offset = 60
  if (logwire.store.getConfig('theme') && logwire.store.getConfig('theme').mode === 'dark') {
    option.customClass = 'el-message-dark'
  }
  const instance = Message(option) as ElMessageComponent & { id: string }

  if (option.type === 'error') {
    messageInstancesIdMap.set(instance.id, instance)
  }
  if (requestUrl) { // 对于后端请求产生的报错，增加弹窗ID 和 Message 的唯一性
    messageInstancesIdMap.set(requestUrl, instance)
    messageInstancesIdMap.set(_.get(param, 'message') as unknown as string, instance)
  }
};

// 为 logwire.ui.message 添加几个便捷方法
(['success', 'warning', 'info', 'error'] as const).forEach((type) => {
  message[type] = (content: string | ElMessageOptions) => {
    const option: ElMessageOptions = {
      type,
      message: _.isString(content) ? content : content.message
    }
    if (_.isObject(content) && content.duration) {
      option.duration = content.duration
    }
    message(option)
  }
})

const logwireSetTimeout = (callback: () => void, delay: number) => {
  const layoutName = getLayoutNameOutsideComponent()
  const currentTimers = timerIdMap.get(layoutName) || []
  // 如果在 timeout 里面重复调用 timeout，那么始终保留一个 timer，已经执行完成的 timer 则自动清理掉
  const timer = setTimeout(() => {
    callback()
    // 自动清理本次生成的 timer
    const currentTimers = timerIdMap.get(layoutName) || []
    _.remove(currentTimers, t => t === timer)
    timerIdMap.set(layoutName, currentTimers)
    clearTimeout(timer)
  }, delay)
  timerIdMap.set(layoutName, [...currentTimers, timer])
  return timer
}

export const MapEchartsInstance: Map<string, Echarts.ECharts> = new Map()

function getEchartsInstance (name: string) {
  return MapEchartsInstance.get(name)
}

export default {
  // 获取某一个 lw-dynamic 组件实例
  getDynamic (name: string): any {
    const currentLayout = getLayoutNameOutsideComponent()
    const dynamic :Record<string, any> = {}
    eventbus.$emit(`${currentLayout}.${name}.getDynamic`, dynamic)
    return dynamic.instance
    // const dynamic: any = document.getElementById(name)
    // return dynamic?.__vue__
  },
  /**
   * 处理登录之后的逻辑
   * 假如是从某一个页面跳转至登录页，那么在登录之后跳转到该页面，否则跳转到首页
   * */
  async proceedAfterLogin () {
    await Promise.all([LocalModule.getI18nMessageAction(), LocalModule.getThemeAction()])
    const { query } = router.currentRoute
    const { redirect } = query
    if (redirect) {
      // 携带当前参数除 redirect 进行跳转
      delete query.redirect
      // 登录页跳转到其他页面采用 replace 的形式
      router.replace({
        path: `/layout/${redirect}`,
        query
      })
    } else {
      getUserDashboard()
        .then(res => {
          // 这里不使用 pushSwitch 是因为 pushSwitch 依赖 layout 的注册事件
          // 但 layout 事件的注册是在 get-layout 接口之后才进行的
          // 针对于登录页在已登录情况下自动跳转这种情况，发生在 get-layout 之前
          router.replace({ name: 'layout', params: res.data.data, query })
        })
        .catch(_ => {
          window.location.href = '/'
          window.location.reload()
        })
    }
  },
  pushSwitch,

  openLayoutInDialog,

  openLayoutInDrawer,

  openPanelInDialog,

  openPanelInDrawer,

  async closeCurrentLayout (params?: Record<string, any>) {
    const callback: () => void = AppModule.layoutStacks.length === 1
      ? () => router.go(-1)
      : () => 0
    await AppModule.closeTargetPopupLayout({ encodedLayoutName: AppModule.currentLayout, params })
    callback()
  },

  closePanel (name: string) {
    const currentLayout = getLayoutNameOutsideComponent()
    eventbus.$emit(`${currentLayout}.close-panel`, name)
  },

  openComponentInDialog ({ layoutName, params }: RouteParams): void {
    // TODO
  },
  openComponentInDrawer ({ layoutName, params }: RouteParams): void {
    // TODO
  },
  alert,
  confirm,
  prompt,
  message,
  /**
   * 触发某一事件
   * @param {string} eventName 事件名
   * @param {object} params 参数
   * */
  emit (eventName: string, params?: Record<string, any>): void {
    warnDeprecatedApi('logwire.ui.emit', 'DataSet api')
    const currentLayout = getLayoutNameOutsideComponent()
    eventbus.$emit(`${currentLayout}.${eventName}`, params)
  },
  /**
   * 获取国际化词条
   * @param {string} code 目标词条 code
   * @param {string|string[]} placeholder 占位符
   * @param {string} namespace 命名空间
   * @return {string} message code 对应的国际化信息
   * */
  getI18nMessage (code: string, placeholder?: string[]|string, namespace?: string): string {
    const layoutName = getDecodedName(getLayoutNameOutsideComponent())
    // 先从 layout 相关 i18n 词条中寻找
    if (layoutName) {
      const { i18n } = LayoutModule.resource[layoutName]
      // TODO 改成根据语言获取
      const i18nValue = i18n?.[code]
      if (i18nValue) {
        return replaceParams(i18nValue, placeholder)
      }
    }
    // 查找全局的词条
    namespace = namespace || layoutName.split('.')[0]
    let value = getGlobalI18nMessage(namespace, code, placeholder)
    if (value === code && namespace !== 'core') {
      value = getGlobalI18nMessage('core', code, placeholder)
    }
    return value
  },
  getEchartsInstance,
  reGetLoginEntry (params: Record<string, any>) { // 平台内置，非公开
    toLoginLayout(params)
  },
  // Web Socket 相关 API
  addWebSocketOpenListener (listenerName: string, callback: () => void) {
    _addUiWebSocketListener('open', listenerName, callback)
  },
  addWebSocketCloseListener (listenerName: string, callback: () => void) {
    _addUiWebSocketListener('close', listenerName, callback)
  },
  addWebSocketCustomMessageListener (listenerName: string, customType: string, callback: () => void) {
    _addUiWebSocketListener('customMessage', listenerName, callback, customType)
  },
  removeWebSocketOpenListener (listenerName: string) {
    _removeUiWebSocketListener('open', listenerName)
  },
  removeWebSocketCloseListener (listenerName: string) {
    _removeUiWebSocketListener('close', listenerName)
  },
  removeWebSocketCustomMessageListener (listenerName: string) {
    _removeUiWebSocketListener('customMessage', listenerName)
  },
  setTimeout: logwireSetTimeout,
  clearTimeout (id: number) {
    clearTimeout(id)
  },
  showLoading () {
    return Toast.showLoading({ level: LoadingLevel.APP })
  },
  hideLoading (key: number) {
    Toast.hideLoading({ timer: key })
  },
  previewDocument (params: string | string[]) {
    const layoutName = getLayoutNameOutsideComponent()
    const strings = layoutName.split('.')
    const namespace = strings.length > 1 ? strings[0] : 'core'
    const transformUrl = (param: string) => {
      const arr = param.split('?code=')
      if (arr.length === 2) {
        return `/api/open/${namespace}/core.document-preview?name=${arr[0]}&code=${arr[1]}`
      } else if (arr.length > 2) { // 说明name 里有 ?code= 的结构
        const code = arr.pop()
        const name = arr.join('')
        return `/api/open/${namespace}/core.document-preview?name=${name}&code=${code}`
      } else { // 没有 ?code= 的结构，默认认为是传递的 code
        return `/api/open/${namespace}/core.document-preview?name=${param}&code=${param}`
      }
    }
    const payload = params instanceof Array ? params.map(transformUrl) : transformUrl(params)
    eventbus.$emit('preview-file', payload)
  }
}
