
import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import TimestampBase from './TimestampBase'

@Component({
  name: 'TableCellTimestamp'
})
class TableCellTimestamp extends Mixins(TableCellBase, TimestampBase) {}
export default TableCellTimestamp
