











import { Component, Mixins } from 'vue-property-decorator'
import TableCellBase from '../TableCellBase.vue'
import { getCsrfToken } from '@/utils/data'
import { getArchiveStorageAppend } from '@/utils/common'
import eventbus from '@/utils/event'

@Component({
  name: 'TableCellUploadFile'
})
class TableCellUploadFile extends TableCellBase {
  getFileName (value: string) {
    return value.split('|').map(o => {
      return o.replace(/(.*)\?.*?$/, '$1')
    }).join(',')
  }

  handleClickPreviewFile () {
    const namespace = this.context.getNamespace()
    const urls = (this.inputValue as string).split('|').filter(url => url !== '').map(item => {
      const [name, src] = item.split('?code=')
      const url = `/api/open/${namespace}/core.document-preview?name=${name}&code=${src}&__csrf_token=${getCsrfToken()}${getArchiveStorageAppend()}`
      return url
    })
    eventbus.$emit('preview-file', urls)
  }
}
export default TableCellUploadFile
