import { formatDate } from '@/utils/data'
import logwire from '@/logwire'
import store from '@/logwire/store'
import { formatDate as formatDateFn } from 'element-ui/src/utils/date-util'

export interface ITimeStampContentStruct {
  value: any;
}

export const formatTimestamp = (date: string): string => {
  const formatDate = store.getConfig('core.i18n.date-format') || 'yyyy-MM-dd'
  const formatTime = store.getConfig('core.i18n.time-format') === '24-hour'
    ? 'HH:mm:ss.SSS'
    : 'hh:mm:ss.SSS A'
  let format = 'yyyy-MM-dd HH:mm:ss'
  format = formatDate + ' ' + formatTime
  return formatDateFn(date, format)
}

const formatContent = ({ value }: ITimeStampContentStruct) => {
  // 转换为用户首选项中设置的日期格式 + 毫秒
  if (!value) return value
  return formatTimestamp(value)
}

export default {
  formatContent
}
