import { Component, Vue } from 'vue-property-decorator'
import {
  attribute2Strings,
  attribute2StringsWithoutDefault,
  attributeForce2Boolean,
  attributeForce2Number
} from '@/utils/layout'

@Component
class DateBase extends Vue {
  [propName: string]: any;

  get maxScore () { return attributeForce2Number('maxScore', this.component, 5) }

  get lowThreshold () { return attributeForce2Number('lowThreshold', this.component, 2) }

  get highThreshold () { return attributeForce2Number('highThreshold', this.component, 4) }

  get colors () { return attribute2StringsWithoutDefault(this.component.colors) }

  get iconClasses () { return attribute2StringsWithoutDefault(this.component.iconClasses) }

  get allowHalf () { return attributeForce2Boolean('allowHalf', this.component, false) }
}

export default DateBase
